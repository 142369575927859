import {actUpdate, actAdd, actDel} from '../../comm/commRD'

const ACT_KL_CERT_UPDATE_ONLINE_DOC_LIST = 'ACT_KL_CERT_UPDATE_ONLINE_DOC_LIST'

const ACT_KL_CERT_UPDATE_ONLINE_DOC_DETAIL = 'ACT_KL_CERT_UPDATE_ONLINE_DOC_DETAIL'
const ACT_KL_CERT_CLEAR_ONLINE_DOC_DETAIL = 'ACT_KL_CERT_CLEAR_ONLINE_DOC_DETAIL'
const ACT_KL_CERT_UPDATE_UPLOAD_DOC_LIST = 'ACT_KL_CERT_UPDATE_UPLOAD_DOC_LIST'


const KlState = {
    certOnlineDocList: {TotalNum: 0, PageSize: 10, PageNum: 1, RecordList: []}, 
    certOnlineDocDetail: undefined, 
    certUploadDocList: {TotalNum: 0, PageSize: 10, PageNum: 1, RecordList: []}, 
}



const getStateKeyByActionType = (actionType) => {
    switch(actionType) {
        case ACT_KL_CERT_UPDATE_ONLINE_DOC_LIST: 
            return "certOnlineDocList"

        case ACT_KL_CERT_UPDATE_ONLINE_DOC_DETAIL: 
        case ACT_KL_CERT_CLEAR_ONLINE_DOC_DETAIL: 
            return "certOnlineDocDetail"

        case ACT_KL_CERT_UPDATE_UPLOAD_DOC_LIST: 
            return "certUploadDocList"


        default:
            return undefined
    }
}

const getStateOpByActionType = (actionType) => {
    switch(actionType) {
        case ACT_KL_CERT_UPDATE_ONLINE_DOC_LIST: 
        case ACT_KL_CERT_UPDATE_ONLINE_DOC_DETAIL:
        case ACT_KL_CERT_UPDATE_UPLOAD_DOC_LIST:
            return "update"


        case ACT_KL_CERT_CLEAR_ONLINE_DOC_DETAIL:
            return "clear"

        default:
            return "other"
    }
}

export const kl_reducer = (state=KlState, action) => {
    let op = getStateOpByActionType(action.type)
    switch(op) {
        case "update":
        {
            let retState = Object.assign({}, state);
            let key = getStateKeyByActionType(action.type)
            retState[key] = action.data;
            return retState;
        }

        case "clear":
        {
            let retState = Object.assign({}, state);
            let key = getStateKeyByActionType(action.type)
            retState[key] = undefined;
            return retState;
        }
        
        // 其他不定action处理
        // case "other":
        // {
        //     switch(action.type) {
        //         case ACT_CERT_UPDATE_TEST_CASE_DETAIL:
        //         {
        //             return retState
        //         }
                

        //     }
        // }

        default:
            return state
    }

}

// certOnlineDocList
export const getCertOnlineDocList = (state) => {
    if (state.kl.certOnlineDocList !== undefined){
        return state.kl.certOnlineDocList;
    }
    return undefined;
}

export const getCertOnlineDocListSuccessHd = (dispatch, rspBody, msgBody) => {
    let data = {
        TotalNum: rspBody.TotalNum, 
        RecordList: rspBody.RecordList, 
        PageSize: msgBody.PageSize, 
        PageNum: msgBody.PageNum, 
    }
    if (!rspBody.RecordList) {
        data.RecordList = []
    }
    dispatch(actUpdate(data, ACT_KL_CERT_UPDATE_ONLINE_DOC_LIST));
}

// certOnlineDocDetail
export const getCertOnlineDocDetail = (state) => {
    if (state.kl.certOnlineDocDetail !== undefined){
        return state.kl.certOnlineDocDetail
    }
    return undefined;
}

export const getCertOnlineDocDetailSuccessHd = (dispatch, rspBody, msgBody) => {
    let data = rspBody.Record
    dispatch(actUpdate(data, ACT_KL_CERT_UPDATE_ONLINE_DOC_DETAIL));
}

export const clearCertOnlineDocDetail = () => {
    return {
        type: ACT_KL_CERT_CLEAR_ONLINE_DOC_DETAIL, 
    }
};

// certUploadDocList
export const getCertUploadDocList = (state) => {
    if (state.kl.certUploadDocList !== undefined){
        return state.kl.certUploadDocList;
    }
    return undefined;
}

export const getCertUploadDocListSuccessHd = (dispatch, rspBody, msgBody) => {
    let data = {
        TotalNum: rspBody.TotalNum, 
        RecordList: rspBody.RecordList, 
        PageSize: msgBody.PageSize, 
        PageNum: msgBody.PageNum, 
    }
    if (!rspBody.RecordList) {
        data.RecordList = []
    }
    dispatch(actUpdate(data, ACT_KL_CERT_UPDATE_UPLOAD_DOC_LIST));
}







