import React, { Component } from 'react';

import {Card, Row, Col, Button, Popconfirm, Space, Modal, Descriptions, Select, Collapse, Typography, Steps, Tabs, Upload, Divider, 
    Tag, Form, Input } from 'antd'
import {SyncOutlined, PlusOutlined, UploadOutlined, CheckOutlined, RollbackOutlined, EditOutlined, CloudUploadOutlined} from '@ant-design/icons'

import {HoBreadcrumb} from '../../component/element'
import {HoBuildTableData, HyMessage} from '../../component/utils'
import {HoTable, HoMkTblColumn, HoMkTblColActions} from '../../component/tbl'

import {cert_scopes, c_apiUrlBase} from '../../comm/config'
import {orderStateMap, findItemByIdFromList} from '../../comm/logic'
import {OrderDetailCard, ApproveForm, getInitApproveInfo, getApproveInfo, approveIsDone, getLoginNickName, getOrderCertification, getResByType} from './utils'
import {ApprovePanel} from './approvePanel'
import {MarginDefault, HqCompany, HqAddress, HqTel, OrderApproveTypePayment, OrderApproveTypeResUpload, OrderApproveTypeSample, 
    OrderApproveTypeConfirm, OrderApproveTypeMntnReport, OrderApproveTypeShowReport} from '../../comm/const'

const { Panel } = Collapse; 
const { Title, Paragraph, Text, Link } = Typography;
const {Step} = Steps
const { TabPane } = Tabs


export class OrderDetailPage extends Component {
    constructor(props){
        super(props)

        this.mkOpButtons = this.mkOpButtons.bind(this)
        this.mkSteps = this.mkSteps.bind(this)
        this.getToken = this.getToken.bind(this)
        this.mkCertTab = this.mkCertTab.bind(this)
        this.uploadOnChangeHd = this.uploadOnChangeHd.bind(this)
        this.getResUploadList = this.getResUploadList.bind(this)
        this.certReEditHd = this.certReEditHd.bind(this)
        this.certPublishHd = this.certPublishHd.bind(this)
        this.receiptCommitHd = this.receiptCommitHd.bind(this)
        this.receiptReEditHd = this.receiptReEditHd.bind(this)
        this.mkBusinessPanel = this.mkBusinessPanel.bind(this)
        this.mkTestReportTab = this.mkTestReportTab.bind(this)
        this.mkReceiptTab = this.mkReceiptTab.bind(this)
        this.submitHd = this.submitHd.bind(this)
        

        this.state = {
            activeKey: undefined, 
            resUpload: {}, 
            certEdit: false, 
            testReportEdit: false, 
            receiptEdit: false, 
            showModel: false, 
        }

        this._breadConf = [
            {name: "订单管理", link: '/operate/orderList'}, 
            {name: "订单列表", link: '/operate/orderList'}, 
            {name: "订单详情", link: undefined}, 
        ]

        this._receiptForm = undefined
    }

    
    mkOpButtons() {
        let {refreshHd, orderDetail, doneOnClickHd, undoneOnClickHd} = this.props
        let disabled = true
        let doneButton = undefined
        if (orderDetail) {
            if (orderDetail.OrderBase.State === "waitPay") {
                disabled = false
            }

            if (orderDetail.OrderBase.State === "certed") {
                doneButton = (
                    <Button type="primary" icon={<CheckOutlined />} onClick={doneOnClickHd}>订单完成</Button>
                )
            }else if (orderDetail.OrderBase.State === "finished") {
                doneButton = (
                    <Button type="primary" icon={<RollbackOutlined />} onClick={undoneOnClickHd}>订单回退</Button>
                )
            }
        }
        return (
            <Row>
                <Space>
                    <Button type="primary" icon={<RollbackOutlined />} onClick={undefined} disabled={disabled}>退回到草稿</Button>
                    {doneButton}
                    <Button type="default" icon={<SyncOutlined />} onClick={refreshHd}>刷新</Button>
                </Space>
            </Row>
        )
    }

    mkSteps() {
        let {orderDetail} = this.props
        if (!orderDetail) {
            return undefined
        }
        let approveTime = undefined
        let {OrderBase} = orderDetail
        let watiApproveHint = undefined
        let reportConfirmTime = undefined

        let curStep = 1
        if (OrderBase.State === 'waitApprove') {
            curStep = 1
            watiApproveHint = "待审核"
        }else if(OrderBase.State === 'approved' || OrderBase.State === 'reportDraftPublish') {
            curStep = 2
        } else if (OrderBase.State === 'reportFirstConfirm' || OrderBase.State === 'reportPublish') {
            curStep = 3
        } else if (OrderBase.State === 'certed') {
            curStep = 4
        } else if (OrderBase.State === 'finished') {
            curStep = 5
        }

        if (curStep >= 2) {
            const tmpInfo = getApproveInfo(orderDetail, OrderApproveTypeConfirm)
            if (tmpInfo) {
                approveTime = tmpInfo.OpTime
            }

            const tmpReport = getApproveInfo(orderDetail, OrderApproveTypeShowReport)
            if (tmpReport) {
                if (tmpReport.ApproveResult === 1) {
                    reportConfirmTime = tmpReport.OpTime
                }
            }
        }

        return (
            <Row style={{marginBottom: "32px"}}>
                <Steps current={curStep}>
                    <Step title="提交订单" description={orderDetail.OrderBase.CommitTime} />
                    <Step title="付款/提交资料/寄样" subTitle="" description={watiApproveHint} />
                    <Step title="订单受理(检测认证中)" description={approveTime} />
                    <Step title="检测报告确认" description={reportConfirmTime} />
                    <Step title="查收证书" description="" />
                    <Step title="完成" description="" />
                </Steps>

            </Row>
        )
    }


    getToken() {
        let {tokenInfo} = this.props
        if (!tokenInfo) {
            return undefined
        }

        return tokenInfo.token
    }

    getPayMentInfo(orderDetail) {
        if (!orderDetail) {
            return undefined
        }

        if (!orderDetail.PaymentInfo) {
            return undefined
        }

        if (orderDetail.PaymentInfo.Id === 0) {
            return undefined
        }

        return orderDetail.PaymentInfo
    }

    getOrderCode(orderDetail) {
        if (!orderDetail) {
            return undefined
        }
        return orderDetail.OrderBase.OrderCode
    }

    getOrderId(orderDetail) {
        if (!orderDetail) {
            return undefined
        }
        return orderDetail.OrderBase.Id
    }

    getResUploadList(resType) {
        let {resUpload} = this.state
        let {orderDetail} = this.props

        if (!orderDetail) {
            return undefined
        }

        let uploadList = []
        if (resUpload[resType]) {
            uploadList.push(resUpload[resType])
        } else {
            if (orderDetail.ProductResList) {
                let find = findItemByIdFromList(resType, "ResouceName", orderDetail.ProductResList)
                if (find) {
                    let item = find.item
                    uploadList.push({uid: "1", name: item.ResouceFileName, url: item.ResouceUrl, status: "done"})
                }
            }
        }
        
        return uploadList;
    }

    mkBusinessPanel() {
        let {orderDetail, tokenInfo, orderApproveHd, modelSetHd} = this.props

        return (
            <Tabs tabPosition="left" size="small" type="line">
                <TabPane tab="订单审核" key="1">
                    <ApprovePanel 
                    orderDetail={orderDetail}
                    tokenInfo={tokenInfo}
                    orderApproveHd={orderApproveHd}
                    modelSetHd={modelSetHd}
                    />
                </TabPane>

                <TabPane tab="检测报告" key="2">
                    {this.mkTestReportTab()}
                </TabPane>

                <TabPane tab="证书" key="3">
                    {this.mkCertTab()}
                </TabPane>

                <TabPane tab="开票信息" key="4">
                    {this.mkReceiptTab()}
                </TabPane>

            </Tabs>
        )


    }

    getUploadUrl() {
        return c_apiUrlBase + "/hq/v1/mntn/cert/order/resource/upload"
    }

    getResUploadList(resType) {
        let {resUpload} = this.state
        let {orderDetail} = this.props

        if (!orderDetail) {
            return undefined
        }

        let uploadList = []
        if (resUpload[resType]) {
            uploadList.push(resUpload[resType])
        } else {
            if (orderDetail.ProductResList) {
                let find = findItemByIdFromList(resType, "ResouceName", orderDetail.ProductResList)
                if (find) {
                    let item = find.item
                    uploadList.push({uid: "1", name: item.ResouceFileName, url: item.ResouceUrl, status: "done"})
                }
            }
        }
        
        return uploadList;
    }

    uploadOnChangeHd(key, param) {
        console.log("#######: key = , param = ", key, param)
        
        let newState = Object.assign({}, this.state)
        let {file} = param

        if (file.status === "done") {
            HyMessage("success", `${file.name} 上传成功.`)
            newState.resUpload[key]= {uid: file.uid, name: file.name, status: "done", url: file.response.Url}
        }else if (file.status === "error") {
            HyMessage("error", `${file.name} 上传失败.`)
            newState.resUpload[key]= {uid: file.uid, name: file.name, status: "error", url: undefined}
        }else if (file.status === "uploading") {
            newState.resUpload[key]= {uid: file.uid, name: file.name, status: "uploading", url: undefined}
        }

        this.setState(newState)

    }

    certReEditHd(certType) {
        let newState = Object.assign({}, this.state)
        if (certType === "testReport") {
            newState.testReportEdit = true
        }else if (certType === "cert") {
            newState.certEdit = true
        }
        this.setState(newState)
    }

    getCertButtonStatus(orderDetail, certType, editFlag) {
        if (!orderDetail) {
            return false
        }

        if (editFlag) {
            return false
        }

        const resInfo = getResByType(orderDetail, certType)
        if (!resInfo) {
            return false
        }

        if (resInfo.published === 1) {
            return true
        }
        return false
    }

    certPublishHd(certType) {
        let {certPublishHd} = this.props
        let newState = Object.assign({}, this.state)

        if (certPublishHd) {
            certPublishHd(certType)
        }

        if (certType === "testReport") {
            newState.testReportEdit = false
        }else if (certType === "cert") {
            newState.certEdit = false
        }
        this.setState(newState)
    }

    getApproveResutlMap(result) {
        if (result === 1) {
            return "通过"
        }
        return "不通过"
    }

    mkTestReportTab() {
        let {orderDetail, tokenInfo, orderApproveHd} = this.props
        let {testReportEdit} = this.state
        let uploadButtonHint = "点击上传检测报告(支持拖拽)"
        
        const dipUploadArea = (
            <div>
                <p className="ant-upload-drag-icon"> <CloudUploadOutlined /> </p>
                <p>{uploadButtonHint}</p>
            </div>
        )

        let mntnApprove = getInitApproveInfo()
        const tmpInfo = getApproveInfo(orderDetail, OrderApproveTypeMntnReport)
        if (tmpInfo) {
            mntnApprove = tmpInfo
        }else{
            mntnApprove.NickName = getLoginNickName(tokenInfo)
        }

        let disApproveInfo = undefined
        let done = false
        const approveInfo = getApproveInfo(orderDetail, OrderApproveTypeShowReport)
        if (approveInfo) {
            disApproveInfo = (
                <Descriptions title="客户确认信息" column={1} >
                    <Descriptions.Item label="确认结果">{this.getApproveResutlMap(approveInfo.ApproveResult)}</Descriptions.Item>
                    <Descriptions.Item label="确认时间">{approveInfo.OpTime}</Descriptions.Item>
                    <Descriptions.Item label="建议">{approveInfo.ApproveResult === 1 ? "" : approveInfo.Remark}</Descriptions.Item>
                </Descriptions>
                )
            done = true
        }else{
            disApproveInfo = (<Descriptions title="客户确认信息" column={1} ></Descriptions>)
        }

        // 发布按钮
        const pulishHint = (
        <Text type="secondary">请在客户已经确认ok的情况下，再点击发布按钮</Text>
        )
        let buttonDisabled = this.getCertButtonStatus(orderDetail, "testReport", testReportEdit)
        let disPulishButton = undefined
        if (buttonDisabled) {
            disPulishButton = (
                <div >
                    {pulishHint}
                    <Row style={{marginTop: "16px"}}>
                        <Button icon={<CheckOutlined />} type="primary" onClick={e => this.certPublishHd("testReport")} disabled={buttonDisabled}>发布</Button>
                        <Button icon={<EditOutlined />} type="primary" onClick={e => this.certReEditHd("testReport")} style={{marginLeft: MarginDefault}}>重新编辑</Button>
                    </Row>
                </div>
            )
        }else{
            disPulishButton = (
                <div >
                {pulishHint}
                <Row style={{marginTop: "16px"}}>
                    <Button icon={<CheckOutlined />} type="primary" onClick={e => this.certPublishHd("testReport")} disabled={buttonDisabled}>发布</Button>
                </Row>
                </div>
            )
        }

        return (
            <div style={{marginTop: MarginDefault, marginBottom: MarginDefault, width: "640px"}}>
                <Row >
                    <Upload.Dragger name={"uploadFile"} 
                    action={this.getUploadUrl()} 
                    data={{OrderId: this.getOrderId(orderDetail), ResName: "testReport"}} 
                    onChange={param => this.uploadOnChangeHd('testReport', param)}
                    fileList={this.getResUploadList("testReport")}
                    headers={{Token: this.getToken()}}
                    showUploadList={{showDownloadIcon: true, showRemoveIcon: false}}
                    style={{width: "640px"}}
                    >
                        {dipUploadArea}
                    </Upload.Dragger>
                </Row>

                <div style={{marginTop: "48px"}}/>
                
                <ApproveForm 
                approveResult={mntnApprove.ApproveResult}
                approveRemark={mntnApprove.Remark}
                approveUserName={mntnApprove.NickName}
                approveType={OrderApproveTypeMntnReport}
                approveSubmitHd={orderApproveHd}
                approveTime={mntnApprove.OpTime}
                approved={mntnApprove.Approved}
                currentLoginNickName={getLoginNickName(tokenInfo)}
                done={done}
                type="test"
                />

                <Divider />

                {disApproveInfo}

                <Divider />
                {disPulishButton}

            </div>
        )
    }

    mkReceiptTab() {
        let {orderDetail} = this.props
        let {receiptEdit} = this.state

        let record = undefined
        if (orderDetail) {
            if (orderDetail.ReceiptInfo) {
                if (orderDetail.ReceiptInfo.Id != 0) {
                    record = orderDetail.ReceiptInfo
                }
            }
        }

        let formDisabled = false
        if (record) {
            if (!receiptEdit) {
                formDisabled = true
            }
        }

        let disButtons = undefined
        if (formDisabled) {
            disButtons = (
                <Space size="middle" >
                    <Button type="primary" onClick={undefined} disabled={formDisabled} htmlType="submit">提交</Button>
                    <Button icon={<EditOutlined />} type="primary" onClick={this.receiptReEditHd} htmlType="submit">重新编辑</Button>
                </Space>
            )
        }else{
            disButtons = (
                <Space size="middle" >
                    <Button type="primary" onClick={undefined} disabled={formDisabled} htmlType="submit">提交</Button>
                </Space>
            )
        }


        return (
            <div style={{width: "640px", marginTop: "16px"}}>
                <Form onFinish={(values) => this.receiptCommitHd(values)} 
                    labelCol={{span: 4}} wrapperCol={{span: 20}}
                    ref={node => this._postForm = node}
                    initialValues={
                        {
                            ExpressCompany: record ? record.ExpressCompany : undefined,  
                            ExpressNum: record ? record.ExpressNum : undefined,  
                            Sender: record ? record.Sender : undefined, 
                            SenderTel: record ? record.SenderTel : undefined, 
                            Remark: record ? record.Remark : undefined, 
                        }
                    }
                    > 
                        <Form.Item rules={[{required: true, message: '请填写快递公司!'}]} label="快递公司" name="ExpressCompany">
                            <Input placeholder="" disabled={formDisabled}  /> 
                        </Form.Item>

                        <Form.Item rules={[{required: true, message: '请填写快递单号!'}]} label="快递单号" name="ExpressNum">
                            <Input placeholder="" disabled={formDisabled} />
                        </Form.Item>

                        <Form.Item label="寄件人" name="Sender">
                            <Input placeholder="" disabled={formDisabled} />
                        </Form.Item>

                        <Form.Item label="联系电话" name="SenderTel">
                            <Input placeholder="" disabled={formDisabled} />
                        </Form.Item>

                        <Form.Item label="备注" name="Remark">
                            <Input placeholder="" disabled={formDisabled} />
                        </Form.Item>

                        <Row justify="" style={{marginBottom: "16px"}}>
                            {disButtons}
                        </Row>
                    </Form>


            </div>
        )
    }

    getCertificationStatus(orderDetail, certEdit) {
        const certification = getOrderCertification(orderDetail)
        if (!certification) {
            return false
        }

        if (certEdit) {
            return false
        }

        return true
    }
    
    mkCertTab() {
        let {orderDetail} = this.props
        if (!orderDetail) {
            return undefined
        }


        let {certEdit} = this.state
        let buttonDisabled = this.getCertificationStatus(orderDetail, certEdit)

        const certification = getOrderCertification(orderDetail)
        let model = orderDetail.OrderBase.Model
        let certName = ""
        if (orderDetail.CertList.length > 0) {
            certName = orderDetail.CertList[0].CertName
        }
        let objName = orderDetail.OrderBase.ObjName

        if (certification) {
            model = certification.Model
            certName = certification.CertName
            objName = certification.ObjName
        }

        const dipUploadArea = (
            <div>
                <p className="ant-upload-drag-icon"> <CloudUploadOutlined /> </p>
                <p>点击上传证书(支持拖拽)</p>
            </div>
        )

        let disReCommit = undefined
        if (buttonDisabled) {
            disReCommit = (<Button icon={<EditOutlined />} type="primary" onClick={e => this.certReEditHd("cert")} style={{marginLeft: MarginDefault}}>重新编辑</Button>)
        }

        return (
            <div style={{marginTop: MarginDefault, marginBottom: MarginDefault, maxWidth: "640px"}}>
                <Row>
                    <Upload.Dragger name={"uploadFile"} 
                    action={this.getUploadUrl()} 
                    data={{OrderId: this.getOrderId(orderDetail), ResName: "cert"}} 
                    onChange={param => this.uploadOnChangeHd('cert', param)}
                    fileList={this.getResUploadList("cert")}
                    headers={{Token: this.getToken()}}
                    showUploadList={{showDownloadIcon: true, showRemoveIcon: false}}
                    style={{width: "640px"}}
                    >
                        {dipUploadArea}
                    </Upload.Dragger>
                </Row>

                <div style={{marginBottom: "48px"}}></div>

                {/* <Divider /> */}
                <Row justify="center" ><Title level={5}>证书信息</Title></Row>
                <Form onFinish={this.submitHd}
                // ref={node => this._approveForm = node}
                style={{maxWidth: "640px"}}
                labelCol={{span: 3}}
                initialValues={{
                    CertificationCode: certification ? certification.CertificationCode : undefined, 
                    ApplyCode: certification ? certification.ApplyCode : undefined, 
                    Applyer: certification ? certification.Applyer : undefined, 
                    Manufacturer: certification ? certification.Manufacturer : undefined, 
                    Factory: certification ? certification.Factory : undefined, 
                    CertName: certName, 
                    ObjName: objName, 
                    Model: model, 
                    PublishDate: certification ? certification.PublishDate : undefined, 
                    ExpireDate: certification ? certification.ExpireDate : undefined, 
                }}
                
                >
                <Form.Item name="CertificationCode" 
                    label="证书编号"
                    rules={[{required: true, message: '请输入证书编号'}]}
                    >
                    <Input disabled={buttonDisabled}/>
                </Form.Item>

                <Form.Item name="ApplyCode" 
                    label="申请编号"
                    rules={[{required: true, message: '请输入申请编号'}]}
                    >
                    <Input disabled={buttonDisabled}/>
                </Form.Item>

                <Form.Item name="Applyer" 
                    label="申请人"
                    >
                    <Input disabled={buttonDisabled}/>
                </Form.Item>

                <Form.Item name="Manufacturer" 
                    label="发证机构"
                    rules={[{required: true, message: '请输入发证机构'}]}
                    >
                    <Input disabled={buttonDisabled}/>
                </Form.Item>

                <Form.Item name="Factory" 
                    label="生产商"
                    >
                    <Input disabled={buttonDisabled}/>
                </Form.Item>

                <Form.Item name="CertName" 
                    label="认证名称"
                    >
                    <Input disabled={buttonDisabled}/>
                </Form.Item>

                <Form.Item name="ObjName" 
                    label="产品名称"
                    >
                    <Input disabled={buttonDisabled}/>
                </Form.Item>

                <Form.Item name="Model" 
                    label="产品型号"
                    >
                    <Input disabled={buttonDisabled}/>
                </Form.Item>

                <Form.Item name="PublishDate" 
                    label="发布日期"
                    rules={[{required: true, message: '请输入发布日期'}]}
                    >
                    <Input disabled={buttonDisabled}/>
                </Form.Item>

                <Form.Item name="ExpireDate" 
                    label="有效期限"
                    >
                    <Input disabled={buttonDisabled}/>
                </Form.Item>
                
                <Form.Item >
                    <Button type="primary" htmlType="submit" loading={undefined} style={{marginLeft: "0px"}} disabled={buttonDisabled}>
                        提交
                    </Button>
                    {disReCommit}
                </Form.Item>
            </Form>

            </div>
        )

    }

    submitHd(values) {
        let {orderDetail, certificationCommitHd} = this.props

        // 先检查证书是否上传
        let uploadCert = getResByType(orderDetail, "cert")
        if (!uploadCert) {
            HyMessage("error", "请先上传证书文件后再提交！")
            return
        }

        if (certificationCommitHd) {
            certificationCommitHd(values)
        }

        let newState = Object.assign({}, this.state)
        newState.certEdit = false
        this.setState(newState)
    }


    receiptCommitHd(values) {
        let {receiptCommitHd} = this.props
        let newState = Object.assign({}, this.state)
        if (receiptCommitHd) {
            receiptCommitHd(values)
        }
        newState.receiptEdit = false
        this.setState(newState)
    }

    receiptReEditHd() {
        let newState = Object.assign({}, this.state)
        newState.receiptEdit = true
        this.setState(newState)
    }

    
    render() {
        let {loading, orderDetail, objDetail} = this.props     
        const disStep = this.mkSteps()
        const businessPanel = this.mkBusinessPanel()

        let defuatlActiveKeys = [1]
        
        return (
            <div>
                <HoBreadcrumb itemList={this._breadConf} extra={{style:{marginBottom: '8px'}}} />
                <Card title="订单详情" extra={this.mkOpButtons()}>
                    {disStep}
                <Collapse collapsible="" defaultActiveKey={defuatlActiveKeys}>
                    <Panel header={<Typography.Text strong >业务处理</Typography.Text>} key="1">
                        {businessPanel}
                    </Panel>

                    <Panel header={<Typography.Text strong >订单信息</Typography.Text>} key="2">
                        <OrderDetailCard 
                        orderDetail={orderDetail}
                        objDetail={objDetail}
                        />
                    </Panel>

                </Collapse>

                    
                </Card>
            </div>
        );
    }

}

