import React, { Component } from 'react'
import { connect } from 'react-redux'
import {getNetRequestStatus, netRequestThunk, actNetRequestClear} from '../../network/netReqRD'
import {getTestsuiList, getTestsuitListSuccessHd} from './testsuitRD'
import {getLoginTokenInfo} from '../account/loginRD'
import {TestsuitListPage} from './testsuitList'
import {HoBuildTableData} from '../../component/utils'
import {HoMkSearchFilters} from '../../component/tbl'

class TestsuitListCT extends Component {
    constructor(props){
        super(props)
        this.pageChangeHd = this.pageChangeHd.bind(this)
        this.tokenCheckHd = this.tokenCheckHd.bind(this)
        this.refreshHd = this.refreshHd.bind(this)
        this.searchHd = this.searchHd.bind(this)
        this.requestData = this.requestData.bind(this)
        this.deleteOnClickHd = this.deleteOnClickHd.bind(this)
        this.delConfirmHd = this.delConfirmHd.bind(this)
        this.getLoadingFlag = this.getLoadingFlag.bind(this)
        this.addConfirmHd = this.addConfirmHd.bind(this)
        this.editConfirmHd = this.editConfirmHd.bind(this)
        this.rowOnClickHd = this.rowOnClickHd.bind(this)
        this.detailOnClickHd = this.detailOnClickHd.bind(this)


        this._pagination = {
            PageSize: 10, 
            PageNum: 1, 
            Filters: [[]], 
            OrderKey: "", 
            Desc: 1, 
        }

        this.state = {
            PageSize: this._pagination.PageSize, 
            PageNum: this._pagination.PageNum, 
        }

        this._delRecord = undefined

    }

    tokenCheckHd() {
        let {tokenInfo, history} = this.props
        if (tokenInfo === undefined) {
            const directPath = '/'
            history.push(directPath)
            return false
        }
        return true
    }

    requestData() {
        if (!this.tokenCheckHd()) {
            return
        }
        let {PageSize, PageNum, Filters, OrderKey, Desc} = this._pagination
        let {dispatch, tokenInfo} = this.props
        let msgBody = {
            PageSize: PageSize, 
            PageNum: PageNum,
            Filters: Filters, 
            OrderKey: OrderKey, 
            Desc: Desc
        }
        dispatch(netRequestThunk("/hq/v1/testsuit/mntn/suit/list", msgBody, "getTestsuitList", getTestsuitListSuccessHd, tokenInfo.token))
    }

    componentWillUnmount(){
        let {dispatch} = this.props
        dispatch(actNetRequestClear("getTestsuitList"))
        dispatch(actNetRequestClear("addTestsuit"))
        dispatch(actNetRequestClear("modTestsuit"))
        dispatch(actNetRequestClear("delTestsuit"))
    }

    componentDidMount() {
        this.requestData()
    }

    refreshHd() {
        this.requestData()
    }

    pageChangeHd(current, size) {
        if (current !== this._pagination.PageNum || size !== this._pagination.PageSize) {
            this._pagination.PageNum = current
            this._pagination.PageSize = size
            // 需要重新请求数据
            this.requestData()
            // set state
            let newState = Object.assign({}, this.state)
            newState.PageNum = current
            newState.PageSize = size
            this.setState(newState)
        }
    }

    searchHd(searchInfo) {
        let filters = HoMkSearchFilters(searchInfo)
        this._pagination.Filters = filters
        this._pagination.PageNum = 1

        // 这里进行网络请求
        this.requestData()
    }

    deleteOnClickHd(key, record) {
        this._delRecord = record
    }

    delConfirmHd(e, type) {
        e.stopPropagation();

        if (type === 'confirm') {
            if (this._delRecord !== undefined) {
                if (!this.tokenCheckHd()) {
                    this._delRecord = undefined  
                    return
                }

                let {dispatch, tokenInfo} = this.props
                let msgBody = {
                    Id: this._delRecord.Id, 
                }
                dispatch(netRequestThunk("/hq/v1/testsuit/mntn/suit/del", msgBody, "delTestsuit", undefined, tokenInfo.token))
            }
        }

        this._delRecord = undefined        
    }


    getLoadingFlag() {
        let {reqStatus, addStatus, modStatus, delStatus, dispatch} = this.props

        let isLoading = false
        if (reqStatus === 1 || addStatus === 1 || modStatus === 1 || delStatus === 1) {
            isLoading = true
        } 

        if (addStatus === 2) {
            dispatch(actNetRequestClear("addTestsuit"))
            this.requestData()
            return isLoading
        }

        if (modStatus === 2) {
            dispatch(actNetRequestClear("modTestsuit"))
            this.requestData()
            return isLoading
        }

        if (delStatus === 2) {
            dispatch(actNetRequestClear("delTestsuit"))
            this.requestData()
            return isLoading
        }

        return isLoading
    }

    editConfirmHd(record) {
        if (!this.tokenCheckHd()) {
            return
        }

        console.log("@@@@_1: record = ", record)

        let {dispatch, tokenInfo} = this.props
        let msgBody = {
            Id: record.Id,
            Name: record.Name, 
            Description: record.Description, 
            LimitDesc: record.LimitDesc, 
            FrontShow: record.FrontShow, 
        }

        dispatch(netRequestThunk("/hq/v1/testsuit/mntn/suit/mod", msgBody, "modTestsuit", undefined, tokenInfo.token))
    }

    addConfirmHd(record) {
        if (!this.tokenCheckHd()) {
            return
        }

        console.log("@@@@_2: record = ", record)
        

        let {dispatch, tokenInfo} = this.props
        let msgBody = {
            Name: record.Name, 
            Description: record.Description, 
            LimitDesc: record.LimitDesc, 
            FrontShow: record.FrontShow, 
        }

        dispatch(netRequestThunk("/hq/v1/testsuit/mntn/suit/add", msgBody, "addTestsuit", undefined, tokenInfo.token))
    }

    rowOnClickHd(record) {
        console.log("@@@@@@: record = ", record)
        let {history} = this.props
        if (history) {
            history.push("/setting/testsuit/detail/" + record.Id)
        }
    }

    detailOnClickHd(key, record) {
        let {history} = this.props
        if (history) {
            history.push("/setting/testsuit/detail/" + record.Id)
        }
    }


    render(){
        let {dataInfo, selectFlag, selectedHd} = this.props
        let {PageNum, PageSize} = this.state
        let totalNum = 0
        let dataList = undefined
        if (dataInfo !== undefined) {
            totalNum = dataInfo.TotalNum
            dataList = HoBuildTableData(dataInfo.RecordList)
        }

        let isLoading = this.getLoadingFlag()
        
        return (
            <TestsuitListPage 
            loading={isLoading}
            totalNum={totalNum}
            dataList={dataList}
            pageChangeHd={this.pageChangeHd}
            searchHd={this.searchHd}
            rowOnClickHd={this.rowOnClickHd}
            detailOnClickHd={this.detailOnClickHd}
            deleteOnClickHd={this.deleteOnClickHd}
            delConfirmHd={this.delConfirmHd}
            addConfirmHd={this.addConfirmHd}
            editConfirmHd={this.editConfirmHd}
            />

        );
    }
}

const mapState = (state) => ({
    dataInfo: getTestsuiList(state), 
    tokenInfo: getLoginTokenInfo(state), 
    reqStatus: getNetRequestStatus(state, 'getTestsuitList'), 
    addStatus: getNetRequestStatus(state, 'addTestsuit'), 
    modStatus: getNetRequestStatus(state, 'modTestsuit'), 
    delStatus: getNetRequestStatus(state, 'delTestsuit'), 
});

export default connect(
    mapState, 
    null
)(TestsuitListCT);


