import React, { Component } from 'react';

import {Card, Row, Col, Button, Popconfirm, Space, Modal, Descriptions, Select, Collapse, Typography, Steps, Tabs, Upload, Divider, 
    Tag, Form, Input, Spin } from 'antd'
import {SyncOutlined, PlusOutlined, UploadOutlined, CheckOutlined, SaveOutlined, EditOutlined, EyeOutlined} from '@ant-design/icons'

import {HoBreadcrumb, HoDocPreview} from '../../../component/element'
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'


export class OnlineDocDetailPage extends Component {
    constructor(props){
        super(props)

        this.mkOpButtons = this.mkOpButtons.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.previewOnclickHd = this.previewOnclickHd.bind(this)
        this.modalCancelHd = this.modalCancelHd.bind(this)
        this.mkPreviewModal = this.mkPreviewModal.bind(this)
        this.inputChangeHd = this.inputChangeHd.bind(this)
        this.saveHd = this.saveHd.bind(this)
        this.selectChangeHd = this.selectChangeHd.bind(this)
        this.detailInit = this.detailInit.bind(this)

        
        

        this._breadConf = [
            {name: "知识库管理", link: '/setting/klCertificationOnlineList'}, 
            {name: "认证知识（在线）列表", link: '/setting/klCertificationOnlineList'}, 
            {name: "文档详情", link: undefined}, 
        ]

        let {detailInfo} = props
        this.state = {
            showPreview: false, 
            previewData: undefined, 
            rawData: undefined, 
            editorState: detailInfo ?  BraftEditor.createEditorState(detailInfo.RawDoc) : BraftEditor.createEditorState(null), 
            docTitle: detailInfo ? detailInfo.Name : "", 
            docDesc: detailInfo ? detailInfo.Description : "", 
            frontShow: detailInfo ? detailInfo.FrontShow : 0, 
        }

        this._detailInit = false

        this._form = undefined
    }

    
    mkOpButtons() {
        let {edit, editHd} = this.props
        let disEditButton = undefined
        if (edit) {
            disEditButton = (<Button type="primary" icon={<SaveOutlined />} onClick={this.saveHd}>保存</Button> )
        }else{
            disEditButton = (<Button type="default" icon={<EditOutlined />} onClick={editHd}>编辑</Button>)
        }
        
        return (
            <Row>
                <Space>
                    {disEditButton}
                    <Button type="primary" icon={<EyeOutlined />} onClick={this.previewOnclickHd} >预览</Button>
                </Space>
            </Row>
        )
    }

    saveHd() {
        let {saveHd} = this.props
        let {editorState} = this.state


        if (saveHd && editorState) {
            const rawData = editorState.toRAW()
            let docTitle = ""
            let docDesc = ""
            let frontShow = 0
            if (this._form) {
                docTitle = this._form.getFieldValue("Name")
                docDesc = this._form.getFieldValue("Description")
                frontShow = this._form.getFieldValue("FrontShow")
            }

            saveHd(docTitle, docDesc, frontShow, rawData)
        }
    }

    previewOnclickHd() {
        let {editorState} = this.state
        let newState = Object.assign({}, this.state)
        newState.showPreview = true
        let rawData = undefined
        if (editorState) {
            rawData = editorState.toRAW()
        }
        newState.previewData = rawData
        this.setState(newState)
    }

    modalCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.showPreview = false
        newState.previewData = undefined
        this.setState(newState)
    }

    
    mkPreviewModal() {
        let {showPreview, previewData} = this.state
        return (
            <Modal 
                title="文档预览"
                width={960}
                visible={showPreview}
                onOk={this.modalCancelHd}
                onCancel={this.modalCancelHd}
                okText="确定"
                footer={null}
                >
                    <HoDocPreview rawData={previewData}/>
            </Modal>
        )
    }

    handleChange(editorState) {
        let newState = Object.assign({}, this.state)
        newState.editorState = editorState
        this.setState(newState)
    } 

    inputChangeHd(tag, e) {
        let value = e.target.value
        if (tag === "title") {
            this._docTitle = value
        }else if (tag === "desc") {
            this._docDesc = value
        }
    }

    selectChangeHd(value) {
        let newState = Object.assign({}, this.state)
        newState.frontShow = value
        this.setState(newState)
    }

    detailInit() {
        let {detailInfo} = this.props
        

        if (this._detailInit) {
            return
        }
        
        if (detailInfo) {
            let newState = Object.assign({}, this.state)
            newState.editorState = BraftEditor.createEditorState(detailInfo.RawDoc)
            newState.docTitle = detailInfo.Name
            newState.docDesc = detailInfo.Description
            newState.frontShow = detailInfo.FrontShow
            this.setState(newState)
            this._detailInit = true

            if (this._form) {
                this._form.setFieldsValue(
                    {
                        Name: detailInfo.Name,  
                        Description: detailInfo.Description, 
                        FrontShow: detailInfo.FrontShow, 
                    }
                )
            }
        }
    }

    render() {
        let {isLoading, edit} = this.props     
        let {editorState, docTitle, docDesc, frontShow} = this.state
        this.detailInit()

        return (
            <div>
                <Spin spinning={isLoading}>
                {this.mkPreviewModal()}
                <HoBreadcrumb itemList={this._breadConf} extra={{style:{marginBottom: '8px'}}} />
                <Card title="文档详情" extra={this.mkOpButtons()} >
                   
                    <Form ref={node => this._form = node}>
                       <Form.Item name="Name" label="文档标题">
                       <Input value={docTitle} placeholder="请输入文档标题" disabled={!edit} onChange={e => this.inputChangeHd("title", e)} className="ho_margin_bottom_detault"/> 
                       </Form.Item>
                       
                       <Form.Item name="Description" label="文档描述">
                       <Input value={docDesc} placeholder="请输入文档描述" disabled={!edit} onChange={e => this.inputChangeHd("desc", e)} className="ho_margin_bottom_detault"/> 
                       </Form.Item>

                       <Form.Item name="FrontShow" label="前台可见"> 
                       <Select value={frontShow} placeholder="请选择前台网页是否显示该文档" onChange={this.selectChangeHd} disabled={!edit} style={{width: '100%'}} className="ho_margin_bottom_detault">
                            <Select.Option value={1}>是</Select.Option>
                            <Select.Option value={0}>否</Select.Option> 
                        </Select>
                        </Form.Item>

                    </Form>

                    <BraftEditor
                            style={{width: '100%'}}
                            value={editorState}
                            readOnly={!edit}
                            onChange={this.handleChange}
                            onSave={undefined}
                            excludeControls={['text-indent', 'superscript', 'subscript', 'remove-styles', 'strike-through']}
                        />

                
                        
                </Card>
                </Spin>
            </div>
        );
    }
}

