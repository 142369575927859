import {actUpdate, actAdd, actDel} from '../../comm/commRD'

const ACT_TEST_SUIT_UPDATE_SUIT_LIST = 'ACT_TEST_SUIT_UPDATE_SUIT_LIST'
const ACT_TEST_SUIT_UPDATE_SUIT_DETAIL = 'ACT_TEST_SUIT_UPDATE_SUIT_DETAIL'
const ACT_TEST_SUIT_CLEAR_SUIT_DETAIL = 'ACT_TEST_SUIT_CLEAR_SUIT_DETAIL'

const TestsuitState = {
    testsuitList: {TotalNum: 0, PageSize: 10, PageNum: 1, RecordList: []}, 
    testsuitDetail: undefined, 
}

const getStateKeyByActionType = (actionType) => {
    switch(actionType) {
        case ACT_TEST_SUIT_UPDATE_SUIT_LIST: 
            return "testsuitList"

        case ACT_TEST_SUIT_UPDATE_SUIT_DETAIL: 
        case ACT_TEST_SUIT_CLEAR_SUIT_DETAIL: 
            return "testsuitDetail"

        default:
            return undefined
    }
}

const getStateOpByActionType = (actionType) => {
    switch(actionType) {
        case ACT_TEST_SUIT_UPDATE_SUIT_LIST: 
        case ACT_TEST_SUIT_UPDATE_SUIT_DETAIL:
            return "update"


        case ACT_TEST_SUIT_CLEAR_SUIT_DETAIL:
            return "clear"

        default:
            return "other"
    }
}

export const testsuit_reducer = (state=TestsuitState, action) => {
    let op = getStateOpByActionType(action.type)
    switch(op) {
        case "update":
        {
            let retState = Object.assign({}, state);
            let key = getStateKeyByActionType(action.type)
            retState[key] = action.data;
            return retState;
        }

        case "clear":
        {
            let retState = Object.assign({}, state);
            let key = getStateKeyByActionType(action.type)
            retState[key] = undefined;
            return retState;
        }
        
        // 其他不定action处理
        // case "other":
        // {
        //     switch(action.type) {
        //         case ACT_CERT_UPDATE_TEST_CASE_DETAIL:
        //         {
        //             return retState
        //         }
                

        //     }
        // }

        default:
            return state
    }
}

// testsuitList
export const getTestsuiList = (state) => {
    if (state.testsuit.testsuitList !== undefined){
        return state.testsuit.testsuitList;
    }
    return undefined;
}

export const getTestsuitListSuccessHd = (dispatch, rspBody, msgBody) => {
    let data = {
        TotalNum: rspBody.TotalNum, 
        RecordList: rspBody.RecordList, 
        PageSize: msgBody.PageSize, 
        PageNum: msgBody.PageNum, 
    }
    if (!rspBody.RecordList) {
        data.RecordList = []
    }
    dispatch(actUpdate(data, ACT_TEST_SUIT_UPDATE_SUIT_LIST));
}

// testsuitDetail
export const getTestsuitDetail = (state) => {
    if (state.testsuit.testsuitDetail !== undefined){
        return state.testsuit.testsuitDetail
    }
    return undefined;
}

export const getTestsuitDetailSuccessHd = (dispatch, rspBody, msgBody) => {
    let data = {
        SuitInfo: rspBody.SuitInfo, 
        TestCategoryList: rspBody.TestCategoryList, 
        TestCaseList: rspBody.TestCaseList, 
        TestParamList: rspBody.TestParamList, 
    }
    dispatch(actUpdate(data, ACT_TEST_SUIT_UPDATE_SUIT_DETAIL));
}

export const clearTestsuitDetail = () => {
    return {
        type: ACT_TEST_SUIT_CLEAR_SUIT_DETAIL, 
    }
}


