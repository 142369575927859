import {findItemByIdFromList} from '../../comm/logic'
import {actUpdate, actAdd, actDel} from '../../comm/commRD'

const ACT_ORDER_UPDATE_DRAFT_LIST = 'ACT_ORDER_UPDATE_DRAFT_LIST'
const ACT_ORDER_DRAFT_DEL = 'ACT_ORDER_DRAFT_DEL'

const ACT_ORDER_UPDATE_ORDER_LIST = 'ACT_ORDER_UPDATE_ORDER_LIST'
const ACT_ORDER_ORDER_DEL = 'ACT_ORDER_ORDER_DEL'

const ACT_ORDER_UPDATE_ORDER_DRAFT_DETAIL= 'ACT_ORDER_UPDATE_ORDER_DRAFT_DETAIL'
const ACT_ORDER_CLEAR_ORDER_DRAFT_DETAIL = 'ACT_ORDER_CLEAR_ORDER_DRAFT_DETAIL'

const ACT_ORDER_UPDATE_ORDER_DETAIL= 'ACT_ORDER_UPDATE_ORDER_DETAIL'
const ACT_ORDER_CLEAR_ORDER_DETAIL = 'ACT_ORDER_CLEAR_ORDER_DETAIL'

const ACT_ORDER_UPDATE_OBJ_DETAIL = 'ACT_ORDER_UPDATE_OBJ_DETAIL'
const ACT_ORDER_CLEAR_OBJ_DETAIL = 'ACT_ORDER_CLEAR_OBJ_DETAIL'



const OrderState = {
    draftList: {TotalNum: 0, PageSize: 10, PageNum: 1, RecordList: []}, 
    orderList: {TotalNum: 0, PageSize: 10, PageNum: 1, RecordList: []}, 
    orderDetail: undefined, 
    orderDraftDetail: undefined, 
    objDetail: undefined, 
}

const getStateKeyByActionType = (actionType) => {
    switch(actionType) {
        case ACT_ORDER_UPDATE_DRAFT_LIST: 
        case ACT_ORDER_DRAFT_DEL: 
            return "draftList"

        case ACT_ORDER_UPDATE_ORDER_LIST: 
        case ACT_ORDER_ORDER_DEL: 
            return "orderList"

        case ACT_ORDER_UPDATE_ORDER_DETAIL: 
        case ACT_ORDER_CLEAR_ORDER_DETAIL: 
            return "orderDetail"

        case ACT_ORDER_UPDATE_ORDER_DRAFT_DETAIL: 
        case ACT_ORDER_CLEAR_ORDER_DRAFT_DETAIL: 
            return "orderDraftDetail"

        case ACT_ORDER_UPDATE_OBJ_DETAIL: 
        case ACT_ORDER_CLEAR_OBJ_DETAIL: 
            return "objDetail"

        default:
            return undefined
    }
}

const getStateOpByActionType = (actionType) => {
    switch(actionType) {
        case ACT_ORDER_UPDATE_DRAFT_LIST: 
        case ACT_ORDER_UPDATE_ORDER_LIST:
        case ACT_ORDER_UPDATE_ORDER_DETAIL:
        case ACT_ORDER_UPDATE_ORDER_DRAFT_DETAIL:
        case ACT_ORDER_UPDATE_OBJ_DETAIL:
            return "update"

        case ACT_ORDER_DRAFT_DEL: 
        case ACT_ORDER_ORDER_DEL:
            return "del"

        case ACT_ORDER_CLEAR_ORDER_DRAFT_DETAIL:
        case ACT_ORDER_CLEAR_ORDER_DETAIL:
        case ACT_ORDER_CLEAR_OBJ_DETAIL:
            return "clear"

        default:
            return "other"
    }
}

export const order_reducer = (state=OrderState, action) => {
    let op = getStateOpByActionType(action.type)
    switch(op) {
        case "update":
        {
            let retState = Object.assign({}, state);
            let key = getStateKeyByActionType(action.type)
            retState[key] = action.data;
            return retState;
        }

        case "del":
        {
            let retState = Object.assign({}, state);
            let key = getStateKeyByActionType(action.type)
            let data = retState[key]

            for (let index in data.RecordList) {
                let item = data.RecordList[index]
                if (item.Id === action.Id) {
                    data.RecordList.splice(index, 1);
                    data.TotalNum = data.RecordList.length
                    break;
                }
            }
            retState[key] = data
            return retState
        } 

        case "clear":
        {
            let retState = Object.assign({}, state);
            let key = getStateKeyByActionType(action.type)
            retState[key] = undefined;
            return retState;
        }
        
        // 其他不定action处理
        // case "other":
        // {
        //     switch(action.type) {
        //         case ACT_CERT_UPDATE_TEST_CASE_DETAIL:
        //         {
        //             return retState
        //         }
                

        //     }
        // }

        default:
            return state
    }

}


// draftList
export const getOrderDraftList = (state) => {
    if (state.order.draftList !== undefined){
        return state.order.draftList;
    }
    return undefined;
}

export const getOrderDraftListSuccessHd = (dispatch, rspBody, msgBody) => {
    let data = {
        TotalNum: rspBody.TotalNum, 
        RecordList: rspBody.RecordList, 
        PageSize: msgBody.PageSize, 
        PageNum: msgBody.PageNum, 
    }
    if (!rspBody.RecordList) {
        data.RecordList = []
    }
    dispatch(actUpdate(data, ACT_ORDER_UPDATE_DRAFT_LIST));
}



// orderList
export const getOrderList = (state) => {
    if (state.order.orderList !== undefined){
        return state.order.orderList;
    }
    return undefined;
}

export const getOrderListSuccessHd = (dispatch, rspBody, msgBody) => {
    let data = {
        TotalNum: rspBody.TotalNum, 
        RecordList: rspBody.RecordList, 
        PageSize: msgBody.PageSize, 
        PageNum: msgBody.PageNum, 
    }
    if (!rspBody.RecordList) {
        data.RecordList = []
    }
    dispatch(actUpdate(data, ACT_ORDER_UPDATE_ORDER_LIST));
}


// orderDraftDetail
export const getOrderDraftDetail = (state) => {
    if (state.order.orderDraftDetail !== undefined){
        return state.order.orderDraftDetail
    }
    return undefined;
}

export const getOrderDraftDetailSuccessHd = (dispatch, rspBody, msgBody) => {
    let data = {
        OrderBase: rspBody.OrderBase, 
        CertList: rspBody.CertList ? rspBody.CertList : [], 
        TestCategoryList: rspBody.TestCategoryList ? rspBody.TestCategoryList : [], 
        TestCaseList: rspBody.TestCaseList ? rspBody.TestCaseList : [], 
        CaseParamList: rspBody.CaseParamList ? rspBody.CaseParamList : [], 
        FeatureList: rspBody.FeatureList ? rspBody.FeatureList : [], 
        QuotItems: rspBody.QuotItems ? rspBody.QuotItems : [], 
        ProfileInfo: rspBody.ProfileInfo ? rspBody.ProfileInfo : undefined, 
        PaymentInfo: rspBody.PaymentInfo ? rspBody.PaymentInfo : undefined, 
        PostSample: rspBody.PostSample ? rspBody.PostSample : undefined, 
        ProductResList: rspBody.ProductResList ? rspBody.ProductResList : [], 
    }
    dispatch(actUpdate(data, ACT_ORDER_UPDATE_ORDER_DRAFT_DETAIL));
}

export const clearOrderDraftDetail = () => {
    return {
        type: ACT_ORDER_CLEAR_ORDER_DRAFT_DETAIL, 
    }
};


// orderDetail
export const getOrderDetail = (state) => {
    if (state.order.orderDetail !== undefined){
        return state.order.orderDetail
    }
    return undefined;
}

export const getOrderDetailSuccessHd = (dispatch, rspBody, msgBody) => {
    let data = {
        OrderBase: rspBody.OrderBase, 
        CertList: rspBody.CertList ? rspBody.CertList : [], 
        TestCategoryList: rspBody.TestCategoryList ? rspBody.TestCategoryList : [], 
        TestCaseList: rspBody.TestCaseList ? rspBody.TestCaseList : [], 
        CaseParamList: rspBody.CaseParamList ? rspBody.CaseParamList : [], 
        FeatureList: rspBody.FeatureList ? rspBody.FeatureList : [], 
        QuotItems: rspBody.QuotItems ? rspBody.QuotItems : [], 
        ProfileInfo: rspBody.ProfileInfo ? rspBody.ProfileInfo : undefined, 
        PaymentInfo: rspBody.PaymentInfo ? rspBody.PaymentInfo : undefined, 
        PostSample: rspBody.PostSample ? rspBody.PostSample : undefined, 
        ProductResList: rspBody.ProductResList ? rspBody.ProductResList : [], 
        ApproveRemarks: rspBody.ApproveRemarks ? rspBody.ApproveRemarks : [], 
        ReceiptInfo: rspBody.ReceiptInfo ? rspBody.ReceiptInfo : undefined, 
        Certification: rspBody.Certification ? rspBody.Certification : undefined, 
    }
    dispatch(actUpdate(data, ACT_ORDER_UPDATE_ORDER_DETAIL));
}

export const clearOrderDetail = () => {
    return {
        type: ACT_ORDER_CLEAR_ORDER_DETAIL, 
    }
};

// objDetail
export const getObjDetail = (state) => {
    if (state.order.objDetail !== undefined){
        return state.order.objDetail
    }
    return undefined;
}

export const getObjDetailSuccessHd = (dispatch, rspBody, msgBody) => {
    let data = {
        DstObjInfo: rspBody.DstObjInfo, 
        ObjCertList: rspBody.ObjCertList ? rspBody.ObjCertList : [], 
        ObjTestCategoryList: rspBody.ObjTestCategoryList ? rspBody.ObjTestCategoryList : [], 
        ObjFeatureRelateList: rspBody.ObjFeatureRelateList ? rspBody.ObjFeatureRelateList : [], 
        ObjTestCaseList: rspBody.ObjTestCaseList ? rspBody.ObjTestCaseList : [], 
    }
    dispatch(actUpdate(data, ACT_ORDER_UPDATE_OBJ_DETAIL));
}

export const clearObjDetail = () => {
    return {
        type: ACT_ORDER_CLEAR_OBJ_DETAIL, 
    }
};



