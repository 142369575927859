import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux'



// ************************************************************
// 各模块路由表配置
// ************************************************************

import OrderDraftListCT from '../../app/order/draftListCT'
import OrderListCT from '../../app/order/orderListCT'
import OrderDraftDetailCT from '../../app/order/draftDetailCT'
import OrderDetailCT from '../../app/order/orderDetailCT'


class OperateRoute extends Component {
    render(){
        return (
            
            <Switch>
                <Route path={`${this.props.match.path}/orderDraftList`} exact component={OrderDraftListCT} />
                <Route path={`${this.props.match.path}/orderDraft/detail/:orderId`} exact component={OrderDraftDetailCT} />
                <Route path={`${this.props.match.path}/orderList`} exact component={OrderListCT} />
                <Route path={`${this.props.match.path}/order/detail/:orderId`} exact component={OrderDetailCT} />


            </Switch>

        );
    }
}

export default connect(
    null, 
    null
)(OperateRoute);

