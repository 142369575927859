import React, { Component } from 'react';

import {Card, Row, Col, Button, Popconfirm, Space, Modal, Descriptions, Select, 
    Tag, Form, Input } from 'antd'
import {SyncOutlined, PlusOutlined} from '@ant-design/icons'

import {HoBreadcrumb} from '../../component/element'
import {HoTable, HoMkTblColumn, HoMkTblColActions} from '../../component/tbl'

import {cert_scopes} from '../../comm/config'
import {orderStateMap} from '../../comm/logic'
import {OrderDetailCard} from './utils'


export class OrderDraftPage extends Component {
    constructor(props){
        super(props)

        this.mkOpButtons = this.mkOpButtons.bind(this)

        this.state = {
            // orderInitStep: 0, 
        }

        this._breadConf = [
            {name: "订单管理", link: '/operate/orderList'}, 
            {name: "订单草稿列表", link: '/operate/orderDraftList'}, 
            {name: "订单草稿详情", link: undefined}, 
        ]

    }

    
    mkOpButtons() {
        let {refreshHd} = this.props
        return (
            <Row>
                <Space>
                    <Button type="default" icon={<SyncOutlined />} onClick={refreshHd}>刷新</Button>
                </Space>
            </Row>
        )
    }

    
    render() {
        let {loading, orderDetail, objDetail} = this.props     
        

        return (
            <div>
                <HoBreadcrumb itemList={this._breadConf} extra={{style:{marginBottom: '8px'}}} />
                <Card title="订单草稿详情" extra={this.mkOpButtons()}>
                    <OrderDetailCard 
                    orderDetail={orderDetail}
                    objDetail={objDetail}
                    />
                    
                </Card>
            </div>
        );
    }

}

