import {sendRequest} from './net';
import {findItemByIdFromList} from '../comm/logic';
import {HyMessage} from '../component/utils';

const ACT_NET_REQUEST_PENDING = 'ACT_NET_REQUEST_PENDING'
const ACT_NET_REQUEST_SUCCESS = 'ACT_NET_REQUEST_SUCCESS'
const ACT_NET_REQUEST_FAILED = 'ACT_NET_REQUEST_FAILED'
const ACT_NET_REQUEST_RESET = 'ACT_NET_REQUEST_RESET'
const ACT_NET_REQUEST_CLEAR = 'ACT_NET_REQUEST_CLEAR'
const ACT_NET_REQUEST_SET_MSG = 'ACT_NET_REQUEST_SET_MSG'



// reducer handle
const getNetRequestByRouteCode = (reqList, routeCode) => {
let result = undefined;
for (let i in reqList){
    let item = reqList[i];
    if (item.routeCode === routeCode){
        result = item;
        break;
    }
}
return result;
}

const netRequestState = [];

export const netRequest_reducer = (state=netRequestState, action) => {
switch(action.type){
    case ACT_NET_REQUEST_PENDING:
    {
        let nextState = Object.assign([], state);
        let request = getNetRequestByRouteCode(nextState, action.routeCode);
        if (request === undefined){
            nextState.push({status: 1, routeCode: action.routeCode, msg: undefined});
        }
        else{
            request.status = 1;
        }
        return nextState;
    }

    case ACT_NET_REQUEST_SUCCESS:
    {
        let nextState = Object.assign([], state);
        let request = getNetRequestByRouteCode(nextState, action.routeCode);
        if (request === undefined){
            nextState.push({status: 2, routeCode: action.routeCode, msg: action.msg});
        }
        else{
            request.status = 2;
            request.msg = action.msg;
        }
        return nextState;
    }

    case ACT_NET_REQUEST_FAILED:
    {
        let nextState = Object.assign([], state);
        let request = getNetRequestByRouteCode(nextState, action.routeCode);
        if (request === undefined){
            nextState.push({status: 1, routeCode: action.routeCode, msg: undefined});
        }
        else{
            request.status = 0;
            request.msg = undefined;
        }
        return nextState;
    }

    case ACT_NET_REQUEST_RESET:
    {
        let nextState = Object.assign([], state);
        let request = getNetRequestByRouteCode(nextState, action.routeCode);
        if (request !== undefined){
            request.status = 0;
            request.msg = undefined;
        }
        
        return nextState;
    }

    case ACT_NET_REQUEST_SET_MSG:
    {
        let nextState = Object.assign([], state);
        let request = getNetRequestByRouteCode(nextState, action.routeCode);
        if (request === undefined){
            nextState.push({status: 2, routeCode: action.routeCode, msg: action.msg});
        }
        else{
            request.msg = action.msg;
        }
        return nextState;
    }

    case ACT_NET_REQUEST_CLEAR:
    {
        let nextState = state.slice();
        const findRet = findItemByIdFromList(action.routeCode, 'routeCode', nextState);
        if (findRet !== undefined){
            nextState.splice(findRet.index, 1);
        }
        return nextState;
    }

    default:
    return state;
}
}



// select handle
export const getNetRequestStatus = (state, routeCode) => {
let status = 0;
const reqInfo = state.net_request;
for (let i in reqInfo){
    let item = reqInfo[i];
    if (item.routeCode === routeCode){
        status = item.status;
        break;
    }
}
return status;
}

export const getNetRequestMsg = (state, routeCode) => {
let msg = undefined;
const reqInfo = state.net_request;
for (let i in reqInfo){
    let item = reqInfo[i];
    if (item.routeCode === routeCode){
        msg = item.msg;
        break;
    }
}

return msg;
}


// actions define
// ACT_NET_REQUEST_PENDING
export const actNetRequestPending = (routeCode) => {
return {
    type: ACT_NET_REQUEST_PENDING, 
    routeCode: routeCode
}
};

// ACT_NET_REQUEST_SUCCESS
export const actNetRequestSuccess = (routeCode, msg=undefined) => {
return {
    type: ACT_NET_REQUEST_SUCCESS, 
    routeCode: routeCode, 
    msg: msg
}
};

// ACT_NET_REQUEST_FAILED
export const actNetRequestFailed = (routeCode) => {
return {
    type: ACT_NET_REQUEST_FAILED, 
    routeCode: routeCode
}
};

// ACT_NET_REQUEST_RESET
export const actNetRequestReset = (routeCode) => {
return {
    type: ACT_NET_REQUEST_RESET, 
    routeCode: routeCode
}
};

// ACT_NET_REQUEST_CLEAR
export const actNetRequestClear = (routeCode) => {
return {
    type: ACT_NET_REQUEST_CLEAR, 
    routeCode: routeCode
}
};

// ACT_NET_REQUEST_SET_MSG
export const actNetRequestSetMsg = (routeCode, msg) => {
return {
    type: ACT_NET_REQUEST_SET_MSG, 
    routeCode: routeCode, 
    msg: msg
}
};

function sleep(ms){
return new Promise((resolve)=>setTimeout(resolve,ms));
}

export const netRequestThunk = (path, msgBody, routeCode, successHd=undefined, token=undefined, errorHd=undefined) => async (dispatch, getState) => {
    dispatch(actNetRequestPending(routeCode));
    // var temple=await sleep(3000);
    const rspBody = await sendRequest(path, msgBody, undefined, token);
    if (rspBody !== undefined){
        if (rspBody.Status !== 0){
            dispatch(actNetRequestFailed(routeCode));

            if (errorHd !== undefined){
                if (errorHd(dispatch, rspBody.Status, rspBody.StatusMsg)){
                    return;
                }
            }

            if (rspBody.StatusMsg !== undefined){
                HyMessage('error', `操作失败! 错误码: ${rspBody.Status} 错误提示: ${rspBody.StatusMsg}`, 5);
            }else{
                HyMessage('error', `操作失败! 错误码: ${rspBody.Status} 错误提示: 服务端错误`, 5);
            }
        
        }
        else{
            if (successHd !== undefined){
                successHd(dispatch, rspBody, msgBody);
            }
            dispatch(actNetRequestSuccess(routeCode));
        }
    }
    else{
        dispatch(actNetRequestFailed(routeCode));
        if (errorHd !== undefined){
            if (errorHd(dispatch, -1, "服务器无响应")){
                return;
            }
        }
        HyMessage('error', `操作失败! 错误码: -1 错误提示: 服务器无响应`, 5);
    }
}

export function dataCommonSuccessHd(flag, msg="操作成功"){
if (flag){
    HyMessage('success', msg);
}
}