import React, { Component } from 'react';

import {Card, Row, Col, Button, Popconfirm, Space, Divider, Typography, Descriptions, Form, Input, Modal, Select } from 'antd'
import {SyncOutlined, PlusOutlined, EditOutlined, SaveOutlined} from '@ant-design/icons'


import {HoBreadcrumb} from '../../component/element'
import {HoTable, HoMkTblColumn, HoMkTblColActions} from '../../component/tbl'


export class CertTestCaseDetailPage extends Component {
    constructor(props){
        super(props)

        this.editOnclickHd = this.editOnclickHd.bind(this)
        this.mkTitleContent = this.mkTitleContent.bind(this)
        this.mkContent = this.mkContent.bind(this)
        this.mkEditModal = this.mkEditModal.bind(this)
        this.modalCancelHd = this.modalCancelHd.bind(this)
        this.addOnclickHd = this.addOnclickHd.bind(this)
        this.modalConfirmHd = this.modalConfirmHd.bind(this)

        
        let {deleteOnClickHd, delConfirmHd} = props
        this.state = {
            editMode: undefined,
            editRecord: {}, 
            showEdit: false, 
        }

        this._form = undefined

        this._breadConf = [
            {name: "认证检测", link: '/setting/certCertification'}, 
            {name: "检测项目", link: '/setting/certTestCase'}, 
            {name: "检测项详情", link: undefined}, 
        ]

        const disDelete = (
            <Popconfirm title="请确认是否要删除？" 
            onConfirm={(e) => delConfirmHd ? delConfirmHd(e, 'confirm') : undefined}
            onCancel={(e) => delConfirmHd ? delConfirmHd(e, 'cancel') : undefined}
            >
                <span>删除</span>
            </Popconfirm>
        )

        let actionContent = HoMkTblColActions([{key: 'edit', display: '编辑', onClickedHd: this.editOnclickHd}, 
                                                {key: 'delete', display: disDelete, onClickedHd: deleteOnClickHd}, ])

        this._columns = [
        HoMkTblColumn('测量参数', 'Item', 'str', '20%', false, false), 
        HoMkTblColumn('参考范围', 'Okdesc', 'str', '20%', false, false), 
        HoMkTblColumn('价格', 'Price', 'number', '10%', false, false), 
        HoMkTblColumn('周期（工作日）', 'Period', 'number', '10%', false, false), 
        HoMkTblColumn('默认选中', 'IsDefault', 'number', '10%', false, false, this.isDefaultMap), 
        HoMkTblColumn('序号', 'Seq', 'number', '10%', false, false), 
        actionContent, 
        
        ]
    }

    isDefaultMap(isDefault) {
        if (isDefault === 1) {
            return "是"
        }else{
            return "否"
        }
    }
    

    editOnclickHd(key, record) {
        console.log("@@@@: record = ", record)
        let newtSate = Object.assign({}, this.state)
        newtSate.editMode = "mod"
        newtSate.editRecord = record
        newtSate.showEdit = true
        this.setState(newtSate)
    }

    mkTitleContent() {
        let {detailInfo} = this.props
        if (!detailInfo) {
            return undefined
        }

        let {CategoryName, StandardName, Name, Remark, IsDefault, Seq} = detailInfo

        let disDefault = ""
        if (IsDefault) {
            if (IsDefault === 1) {
                disDefault = "是"
            }else{
                disDefault = "否"
            }
        }

        let content = (
            <Descriptions title="检测项概述" column={2}>
                <Descriptions.Item label="检测大类">{CategoryName}</Descriptions.Item>
                <Descriptions.Item label="检测标准">{StandardName}</Descriptions.Item>
                <Descriptions.Item label="检测项名称">{Name}</Descriptions.Item>
                <Descriptions.Item label="默认选中">{disDefault}</Descriptions.Item>
                <Descriptions.Item label="序号">{Seq}</Descriptions.Item>
                <Descriptions.Item label="备注">{Remark}</Descriptions.Item>
            </Descriptions>
        )
        return content
    }

    mkContent() {
        let {detailInfo, loading} = this.props
        let dataList = undefined
        if (detailInfo) {
            dataList = detailInfo.AllowParamList
        }

        return (
            <HoTable columns={this._columns} 
                            size="middle" 
                            dataList={dataList}
                            loading={loading}
                            noPagination={true}
            />
        )

    }

    modalCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.editMode = undefined
        newState.showEdit = false
        newState.editRecord = {}
        this.setState(newState) 
    }

    addOnclickHd() {
        let newState = Object.assign({}, this.state)
        newState.editMode = "add"
        newState.showEdit = true
        this.setState(newState) 
    }


    modalConfirmHd(values) {
        console.log("modalConfirmHd: values = ", values)

        let {editMode, editRecord} = this.state
        let {editConfirmHd, addConfirmHd} = this.props

        let result = {}
        result.Id = editRecord.Id
        result.Item = values.Item
        result.Okdesc = values.Okdesc
        result.Price = parseFloat(values.Price) 
        result.Period = parseFloat(values.Period)
        result.IsDefault = parseInt(values.IsDefault)
        result.Seq = parseInt(values.Seq)

        if (editMode === 'add') {
            if (addConfirmHd) {
                addConfirmHd(result)
            }
        }else if (editMode === 'mod') {
            if (editConfirmHd) {
                editConfirmHd(result)
            }
        }

        let newState = Object.assign({}, this.state)
        newState.editMode = undefined
        newState.showEdit = false
        newState.editRecord = {}
        this.setState(newState)
    }

    mkEditModal() {
        let {showEdit, editMode, editRecord} = this.state
        if (this._form) {
            this._form.setFieldsValue(
                {
                    Item: editRecord.Item, 
                    Okdesc: editRecord.Okdesc, 
                    Price: editRecord.Price, 
                    Period: editRecord.Period, 
                    IsDefault: editRecord.IsDefault ? editRecord.IsDefault : 1, 
                    Seq: editRecord.Seq, 
                }
            )
        }
    
        let content = (
            <Form onFinish={(values) => this.modalConfirmHd(values)}
            labelCol={{span: 6}}
            wrapperCol={{span: 18}}
            ref={node => this._form = node}
            initialValues={
                {
                    Item: editRecord.Item, 
                    Okdesc: editRecord.Okdesc, 
                    Price: editRecord.Price, 
                    Period: editRecord.Period, 
                    IsDefault: editRecord.IsDefault ? editRecord.IsDefault : 1, 
                    Seq: editRecord.Seq, 
                }
            }
            >
                      
                <Form.Item 
                    name="Item"
                    rules={[{required: true, message: '请输入测量参数!'}]} 
                    label="测量参数" value={editRecord ? editRecord.Item : undefined}
                    >
                        <Input />
                </Form.Item>
                <Form.Item 
                    name="Okdesc"
                    rules={[{required: true, message: '请输入参考范围!'}]} 
                    label="参考范围" value={editRecord ? editRecord.Okdesc : undefined}
                    >
                        <Input  />
                </Form.Item>

                <Form.Item name="Price" label="价格" >
                    <Input />
                </Form.Item>
                <Form.Item name="Period" label="周期（工作日）" >
                    <Input />
                </Form.Item>
                <Form.Item name="IsDefault" label="默认选中" >
                    <Select value={editRecord ? editRecord.IsDefault : undefined} 
                        defaultValue={1}>
                        <Select.Option value={1}>是</Select.Option>
                        <Select.Option value={0}>否</Select.Option> 
                    </Select>
                </Form.Item>
                <Form.Item name="Seq" label="序号" >
                    <Input />
                </Form.Item>
                    
                <Row justify="end">
                    <Space size="middle" >
                    <Button type="default" onClick={() => this.modalCancelHd()}>取消</Button>
                    <Button type="primary" onClick={undefined} htmlType="submit">保存</Button>
                    </Space>
                </Row>
            </Form>
        )
    
        return (
            <Modal 
                title="测量参数配置"
                visible={showEdit}
                onCancel={this.modalCancelHd}
                okText="保存" cancelText="取消"
                footer={null}
                width={560}
                >
                {content}
                   
            </Modal>
        )
    }


    render() {
        // let {detailInfo, loading} = this.props     
        let titleContent = this.mkTitleContent()
        let content = this.mkContent()
        let editModal = this.mkEditModal()
        
        return (
            <div>
                {editModal}
                <HoBreadcrumb itemList={this._breadConf} extra={{style:{marginBottom: '8px'}}} />
                <Card title="检测项详情" extra={undefined}>
                    {titleContent}
    
                    <Divider />
                    <Descriptions title="测量参数列表" 
                    extra={<Button type="primary" icon={<PlusOutlined />} onClick={this.addOnclickHd}>添加</Button>}>
                    </Descriptions>

                    {content}
                </Card> 
            </div>
        );
    }

}

