

import React, { Component } from 'react';

import {Card, Row, Col, Button, Descriptions, Select, Typography, Tabs, Form, Input } from 'antd'
import {SyncOutlined, PlusOutlined} from '@ant-design/icons'

import {HoTable, HoMkTblColumn, HoMkTblColActions} from '../../component/tbl'
import {HoBuildTableData} from '../../component/utils'

import {cert_scopes} from '../../comm/config'
import {orderStateMap, findItemByIdFromList, findInList} from '../../comm/logic'
import {MarginDefault} from "../../comm/const"

// ---------------------------------------------------
const { Title, Paragraph, Text } = Typography;





// ----------------------------------------------------------
// 通用函数定义
export const getApproveInfo = (orderDetail, approveType) => {
    if (!orderDetail) {
        return undefined
    }

    for (let item of orderDetail.ApproveRemarks) {
        if (item.ApproveType === approveType) {
            return {
                ApproveType: item.ApproveType, 
                ApproveResult: item.ApproveResult, 
                Remark: item.Remark, 
                OpTime: item.OpTime,
                NickName: item.NickName,
                Approved: true, 
            }
        }
    }

    return undefined
}


export const getInitApproveInfo = () => {
    return {
        ApproveType: undefined,  
        ApproveResult: undefined, 
        Remark: undefined, 
        OpTime: undefined, 
        NickName: undefined,
        Approved: false, 
    }
}

export const approveIsDone = (orderDetail) => {
    if (!orderDetail) {
        return true
    }

    if (orderDetail.OrderBase.State === "waitPay" || orderDetail.OrderBase.State === "waitApprove") {
        return false
    } else {
        return true
    }
}

export const getLoginNickName = (tokenInfo) => {
    if (!tokenInfo) {
        return undefined
    }
    return tokenInfo.nickName
}

export const getOrderCertification = (orderDetail) => {
    if (!orderDetail) {
        return undefined
    }

    let {Certification} = orderDetail

    if (!Certification) {
        return undefined
    }

    if (Certification.Id === 0) {
        return undefined
    }

    return Certification
}

export const getResByType = (orderDetail, resType) => {
    if (!orderDetail) {
        return undefined
    }

    if (orderDetail.ProductResList) {
        let find = findItemByIdFromList(resType, "ResouceName", orderDetail.ProductResList)
        if (find) {
            let item = find.item
            return {
                name: item.ResouceFileName, 
                url: item.ResouceUrl, 
                published: item.Published, 
                resName: item.ResouceName, 
            }
        }
    }

    return undefined
}





// ----------------------------------------------------------
export class OrderDetailCard extends Component {
    constructor(props){
        super(props)

        this.mkCustomerCard = this.mkCustomerCard.bind(this)
        this.mkOrderBaseCard = this.mkOrderBaseCard.bind(this)
        this.mkTestItemsCard = this.mkTestItemsCard.bind(this)
        this.testCasesRowRender = this.testCasesRowRender.bind(this)


        this.state = {
            // orderInitStep: 0, 
        }

        this._testCategoryColumns = [
            HoMkTblColumn('检测大类', 'TestCategoryName', 'str', '25%', false, false), 
            HoMkTblColumn('遵循标准', 'StandardList', 'str', '25%', false, false, this.standardListMap), 
            HoMkTblColumn('价格（元）', 'Fee', 'number', '25%', false, false), 
            HoMkTblColumn('周期（工作日）', 'Period', 'number', '25%', false, false), 
        ]

        this._testCaseColumns = [
            HoMkTblColumn('检测项', 'TestCaseName', 'str', '20%', false, false), 
            HoMkTblColumn('遵循标准', 'StandardName', 'str', '20%', false, false), 
            HoMkTblColumn('适用特性', 'FeatureName', 'str', '20%', false, false), 
            HoMkTblColumn('价格（元）', 'Fee', 'number', '20%', false, false), 
            HoMkTblColumn('周期（工作日）', 'Period', 'number', '20%', false, false), 
        ]

        this._caseAllowParamsColumns = [
            HoMkTblColumn('测量参数', 'Item', 'str', '25%', false, false), 
            HoMkTblColumn('参考范围', 'Okdesc', 'str', '25%', false, false), 
            HoMkTblColumn('价格（元）', 'Price', 'number', '25%', false, false), 
            HoMkTblColumn('周期（工作日）', 'Period', 'number', '25%', false, false), 
        ]

    }


    mkCustomerCard() {
        let {orderDetail} = this.props

        if (!orderDetail) {
            return undefined
        }

        let {ProfileInfo} = orderDetail


        if (!ProfileInfo) {
            return undefined
        }

        let {NickName, PhoneNum, Profile} = ProfileInfo
        
        return (
            <Card title="客户信息">
                <Descriptions column={3} >
                    <Descriptions.Item label={<Text strong>客户（customer）</Text>}>{NickName}</Descriptions.Item>
                    <Descriptions.Item label={<Text strong>手机（Mobile Phone）</Text>}>{PhoneNum}</Descriptions.Item>
                    <Descriptions.Item label={<Text strong>座机（TEL）</Text>}>{Profile.CompanyTel}</Descriptions.Item>
                    
                    <Descriptions.Item label={<Text strong>邮件（E-mail）</Text>}>{Profile.Email}</Descriptions.Item>
                    <Descriptions.Item label={<Text strong>公司（Company）</Text>}>{Profile.CompanyName}</Descriptions.Item>
                    <Descriptions.Item label={<Text strong>公司联系人（Company Contact）</Text>}>{Profile.CompanyContact}</Descriptions.Item>
                </Descriptions>
            </Card>
            
        )
    }

    mkOrderBaseCard() {
        let {orderDetail, objDetail} = this.props

        if (!orderDetail) {
            return undefined
        }

        if (!objDetail) {
            return undefined
        }

        let {DstObjInfo} = objDetail
        let {OrderBase, FeatureList, CertList} = orderDetail
        let category = undefined
        for (let item of DstObjInfo.CategoryList) {
            if (item.CategoryId === OrderBase.CategoryId) {
                category = item.CategoryName
            }
        }

        let feature = ""
        for (let item of FeatureList) {
            feature += item.Name + "  "
        }

        let certs = ""
        for (let item of CertList) {
            certs += item.CertName + "  "
        }

        return (
            <Card title="订单摘要信息">
                <Descriptions column={3} >
                    <Descriptions.Item label={<Text strong>产品名称</Text>}>{DstObjInfo.Name}</Descriptions.Item>
                    <Descriptions.Item label={<Text strong>产品类别</Text>}>{category}</Descriptions.Item>
                    <Descriptions.Item label={<Text strong>产品附加特性</Text>}>{feature}</Descriptions.Item>
                    
                    <Descriptions.Item label={<Text strong>认证类型</Text>}>{certs}</Descriptions.Item>
                    <Descriptions.Item label={<Text strong>费用</Text>}>{OrderBase.Price}</Descriptions.Item>
                    <Descriptions.Item label={<Text strong>周期（工作日）</Text>}>{OrderBase.Period}</Descriptions.Item>
                </Descriptions>
            </Card>
        )

    }

    standardListMap(srcList) {
        if (!srcList) {
            return undefined
        }

        let result = srcList.map(
            (item, index) => 
                (
                    <Row key={index}>
                        {item}
                    </Row>
                )
        )
        return result
    }

    tfTestItemData(orderDetail, objDetail) {
        if (!orderDetail || !objDetail) {
            return undefined
        }

        let {TestCategoryList, TestCaseList, CaseParamList} = orderDetail
        let {ObjTestCategoryList, ObjTestCaseList} = objDetail

        let categoryData = []
        let caseData = []

        // testcategory 处理
        for (let category of TestCategoryList) {
            let find = findItemByIdFromList(category.ObjTestCategoryId, "Id", ObjTestCategoryList)
            if (!find) {
                continue
            }
            let rfCategory = find.item

            let tmpData = {
                TestCategoryId: rfCategory.TestCategoryId, 
                TestCategoryName: rfCategory.TestCategoryName, 
                StandardList: [], 
                Fee: rfCategory.Price, 
                Period: rfCategory.Period, 
            }
            categoryData.push(tmpData)
        }

        // testcase 处理
        for (let record of TestCaseList) {
            let find = findItemByIdFromList(record.TestCaseId, "Id", ObjTestCaseList)
            if (!find) {
                continue
            }
            let rfCase = find.item

            let tmpData = {
                TestCategoryId: rfCase.TestCategoryId, 
                TestCaseName: rfCase.TestCaseName, 
                StandardName: rfCase.StandardName, 
                FeatureName: rfCase.FeatureName, 
                Fee: rfCase.Price, 
                Period: rfCase.Period,
            }

            caseData.push(tmpData)
        }

        // 汇总 testcategory 数据
        for (let index in categoryData) {

            for (let record of caseData) {
                if (record.TestCategoryId === categoryData[index].TestCategoryId) {
                    // 标准信息处理
                    let find = findInList(record.StandardName, categoryData[index].StandardList)

                    if (!find) {
                        categoryData[index].StandardList.push(record.StandardName)
                    }
                    categoryData[index].Fee += record.Fee
                    categoryData[index].Period += record.Period
                }
            }
        }

        return {
            categoryData: categoryData, 
            caseData: caseData,
        }
    }

    testCasesRowRender(record) {
        let {orderDetail, objDetail} = this.props

        if (!record) {
            return undefined
        }

        const tfData = this.tfTestItemData(orderDetail, objDetail)
        if (!tfData) {
            return undefined
        }

        
        let dataList = []
        for (let item of tfData.caseData) {
            if (record.TestCategoryId === item.TestCategoryId) {
                dataList.push(item)
            }
        }

        return (
            <HoTable columns={this._testCaseColumns} 
                    size="middle" 
                    dataList={HoBuildTableData(dataList)}
                    disablePagination={true}
            />
        )
    }

    mkTestItemsCard() {
        let {orderDetail, objDetail} = this.props

        const tfData = this.tfTestItemData(orderDetail, objDetail)
        let categoryData = undefined
        if (tfData) {
            categoryData = HoBuildTableData(tfData.categoryData)
        }

        console.log("@@@@@: tfData = ", tfData)

        return (
            <Card title="检测项信息">
                <HoTable columns={this._testCategoryColumns} 
                    size="middle" 
                    dataList={categoryData}
                    disablePagination={true}
                    expandable={{expandedRowRender: (record) => this.testCasesRowRender(record), expandRowByClick: true}}
                />
            </Card>
        )
    }


    render() {
        let {loading} = this.props    

        // let disDraft = this.mkDraftCard()
        const disCustomer = this.mkCustomerCard()
        const disBase = this.mkOrderBaseCard()
        const disTestItems = this.mkTestItemsCard()

        return (
            <div>
                {disCustomer}

                <div style={{marginTop: MarginDefault}}>
                    {disBase}
                </div>

                <div style={{marginTop: MarginDefault}}>
                    {disTestItems}
                </div>
                
                
            </div>
        );
    }

}

export class ApproveForm extends Component {
    constructor(props) {
        super(props)

        this.submitHd = this.submitHd.bind(this)
        this.reapproveOnclickHd = this.reapproveOnclickHd.bind(this)

        this.state = {
            reApprove: false
        }

    }

    submitHd(values) {
        let {approveSubmitHd, approveType} = this.props

        if (approveSubmitHd) {
            approveSubmitHd(values, approveType)
        }

        let newState = Object.assign({}, this.state)
        newState.reApprove = false
        this.setState(newState)
    }

    reapproveOnclickHd() {
        let newState = Object.assign({}, this.state)
        newState.reApprove = true
        this.setState(newState)
    }

    render() {
        let {approveResult, approveRemark, approveUserName, approveTime, approved, currentLoginNickName, done, type} = this.props
        let {reApprove} = this.state

        let approveHint = {
            label: "审核结果", 
            select1: "通过", 
            select2: "不通过"
        }
        if (type === "test") {
            approveHint.label = "检测结果"
            approveHint.select1 = "合格"
            approveHint.select2 = "不合格"
        }

        let disApproveUserName = undefined
        if (reApprove) {
            disApproveUserName = currentLoginNickName
        }else{
            disApproveUserName = approveUserName
        }

        let disApproveTime = undefined
        if (approved) {
            disApproveTime = (
                <Form.Item name="ApproveTime" 
                    label="核准时间"
                    >
                    <Text>{approveTime}</Text>
                </Form.Item>
            )
        }

        let disableApprove = false
        if (approved) {
            if (!reApprove) {
                disableApprove = true
            }
        }

        let disReapproveButton = undefined
        if (disableApprove) {
            if (!done) {
                disReapproveButton = (
                    <Button type="primary" loading={undefined} onClick={this.reapproveOnclickHd} style={{marginLeft: "16px"}} >
                        重新审核
                    </Button>
                )
            }
        }

        return (
            
            <Form onFinish={this.submitHd}
                // ref={node => this._approveForm = node}
                style={{maxWidth: "640px"}}
                labelCol={{span: 3}}
                initialValues={{
                    ApproveResult: approveResult, 
                    ApproveRemark: approveRemark, 
                    ApproveUserName: disApproveUserName, 
                    ApproveTime: approveTime, 
                }}
                
                >
                <Form.Item name="ApproveResult" label={approveHint.label} >
                    <Select disabled={disableApprove}>
                        <Select.Option value={1}>{approveHint.select1}</Select.Option>
                        <Select.Option value={0}>{approveHint.select2}</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item name="ApproveUserName" 
                    label="核准人"
                    >
                    <Text>{disApproveUserName}</Text>
                </Form.Item>
                {disApproveTime}
                <Form.Item name="ApproveRemark" 
                    label="备注"
                    >
                        <Input.TextArea
                        rows={4}
                        disabled={disableApprove}
                        />
                </Form.Item>
                <Form.Item >
                    {/* <Row justify="end"> */}
                    <Button type="primary" htmlType="submit" loading={undefined} style={{marginLeft: "0px"}} disabled={disableApprove}>
                        提交
                    </Button>
                    {disReapproveButton}
                    {/* </Row> */}
                </Form.Item>
            </Form>
            
        );
    }
}



