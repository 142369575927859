import React, { Component } from 'react';

import {Card, Row, Col, Button, Popconfirm, Space, Modal, Descriptions, Select, Upload, Typography, 
    Tag, Form, Input } from 'antd'
import {SyncOutlined, PlusOutlined, EyeOutlined, CloudUploadOutlined} from '@ant-design/icons'


import {HoBreadcrumb, HoDocPreview} from '../../../component/element'
import {HoTable, HoMkTblColumn, HoMkTblColActions} from '../../../component/tbl'
import {HyMessage} from '../../../component/utils'
import {c_apiUrlBase} from '../../../comm/config'


import {yesOrNoMap} from '../../../comm/logic'


export class CertUploadDocListPage extends Component {
    constructor(props){
        super(props)

        this.mkOpButtons = this.mkOpButtons.bind(this)
        this.mkEditModal = this.mkEditModal.bind(this)
        this.AddOnclickHd = this.AddOnclickHd.bind(this)
        this.modalCancelHd = this.modalCancelHd.bind(this)
        this.getFormValue = this.getFormValue.bind(this)
        this.uploadOnChangeHd = this.uploadOnChangeHd.bind(this)
        this.editHd = this.editHd.bind(this)
        this.submitHd = this.submitHd.bind(this)
        this.beforeUploadHd = this.beforeUploadHd.bind(this)
        

        let {detailOnclickHd, deleteOnClickHd} = props
        this.state = {
            modelShow: false, 
            uploadInfo: undefined, 
            record: undefined
        }

        this._breadConf = [
            {name: "知识库管理", link: undefined}, 
            {name: "文档（上传）列表", link: undefined}, 
        ]

        let actionContent = HoMkTblColActions([
                                               {key: 'delete', display: '删除', onClickedHd: deleteOnClickHd}, 
                                               {key: 'edit', display: '编辑', onClickedHd: this.editHd}, 
                                               ])


        this._columns = [
            HoMkTblColumn('文档名称', 'Name', 'str', '20%', true, false), 
            HoMkTblColumn('文档描述', 'Description', 'str', '20%', true, false), 
            HoMkTblColumn('前台展示', 'FrontShow', 'number', '10%', true, false, yesOrNoMap), 
            HoMkTblColumn('前台展示次数', 'ShowCount', 'str', '10%', false, false), 
            HoMkTblColumn('下载次数', 'DownLoadCount', 'str', '10%', false, false), 
            
            actionContent, 
        ]

        this._form = undefined
        this._recordInit = false
    }


    editHd(key, record) {
        console.log("@@@@@@@@@@: key = " + key + ", record = ", record)
        let newState = Object.assign({}, this.state)
        newState.modelShow = true
        newState.record = record
        this._recordInit = true
        this.setState(newState)

    }

    
    mkOpButtons() {
        let {refreshHd} = this.props
        return (
            <Row>
                <Space>
                    <Button type="primary" icon={<PlusOutlined />} onClick={this.AddOnclickHd}>新增</Button>
                    <Button type="default" icon={<SyncOutlined />} onClick={refreshHd}>刷新</Button>
                </Space>
            </Row>
        )
    }

    AddOnclickHd(key, record) {
        let newState = Object.assign({}, this.state)
        newState.modelShow = true
        newState.record = undefined
        // this._recordInit = true
        if (this._form) {
            this._form.setFieldsValue(
                {
                    Name: undefined, 
                    Description: undefined, 
                    FrontShow: undefined,
                }
            )
        }

        this.setState(newState)
    }

    modalCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.modelShow = false
        newState.record = undefined
        newState.uploadInfo = undefined
        this._recordInit = false
        this.setState(newState)
    }

    uploadOnChangeHd(param) {
        let newState = Object.assign({}, this.state)
        let {file} = param

        if (file.status === "done") {
            HyMessage("success", `${file.name} 上传成功.`)
            newState.uploadInfo= {uid: file.uid, name: file.name, status: "done", url: file.response.Url, objPath: file.response.ObjPath}
        }else if (file.status === "error") {
            HyMessage("error", `${file.name} 上传失败.`)
            newState.uploadInfo= {uid: file.uid, name: file.name, status: "error", url: undefined}
        }else if (file.status === "uploading") {
            newState.uploadInfo= {uid: file.uid, name: file.name, status: "uploading", url: undefined}
        }

        this.setState(newState)
    }

    getToken() {
        let {tokenInfo} = this.props
        if (!tokenInfo) {
            return undefined
        }

        return tokenInfo.token
    }

    getFormValue(key) {
        console.log("@@@@@@ key = " + key)
        if (!this._form) {
            return undefined
        }

        console.log("@@@@@@______2 key = " + key)

        return this._form.getFieldValue(key)
    }

    getUploadList() {
        let {uploadInfo, record} = this.state

        

        let uploadList = []
        if (uploadInfo) {
            uploadList.push(uploadInfo)
        } else {
            if (record) {
                uploadList.push({uid: "1", name: record.FileName, url: record.DownLoadUrl, status: "done"})
            }
        }
        
        return uploadList;
    }

    getUploadUrl() {
        return c_apiUrlBase + "/hq/v1/kl/certification/upload"
    }

    beforeUploadHd() {
        if (!this._form) {
            return false
        }

        this._Name = this._form.getFieldValue("Name")
        this._Description = this._form.getFieldValue("Description")
        this._FrontShow = this._form.getFieldValue("FrontShow")

        if (this._Name === undefined || this._Description === undefined || this._FrontShow === undefined) {
            HyMessage("error", "请表格内容填写完整后，再上传文件！")
            return false
        }

        console.log("Name = , Description = , FrontShow = ", this._Name, this._Description, this._FrontShow)
        

        return true
    }

    submitHd(values) {
        let {uploadInfo, record} = this.state
        let {setHd} = this.props
        
        if (setHd) {
            let body = {
                Name: values.Name, 
                Description:　values.Description, 
                FrontShow: values.FrontShow, 
                FileName: uploadInfo ? uploadInfo.name : record ? record.FileName : "", 
                DownLoadUrl: uploadInfo ? uploadInfo.url : record ? record.DownLoadUrl : "", 
                ObjPath: uploadInfo ? uploadInfo.objPath : record ? record.ObjPath : "", 
            }
            setHd(body)
        }
        let newState = Object.assign({}, this.state)
        newState.modelShow = false
        newState.record = undefined
        newState.uploadInfo = undefined
        this._recordInit = false
        this.setState(newState)
    }

    
    mkEditModal() {
        let {modelShow, record} = this.state

        const dipUploadArea = (
            <div>
                <p className="ant-upload-drag-icon"> <CloudUploadOutlined /> </p>
                <p>点击上传文档(支持拖拽)</p>
            </div>
        )

        if (this._form && this._recordInit) {
            this._form.setFieldsValue(
                {
                    Name: record ? record.Name : undefined, 
                    Description: record ? record.Description : undefined, 
                    FrontShow: record ? record.FrontShow : undefined,
                }
            )
            this._recordInit = false
        }

        const disContent = (
            <div>
                <Form onFinish={this.submitHd}
                ref={node => this._form = node}
                style={{maxWidth: "640px"}}
                labelCol={{span: 3}}
                initialValues={{
                    Name: record ? record.Name : undefined, 
                    Description: record ? record.Description : undefined, 
                    FrontShow: record ? record.FrontShow : undefined,
                }}
                
                >
                    <Form.Item name="Name" 
                        label="文档标题"
                        rules={[{required: true, message: '请输入文档标题!'}]}
                        >
                        <Input />
                    </Form.Item>

                    <Form.Item name="Description" 
                        label="文档描述"
                        rules={[{required: true, message: '请输入文档描述!'}]}
                        >
                        <Input />
                    </Form.Item>

                    <Form.Item name="FrontShow" label="前台可见" rules={[{required: true, message: '请选择前台是否可见!'}]}> 
                       <Select placeholder="请选择前台网页是否显示该文档" >
                            <Select.Option value={1}>是</Select.Option>
                            <Select.Option value={0}>否</Select.Option> 
                        </Select>
                    </Form.Item>

                    <Row>
                    <Upload.Dragger name={"uploadFile"} 
                    action={this.getUploadUrl()} 
                    onChange={param => this.uploadOnChangeHd(param)}
                    fileList={this.getUploadList()}
                    headers={{Token: this.getToken()}}
                    showUploadList={{showDownloadIcon: true, showRemoveIcon: false}}
                    style={{width: "640px"}}
                    >
                        {dipUploadArea}
                        
                    </Upload.Dragger>
                </Row>

                <div style={{marginBottom: "48px"}}></div>

                    <Row justify="end">
                    <Space size="middle" >
                    <Button type="default" onClick={() => this.modalCancelHd()}>取消</Button>
                    <Button type="primary" onClick={undefined} htmlType="submit">提交</Button>
                    </Space>
                </Row>


                </Form>

                
                
            </div>
        )

        return (
            <Modal 
                title={<Typography.Text type="danger">注意：请上传文件成功后，再提交！</Typography.Text>}
                width={720}
                visible={modelShow}
                onOk={this.modalCancelHd}
                onCancel={this.modalCancelHd}
                okText="确定"
                footer={null}
                >
                {disContent}
                   
            </Modal>
        )
    }


    render() {
        let {pageChangeHd, totalNum, dataList, searchHd, loading, rowOnClickHd} = this.props       

        return (
            <div>
                {this.mkEditModal()}
                <HoBreadcrumb itemList={this._breadConf} extra={{style:{marginBottom: '8px'}}} />
                <Card title="文件（上传）列表" extra={this.mkOpButtons()}>
                    <HoTable columns={this._columns} 
                        size="middle" 
                        dataList={dataList}
                        searchHd={searchHd}
                        totalNum={totalNum}
                        pageChangeHd={pageChangeHd}
                        loading={loading}
                        rowOnClickHd={rowOnClickHd}
                    />
                </Card>
            </div>
        );
    }

}

