import React, { Component } from 'react'
import { connect } from 'react-redux'
import {getNetRequestStatus, netRequestThunk, actNetRequestClear} from '../../../network/netReqRD'
import {getCertOnlineDocDetail, getCertOnlineDocDetailSuccessHd, clearCertOnlineDocDetail} from '../klRD'
import {getLoginTokenInfo} from '../../account/loginRD'
import {OnlineDocDetailPage} from './onlineDocDetail'
import {OnlineDocCateCert} from '../../../comm/const'
import {HyMessage} from '../../../component/utils'



class OnlineDocDetailCT extends Component {
    constructor(props){
        super(props)
        this.tokenCheckHd = this.tokenCheckHd.bind(this)
        this.requestData = this.requestData.bind(this)
        this.refreshHd = this.refreshHd.bind(this)    
        this.editHd = this.editHd.bind(this)
        this.saveHd = this.saveHd.bind(this)

        let {match} = props
        let docId = undefined
        let edit = true
        if (match) {
            if (match.params) {
                docId = parseInt(match.params.docId)
                if (docId) {
                    edit = false
                }
                
            }
            console.log("@@@@@: docId = ", docId)
        }

        this.state = {
            docId: docId, 
            edit: edit, 
        }

    }

    tokenCheckHd() {
        let {tokenInfo, history} = this.props
        if (tokenInfo === undefined) {
            const directPath = '/'
            history.push(directPath)
            return false
        }
        return true
    }

    requestData() {
        if (!this.tokenCheckHd()) {
            return
        }

        let {docId} = this.state

        if (!docId) {
            return
        }

        let {dispatch, tokenInfo} = this.props
        let msgBody = {
            Id: docId, 
        }
        dispatch(netRequestThunk("/hq/v1/kl/online/doc/get", msgBody, "getCertOnlineDocDetail", getCertOnlineDocDetailSuccessHd, tokenInfo.token))
    }

    componentWillUnmount(){
        let {dispatch} = this.props
        dispatch(actNetRequestClear("getCertOnlineDocDetail"))
        dispatch(actNetRequestClear("saveOnlineDoc"))
        dispatch(clearCertOnlineDocDetail())
    }

    componentDidMount() {
        this.requestData()
    }

    refreshHd() {
        this.requestData()
    }


    getLoadingFlag() {
        let {reqStatus, saveStatus, dispatch} = this.props

        let isLoading = false
        if (reqStatus === 1 || saveStatus === 1) {
            isLoading = true
        } 

        if (saveStatus === 2) {
            let newState = Object.assign({}, this.state)
            newState.edit = false
            this.setState(newState)
            dispatch(actNetRequestClear("saveOnlineDoc"))
            this.requestData()
        }

        return isLoading
    }

    editHd() {
        let newState = Object.assign({}, this.state)
        newState.edit = true
        this.setState(newState)
    }

    saveHd(title, desc, frontShow, data) {
        if (!this.tokenCheckHd()) {
            return
        }

        let {dispatch, tokenInfo} = this.props
        let {docId} = this.state

        let msgBody = {
            Category: OnlineDocCateCert,
            Name: title, 
            Description: desc, 
            FrontShow: frontShow, 
            RawDoc: data, 
        }

        if (docId) {
            msgBody.Id = docId
        }

        if (data) {
            let KBLen = data.length / 1024
            if (KBLen >= 4096) {
                HyMessage("error", "保存文件大小为 " + KBLen.toFixed(2) + " KB, 超出最大保存文件4MB的限制！ 请适当减少内容后再保存（一般图片占用空间较大）", 5)
                return
            }
        }

        dispatch(netRequestThunk("/hq/v1/kl/online/doc/save", msgBody, "saveOnlineDoc", undefined, tokenInfo.token))
    }

    

    render(){
        let {detailInfo} = this.props
        let {edit} = this.state


        let isLoading = this.getLoadingFlag()
        
        return (
            <OnlineDocDetailPage
            edit={edit}
            detailInfo={detailInfo}
            isLoading={isLoading}
            saveHd={this.saveHd}
            editHd={this.editHd}
            />
        );
    }
}

const mapState = (state) => ({
    detailInfo: getCertOnlineDocDetail(state), 
    tokenInfo: getLoginTokenInfo(state), 
    reqStatus: getNetRequestStatus(state, 'getCertOnlineDocDetail'), 
    saveStatus: getNetRequestStatus(state, 'saveOnlineDoc'), 

});

export default connect(
    mapState, 
    null
)(OnlineDocDetailCT);


