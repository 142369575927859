import React, { Component } from 'react'
import { connect } from 'react-redux'
import {getNetRequestStatus, netRequestThunk, actNetRequestClear} from '../../network/netReqRD'
import {getTestsuitDetail, getTestsuitDetailSuccessHd, clearTestsuitDetail} from './testsuitRD'
import {getLoginTokenInfo} from '../account/loginRD'
import {TestsuitDetailPage} from './testsuitDetail'



class TestSuitDetailCT extends Component {
    constructor(props){
        super(props)
        this.tokenCheckHd = this.tokenCheckHd.bind(this)
        this.requestData = this.requestData.bind(this)
        this.refreshHd = this.refreshHd.bind(this)    
        this.addConfirmHd = this.addConfirmHd.bind(this)
        this.editConfirmHd = this.editConfirmHd.bind(this)
        this.deleteOnClickHd = this.deleteOnClickHd.bind(this)
        this.delConfirmHd = this.delConfirmHd.bind(this)
        
        let {match} = props
        let suitId = undefined
        if (match) {
            if (match.params) {
                suitId = parseInt(match.params.suitId)
                
                
            }
            console.log("@@@@@: suitId = ", suitId)
        }

        this.state = {
            suitId: suitId, 
        }

    }

    tokenCheckHd() {
        let {tokenInfo, history} = this.props
        if (tokenInfo === undefined) {
            const directPath = '/'
            history.push(directPath)
            return false
        }
        return true
    }

    requestData() {
        if (!this.tokenCheckHd()) {
            return
        }

        let {suitId} = this.state

        if (!suitId) {
            return
        }

        let {dispatch, tokenInfo} = this.props
        let msgBody = {
            Id: suitId, 
        }
        dispatch(netRequestThunk("/hq/v1/testsuit/mntn/suit/detail", msgBody, "getTestsuitDetail", getTestsuitDetailSuccessHd, tokenInfo.token))
    }

    componentWillUnmount(){
        let {dispatch} = this.props
        dispatch(actNetRequestClear("getTestsuitDetail"))
        dispatch(clearTestsuitDetail())
    }

    componentDidMount() {
        this.requestData()
    }

    refreshHd() {
        this.requestData()
    }

    addConfirmHd(record, reqType) {
        if (!this.tokenCheckHd()) {
            return
        }

        console.log("##$$##$$: record = , reqType = ", record, reqType)

        let {dispatch, tokenInfo} = this.props
        let {suitId} = this.state
        let msgBody = {}

        if (reqType === "rfGategory") {
            msgBody.SuitId = suitId
            msgBody.CategoryId = record.TestCategoryId
            msgBody.Period = record.Period
            msgBody.Price = record.Price
            msgBody.IsDefault = record.IsDefault
            msgBody.Seq = record.Seq
            msgBody.Remark = record.Remark

            dispatch(netRequestThunk("/hq/v1/testsuit/mntn/suit/addTestCategory", msgBody, "addTestsuitCategory", undefined, tokenInfo.token))
            return
        }

        // if (reqType === "rfCase") {
        //     msgBody.ObjId = recordId
        //     msgBody.CertId = record.CertId
        //     msgBody.TestCategoryId = record.TestCategoryId
        //     msgBody.Period = record.Period
        //     msgBody.Price = record.Price
        //     msgBody.IsDefault = record.IsDefault
        //     msgBody.Seq = record.Seq
        //     msgBody.Remark = record.Remark
        //     dispatch(netRequestThunk("/hq/v1/mntn/product/objTestcategory/add", msgBody, "addObjTestCategory", undefined, tokenInfo.token))
        //     return
        // }

        // if (reqType === "rfParam") {
        //     msgBody.ObjId = recordId
        //     msgBody.CertId = record.CertId
        //     msgBody.FeatureId = record.FeatureId
        //     msgBody.Period = record.Period
        //     msgBody.Price = record.Price
        //     msgBody.IsDefault = record.IsDefault
        //     msgBody.Seq = record.Seq
        //     msgBody.Remark = record.Remark
        //     dispatch(netRequestThunk("/hq/v1/mntn/product/objFeature/add", msgBody, "addObjFeatureRelate", undefined, tokenInfo.token))
        //     return
        // }

    }

    editConfirmHd(record, reqType) {
        if (!this.tokenCheckHd()) {
            return
        }
        let {dispatch, tokenInfo} = this.props
        let {recordId} = this.props
        let msgBody = {}

        console.log("@@@@@@: reqType = " + reqType + "record = ", record, )

        if (reqType === "rfGategory") {
            msgBody.Id = record.Id
            msgBody.Period = record.Period
            msgBody.Price = record.Price
            msgBody.IsDefault = record.IsDefault
            msgBody.Seq = record.Seq
            msgBody.Remark = record.Remark
            dispatch(netRequestThunk("/hq/v1/testsuit/mntn/suit/modTestCategory", msgBody, "modTestsuitCategory", undefined, tokenInfo.token))
            return
        }


        

        // if (reqType === "objCert") {
        //     msgBody.Id = record.Id
        //     msgBody.ObjId = recordId
        //     msgBody.CertId = record.CertId
        //     dispatch(netRequestThunk("/hq/v1/mntn/product/objCert/mod", msgBody, "modObjCert", undefined, tokenInfo.token))
        //     return
        // }

        // if (reqType === "objTestCategory") {
        //     msgBody.Id = record.Id
        //     msgBody.ObjId = recordId
        //     msgBody.CertId = record.CertId
        //     msgBody.TestCategoryId = record.TestCategoryId
        //     msgBody.Period = record.Period
        //     msgBody.Price = record.Price
        //     msgBody.IsDefault = record.IsDefault
        //     msgBody.Seq = record.Seq
        //     msgBody.Remark = record.Remark
        //     dispatch(netRequestThunk("/hq/v1/mntn/product/objTestcategory/mod", msgBody, "modObjTestCategory", undefined, tokenInfo.token))
        //     return
        // }

        // if (reqType === "objFeatureRelate") {
        //     msgBody.Id = record.Id
        //     msgBody.ObjId = recordId
        //     msgBody.CertId = record.CertId
        //     msgBody.FeatureId = record.FeatureId
        //     msgBody.Period = record.Period
        //     msgBody.Price = record.Price
        //     msgBody.IsDefault = record.IsDefault
        //     msgBody.Seq = record.Seq
        //     msgBody.Remark = record.Remark
        //     dispatch(netRequestThunk("/hq/v1/mntn/product/objFeature/mod", msgBody, "modObjFeatureRelate", undefined, tokenInfo.token))
        //     return
        // }

        // if (reqType === "objTestCaseRelate") {
        //     msgBody.Id = record.Id
        //     msgBody.ObjId = recordId
        //     msgBody.TestCaseId = record.TestCaseId
        //     msgBody.CertId = record.CertId
        //     msgBody.FeatureId = record.FeatureId
        //     msgBody.Period = record.Period
        //     msgBody.Price = record.Price
        //     msgBody.IsDefault = record.IsDefault
        //     msgBody.Seq = record.Seq
        //     msgBody.Remark = record.Remark

        //     console.log("###$$$$$$: msgBody = ", msgBody)

        //     dispatch(netRequestThunk("/hq/v1/mntn/product/objTestCase/mod", msgBody, "modObjTestCaseRelate", undefined, tokenInfo.token))
        //     return
        // }


    }

    deleteOnClickHd(key, record) {
        this._delRecord = record
        this._delKey = key
    }

    delConfirmHd(e, type) {
        e.stopPropagation();
        if (type === 'confirm') {
            if (this._delRecord !== undefined) {
                if (!this.tokenCheckHd()) {
                    this._delRecord = undefined  
                    return
                }

                let {dispatch, tokenInfo} = this.props
                // let msgBody = {}

                let msgBody = {
                    Id: this._delRecord.Id, 
                }

                if (this._delKey === "rfGategory") {
                    dispatch(netRequestThunk("/hq/v1/testsuit/mntn/suit/delTestCategory", msgBody, "delTestsuitCategory", undefined, tokenInfo.token))
                }else if (this._delKey === "rfCase") {
                    dispatch(netRequestThunk("/hq/v1/testsuit/mntn/suit/delTestCase", msgBody, "delTestsuitCase", undefined, tokenInfo.token))
                }else if (this._delKey === "rfParam") {
                    dispatch(netRequestThunk("/hq/v1/testsuit/mntn/suit/delTestParam", msgBody, "delTestsuitParam", undefined, tokenInfo.token))
                }
            }
        }

        this._delRecord = undefined        
        this._delKey = undefined
    }


    getLoadingFlag() {
        let {reqStatus, addCategoryStatus, modCategoryStatus, delCategoryStatus, addCaseStatus, modCaseStatus, delCaseStatus, 
            addParamStatus, modParamStatus, delParamStatus, dispatch} = this.props

        let isLoading = false
        const statusList = [reqStatus, addCategoryStatus, modCategoryStatus, delCategoryStatus, addCaseStatus, modCaseStatus, delCaseStatus, 
            addParamStatus, modParamStatus, delParamStatus]
        
        for (let status of statusList) {
            if (status === 1) {
                isLoading = true
                break
            }
        }

        const refreshList = [
            {status: addCategoryStatus, clearFlag: "addTestsuitCategory"}, 
            {status: modCategoryStatus, clearFlag: "modTestsuitCategory"}, 
            {status: delCategoryStatus, clearFlag: "delTestsuitCategory"}, 
        ]

        for (let item of refreshList) {
            if (item.status === 2) {
                dispatch(actNetRequestClear(item.clearFlag))
                this.requestData()
                return isLoading
            }
        }


        return isLoading
    }

    render(){
        let {detailInfo} = this.props
        let {edit} = this.state


        let isLoading = this.getLoadingFlag()
        
        return (
            <TestsuitDetailPage
            detailInfo={detailInfo}
            addConfirmHd={this.addConfirmHd}
            editConfirmHd={this.editConfirmHd}
            deleteOnClickHd={this.deleteOnClickHd}
            delConfirmHd={this.delConfirmHd}
            
            />
        );
    }
}

const mapState = (state) => ({
    detailInfo: getTestsuitDetail(state), 
    tokenInfo: getLoginTokenInfo(state), 
    reqStatus: getNetRequestStatus(state, 'getTestsuitDetail'), 
    addCategoryStatus: getNetRequestStatus(state, 'addTestsuitCategory'), 
    modCategoryStatus: getNetRequestStatus(state, 'modTestsuitCategory'), 
    delCategoryStatus: getNetRequestStatus(state, 'delTestsuitCategory'), 
    addCaseStatus: getNetRequestStatus(state, 'addTestsuitCase'), 
    modCaseStatus: getNetRequestStatus(state, 'modTestsuitCase'), 
    delCaseStatus: getNetRequestStatus(state, 'delTestsuitCase'), 
    addParamStatus: getNetRequestStatus(state, 'addTestsuitParam'), 
    modParamStatus: getNetRequestStatus(state, 'modTestsuitParam'), 
    delParamStatus: getNetRequestStatus(state, 'delTestsuitParam'), 
});

export default connect(
    mapState, 
    null
)(TestSuitDetailCT);


