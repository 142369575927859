import React, { Component } from 'react'

import {Card, Row, Col, Button, Popconfirm, Space, Form, Input, Modal, Select } from 'antd'
import {SyncOutlined, PlusOutlined, CheckOutlined} from '@ant-design/icons'
import {HoBreadcrumb} from '../../component/element'
import {HoTable, HoMkTblColumn, HoMkTblColActions} from '../../component/tbl'
import {yesOrNoMap} from '../../comm/logic'


export class TestsuitListPage extends Component {
    constructor(props){
        super(props)

        this.mkOpButtons = this.mkOpButtons.bind(this)
        this.addOnclickHd = this.addOnclickHd.bind(this)
        this.mkEditModal = this.mkEditModal.bind(this)
        this.modalCancelHd = this.modalCancelHd.bind(this)
        this.modalConfirmHd = this.modalConfirmHd.bind(this)
        this.editOnclickHd = this.editOnclickHd.bind(this)


        let {deleteOnClickHd, delConfirmHd, detailOnClickHd, selectFlag, selectedHd} = props
        this.state = {
            showEdit: false, 
            editMode: undefined,
            editRecord: {}, 
        }

        this._form = undefined


        this._breadConf = [
            {name: "检测套餐", link: undefined}, 
            {name: "套餐列表", link: undefined}, 
        ]

        const disDelete = (
            <Popconfirm title="请确认是否要删除？" 
            onConfirm={(e) => delConfirmHd ? delConfirmHd(e, 'confirm') : undefined}
            onCancel={(e) => delConfirmHd ? delConfirmHd(e, 'cancel') : undefined}
            >
                <span>删除</span>
            </Popconfirm>
        )

        let actionContent = HoMkTblColActions([{key: 'detail', display: '详情', onClickedHd: detailOnClickHd}, 
                                               {key: 'edit', display: '编辑', onClickedHd: this.editOnclickHd}, 
                                               {key: 'delete', display: disDelete, onClickedHd: deleteOnClickHd}, ])

        this._columns = [
            HoMkTblColumn('套餐名称', 'Name', 'str', '20%', true, false), 
            HoMkTblColumn('描述', 'Description', 'number', '20%', true, false), 
            HoMkTblColumn('限制描述', 'LimitDesc', 'str', '20%', true, false), 
            HoMkTblColumn('是否显示', 'FrontShow', 'number', '10%', true, false, yesOrNoMap), 
            actionContent, 
        ]
    }

    mkOpButtons() {
        let {refreshHd, addOnclickHd} = this.props
        return (
            <Row>
                <Space>
                    <Button type="primary" icon={<PlusOutlined />} onClick={this.addOnclickHd}>新增</Button>
                    <Button type="default" icon={<SyncOutlined />} onClick={refreshHd}>刷新</Button>
                </Space>
            </Row>
        )
    }

    addOnclickHd() {
        let newState = Object.assign({}, this.state)
        newState.editMode = "add"
        newState.showEdit = true
        newState.editRecord = {}
        this.setState(newState)
    }

    modalCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.editMode = undefined
        newState.showEdit = false
        newState.editRecord = {}
        this.setState(newState)
    }

    editOnclickHd(key, record) {
        let newState = Object.assign({}, this.state)
        newState.editMode = "mod"
        newState.showEdit = true
        newState.editRecord = record
        this.setState(newState)
    }

    modalConfirmHd(values) {
        let {editMode, editRecord} = this.state
        let {addConfirmHd, editConfirmHd} = this.props

        console.log("@@@########__ values = ", values)

        let result = {
            Name: values.Name, 
            Description: values.Description, 
            LimitDesc: values.LimitDesc, 
            FrontShow: values.FrontShow, 
        }

        if (editMode === "add") {
            if (addConfirmHd) {
                addConfirmHd(result)
            }


        }else if (editMode === "mod") {
            result.Id = editRecord.Id
            if (editConfirmHd) {
                editConfirmHd(result)
            }
        }

        let newState = Object.assign({}, this.state)
        newState.editMode = undefined
        newState.showEdit = false
        newState.editRecord = {}
        this.setState(newState)
    }


    mkEditModal() {
        let {showEdit, editRecord} = this.state

        if (this._form) {
            this._form.setFieldsValue(
                {
                    Name: editRecord.Name, 
                    Description: editRecord.Description, 
                    LimitDesc: editRecord.LimitDesc, 
                    FrontShow: editRecord.FrontShow,
                }
            )
        }

        let content = (
            <Form onFinish={(values) => this.modalConfirmHd(values)}
            labelCol={{span: 4}}
            wrapperCol={{span: 20}}
            ref={node => this._form = node}
            initialValues={
                {
                    Name: editRecord.Name, 
                    Description: editRecord.Description, 
                    LimitDesc: editRecord.LimitDesc, 
                    FrontShow: editRecord.FrontShow ? editRecord.FrontShow : 1,
                }
            }
            >

                <Form.Item name="Name" rules={[{required: true, message: '请输入检测套餐名称!'}]} label="套餐名称" >
                    <Input />
                </Form.Item>

                <Form.Item name="FrontShow" label="前台显示" >
                    <Select >
                        <Select.Option value={1}>是</Select.Option>
                        <Select.Option value={0}>否</Select.Option> 
                    </Select>
                </Form.Item>

                <Form.Item name="Description" label="套餐描述" >
                    <Input.TextArea rows={2} />
                </Form.Item>

                <Form.Item name="LimitDesc" label="限制描述" >
                    <Input.TextArea rows={3} />
                </Form.Item>

                    
                <Row justify="end">
                    <Space size="middle" >
                    <Button type="default" onClick={() => this.modalCancelHd()}>取消</Button>
                    <Button type="primary" onClick={undefined} htmlType="submit">保存</Button>
                    </Space>
                </Row>
            </Form>
        )

        return (
            <Modal 
                title="检测套餐"
                visible={showEdit}
                onCancel={this.modalCancelHd}
                okText="保存" cancelText="取消"
                footer={null}
                width={720}
                >
                {content}
                   
            </Modal>
        )
    }


    render() {
        let {pageChangeHd, totalNum, dataList, searchHd, rowOnClickHd, loading} = this.props   
        let editModal = this.mkEditModal()
        return (
            <div>
                <HoBreadcrumb itemList={this._breadConf} extra={{style:{marginBottom: '8px'}}} />
                {editModal}
                <Card title="检测套餐列表" extra={this.mkOpButtons()}>
                    <HoTable columns={this._columns} 
                        size="middle" 
                        dataList={dataList}
                        searchHd={searchHd}
                        totalNum={totalNum}
                        pageChangeHd={pageChangeHd}
                        loading={loading}
                        rowOnClickHd={rowOnClickHd}
                    />
                </Card>
            </div>
        );
    }


}

