import JSEncrypt from "jsencrypt"


// ------------------------------------
const PUBLIC_KEY = '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC7qY7rpCBeN18I0GwuHQZFGVxOB+nw6AwokOhU5+t5EHwWEfN6hWmvA7NRNFH85ncSS3+Z25gk878It3cD1dI0Wgh1iR1nJt0n38JQAGArsGR3a4VkhRVEuz5ygjq6DzWxJcGpqiQsmCr41oFiCbwZjhn+NyyjahuJzzUdlGmukwIDAQAB-----END PUBLIC KEY-----'

// ------------------------------------
export function encrypt(str){
    //使用公钥加密
    let encrypt = new JSEncrypt();
    encrypt.setPublicKey(PUBLIC_KEY);
    return encrypt.encrypt(str);
}

// 估计只能使用私钥解密
export function decrypt(str){
    //使用公钥解密
    let encrypt = new JSEncrypt();
    encrypt.setPublicKey(PUBLIC_KEY);
    return encrypt.decrypt(str);
}


//  按key 且key字段类型为字符串类型搜索对象数组
export const searchStringByKeyFromList = (key, value, itemList, tfunc=undefined) => {
    let result_list = [];
    
    if (itemList === undefined){
        return result_list;
    }

    for (let i = 0; i < itemList.length; i++){
        let item = itemList[i];
        // 转换成字符串搜索
        let searchSrc = '';
        if(tfunc !== undefined){
            searchSrc += tfunc(item[key]);
        }
        else{
            searchSrc += item[key];
        }
        let searchValue = '' + value;
        if (searchSrc.search(searchValue) !== -1){
            result_list.push(item);
        }
    }
    return result_list;
};

// 按key 对列表进行排序，暂时先支持字符串和数字类型
export const sortListByKey = (key, itemList, descending=true) => {
    let resultList = undefined;
    if(descending){
        resultList = itemList.sort(
            (a, b) => {
                let result = 0;
                if (a[key] > b[key]){
                    result = 1;
                }
                else if(a[key] < b[key]){
                    result = -1;
                }
                return result;
            }
        );
    }
    else{
        resultList = itemList.sort(
            (a, b) => {
                let result = 0;
                if (a[key] > b[key]){
                    result = -1;
                }
                else if(a[key] < b[key]){
                    result = 1;
                }
                return result;
            }
        );
    }
    return resultList;
}

export const findItemByIdFromList = (findId, key, itemList) => {
    let result = undefined;
    for (let i in itemList){
        let item = itemList[i];
        if (findId === item[key]){
            result = {
                index: i, 
                item: item
            }
        }
    }
    return result;
}

// 按title 获取属性字段值
export const getPropertyFromTblDesByTitle = (title, desList) => {
    let result = undefined;

    for (let i in desList){
        let item = desList[i];
        if (item.title === title){
            result = item.property;
            break;
        }
    }
    return result;
}

export const getPropertyInfoFromTblDes = (title, desList) => {
    let result = undefined;
    for (let i in desList){
        let item = desList[i];
        if (item.title === title){
            result = item;
            break;
        }
    }
    return result;
}


// 获取当前时间显示信息
export const getDisplayTime = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const hour = date.getHours();
    const min = date.getMinutes();
    const sec = date.getSeconds();

    const result = year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + sec;
    return result;
}

// 获取指定时间显示信息
export const getFixedDisplayTime = (fixedTime) => {
    const date = new Date(fixedTime);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const hour = date.getHours();
    const min = date.getMinutes();
    const sec = date.getSeconds();

    const result = year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + sec;
    return result;
}

export function getDateDiff(dateTimeStamp) {
    //若你得到的时间格式不是时间戳，可以使用下面的JavaScript函数把字符串转换为时间戳, 本函数的功能相当于JS版的strtotime：
    if (dateTimeStamp === undefined){
        return "";
    }

    let idata = Date.parse(dateTimeStamp.replace(/-/gi,"/"));  //js函数代码：字符串转换为时间
    let now = new Date().getTime();
    let diffValue = now - idata;
    let result = dateTimeStamp + " ";
    if (diffValue >= 0) {
        const monthC = diffValue / (7 * 1000 * 60 * 60 * 24 * 30);
        const weekC = diffValue / (7 * 1000 * 60 * 60 * 24);
        const dayC = diffValue / (1000 * 60 * 60 * 24);
        const hourC = diffValue / (1000 * 60 * 60);
        const minC = diffValue / (1000 * 60);
        
        if (monthC >= 1) {
            result += "(" + parseInt(monthC) + "个月前)";
        }else if (weekC >= 1) {
            result += "(" + parseInt(weekC) + "周前)";
        }else if (dayC >= 1) {
            result += "(" + parseInt(dayC) + "天前)";
        }else if (hourC >= 1) {
            result += "(" + parseInt(hourC) + "小时前)";
        }else if (minC >= 1) {
            result += "(" + parseInt(minC) + "分钟前)";
        }else{
            result += "(刚刚)";
        } 
    }
     
    return result;
}

export function getDateRemaining(dateTimeStamp) {

    let idata = Date.parse(dateTimeStamp.replace(/-/gi,"/"));  //js函数代码：字符串转换为时间
    let now = new Date().getTime();

    let diff = idata - now;
    let flag = diff >= 0;
    let day = parseInt((flag ? diff : -diff) / (1000 * 60 * 60 * 24));
    day+=1
    let date = flag ? `剩余${day}天` : `已超期${day}天`;
    
    return {date: date, flag: flag};
}

export function addRecord(list, record, recordId, recordIdKey){
    
    list = list || [];

    const findRet = findItemByIdFromList(recordId, recordIdKey, list);
    if (findRet === undefined){
        list.splice(0, 0, record);
    }else{
        list.splice(findRet.index, 1);
        list.splice(findRet.index, 0, record);
    }

    return list;
}

export function delRecord(list, recordId, recordIdKey){
    list = list || [];
    for (let i in list){
        let item = list[i];
        if (item[recordIdKey] === recordId){
            list.splice(i, 1);
            break;
        }
    }

    return list;
}

export function formatValidTime(t, validValue=""){
    return (t === undefined || t === '1000-01-01 00:00:00') ? validValue : t;
}


export const yesOrNoMap = (src) => src === 1 ? "是" : "否"

// 从列表中查找某元素
export const findInList = (item, srcList) => {
    for (let record of srcList) {
        if (record === item) {
            return true
        }
    }
    return false
}

// 构造form 字段值
export const calcFromFields = (src, dst) => {
    let result = {}
    for (let key of src) {
        result[key] = dst[key] ? dst[key] : undefined
    }
    return result
}


export const orderStateMap = (src) => {
    let result = src
    if (src === "draft") {
        result = "草稿"
    } else if (src === "waitPay") {
        result = "待受理"
    } else if (src === "approved") {
        result = "订单受理"
    } else if (src === "reportDraftPublish") {
        result = "检测报告上传"
    }else if (src === "reportFirstConfirm") {
        result = "检测报告初确认"
    }else if (src === "reportPublish") {
        result = "检测报告发布"
    } else if (src === "certed") {
        result = "认证完成"
    } else if (src === "finished") {
        result = "完成"
    } 
    return result
}




