// export const c_apiUrlBase = "http://netadap.angliancd.com:8050"
// export const c_apiToken = "08bbe3dee8d911eab7f51831bf6d8bd5"

// export const c_apiUrlBase = "https://apimntntest.ludiqiao.com:8036"
// export const c_apiToken = "a3135c53c6ef4fb5b92a27f6340620ac"

export const c_apiUrlBase = "https://api.hqdoc.top:7216"
export const c_apiToken = "a3135c53c6ef4fb5b92a27f6340620ac"


export const cert_scopes = ["全球", "欧洲", "亚洲", "美洲", "澳洲", "中东", "非洲"]




