import React, { Component } from 'react';

import {Card, Row, Col, Button, Popconfirm, Space, Form, Input, Modal, Select } from 'antd'
import {SyncOutlined, PlusOutlined, CheckOutlined} from '@ant-design/icons'

import {HoBreadcrumb} from '../../component/element'
import {HoTable, HoMkTblColumn, HoMkTblColActions} from '../../component/tbl'
import CertTestCategoryListCT from './testCategoryCT'
import CertTestStandardListCT from './testStandardCT'
import {yesOrNoMap} from '../../comm/logic'


export class CertTestCaseListPage extends Component {
    constructor(props){
        super(props)

        this.mkOpButtons = this.mkOpButtons.bind(this)
        this.addOnclickHd = this.addOnclickHd.bind(this)
        this.mkEditModal = this.mkEditModal.bind(this)
        this.modalCancelHd = this.modalCancelHd.bind(this)
        this.mkSelectCategoryModal = this.mkSelectCategoryModal.bind(this)
        this.selectModalCancelHd = this.selectModalCancelHd.bind(this)

        this.selectCategoryButtonOnClickHd = this.selectCategoryButtonOnClickHd.bind(this)
        this.selectStandardButtonOnClickHd = this.selectStandardButtonOnClickHd.bind(this)
        this.selectStandardModalCancelHd = this.selectStandardModalCancelHd.bind(this)

        this.categorySelectedHd = this.categorySelectedHd.bind(this)
        this.standardSelectedHd = this.standardSelectedHd.bind(this)
        this.modalInputChangeHd = this.modalInputChangeHd.bind(this)

        this.modalConfirmHd = this.modalConfirmHd.bind(this)
        this.editOnclickHd = this.editOnclickHd.bind(this)


        let {deleteOnClickHd, delConfirmHd, selectFlag, selectedHd, detailOnclickHd} = props
        this.state = {
            showEdit: false, 
            editMode: undefined,
            editRecord: {}, 
            showCategorySelect: false, 
            showStandardSelect: false, 
        }

        this._form = undefined

        this._editRecord = {}


        this._breadConf = [
            {name: "认证检测", link: '/setting/certCertification'}, 
            {name: "检测项", link: undefined}, 
        ]

        const disDelete = (
            <Popconfirm title="请确认是否要删除？" 
            onConfirm={(e) => delConfirmHd ? delConfirmHd(e, 'confirm') : undefined}
            onCancel={(e) => delConfirmHd ? delConfirmHd(e, 'cancel') : undefined}
            >
                <span>删除</span>
            </Popconfirm>
        )

        let actionContent = HoMkTblColActions([{key: 'detail', display: '详情', onClickedHd: detailOnclickHd}, 
                                               {key: 'edit', display: '编辑', onClickedHd: this.editOnclickHd}, 
                                               {key: 'delete', display: disDelete, onClickedHd: deleteOnClickHd}, ])
        if (selectFlag) {
            actionContent = HoMkTblColActions([{key: 'select', display: '选择', onClickedHd: selectedHd}])
        }

        this._columns = [
            HoMkTblColumn('检测大类', 'CategoryName', 'str', '15%', true, false), 
            HoMkTblColumn('检测标准', 'StandardName', 'str', '15%', true, false), 
            HoMkTblColumn('检测项', 'Name', 'str', '15%', true, false), 
            HoMkTblColumn('默认选中', 'IsDefault', 'number', '10%', true, false, yesOrNoMap), 
            HoMkTblColumn('序号', 'Seq', 'number', '10%', true, false), 
            HoMkTblColumn('备注', 'Remark', 'str', '15%', true, false), 
            actionContent, 
        ]

    }

    displayMap(Display) {
        if (Display === 0) {
            return "否"
        }else {
            return "是"
        }
    }

    
    mkOpButtons() {
        let {refreshHd, addOnclickHd} = this.props
        return (
            <Row>
                <Space>
                    <Button type="primary" icon={<PlusOutlined />} onClick={this.addOnclickHd}>新增</Button>
                    <Button type="default" icon={<SyncOutlined />} onClick={refreshHd}>刷新</Button>
                </Space>
            </Row>
        )
    }

    editOnclickHd(key, record) {
        let newState = Object.assign({}, this.state)
        newState.editMode = "mod"
        newState.showEdit = true
        newState.editRecord = record
        this.setState(newState)
    }

    mkEditModal() {
        let {showEdit, editRecord} = this.state

        if (this._form) {
            this._form.setFieldsValue(
                {
                    CategoryName: editRecord.CategoryName, 
                    StandardName: editRecord.StandardName, 
                    Name: editRecord.Name, 
                    IsDefault: editRecord.IsDefault, 
                    Seq: editRecord.Seq, 
                    Remark: editRecord.Remark, 
                }
            )
        }

        let content = (
            <Form onFinish={(values) => this.modalConfirmHd(values)}
            labelCol={{span: 4}}
            wrapperCol={{span: 20}}
            ref={node => this._form = node}
            initialValues={
                {
                    CategoryName: editRecord.CategoryName, 
                    StandardName: editRecord.StandardName, 
                    Name: editRecord.Name, 
                    IsDefault: editRecord.IsDefault, 
                    Seq: editRecord.Seq, 
                    Remark: editRecord.Remark, 
                }
            }
            >

                <Form.Item rules={[{required: true, message: '请选择检测大类!'}]} label="检测大类" name="CategoryName">
                    <Row justify="start">
                        
                        <Col span={14}>
                        <Input placeholder="请点击右边按钮选择检测大类" value={editRecord.CategoryName} disabled/>
                        </Col>

                        <Col push={2} span={6}>
                        <Button type="primary" icon={<CheckOutlined />} onClick={this.selectCategoryButtonOnClickHd} >选择检测大类</Button>
                        </Col>

                    </Row>    
                </Form.Item>

                <Form.Item rules={[{required: true, message: '请选择检测标准!'}]} label="检测标准" name="StandardName">
                    <Row justify="start">
                        
                        <Col span={14}>
                        <Input placeholder="请点击右边按钮选择检测标准" value={editRecord.StandardName} disabled/>
                        </Col>

                        <Col push={2} span={6}>
                        <Button type="primary" icon={<CheckOutlined />} onClick={this.selectStandardButtonOnClickHd} >选择检测标准</Button>
                        </Col>
                        
                    </Row>    
                </Form.Item>
                      
                <Form.Item name="Name" rules={[{required: true, message: '请输入检测项!'}]} label="检测项" >
                    <Input />
                </Form.Item>

                <Form.Item name="IsDefault" label="默认选中" >
                    <Select >
                        <Select.Option value={1}>是</Select.Option>
                        <Select.Option value={0}>否</Select.Option> 
                    </Select>
                </Form.Item>

                <Form.Item name="Seq" label="序号" >
                    <Input />
                </Form.Item>

                <Form.Item name="Remark" label="备注" >
                    <Input />
                </Form.Item>
                    
                <Row justify="end">
                    <Space size="middle" >
                    <Button type="default" onClick={() => this.modalCancelHd()}>取消</Button>
                    <Button type="primary" onClick={undefined} htmlType="submit">保存</Button>
                    </Space>
                </Row>
            </Form>
        )

        return (
            <Modal 
                title="检测项概述"
                visible={showEdit}
                // onOk={this.modalConfirmHd}
                onCancel={this.modalCancelHd}
                okText="保存" cancelText="取消"
                footer={null}
                width={560}
                >
                {content}
                   
            </Modal>
        )
    }

    mkSelectCategoryModal() {
        let {showCategorySelect} = this.state
        return (
            <Modal 
            title=""
            visible={showCategorySelect}
            width={1280}
            footer={null}
            onOk={undefined}
            onCancel={this.selectModalCancelHd}
            >
                <CertTestCategoryListCT 
                selectFlag={true}
                selectedHd={this.categorySelectedHd}
                />

            </Modal>
        )
    }

    mkSelectStandardModal() {
        let {showStandardSelect} = this.state
        return (
            <Modal 
            title=""
            visible={showStandardSelect}
            width={1280}
            footer={null}
            onOk={undefined}
            onCancel={this.selectStandardModalCancelHd}
            >
                <CertTestStandardListCT 
                selectFlag={true}
                selectedHd={this.standardSelectedHd}
                />

            </Modal>
        )
    }

    modalConfirmHd(values) {
        console.log("modalConfirmHd: values = ", values)

        let {editMode, editRecord} = this.state
        let {editConfirmHd, addConfirmHd} = this.props

        let result = {}
        result.Id = editRecord.Id
        result.CategoryId = editRecord.CategoryId
        result.StandardId = editRecord.StandardId
        result.Name = values.Name
        result.IsDefault = parseInt(values.IsDefault)
        result.Seq = parseInt(values.Seq)
        result.Remark = values.Remark

        if (editMode === 'add') {
            if (addConfirmHd) {
                addConfirmHd(result)
            }
        }else if (editMode === 'mod') {
            if (editConfirmHd) {
                editConfirmHd(result)
            }
        }

        let newState = Object.assign({}, this.state)
        newState.editMode = undefined
        newState.showEdit = false
        newState.editRecord = {}
        this.setState(newState)
    }

    selectStandardModalCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.showStandardSelect = false
        this.setState(newState)
    }

    addOnclickHd() {
        let newState = Object.assign({}, this.state)
        newState.editMode = "add"
        newState.showEdit = true
        this.setState(newState)
    }

    selectCategoryButtonOnClickHd() {
        let newState = Object.assign({}, this.state)
        newState.showCategorySelect = true
        this.setState(newState)
    }

    selectStandardButtonOnClickHd() {
        let newState = Object.assign({}, this.state)
        newState.showStandardSelect = true
        this.setState(newState)
    }

    selectModalCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.showCategorySelect = false
        this.setState(newState)
    }

    modalCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.editMode = undefined
        newState.showEdit = false
        newState.showCategorySelect = false
        newState.showStandardSelect = false
        this.setState(newState)
    }

    categorySelectedHd(key, record) {
        let {editRecord} = this.state

        editRecord.CategoryName = record.Name
        editRecord.CategoryId = record.Id

        let newState = Object.assign({}, this.state)
        newState.showCategorySelect = false
        newState.editRecord = editRecord
        this.setState(newState)
    }

    standardSelectedHd(key, record) {
        let {editRecord} = this.state

        editRecord.StandardName = record.Name
        editRecord.StandardId = record.Id

        let newState = Object.assign({}, this.state)
        newState.showStandardSelect = false
        newState.editRecord = editRecord
        this.setState(newState)
    }

    modalInputChangeHd(key, value) {
        let {editRecord} = this.state
        console.log("key = , value = ", key, value)

        editRecord[key] = value
        
        let newState = Object.assign({}, this.state)
        newState.editRecord = editRecord
        this.setState(newState)
    }


    render() {
        let {pageChangeHd, totalNum, dataList, searchHd, loading} = this.props   
        
        let editModal = this.mkEditModal()
        let selectCategoryModal = this.mkSelectCategoryModal()
        let selectStandardModal = this.mkSelectStandardModal()
        return (
            <div>
                    <HoBreadcrumb itemList={this._breadConf} extra={{style:{marginBottom: '8px'}}} />
                    {editModal}
                    {selectCategoryModal}
                    {selectStandardModal}
                    <Card title="检测项概述列表" extra={this.mkOpButtons()}>
                        <HoTable columns={this._columns} 
                            size="middle" 
                            dataList={dataList}
                            searchHd={searchHd}
                            totalNum={totalNum}
                            pageChangeHd={pageChangeHd}
                            loading={loading}
                        />

                    </Card>
            </div>
        );
    }

}

