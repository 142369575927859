import React, { Component } from 'react';

import {Card, Row, Col, Button, Popconfirm, Space, Modal, Descriptions, Select, Collapse, Typography, Steps, Tabs, Upload, Divider, Tag, Form, Input } from 'antd'
import {SyncOutlined, PlusOutlined, UploadOutlined, CheckOutlined, RollbackOutlined, EditOutlined} from '@ant-design/icons'

import {ApproveForm, getInitApproveInfo, getApproveInfo, approveIsDone, getResByType} from './utils'
import {MarginDefault, HqCompany, HqAddress, HqTel, OrderApproveTypePayment, OrderApproveTypeResUpload, OrderApproveTypeSample, 
    OrderApproveTypeConfirm, OrderApproveTypeCertDraft} from '../../comm/const'
import {orderStateMap, findItemByIdFromList} from '../../comm/logic'
import {HyMessage} from '../../component/utils'

const { Title, Paragraph, Text, Link } = Typography;
const { TabPane } = Tabs


export class ApprovePanel extends Component {
    constructor(props) {
        super(props)

        this.mkPostSampleTab = this.mkPostSampleTab.bind(this)
        this.mkUploadResourceTab = this.mkUploadResourceTab.bind(this)
        this.approveOnclickHd = this.approveOnclickHd.bind(this)
        this.modalCancelHd = this.modalCancelHd.bind(this)
        this.showModalHd = this.showModalHd.bind(this)
        this.modalConfirmHd = this.modalConfirmHd.bind(this)
        this.mkSetModelModal = this.mkSetModelModal.bind(this)
        this.tabsOnchangeHd = this.tabsOnchangeHd.bind(this)

        

        this.state = {
            activeKey: undefined, 
            showModel: false, 
        }

    }

    getLoginNickName(tokenInfo) {
        if (!tokenInfo) {
            return undefined
        }
        return tokenInfo.nickName
    }


    getPayMentInfo(orderDetail) {
        if (!orderDetail) {
            return undefined
        }

        if (!orderDetail.PaymentInfo) {
            return undefined
        }

        if (orderDetail.PaymentInfo.Id === 0) {
            return undefined
        }

        return orderDetail.PaymentInfo
    }


    mkPaymentTab() {
        let {orderDetail, orderApproveHd, tokenInfo} = this.props
        let {payFee, paymentUpload} = this.state
        let disPayFee = payFee
        let disCredentialUrl = undefined
        let disCredentialName = undefined

        let approveResult = undefined
        let approveRemark = undefined
        let approveTime = undefined
        let approveUserName = undefined
        let approved = false
        const tmpInfo = getApproveInfo(orderDetail, OrderApproveTypePayment)
        if (tmpInfo) {
            approved = true
            approveResult = tmpInfo.ApproveResult
            approveRemark = tmpInfo.Remark
            approveTime = tmpInfo.OpTime
            approveUserName = tmpInfo.NickName
        }else{
            approveUserName = this.getLoginNickName(tokenInfo)
        }

        
        const payment = this.getPayMentInfo(orderDetail)
        if (payment) {
            disCredentialUrl = payment.CredentialUrl
            disCredentialName = payment.CredentialFileName
            if (!disPayFee) {
                disPayFee = payment.PaiedFee
            }
        } 

        return (
            <div style={{marginBottom: MarginDefault}}>
                <Descriptions title="客户付款信息" column={1}>
                    <Descriptions.Item label="付款凭证">
                        <Link href={disCredentialUrl}>{disCredentialName}</Link>
                    </Descriptions.Item>
                    <Descriptions.Item label="付款金额">{disPayFee} 元</Descriptions.Item>
                </Descriptions>

                <Divider />

                <ApproveForm 
                approveResult={approveResult}
                approveRemark={approveRemark}
                approveUserName={approveUserName}
                approveType={OrderApproveTypePayment}
                approveSubmitHd={orderApproveHd}
                approveTime={approveTime}
                approved={approved}
                currentLoginNickName={this.getLoginNickName(tokenInfo)}
                done={approveIsDone(orderDetail)}
                />
            </div>
        )
    }

    mkUploadResourceTab() {
        let {orderDetail, orderApproveHd, tokenInfo} = this.props

        const resDesList = [
            {name: "1. 营业执照（含制造商和工厂）", resType: "businessLicense"}, 
            {name: "2. 产品铭牌", resType: "productLabel"}, 
            {name: "3. 产品说明书", resType: "productManual"}, 
            {name: "4. 产品规格书", resType: "productSpec"}, 
            {name: "5. 产品原理框图", resType: "productSCH"}, 
            {name: "6. 其他", resType: "userOthers"}
        ]

        let disDes = resDesList.map(
            (item, index) => {
                let url = undefined
                let displayName = undefined
                const resInfo = getResByType(orderDetail, item.resType)
                if (resInfo) {
                    url = resInfo.url
                    displayName = resInfo.name
                }

                return (
                    <Descriptions.Item label={item.name} key={index}>
                        <Link href={url}>{displayName}</Link>
                    </Descriptions.Item>
                )
            }
        )

        let approveResult = undefined
        let approveRemark = undefined
        let approveTime = undefined
        let approveUserName = undefined
        let approved = false
        const tmpInfo = getApproveInfo(orderDetail, OrderApproveTypeResUpload)
        if (tmpInfo) {
            approved = true
            approveResult = tmpInfo.ApproveResult
            approveRemark = tmpInfo.Remark
            approveTime = tmpInfo.OpTime
            approveUserName = tmpInfo.NickName
        }else{
            approveUserName = this.getLoginNickName(tokenInfo)
        }

        return (
            <div style={{marginBottom: MarginDefault}}>
                <Descriptions title="客户上传资料信息" column={1}>
                    {disDes}
                </Descriptions>

                <Divider />

                <ApproveForm 
                approveResult={approveResult}
                approveRemark={approveRemark}
                approveTime={approveTime}
                approved={approved}
                approveUserName={approveUserName}
                approveType={OrderApproveTypeResUpload}
                approveSubmitHd={orderApproveHd}
                currentLoginNickName={this.getLoginNickName(tokenInfo)}
                done={approveIsDone(orderDetail)}
                />

            </div>
        )
    }

    mkPostSampleTab() {
        let {orderApproveHd, orderDetail, tokenInfo} = this.props
        let record = undefined
        if (orderDetail) {
            if (orderDetail.PostSample) {
                if (orderDetail.PostSample.Id != 0) {
                    record = orderDetail.PostSample
                }
            }
        }

        let approveResult = undefined
        let approveRemark = undefined
        let approveTime = undefined
        let approveUserName = undefined
        let approved = false
        const tmpInfo = getApproveInfo(orderDetail, OrderApproveTypeSample)
        if (tmpInfo) {
            approved = true
            approveResult = tmpInfo.ApproveResult
            approveRemark = tmpInfo.Remark
            approveTime = tmpInfo.OpTime
            approveUserName = tmpInfo.NickName
        }else{
            approveUserName = this.getLoginNickName(tokenInfo)
        }

        return (
            <div>
                <Descriptions title="客户寄样信息" column={1}>
                    <Descriptions.Item label="样品数量">
                        {record ? record.Amount : undefined}
                    </Descriptions.Item>
                    <Descriptions.Item label="快递公司">
                        {record ? record.ExpressCompany : undefined}
                    </Descriptions.Item>
                    <Descriptions.Item label="快递单号">
                        {record ? record.ExpressNum : undefined}
                    </Descriptions.Item>
                    <Descriptions.Item label="寄件人">
                        {record ? record.Sender : undefined}
                    </Descriptions.Item>
                    <Descriptions.Item label="联系电话">
                        {record ? record.SenderTel : undefined}
                    </Descriptions.Item>
                    <Descriptions.Item label="备注">
                        {record ? record.Remark : undefined}
                    </Descriptions.Item>
                </Descriptions>

                <Divider />

                <ApproveForm 
                approveResult={approveResult}
                approveRemark={approveRemark}
                approveTime={approveTime}
                approved={approved}
                approveUserName={approveUserName}
                approveType={OrderApproveTypeSample}
                approveSubmitHd={orderApproveHd}
                currentLoginNickName={this.getLoginNickName(tokenInfo)}
                done={approveIsDone(orderDetail)}
                />

            </div>
        )
    }

    approveOnclickHd() {
        let {orderApproveHd, orderDetail} = this.props

        // 校验是否可以接受订单
        const desList = [
            {approveType: OrderApproveTypePayment, hint: "请先审核客户付款信息后，再确定受理该订单！"},
            {approveType: OrderApproveTypeResUpload, hint: "请先审核客户上传资料信息后，再确定受理该订单！"},
            {approveType: OrderApproveTypeSample, hint: "请先审核客户寄样信息后，再确定受理该订单！"},
        ]

        for (let item of desList) {
            let tmpInfo = getApproveInfo(orderDetail, item.approveType)
            if (!tmpInfo) {
                HyMessage("error", item.hint)
                return
            }
            if (tmpInfo.ApproveResult != 1) {
                HyMessage("error", item.hint)
                return
            }
        }

        // 校验通过，可以受理该订单
        if (orderApproveHd) {
            orderApproveHd({ApproveResult: 1, ApproveRemark: ""}, OrderApproveTypeConfirm)
        }

    }

    getDisplayApproveButton(orderDetail, tokenInfo) {
        if (!orderDetail) {
            return undefined
        }

        const tmpInfo = getApproveInfo(orderDetail, OrderApproveTypeConfirm)
        if (tmpInfo) {
            return (
                <Row justify="end">
                    <Typography style={{marginRight: "16px", marginTop: "8px"}}><Text >受理核准人： {tmpInfo.NickName}</Text></Typography>
                    <Space>
                    <Button type="primary" onClick={this.approveOnclickHd} disabled={true}>确认受理该订单</Button>
                    <Button type="primary" icon={<EditOutlined />} onClick={this.showModalHd} >设置型号</Button>
                    </Space>
                </Row>
            )
        }

        let {OrderBase} = orderDetail
        if (OrderBase.State === 'waitApprove') {
            return (
                <Row justify="end">
                    <Typography style={{marginRight: "16px", marginTop: "8px"}}><Text >受理核准人： {this.getLoginNickName(tokenInfo)}</Text></Typography>
                    <Space>
                    <Button type="primary" onClick={this.approveOnclickHd} >确认受理该订单</Button>
                    <Button type="primary" icon={<EditOutlined />} onClick={this.showModalHd} >设置型号</Button>
                    </Space>
                </Row>
            )
        }

        return undefined
    }


    modalCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.showModel = false
        this.setState(newState)
    }

    showModalHd() {
        let newState = Object.assign({}, this.state)
        newState.showModel = true
        this.setState(newState)
    }

    modalConfirmHd(values) {
        let {modelSetHd} = this.props
        if (modelSetHd) {
            if (values) {
                modelSetHd(values.Model)
            }
        }

        let newState = Object.assign({}, this.state)
        newState.showModel = false
        this.setState(newState)
    }

    mkSetModelModal() {
        let {showModel} = this.state
        let {orderDetail} = this.props

        // if (this._form) {
        //     this._form.setFieldsValue(
        //         {
        //             Model: editRecord.CategoryName, 
        //         }
        //     )
        // }

        let model = undefined
        if (orderDetail) {
            model = orderDetail.OrderBase.Model
        }

        let content = (
            <Form onFinish={(values) => this.modalConfirmHd(values)}
            // labelCol={{span: 4}}
            // wrapperCol={{span: 20}}
            ref={node => this._form = node}
            initialValues={
                {
                    Model: model,  
                }
            }
            >
                <Form.Item name="Model" label="型号" >
                    <Input />
                </Form.Item>
                    
                <Row justify="end">
                    <Space size="middle" >
                    <Button type="default" onClick={() => this.modalCancelHd()}>取消</Button>
                    <Button type="primary" onClick={undefined} htmlType="submit">保存</Button>
                    </Space>
                </Row>
            </Form>
        )

        return (
            <Modal 
                title="设置型号"
                visible={showModel}
                onCancel={this.modalCancelHd}
                okText="保存" cancelText="取消"
                footer={null}
                width={560}
                >
                {content}
                   
            </Modal>
        )
    }

    tabsOnchangeHd(activeKey) {
        let newState = Object.assign({}, this.state)
        newState.activeKey = activeKey
        this.setState(newState)
    }


    render() {
        let {orderDetail, tokenInfo} = this.props
        let {activeKey} = this.state

        let paymentTab = this.mkPaymentTab()
        let uploadResourceTab = this.mkUploadResourceTab()
        let postSampleTab = this.mkPostSampleTab()

        let disApproveButton = this.getDisplayApproveButton(orderDetail, tokenInfo)
        
        return (
            <div>
                {this.mkSetModelModal()}
                {disApproveButton}
            
                <Tabs activeKey={activeKey} onChange={this.tabsOnchangeHd} type="card">
                    <TabPane tab="付款信息" key="1">
                        {paymentTab}
                    </TabPane>

                    <TabPane tab="产品资料" key="2">
                        {uploadResourceTab}
                    </TabPane>

                    <TabPane tab="样品信息" key="3">
                        {postSampleTab}
                    </TabPane>

                </Tabs>
            </div>
        )
    }
}