import React, { Component } from 'react';
import  {message, Input, Space, Button, Select, Row, Col, Tag, DatePicker, TimePicker  } from 'antd'

import {SyncOutlined, FileExcelOutlined, LineChartOutlined, AreaChartOutlined, 
    SearchOutlined} from '@ant-design/icons'
import moment from 'moment';


export const HyMessage = (type, msgBody, duration=3, onClose=undefined) => {
    let fun = undefined
    switch(type) {
        case "success":
            fun = message.success
            break;

        case "error":
            fun = message.error
            break;

        case "info":
            fun = message.info
            break;

        case "warning":
            fun = message.warning
            break;

        case "loading":
            fun = message.loading
            break;

        default:
            break;

    }
    if (fun !== undefined){
        fun(msgBody, duration, onClose)
    }
}


export const HoBuildTableData = (dataList) => {
    let result_list = [];
    if (dataList === undefined) {
        return undefined;
    }

    for (let i = 0; i < dataList.length; i++){
        let item = dataList[i];
        item.key = i;
        result_list.push(item);
    }

    return result_list;
}

export const HoPaginationConf = (totalNum, pageNum=1, pageSize=10, position="", pageOps=['10', '20', '50', '100']) => {
    return {
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: pageOps,
        defaultCurrent: pageNum,
        defaultPageSize: pageSize,
        position: [position], 
        showTotal: (totalNum, range) => `显示总共 ${totalNum} 条记录中的 ${range[0]} 至 ${range[1]} 条`,
        total: totalNum ? totalNum : 0,
    };
}

export const HoBuildTblColNumberSearch = (dataIndex, title, handleSearch, handleReset) => {
    const searchChangeHd = (value, index, selectedKeys, setSelectedKeys) => {
        if (value === undefined) {
            return;
        }

        let newKeys = Object.assign([], selectedKeys)
        newKeys[index] = value
        setSelectedKeys(newKeys)
    }

    let filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Row >
                <Input
                placeholder={`搜索 ${title}`}
                value={selectedKeys[0]}
                // onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onChange={e => searchChangeHd(e.target.value, 0, selectedKeys, setSelectedKeys)}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
            </Row>

            <Row style={{ width: 188, marginBottom: 8}}>
                <Button type="text" color="#f0f5ff">操作符</Button>
                <Select defaultValue="=" style={{width: "114px"}} onChange={value => searchChangeHd(value, 1, selectedKeys, setSelectedKeys)}>
                    <Select.Option value="=">=</Select.Option>
                    <Select.Option value=">=">&ge;</Select.Option>
                    <Select.Option value=">">&gt;</Select.Option>
                    <Select.Option value="<=">&le;</Select.Option>
                    <Select.Option value="<">&lt;</Select.Option>
                </Select>
            </Row>
                
            <Row>
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        搜索
                    </Button>
                
                    <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
                        清除
                    </Button>
                </Space>
            </Row>
        </div>
    )

    let filterIcon = (filtered) => (<SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />)

    return {
        filterDropdown: filterDropdown, 
        filterIcon: filterIcon, 
    }
}

export const HoBuildTblColStrSearch = (dataIndex, title, handleSearch, handleReset) => {
    let filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
                <Input
                  placeholder={`搜索 ${title}`}
                  value={selectedKeys[0]}
                  onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                  onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                  <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                  >
                    搜索
                  </Button>
                  <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
                    清除
                  </Button>
                </Space>
              </div>
        )

    let filterIcon = (filtered) => (<SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />)

    return {
        filterDropdown: filterDropdown, 
        filterIcon: filterIcon, 
    }
}

export const HoBuildTblColTimeSearch = (dataIndex, title, handleSearch, handleReset) => {
    const searchChangeHd = (value, index, selectedKeys, setSelectedKeys) => {
        if (value === undefined) {
            return;
        }

        let newKeys = Object.assign([], selectedKeys)
        newKeys[index] = value
        setSelectedKeys(newKeys)
    }

    const testHd = (time, timeStr) => {
        console.log("@@@@@: time = ", time)
        console.log("@@@@@: timeStr = ", timeStr)
    }

    let rowStyle = {width: 248, marginBottom: 8}
    let rolStyle = {width: 120}

    let filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Row style={rowStyle}>
            <Space>
                <DatePicker onChange={testHd} placeholder="开始日期" style={rolStyle}/>
                <TimePicker onChange={testHd} placeholder="开始时间" defaultValue={moment('00:00:00', 'HH:mm:ss')} style={rolStyle}/>
            </Space>
            </Row>

            <Row style={rowStyle}>
                <Space>
                    <DatePicker onChange={testHd} placeholder="结束日期" style={rolStyle}/>
                    <TimePicker onChange={testHd} placeholder="结束时间" defaultValue={moment('00:00:00', 'HH:mm:ss')} style={rolStyle}/>
                </Space>
            </Row>
                
            <Row>
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={rolStyle}
                    >
                        搜索
                    </Button>
                
                    <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={rolStyle}>
                        清除
                    </Button>
                </Space>
            </Row>
        </div>
    )

    let filterIcon = (filtered) => (<SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />)

    return {
        filterDropdown: filterDropdown, 
        filterIcon: filterIcon, 
    }
}

