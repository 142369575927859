import React, { Component } from 'react'
import { connect } from 'react-redux'
import {getNetRequestStatus, netRequestThunk, actNetRequestClear} from '../../network/netReqRD'
import {getFeatureCategory, getFeatureSubcategory, getFeatureCategoryListSuccessHd, getFeatureSubcategoryListSuccessHd} from './productRD'
import {getLoginTokenInfo} from '../account/loginRD'
import {FeatureSubcategoryPage} from './featureSubcategory'
import {HoBuildTableData} from '../../component/utils'
import {HoMkSearchFilters} from '../../component/tbl'

class FeatureSubcategoryCT extends Component {
    constructor(props){
        super(props)
        this.pageChangeHd = this.pageChangeHd.bind(this)
        this.tokenCheckHd = this.tokenCheckHd.bind(this)
        this.refreshHd = this.refreshHd.bind(this)
        this.searchHd = this.searchHd.bind(this)
        this.requestData = this.requestData.bind(this)
        this.deleteOnClickHd = this.deleteOnClickHd.bind(this)
        this.delConfirmHd = this.delConfirmHd.bind(this)
        this.getLoadingFlag = this.getLoadingFlag.bind(this)
        this.addConfirmHd = this.addConfirmHd.bind(this)
        this.editConfirmHd = this.editConfirmHd.bind(this)


        this._pagination = {
            PageSize: 50, 
            PageNum: 1, 
            Filters: [[]], 
            OrderKey: "", 
            Desc: 1, 
        }

        this.state = {
            PageSize: this._pagination.PageSize, 
            PageNum: this._pagination.PageNum, 
        }

        this._delRecord = undefined

    }

    tokenCheckHd() {
        let {tokenInfo, history} = this.props
        if (tokenInfo === undefined) {
            const directPath = '/'
            history.push(directPath)
            return false
        }
        return true
    }

    requestData() {
        if (!this.tokenCheckHd()) {
            return
        }
        let {PageSize, PageNum, Filters, OrderKey, Desc} = this._pagination
        let {dispatch, tokenInfo} = this.props
        let msgBody = {
            PageSize: PageSize, 
            PageNum: PageNum,
            Filters: Filters, 
            OrderKey: OrderKey, 
            Desc: Desc
        }
        dispatch(netRequestThunk("/hq/v1/mntn/product/featureSubcategory/list", msgBody, "getFeatureSubcategoryList", 
        getFeatureSubcategoryListSuccessHd, tokenInfo.token))
    }

    componentWillUnmount(){
        let {dispatch} = this.props
        dispatch(actNetRequestClear("getFeatureCategoryList"))
        dispatch(actNetRequestClear("getFeatureSubcategoryList"))
        dispatch(actNetRequestClear("addFeatureSubcategory"))
        dispatch(actNetRequestClear("modFeatureSubcategory"))
        dispatch(actNetRequestClear("delFeatureSubcategory"))
    }

    componentDidMount() {
        this.requestData()

        // 获取特性类别
        let {dispatch, tokenInfo} = this.props
        let msgBody = {
            PageSize: 5000, 
            PageNum: 1,
            Filters: [[]], 
            OrderKey: "", 
            Desc: 0
        }
        dispatch(netRequestThunk("/hq/v1/mntn/product/featureCategory/list", msgBody, "getFeatureCategoryList", getFeatureCategoryListSuccessHd, tokenInfo.token))
    }

    refreshHd() {
        this.requestData()
    }

    pageChangeHd(current, size) {
        if (current !== this._pagination.PageNum || size !== this._pagination.PageSize) {
            this._pagination.PageNum = current
            this._pagination.PageSize = size
            // 需要重新请求数据
            this.requestData()
            // set state
            let newState = Object.assign({}, this.state)
            newState.PageNum = current
            newState.PageSize = size
            this.setState(newState)
        }
    }

    searchHd(searchInfo) {
        let filters = HoMkSearchFilters(searchInfo)
        this._pagination.Filters = filters
        this._pagination.PageNum = 1

        // 这里进行网络请求
        this.requestData()
    }

    deleteOnClickHd(key, record) {
        this._delRecord = record
    }

    delConfirmHd(e, type) {
        e.stopPropagation();
        if (type === 'confirm') {
            if (this._delRecord !== undefined) {
                if (!this.tokenCheckHd()) {
                    this._delRecord = undefined  
                    return
                }

                let {dispatch, tokenInfo} = this.props
                let msgBody = {
                    Id: this._delRecord.SubcategoryId, 
                }
                dispatch(netRequestThunk("/hq/v1/mntn/product/featureSubcategory/del", msgBody, "delFeatureSubcategory", undefined, tokenInfo.token))
            }
        }

        this._delRecord = undefined        
    }


    getLoadingFlag() {
        let {reqStatus, addStatus, modStatus, delStatus, dispatch} = this.props

        let isLoading = false
        if (reqStatus === 1 || addStatus === 1 || modStatus === 1 || delStatus === 1) {
            isLoading = true
        } 

        if (addStatus === 2) {
            dispatch(actNetRequestClear("addFeatureSubcategory"))
            this.requestData()
            return isLoading
        }

        if (modStatus === 2) {
            dispatch(actNetRequestClear("modFeatureSubcategory"))
            this.requestData()
            return isLoading
        }

        if (delStatus === 2) {
            dispatch(actNetRequestClear("delFeatureSubcategory"))
            this.requestData()
            return isLoading
        }

        return isLoading
    }

    editConfirmHd(record) {
        if (!this.tokenCheckHd()) {
            return
        }
        let {dispatch, tokenInfo} = this.props
        let msgBody = {
            Id: record.Id, 
            CategoryId: record.CategoryId, 
            Name: record.Name, 
            Display: record.Display, 
            Remark: record.Remark, 
        }

        dispatch(netRequestThunk("/hq/v1/mntn/product/featureSubcategory/mod", msgBody, "modFeatureSubcategory", undefined, tokenInfo.token))
    }

    addConfirmHd(record) {
        if (!this.tokenCheckHd()) {
            return
        }

        let {dispatch, tokenInfo} = this.props
        let msgBody = {
            CategoryId: record.CategoryId, 
            Name: record.Name, 
            Display: record.Display, 
            Remark: record.Remark, 
        }

        dispatch(netRequestThunk("/hq/v1/mntn/product/featureSubcategory/add", msgBody, "addFeatureSubcategory", undefined, tokenInfo.token))
    }


    render(){
        let {dataInfo, selectFlag, selectedHd, categorys} = this.props
        let {PageNum, PageSize} = this.state
        let totalNum = 0
        let dataList = undefined
        if (dataInfo !== undefined) {
            totalNum = dataInfo.TotalNum
            dataList = HoBuildTableData(dataInfo.RecordList)
        }

        let isLoading = this.getLoadingFlag()
        
        return (
            <FeatureSubcategoryPage 
            pageChangeHd={this.pageChangeHd}
            totalNum={totalNum}
            dataList={dataList}
            refreshHd={this.refreshHd}
            searchHd={this.searchHd}
            pageNum={PageNum}
            pageSize={PageSize}
            loading={isLoading}
            selectFlag={selectFlag}
            selectedHd={selectedHd}
            categorys={categorys}
            deleteOnClickHd={this.deleteOnClickHd}
            delConfirmHd={this.delConfirmHd}
            editConfirmHd={this.editConfirmHd}
            addConfirmHd={this.addConfirmHd}
            />
        );
    }
}

const mapState = (state) => ({
    dataInfo: getFeatureSubcategory(state), 
    categorys: getFeatureCategory(state), 
    tokenInfo: getLoginTokenInfo(state), 
    reqStatus: getNetRequestStatus(state, 'getFeatureSubcategoryList'), 
    addStatus: getNetRequestStatus(state, 'addFeatureSubcategory'), 
    modStatus: getNetRequestStatus(state, 'modFeatureSubcategory'), 
    delStatus: getNetRequestStatus(state, 'delFeatureSubcategory'), 
});

export default connect(
    mapState, 
    null
)(FeatureSubcategoryCT);


