import React, { Component } from 'react'
import {Card, Row, Col, Button, Popconfirm, Space, Divider, Typography, Descriptions, Form, Input, Modal, Select, Collapse, Tag } from 'antd'
import {SyncOutlined, PlusOutlined, EditOutlined, SaveOutlined, CheckOutlined} from '@ant-design/icons'

import CertTestCategoryListCT from '../cert/testCategoryCT'


// -------------------------------------------------------------------------
export class TestsuitCategoryForm extends Component {
    constructor(props){
        super(props)

        this.mkSelectModal = this.mkSelectModal.bind(this)
        this.showSelectHd = this.showSelectHd.bind(this)
        this.selectCancelHd = this.selectCancelHd.bind(this)
        this.selectHd = this.selectHd.bind(this)
        this.hoClearForm = this.hoClearForm.bind(this)
        this.finishedHd = this.finishedHd.bind(this)
        this.hoSetEditMode = this.hoSetEditMode.bind(this)

        this.state = {
            showSelect: false, 
            editRecord: {}, 
            editMode: "add"
        }
    }

    hoClearForm() {
        if (this._form) {
            // 清空相关信息
            console.log("@@@@@: hoClearForm enter!")
            this._form.setFieldsValue(
                {
                    TestCategoryName: undefined, 
                    Period: undefined,  
                    Price: undefined, 
                    IsDefault: undefined,  
                    Seq: undefined,  
                    Remark: undefined,  
                }
            )
            this._formInit = false
        }
        let newState = Object.assign({}, this.state)
        newState.showSelect = false
        newState.editRecord = {}
        this.setState(newState)
    }

    hoSetEditMode(editMode) {
        let newState = Object.assign({}, this.state)
        newState.editMode = editMode
        this.setState(newState)
    }

    mkSelectModal() {
        let {showSelect} = this.state
        return (
            <Modal 
            title="关联检测大类"
            visible={showSelect}
            width={1280}
            footer={null}
            onOk={undefined}
            onCancel={this.selectCancelHd}
            >
                <CertTestCategoryListCT 
                selectFlag={true}
                selectedHd={this.selectHd}
                />

            </Modal>
        )
    }

    showSelectHd() {
        let newState = Object.assign({}, this.state)
        newState.showSelect = true
        this.setState(newState)
    }

    selectCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.showSelect = false
        this.setState(newState)
    }

    selectHd(key, record) {
        let {editRecord} = this.state

        console.log("@@@@@@___2: record = ", record)
        
        // editRecord.Id = record.Id
        editRecord.TestCategoryName = record.Name
        editRecord.TestCategoryId = record.Id

        if (this._form) {
            this._form.setFieldsValue(
                {
                    TestCategoryName: editRecord.TestCategoryName, 
                }
            )
        }

        let newState = Object.assign({}, this.state)
        newState.showSelect = false
        newState.editRecord = editRecord
        this.setState(newState)
    }

    finishedHd(values) {
        let {editRecord} = this.state
        let {formSubmitHd, record} = this.props
        if (formSubmitHd) {
            values.Id = record ? record.Id : undefined
            values.TestCategoryId = editRecord.TestCategoryId ? editRecord.TestCategoryId : record ? record.CategoryId : undefined
            values.Period = parseFloat(values.Period)
            values.Price = parseFloat(values.Price)
            values.Seq = parseInt(values.Seq)
            formSubmitHd(values)
        }

        let newState = Object.assign({}, this.state)
        newState.showSelect = false
        newState.editRecord = editRecord
        this.setState(newState)
    }


    render() {
        let {record, modalCancelHd} = this.props
        let {editRecord, editMode} = this.state
        let selectButtonDisable = false
        if (record) {
            selectButtonDisable = true
        }

        if (this._form && !this._formInit) {
            if (editMode === "mod") {
                this._form.setFieldsValue(
                    {
                        TestCategoryName: record ? record.CategoryName : undefined, 
                        Period: record ? record.Period : undefined, 
                        Price: record ? record.Price : undefined, 
                        IsDefault: record ? record.IsDefault : 1, 
                        Seq: record ? record.Seq : 0, 
                        Remark: record ? record.Remark : "", 
                    }
                )
                this._formInit = true
            }
        }

        return (
            <div>
                {this.mkSelectModal()}
                <Form onFinish={this.finishedHd} ref={node => this._form = node} 
                labelCol={{span: 4}} wrapperCol={{span: 20}} 
                initialValues={
                    {
                        TestCategoryName: record ? record.CategoryName : undefined, 
                        Period: record ? record.Period : undefined, 
                        Price: record ? record.Price : undefined, 
                        IsDefault: record ? record.IsDefault : 1, 
                        Seq: record ? record.Seq : 0, 
                        Remark: record ? record.Remark : "", 
                    }
                }
                >
                    <Form.Item rules={[{required: true, message: '请选择检测大类!'}]} label="检测大类" name="TestCategoryName">
                        <Row justify="start">
                            
                            <Col span={16}>
                            <Input placeholder="请点击右边按钮选择检测大类" 
                            value={editRecord.TestCategoryName ? editRecord.TestCategoryName : (record ? record.CategoryName : undefined)} disabled/>
                            </Col>
                            <Col push={1} >
                            <Button type="primary" icon={<CheckOutlined />} onClick={this.showSelectHd} disabled={editMode === "mod"}>选择检测大类</Button>
                            </Col>
                        </Row>    
                    </Form.Item>

                    <Form.Item name="Period" rules={[{required: true, message: '请输入周期!'}]} label="周期（工作日）" >
                        <Input  type="number"/>
                    </Form.Item>

                    <Form.Item name="Price" rules={[{required: true, message: '请输入价格!'}]} label="价格" >
                        <Input  type="number"/>
                    </Form.Item>

                    <Form.Item name="IsDefault" label="默认选中" >
                        <Select>
                            <Select.Option value={1}>是</Select.Option>
                            <Select.Option value={0}>否</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name="Seq" label="序号" >
                        <Input  type="number"/>
                    </Form.Item>

                    <Form.Item name="Remark" label="备注" >
                        <Input  />
                    </Form.Item>

                    <Row justify="end">
                        <Space size="middle" >
                        <Button type="default" onClick={modalCancelHd}>取消</Button>
                        <Button type="primary" onClick={undefined} htmlType="submit">保存</Button>
                        </Space>
                    </Row>

                </Form>
            </div>
        )
    }
}

export class TestsuitCaseForm extends Component {
    constructor(props){
        super(props)

        this.state = {
            
        }
    }

    render() {

        return (
            <div>

            </div>
        )
    }
}

export class TestsuitParamForm extends Component {
    constructor(props){
        super(props)

        this.state = {
            
        }
    }

    render() {

        return (
            <div>

            </div>
        )
    }
}

