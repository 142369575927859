import React, { Component } from 'react';

import  {message, Table, Input, Space, Button, Select, Row, Col, Tag, DatePicker, TimePicker  } from 'antd'
import {SearchOutlined} from '@ant-design/icons'
import moment from 'moment';
import {HoPaginationConf} from './utils'


export const HoMkTblColumn = (title, dataIndex, type, width='20%', searchable=false, sortable=false, valueMapFunc=undefined, extra={}) => {
    let ret = {
        title: title,
        dataIndex: dataIndex,
        type: type,
        key: dataIndex, 
        width: width,
        searchable: searchable,
        sorter: sortable,
        ...extra,
    }

    if (valueMapFunc !== undefined){
        ret.render = (text, record) => {
            return valueMapFunc(record[dataIndex])
        }
    }

    return ret;
}

export const HoMkTblColumn2 = (title, dataIndex, type, width='20%', searchable=false, sortable=false, valueMapFunc=undefined, rowSpan=false, extra={}) => {
    let ret = {
        title: title,
        dataIndex: dataIndex,
        type: type,
        key: dataIndex, 
        width: width,
        searchable: searchable,
        sorter: sortable,
        ...extra,
    }

    if (valueMapFunc !== undefined){
        ret.render = (text, row, index) => {
            return valueMapFunc(row[dataIndex])
        }
    }

    if (rowSpan) {
        ret.render = (text, row, index) => {
            const obj = {
                children: text.value,
                props: {rowSpan: text.rowSpan},
              };
            return obj
        }
    }

    return ret;
}

// actions = [{key: '', display: '', onClickedHd: undefine}]
export const HoMkTblColActions = (actions, width="10%", title="操作") => {
    let ret = {
        title: title,
        dataIndex: 'op', 
        key: 'op', 
        width: width, 
    }

    ret['render'] = (text, record) => {
        const actionContent = actions.map(
            (item, index) => (
                <a key={index} 
                onClick={(e) => {
                    e.stopPropagation()
                    return (item.onClickedHd ? item.onClickedHd(item.key, record) : undefined)
                    } }>
                    {item.display}</a>
            )
        );

        return (
            <Space>
                {actionContent}
            </Space>
        )
    }

    return ret
}

const _searchValueMap = (item) => {
    let ret = []
    switch(item.type) {
        case "number":
        {
            let tmpRet = {Key: item.dataIndex, Type: "int", Op: item.op, Value: item.value}
            ret.push(tmpRet)
        }
        break;
        
        case "time": 
            if (item.startTime !== undefined) {
                let tmpRet = {Key: item.dataIndex, Type: "str", Op: ">=", Value: item.startTime}
                ret.push(tmpRet)
            }

            if (item.endTime !== undefined) {
                let tmpRet = {Key: item.dataIndex, Type: "str", Op: "<=", Value: item.endTime}
                ret.push(tmpRet)
            }
        break;

        case "str":
        {
            let tmpRet = {Key: item.dataIndex, Type: "str", Op: "like", Value: item.value}
            ret.push(tmpRet)
        }
        break;

        default:
        break;
    }
    return ret
}

export const HoMkSearchFilters = (searchInfo) => {
    let filters = [[]]
    do {
        if (searchInfo === undefined){
            break;
        }

        for (let dataIndex in searchInfo) {
            let item = searchInfo[dataIndex]
            if (item === undefined) {
                continue
            }

            let contents = _searchValueMap(item)
            for (let tmpContent of contents) {
                filters[0].push(tmpContent)
            }
        }

    }while(false)

    return filters
}

export class HoTable extends Component {
    constructor(props) {
        super(props)
        
        this.onChangeHd = this.onChangeHd.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.handleReset = this.handleReset.bind(this)
        this.getColSearchInfo = this.getColSearchInfo.bind(this)
        this.setColSearchInfo = this.setColSearchInfo.bind(this)
        this.rowOnClickHd = this.rowOnClickHd.bind(this)

        let {columns} = props
        this._columns = this.genColumnConf(columns)
        
        this.state = {
            searchInfo: {},
        }

    }

    genColumnConf(srcCols) {
        let columns = Object.assign([], srcCols)
        for (let item of columns) {
            if (item.searchable === true) {
                if (item.type === 'str') {
                    Object.assign(item, this.buildTblColStrSearch(item.dataIndex, item.title))
                }else if (item.type === 'number') {
                    Object.assign(item, this.buildTblColNumberSearch(item.dataIndex, item.title))
                }else if (item.type === 'time') {
                    Object.assign(item, this.buildTblColTimeSearch(item.dataIndex, item.title))
                }
            }
        }

        return columns
    }

    getColSearchInfo(dataIndex) {
        let {searchInfo} = this.state
        return searchInfo[dataIndex]
    }

    setColSearchInfo(dataIndex, info) {
        let {searchInfo} = this.state
        searchInfo[dataIndex] = info
    }

    handleSearch(selectedKeys, confirm, dataIndex) {
        confirm()
        let {searchInfo} = this.state

        // 搜索信息传递到上传
        let {searchHd} = this.props
        if (searchHd !== undefined) {
            searchHd(searchInfo)
        }

        console.log("@#@#@#@#@#@: searchInfo = ", searchInfo)
    }

    handleReset(clearFilters, dataIndex) {
        clearFilters()

        let itemInfo = this.getColSearchInfo(dataIndex)
        if (itemInfo === undefined) {
            return
        }
        this.setColSearchInfo(dataIndex, undefined)
        let {searchInfo} = this.state
        let {searchHd} = this.props
        if (searchHd !== undefined) {
            searchHd(searchInfo)
        }
        console.log("@#@#@#@#@#@: searchInfo = ", searchInfo)
    }

    onChangeHd(pagination, filters, sorter, extra) {
        let {pageChangeHd} = this.props
        if (extra === undefined) {
            return
        }

        if (extra.action === "paginate"){
            // 分页操作
            if (pageChangeHd === undefined || pagination === {}) {
                return
            }

            pageChangeHd(pagination.current, pagination.pageSize)
            return
        }

    }

    buildTblColStrSearch(dataIndex, title) {
        let rowStyle = {width: 168, marginBottom: 8}
        let rolStyle = {width: 80}

        const getSearchValue = (dataIndex) => {
            let searchInfo = this.getColSearchInfo(dataIndex)
            if (searchInfo === undefined) {
                return undefined
            }
            return searchInfo.value
        }

        const strChangeHd = (dataIndex, value, setSelectedKeys, selectedKeys) => {
            let searchInfo = this.getColSearchInfo(dataIndex)
            if (searchInfo === undefined) {
                searchInfo = {dataIndex: dataIndex, type: 'str', value: value}
            }else{
                searchInfo.value = value
            }
            this.setColSearchInfo(dataIndex, searchInfo)

            let newKeys = Object.assign([], selectedKeys)
            newKeys[0] = value
            setSelectedKeys(newKeys)
        }

        let filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Row>
                    <Input
                      placeholder={`搜索 ${title}`}
                      value={getSearchValue(dataIndex)}
                    //   onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                      onChange={e => strChangeHd(dataIndex, e.target.value, setSelectedKeys, selectedKeys)}
                      onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                      style={rowStyle}
                    />
                </Row>

                <Row>
                    <Space>
                      <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={rolStyle}
                      >
                        搜索
                      </Button>
                      <Button onClick={() => this.handleReset(clearFilters, dataIndex)} size="small" style={rolStyle}>
                        清除
                      </Button>
                    </Space>
                </Row>
            </div>
            )
    
        let filterIcon = (filtered) => (<SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />)
    
        return {
            filterDropdown: filterDropdown, 
            filterIcon: filterIcon, 
        }
    }

    buildTblColNumberSearch(dataIndex, title) {
        let rowStyle = {width: 168, marginBottom: 8}
        let rolStyle = {width: 80}

        const getSearchValue = (dataIndex, type) => {
            let searchInfo = this.getColSearchInfo(dataIndex)
            if (searchInfo === undefined) {
                return undefined
            }
            if (type === 0) {
                return searchInfo.value
            }else{
                return searchInfo.op
            }
        }

        const numberChangeHd = (dataIndex, value, index, setSelectedKeys, selectedKeys) => {
            let searchInfo = this.getColSearchInfo(dataIndex)
            if (searchInfo === undefined) {
                if (index === 0) {
                    searchInfo = {dataIndex: dataIndex, type: 'number', value: value, op: "="}
                }else{
                    searchInfo = {dataIndex: dataIndex, type: 'number', value: undefined, op: value}
                }
            }else{
                if (index === 0) {
                    searchInfo.value = value
                }else{
                    searchInfo.op = value
                }
            }
            this.setColSearchInfo(dataIndex, searchInfo)

            let newKeys = Object.assign([], selectedKeys)
            newKeys[index] = value
            setSelectedKeys(newKeys)
        }
    
        let filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Row >
                    <Input
                    placeholder={`搜索 ${title}`}
                    value={getSearchValue(dataIndex, 0)}
                    onChange={e => numberChangeHd(dataIndex, e.target.value, 0, setSelectedKeys, selectedKeys)}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={rowStyle}
                    />
                </Row>
    
                <Row style={rowStyle}>
                    <Button type="text" color="#f0f5ff">操作符</Button>
                    <Select defaultValue="=" 
                        style={{width: "94px"}} 
                        onChange={value => numberChangeHd(dataIndex, value, 1, setSelectedKeys, selectedKeys)}
                        value={getSearchValue(dataIndex, 1)}
                        // value={">="}
                        >
                        <Select.Option value="=">=</Select.Option>
                        <Select.Option value=">=">&ge;</Select.Option>
                        <Select.Option value=">">&gt;</Select.Option>
                        <Select.Option value="<=">&le;</Select.Option>
                        <Select.Option value="<">&lt;</Select.Option>
                    </Select>
                </Row>
                    
                <Row>
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={rolStyle}
                        >
                            搜索
                        </Button>
                    
                        <Button onClick={() => this.handleReset(clearFilters, dataIndex)} size="small" style={rolStyle}>
                            清除
                        </Button>
                    </Space>
                </Row>
            </div>
        )

        let filterIcon = (filtered) => (<SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />)
        return {
            filterDropdown: filterDropdown, 
            filterIcon: filterIcon, 
        }
    }

    buildTblColTimeSearch(dataIndex, title) {
        let rowStyle = {width: 188, marginBottom: 8}
        let rolStyle = {width: 90}

        const getSearchValue = (dataIndex, index) => {
            let ret = null
            let searchInfo = this.getColSearchInfo(dataIndex)
            if (searchInfo !== undefined) {
                if (index === 0) {
                    ret = searchInfo.startTime === undefined ? null :  moment(searchInfo.startTime, 'YYYY-MM-DD HH:mm:ss')
                }else{
                    ret = searchInfo.endTime === undefined ? null :  moment(searchInfo.endTime, 'YYYY-MM-DD HH:mm:ss')
                }
            }
            return ret;
        }

        const getValue2 = (dataIndex, index) => {
            // 必须要封装一个函数后调用才能获取正确的值
            return getSearchValue(dataIndex, index)
        }

        const timeChangeHd = (dataIndex, value, index, setSelectedKeys, selectedKeys) => {
            let searchInfo = this.getColSearchInfo(dataIndex)
            if (searchInfo === undefined){
                searchInfo = {dataIndex: dataIndex, type: 'time', startTime: undefined, endTime: undefined}
            }

            if (index === 0) {
                searchInfo.startTime = value
            }else{
                searchInfo.endTime = value
            }

            this.setColSearchInfo(dataIndex, searchInfo)

            let newKeys = Object.assign([], selectedKeys)
            newKeys[index] = value
            setSelectedKeys(newKeys)
        }

        let filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Row style={rowStyle}>
                    <DatePicker onChange={(time, timeStr) => timeChangeHd(dataIndex, timeStr, 0, setSelectedKeys, selectedKeys)} 
                        key="1"
                        placeholder="开始时间" 
                        value={getSearchValue(dataIndex, 0)}
                        showTime={true}
                        />
                </Row>
    
                <Row style={rowStyle}>
                    <DatePicker onChange={(time, timeStr) => timeChangeHd(dataIndex, timeStr, 1, setSelectedKeys, selectedKeys)} 
                        placeholder="结束时间" 
                        key="2"
                        value={getValue2(dataIndex, 1)}
                        showTime={true}
                        />
                </Row>
                    
                <Row>
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={rolStyle}
                        >
                            搜索
                        </Button>
                    
                        <Button onClick={() => this.handleReset(clearFilters, dataIndex)} size="small" style={rolStyle}>
                            清除
                        </Button>
                    </Space>
                </Row>
            </div>
        )
    
        let filterIcon = (filtered) => (<SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />)
    
        return {
            filterDropdown: filterDropdown, 
            filterIcon: filterIcon, 
        }
    }

    rowOnClickHd(record) {
        let {rowOnClickHd} = this.props
        if (rowOnClickHd) {
            rowOnClickHd(record)
        }
    }


    render() {
        let {dataList, totalNum, size, pageNum, pageSize, position, pageOps, loading, rowSelection, expandable, disablePagination, disableRowClass, extra} = this.props
        let isLoading = false
        if (loading !== undefined) {
            isLoading = loading
        }
        let tblSize = "small"
        if (size !== undefined){
            tblSize = size
        }

        let rowClassName = (record, index) => {
            return (index % 2) ? 'ho_table_cell_1' : '';
        }

        if (disableRowClass) {
            rowClassName = undefined
        }

        return (
            <Table columns={this._columns} 
                rowSelection={rowSelection}
                expandable={expandable}
                size={tblSize}
                bordered={true}
                dataSource={dataList}
                onChange={this.onChangeHd}
                loading={isLoading}
                pagination={disablePagination ? false : HoPaginationConf(totalNum, pageNum, pageSize, position, pageOps)}
                rowClassName={rowClassName}
                onRow={record => {return{
                    onClick: event => {this.rowOnClickHd(record)}, 
                }}}
                {...extra}
                // rowClassName={(record, index) => {
                //         return (index % 2) ? 'ho_table_cell_1' : '';
                //     }}
                />
        )
    }
}


