import React, { Component } from 'react'

import {Card, Row, Col, Button, Descriptions, Popconfirm, Space, Form, Input, Modal, Select, Collapse, Typography, Divider, Tabs } from 'antd'
import {SyncOutlined, PlusOutlined, CheckOutlined} from '@ant-design/icons'
import {HoBreadcrumb} from '../../component/element'
import {yesOrNoMap} from '../../comm/logic'
import {HoTable, HoMkTblColumn, HoMkTblColActions} from '../../component/tbl'
import {TestsuitCategoryForm, TestsuitCaseForm, TestsuitParamForm} from './detailForm'
import {HoBuildTableData} from '../../component/utils'

const { Panel } = Collapse
const { TabPane } = Tabs


export class TestsuitDetailPage extends Component {
    constructor(props){
        super(props)

        this.mkOpButtons = this.mkOpButtons.bind(this)
        this.mkTitleContent = this.mkTitleContent.bind(this)
        this.mkCategoryPanel = this.mkCategoryPanel.bind(this)
        this.mkCasePanel = this.mkCasePanel.bind(this)
        this.mkParamPanel = this.mkParamPanel.bind(this)
        this.categoryRfAddOnClickHd = this.categoryRfAddOnClickHd.bind(this)
        this.caseRfAddOnClickHd = this.caseRfAddOnClickHd.bind(this)
        this.paramRfAddOnClickHd = this.paramRfAddOnClickHd.bind(this)
        this.mkGategoryRfModel = this.mkGategoryRfModel.bind(this)
        this.mkCaseRfModel = this.mkCaseRfModel.bind(this)
        this.mkParamRfModel = this.mkParamRfModel.bind(this)
        this.cancelCategoryRfModleHd = this.cancelCategoryRfModleHd.bind(this)
        this.cancelCaseRfModleHd = this.cancelCaseRfModleHd.bind(this)
        this.paramFormSubmitHd = this.paramFormSubmitHd.bind(this)
        this.categoryFormSubmitHd = this.categoryFormSubmitHd.bind(this)
        this.caseFormSubmitHd = this.caseFormSubmitHd.bind(this)
        this.paramFormSubmitHd = this.paramFormSubmitHd.bind(this)

        
        
        let {deleteOnClickHd, delConfirmHd} = props

        this.state = {
            showCategory: false, 
            categoryRecord: undefined, 

            showCase: false, 
            caseRecord: undefined, 

            showParam: false, 
            paramRecord: undefined,

            editMode: undefined,
        }

        this._breadConf = [
            {name: "检测套餐", link: '/setting/testsuitList'}, 
            {name: "套餐列表", link: '/setting/testsuitList'}, 
            {name: "套餐详情", link: undefined }, 
        ]

        const disDelete = (
            <Popconfirm title="请确认是否要删除？" 
            onConfirm={(e) => delConfirmHd ? delConfirmHd(e, 'confirm') : undefined}
            onCancel={(e) => delConfirmHd ? delConfirmHd(e, 'cancel') : undefined}
            >
                <span>删除</span>
            </Popconfirm>
        )

        let rlGategoryAction = HoMkTblColActions([
            {key: 'edit', display: '编辑', onClickedHd: (key, record) => this.editOnclickHd("rfGategory", record)},
            {key: 'delete', display: disDelete, onClickedHd: (key, record) => deleteOnClickHd("rfGategory", record)}, ])

        this._rlCategoryCols = [
            HoMkTblColumn('检测大类', 'CategoryName', 'str', '15%', false, false), 
            HoMkTblColumn('价格', 'Price', 'str', '10%', false, false), 
            HoMkTblColumn('周期(工作日)', 'Period', 'str', '10%', false, false), 
            HoMkTblColumn('默认选中', 'IsDefault', 'number', '10%', false, false, yesOrNoMap), 
            HoMkTblColumn('序号', 'Seq', 'str', '10%', false, false), 
            HoMkTblColumn('备注', 'Remark', 'str', '15%', false, false), 
            rlGategoryAction, 
        ]

        let rlCaseAction = HoMkTblColActions([
            {key: 'edit', display: '编辑', onClickedHd: (key, record) => this.editOnclickHd("rfCase", record)},
            {key: 'delete', display: disDelete, onClickedHd: (key, record) => deleteOnClickHd("rfCase", record)}, ])

        this._rlCaseCols = [
            HoMkTblColumn('检测大类', 'CategoryName', 'str', '10%', false, false), 
            HoMkTblColumn('检测标准', 'StandardName', 'str', '10%', false, false), 
            HoMkTblColumn('检测项', 'CaseName', 'str', '10%', false, false), 
            HoMkTblColumn('价格', 'Price', 'str', '10%', false, false), 
            HoMkTblColumn('周期(工作日)', 'Period', 'str', '10%', false, false), 
            HoMkTblColumn('默认选中', 'IsDefault', 'number', '10%', false, false, yesOrNoMap), 
            HoMkTblColumn('序号', 'Seq', 'str', '10%', false, false), 
            HoMkTblColumn('备注', 'Remark', 'str', '10%', false, false), 
            rlCaseAction, 
        ]

        let rlParamAction = HoMkTblColActions([
            {key: 'edit', display: '编辑', onClickedHd: (key, record) => this.editOnclickHd("rfParam", record)},
            {key: 'delete', display: disDelete, onClickedHd: (key, record) => deleteOnClickHd("rfParam", record)}, ])

        this._rlParamCols = [
            HoMkTblColumn('检测项', 'CaseName', 'str', '10%', false, false), 
            HoMkTblColumn('测量参数', 'ParamItem', 'str', '10%', false, false), 
            HoMkTblColumn('参考范围', 'ParamOkdesc', 'str', '10%', false, false), 
            HoMkTblColumn('价格', 'Price', 'str', '10%', false, false), 
            HoMkTblColumn('周期(工作日)', 'Period', 'str', '10%', false, false), 
            HoMkTblColumn('默认选中', 'IsDefault', 'number', '10%', false, false, yesOrNoMap), 
            HoMkTblColumn('序号', 'Seq', 'str', '10%', false, false), 
            HoMkTblColumn('备注', 'Remark', 'str', '10%', false, false), 
            rlParamAction, 
        ]

        this._categoryModel = undefined
        this._caseModel = undefined
        this._paramModel = undefined

    }

    mkOpButtons() {
        let {refreshHd} = this.props
        return (
            <Row>
                <Space>
                    <Button type="default" icon={<SyncOutlined />} onClick={refreshHd}>刷新</Button>
                </Space>
            </Row>
        )
    }

    editOnclickHd(key, record) {
        console.log("@@@@: record = , key = ", record, key)
        let newtSate = Object.assign({}, this.state)
        newtSate.editMode = "mod"
        newtSate.editRecord = record
        newtSate.srcRecord = record
        if (key === "rfGategory") {
            newtSate.showCategory = true
            newtSate.categoryRecord = record
            if (this._categoryModel) {
                this._categoryModel.hoSetEditMode("mod")
            }
        }else if (key === "rfCase"){
            newtSate.showCase = true
            newtSate.caseRecord = record
        }else if (key === "rfParam") {
            newtSate.showParam = true
            newtSate.paramRecord = record
        }
        this.setState(newtSate)
    }

    mkTitleContent() {
        let {detailInfo} = this.props
        if (!detailInfo) {
            return undefined
        }

        if (!detailInfo.SuitInfo){
            return undefined
        }

        let {Name, Description, LimitDesc, FrontShow} = detailInfo.SuitInfo

        let content = (
            <Descriptions title="检测套餐基本信息" column={2}>
                <Descriptions.Item label="套餐名称">{Name}</Descriptions.Item>
                <Descriptions.Item label="描述">{Description}</Descriptions.Item>
                <Descriptions.Item label="限制描述">{LimitDesc}</Descriptions.Item>
                <Descriptions.Item label="是否显示">{FrontShow === 1 ? "是" : "否"}</Descriptions.Item>
            </Descriptions>
        )
        return content  
    }  

    mkCategoryPanel() {
        let {isLoading, detailInfo} = this.props
        let dataList = undefined
        if (detailInfo) {
            dataList = HoBuildTableData(detailInfo.TestCategoryList)
        }
        return (
            <div>
                <Row justify="end" style={{marginBottom: "16px"}}>
                    <Button type="primary" icon={<PlusOutlined />} onClick={this.categoryRfAddOnClickHd}>新增</Button>
                </Row>
                <HoTable columns={this._rlCategoryCols} 
                                size="middle" 
                                dataList={dataList}
                                loading={isLoading}
                                // noPagination={true}
                />
            </div>
        )
    }

    mkCasePanel() {

        return (
            <div>
                <Row justify="end" style={{marginBottom: "16px"}}>
                    <Button type="primary" icon={<PlusOutlined />} onClick={this.featureAddButtonOnClick}>新增</Button>
                </Row>
                <HoTable columns={this._rlCaseCols} 
                                size="middle" 
                                dataList={undefined}
                                loading={undefined}
                                // noPagination={true}
                />
            </div>
        )
    }

    mkParamPanel() {
        return (
            <div>
                <Row justify="end" style={{marginBottom: "16px"}}>
                    <Button type="primary" icon={<PlusOutlined />} onClick={this.featureAddButtonOnClick}>新增</Button>
                </Row>
                <HoTable columns={this._rlParamCols} 
                                size="middle" 
                                dataList={undefined}
                                loading={undefined}
                                // noPagination={true}
                />
            </div>
        )
    }

    categoryRfAddOnClickHd() {
        console.log("@@@@######$____1")
        let newState = Object.assign({}, this.state)
        newState.showCategory = true
        if (this._categoryModel) {
            this._categoryModel.hoSetEditMode("add")
        }
        this.setState(newState)
    }

    caseRfAddOnClickHd() {
        let newState = Object.assign({}, this.state)
        newState.showCase = true
        this.setState(newState)
    }

    paramRfAddOnClickHd() {
        let newState = Object.assign({}, this.state)
        newState.showParam = true
        this.setState(newState)
    }

    mkGategoryRfModel() {
        let {showCategory, categoryRecord} = this.state

        return (
            <Modal 
                title="检测大类配置"
                visible={showCategory}
                onCancel={this.cancelCategoryRfModleHd}
                okText="保存" cancelText="取消"
                footer={null}
                width={720}
                >
                <TestsuitCategoryForm 
                ref={node => this._categoryModel = node} 
                record={categoryRecord}
                modalCancelHd={this.cancelCategoryRfModleHd}
                formSubmitHd={this.categoryFormSubmitHd}
                />
            </Modal>
        )
    }

    mkCaseRfModel() {
        
    }

    mkParamRfModel() {
        
    }

    cancelCategoryRfModleHd() {
        let newState = Object.assign({}, this.state)
        newState.showCategory = false
        if (this._categoryModel) {
            this._categoryModel.hoClearForm()
        }
        this.setState(newState)
    }

    cancelCaseRfModleHd() {
        let newState = Object.assign({}, this.state)
        newState.showCase = false
        this.setState(newState)
    }

    cancelParamRfModleHd() {
        let newState = Object.assign({}, this.state)
        newState.showParam = false
        this.setState(newState)
    }

    categoryFormSubmitHd(values) {
        console.log("@@@@######: values", values)
        let {addConfirmHd, editConfirmHd} =  this.props
        let {editMode} = this.state
        if (editMode === "mod") {
            if (editConfirmHd) {
                editConfirmHd(values, "rfGategory")
            }
        }else{
            if (addConfirmHd) {
                addConfirmHd(values, "rfGategory")
            }
        }

        let newState = Object.assign({}, this.state)
        newState.showCategory = false
        if (this._categoryModel) {
            this._categoryModel.hoClearForm()
        }
        this.setState(newState)

        // console.log("@@@@@@@@@@: values = , editMode = ", values, editMode)
    }

    caseFormSubmitHd() {
        
    }

    paramFormSubmitHd() {
        
    }

    


    render() {
        let titleContent = this.mkTitleContent()
        
        return (
            <div>
                <HoBreadcrumb itemList={this._breadConf} extra={{style:{marginBottom: '8px'}}} />
                {this.mkGategoryRfModel()}
                {this.mkCaseRfModel()}
                {this.mkParamRfModel()}
                <Card title="检测套餐详情" extra={this.mkOpButtons()}>
                    {titleContent}
                    <Divider />

                    <Typography.Title level={5} style={{marginBottom: "16px"}}>关联配置</Typography.Title>

                    <Tabs type="card">
                        <TabPane tab="检测大类" key="1">
                            {this.mkCategoryPanel()}
                        </TabPane>

                        <TabPane tab="检测项" key="2">
                            {this.mkCasePanel()}
                        </TabPane>

                        <TabPane tab="测量参数" key="3">
                            {this.mkParamPanel()}
                        </TabPane>

                    </Tabs>


                    {/* <Collapse collapsible="" bordered={true} defaultActiveKey={[1, 2, 3]} ghost={false}>
                        <Panel header={<Typography.Text strong >关联检测大类配置</Typography.Text>} key="1">
                            
                        </Panel>

                        <Panel header={<Typography.Text strong >关联检测项配置</Typography.Text>} key="2">
                            
                        </Panel>

                        <Panel header={<Typography.Text strong >关联检测参数配置</Typography.Text>} key="3">
                            
                        </Panel>

                        


                    </Collapse> */}
                    
                </Card>
            </div>
        );
    }


}

