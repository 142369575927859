import {combineReducers} from 'redux';

// 各业务模块reducer导入
import {netRequest_reducer} from '../network/netReqRD'
import {login_reducer} from '../app/account/loginRD'
import {menu_reducer} from '../component/menuRD'
import {cert_reducer} from '../app/cert/certRD'
import {product_reducer} from '../app/product/productRD'
import {order_reducer} from '../app/order/orderRD'
import {kl_reducer} from '../app/kl/klRD'
import {testsuit_reducer} from '../app/testsuit/testsuitRD'


export const RootReducer = combineReducers(
    {
        // 这里添加各action 的处理 handle
        // 公共模块
        net_request: netRequest_reducer, 
        menu: menu_reducer, 

        // 账户
        login: login_reducer, 

        // 业务模块
        cert: cert_reducer, 
        product: product_reducer, 

        // 订单模块
        order: order_reducer, 

        // 知识库
        kl: kl_reducer, 
        testsuit: testsuit_reducer, 

    }
);


