import React, { Component } from 'react';

import {Card, Row, Col, Button, Popconfirm, Space, Modal, Descriptions, Select, 
    Tag, Form, Input } from 'antd'
import {SyncOutlined, PlusOutlined, EyeOutlined} from '@ant-design/icons'


import {HoBreadcrumb, HoDocPreview} from '../../../component/element'
import {HoTable, HoMkTblColumn, HoMkTblColActions} from '../../../component/tbl'


import {yesOrNoMap} from '../../../comm/logic'


export class CertOnlineDocListPage extends Component {
    constructor(props){
        super(props)

        this.mkOpButtons = this.mkOpButtons.bind(this)
        this.mkPreviewModal = this.mkPreviewModal.bind(this)
        this.modalCancelHd = this.modalCancelHd.bind(this)
        this.previewOnclickHd = this.previewOnclickHd.bind(this)

        let {detailOnclickHd, deleteOnClickHd} = props
        this.state = {
            showPreview: false, 
            previewData: undefined, 
        }

        this._breadConf = [
            {name: "知识库管理", link: undefined}, 
            {name: "认证知识（在线）列表", link: undefined}, 
        ]

        let actionContent = HoMkTblColActions([{key: 'detail', display: '详情', onClickedHd: detailOnclickHd}, 
                                            //    {key: 'preview', display: '预览', onClickedHd: this.previewOnclickHd}, 
                                               {key: 'delete', display: '删除', onClickedHd: deleteOnClickHd}, 
                                               ])


        this._columns = [
            HoMkTblColumn('文档名称', 'Name', 'str', '20%', true, false), 
            HoMkTblColumn('文档描述', 'Description', 'str', '30%', true, false), 
            HoMkTblColumn('前台展示', 'FrontShow', 'number', '10%', true, false, yesOrNoMap), 
            HoMkTblColumn('前台展示次数', 'ShowCount', 'str', '10%', false, false), 
            actionContent, 
        ]

    }

    
    mkOpButtons() {
        let {refreshHd, addHd} = this.props
        return (
            <Row>
                <Space>
                    <Button type="primary" icon={<PlusOutlined />} onClick={addHd}>新增</Button>
                    <Button type="default" icon={<SyncOutlined />} onClick={refreshHd}>刷新</Button>
                </Space>
            </Row>
        )
    }

    previewOnclickHd(key, record) {
        let newState = Object.assign({}, this.state)
        newState.showPreview = true
        newState.previewData = record.RawData
        this.setState(newState)
    }

    modalCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.showPreview = false
        newState.previewData = undefined
        this.setState(newState)
    }

    
    mkPreviewModal() {
        let {showPreview, previewData} = this.state

        return (
            <Modal 
                title="文档预览"
                visible={showPreview}
                onOk={this.modalCancelHd}
                onCancel={this.modalCancelHd}
                okText="确定"
                footer={null}
                >
                <HoDocPreview rawData={previewData}/>
                   
            </Modal>
        )
    }


    render() {
        let {pageChangeHd, totalNum, dataList, searchHd, loading, rowOnClickHd} = this.props       
        let previewModal = this.mkPreviewModal()

        return (
            <div>
                {previewModal}
                <HoBreadcrumb itemList={this._breadConf} extra={{style:{marginBottom: '8px'}}} />
                <Card title="认证知识（在线文档）列表" extra={this.mkOpButtons()}>
                    <HoTable columns={this._columns} 
                        size="middle" 
                        dataList={dataList}
                        searchHd={searchHd}
                        totalNum={totalNum}
                        pageChangeHd={pageChangeHd}
                        loading={loading}
                        rowOnClickHd={rowOnClickHd}
                    />
                </Card>
            </div>
        );
    }

}

