import React, { Component } from 'react'
import { connect } from 'react-redux'
import {getNetRequestStatus, netRequestThunk, actNetRequestClear} from '../../network/netReqRD'
import {getOrderDetail, getOrderDetailSuccessHd, clearOrderDraftDetail, getObjDetail, getObjDetailSuccessHd, clearObjDetail} from './orderRD'
import {getLoginTokenInfo} from '../account/loginRD'
import {OrderDetailPage} from './orderDetail'
import {HoBuildTableData} from '../../component/utils'
import {HoMkSearchFilters} from '../../component/tbl'

class OrderDetailCT extends Component {
    constructor(props){
        super(props)
        this.tokenCheckHd = this.tokenCheckHd.bind(this)
        this.requestData = this.requestData.bind(this)
        this.requestObjDetail = this.requestObjDetail.bind(this)
        this.refreshHd = this.refreshHd.bind(this)
        this.getOrderInitInfo = this.getOrderInitInfo.bind(this)
        this.orderApproveHd = this.orderApproveHd.bind(this)
        this.certPublishHd = this.certPublishHd.bind(this)
        this.receiptCommitHd = this.receiptCommitHd.bind(this)
        this.modelSetHd = this.modelSetHd.bind(this)
        this.certificationCommitHd = this.certificationCommitHd.bind(this)
        this.doneOnClickHd = this.doneOnClickHd.bind(this)
        this.undoneOnClickHd = this.undoneOnClickHd.bind(this)


        let {match} = props
        let orderId = undefined
        if (match) {
            if (match.params) {
                orderId = parseInt(match.params.orderId)
            }
        }

        this.state = {
            OrderId: orderId, 
        }

        this.__orderInit = 0

    }

    tokenCheckHd() {
        let {tokenInfo, history} = this.props
        if (tokenInfo === undefined) {
            const directPath = '/'
            history.push(directPath)
            return false
        }
        return true
    }

    requestData() {
        if (!this.tokenCheckHd()) {
            return
        }

        let {OrderId} = this.state

        let {dispatch, tokenInfo} = this.props
        let msgBody = {
            OrderId: OrderId, 
        }
        dispatch(netRequestThunk("/hq/v1/mntn/cert/order/detail", msgBody, "getOrderDetail", getOrderDetailSuccessHd, tokenInfo.token))
    }

    componentWillUnmount(){
        let {dispatch} = this.props
        dispatch(actNetRequestClear("getOrderDetail"))
        dispatch(actNetRequestClear("getObjDetail"))
        dispatch(actNetRequestClear("orderApproved"))
        dispatch(actNetRequestClear("orderCertPublished"))
        dispatch(actNetRequestClear("orderReceiptCommit"))
        dispatch(actNetRequestClear("orderModelSet"))
        dispatch(actNetRequestClear("orderCertificationSet"))
        dispatch(actNetRequestClear("orderDone"))
        dispatch(actNetRequestClear("orderunDone"))

        dispatch(clearOrderDraftDetail())
        dispatch(clearObjDetail())
    }

    componentDidMount() {
        this.requestData()
    }

    refreshHd() {
        this.requestData()
    }


    getLoadingFlag() {
        let {reqStatus, approveStatus, certPublishStatus, receiptCommitStatus, modelSetStatus, certSetStatus, doneStatus, undoneStatus, dispatch} = this.props

        let isLoading = false
        if (reqStatus === 1 || approveStatus === 1) {
            isLoading = true
        } 

        if (approveStatus === 2) {
            this.requestData()
            dispatch(actNetRequestClear("orderApproved"))
        }

        if (certPublishStatus === 2) {
            this.requestData()
            dispatch(actNetRequestClear("orderCertPublished"))
        }

        if (receiptCommitStatus === 2) {
            this.requestData()
            dispatch(actNetRequestClear("orderReceiptCommit"))
        }

        if (modelSetStatus === 2) {
            this.requestData()
            dispatch(actNetRequestClear("orderModelSet"))
        }

        if (certSetStatus === 2) {
            this.requestData()
            dispatch(actNetRequestClear("orderCertificationSet"))
        }

        if (doneStatus === 2) {
            this.requestData()
            dispatch(actNetRequestClear("orderDone"))
        }

        if (undoneStatus === 2) {
            this.requestData()
            dispatch(actNetRequestClear("orderunDone"))
        }


        return isLoading
    }

    requestObjDetail(objId) {
        let {dispatch, tokenInfo} = this.props
        let msgBody = {
            Id: objId, 
        }

        dispatch(netRequestThunk("/hq/v1/mntn/product/dstObj/detail", msgBody, "getObjDetail", getObjDetailSuccessHd, tokenInfo.token))
    }

    getOrderInitInfo(orderDetail) {
        if (this.__orderInit === 1) {
            return
        }

        if (!orderDetail) {
            return
        }

        let {OrderBase} = orderDetail
        if (OrderBase) {
            this.requestObjDetail(OrderBase.DstObjId)
            this.__orderInit = 1
        }
    }

    orderApproveHd(values, approveType) {
        if (!this.tokenCheckHd()) {
            return
        }

        console.log("@@@@@@: values = , approveType = ", values, approveType)
        let {OrderId} = this.state

        let {dispatch, tokenInfo} = this.props
        let msgBody = {
            OrderId: OrderId, 
            ApproveType: approveType, 
            ApproveResult: values.ApproveResult, 
            Remark: values.ApproveRemark,
        }

        dispatch(netRequestThunk("/hq/v1/mntn/cert/order/approve", msgBody, "orderApproved", undefined, tokenInfo.token))
    }

    certPublishHd(certType) {
        if (!this.tokenCheckHd()) {
            return
        }

        let {OrderId} = this.state
        let {dispatch, tokenInfo} = this.props
        let msgBody = {
            OrderId: OrderId, 
            ResName: certType, 
            Published: 1, 
        }

        dispatch(netRequestThunk("/hq/v1/mntn/cert/order/publishRes", msgBody, "orderCertPublished", undefined, tokenInfo.token))
    }

    certificationCommitHd(values) {
        if (!this.tokenCheckHd()) {
            return
        }
        let {OrderId} = this.state
        let {dispatch, tokenInfo} = this.props
        let msgBody = {
            OrderId: OrderId, 
            CertificationCode: values.CertificationCode,
            ApplyCode: values.ApplyCode,
            Applyer: values.Applyer,   
            Manufacturer: values.Manufacturer,
            Factory: values.Factory,
            CertName: values.CertName,
            Model: values.Model,
            PublishDate: values.PublishDate,
            ExpireDate: values.ExpireDate,     
        }
        dispatch(netRequestThunk("/hq/v1/mntn/cert/order/setCertification", msgBody, "orderCertificationSet", undefined, tokenInfo.token))

    }

    receiptCommitHd(values) {
        if (!this.tokenCheckHd()) {
            return
        }
        let {OrderId} = this.state
        let {dispatch, tokenInfo} = this.props
        let msgBody = {
            OrderId: OrderId, 
            ExpressCompany: values.ExpressCompany, 
            ExpressNum: values.ExpressNum, 
            Sender: values.Sender, 
            SenderTel: values.SenderTel, 
            Remark: values.Remark, 
        }
        dispatch(netRequestThunk("/hq/v1/mntn/cert/order/receipt/commit", msgBody, "orderReceiptCommit", undefined, tokenInfo.token))
    }

    modelSetHd(model) {
        if (!this.tokenCheckHd()) {
            return
        }
        let {OrderId} = this.state
        let {dispatch, tokenInfo} = this.props
        let msgBody = {
            OrderId: OrderId, 
            Model: model, 
        }
        dispatch(netRequestThunk("/hq/v1/mntn/cert/order/model/set", msgBody, "orderModelSet", undefined, tokenInfo.token))
    }

    doneOnClickHd() {
        if (!this.tokenCheckHd()) {
            return
        }
        let {OrderId} = this.state
        let {dispatch, tokenInfo} = this.props
        let msgBody = {
            OrderId: OrderId, 
        }
        dispatch(netRequestThunk("/hq/v1/mntn/cert/order/done", msgBody, "orderDone", undefined, tokenInfo.token))
    }

    undoneOnClickHd() {
        if (!this.tokenCheckHd()) {
            return
        }
        let {OrderId} = this.state
        let {dispatch, tokenInfo} = this.props
        let msgBody = {
            OrderId: OrderId, 
        }
        dispatch(netRequestThunk("/hq/v1/mntn/cert/order/undone", msgBody, "orderunDone", undefined, tokenInfo.token))
    }

    render(){
        let {detailInfo, objDetail, tokenInfo} = this.props
        this.getOrderInitInfo(detailInfo)

        let isLoading = this.getLoadingFlag()
        
        return (
            <OrderDetailPage 
            isLoading={isLoading}
            orderDetail={detailInfo}
            objDetail={objDetail}
            refreshHd={this.refreshHd}
            orderApproveHd={this.orderApproveHd}
            tokenInfo={tokenInfo}
            certPublishHd={this.certPublishHd}
            receiptCommitHd={this.receiptCommitHd}
            modelSetHd={this.modelSetHd}
            certificationCommitHd={this.certificationCommitHd}
            doneOnClickHd={this.doneOnClickHd}
            undoneOnClickHd={this.undoneOnClickHd}
            />
        );
    }
}

const mapState = (state) => ({
    detailInfo: getOrderDetail(state), 
    tokenInfo: getLoginTokenInfo(state), 
    reqStatus: getNetRequestStatus(state, 'getOrderDetail'), 
    approveStatus: getNetRequestStatus(state, 'orderApproved'), 
    certPublishStatus: getNetRequestStatus(state, 'orderCertPublished'), 
    receiptCommitStatus: getNetRequestStatus(state, 'orderReceiptCommit'), 
    modelSetStatus: getNetRequestStatus(state, 'orderModelSet'), 
    certSetStatus: getNetRequestStatus(state, 'orderCertificationSet'), 
    doneStatus: getNetRequestStatus(state, 'orderDone'), 
    undoneStatus: getNetRequestStatus(state, 'orderunDone'), 

    objDetail: getObjDetail(state),

    
    
});

export default connect(
    mapState, 
    null
)(OrderDetailCT);


