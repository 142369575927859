import React, { Component } from 'react';
import { Row, Form, Input, Button, Typography, Card} from 'antd';

import {UserOutlined, LockOutlined } from '@ant-design/icons';
import {HoLoadingButton} from '../../component/element'



export class LoginPage extends Component {
    constructor(props){
        super(props)

        let {phoneNum, password} = props;

        this.state = {
            
        }

    }


    render(){
        let {onLoginHd, isLoading, defaultUser} = this.props
        if (isLoading === undefined){
            isLoading = false
        }

        const {Title} = Typography;

        return (
            <div >
                <div style={{paddingTop: "30px"}} />            

                <Row type="flex" justify="center">
                    <Title level={3}>
                    华秋管理后台
                    </Title>
                </Row>

                <Row type="flex" justify="center">

                    <Form className="ho_login_form" onFinish={onLoginHd}>
                        <Form.Item name="userName" 
                        rules={[{required: true, message: '请输入登录账号!'}]}
                        initialValue={defaultUser}
                        >
                        
                            <Input
                            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder="请输入登录账号"
                            // defaultValue={defaultUser}
                            />
                        </Form.Item>
                        <Form.Item name="password" 
                        rules={[{required: true, message: '请输入密码!'}]}>
                            <Input
                            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                            type="password"
                            placeholder="请输入密码"
                            />
                        </Form.Item>
                        <Form.Item>
                        <Button type="primary" htmlType="submit" className="ho_login_button" loading={isLoading} >
                            登录
                        </Button>
                        </Form.Item>
                    </Form>
                </Row>

            </div>
           
        );
    }
}



