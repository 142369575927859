import React, { Component } from 'react';
import { Menu, Icon, Row, Col, Typography, Space } from 'antd';
import { connect } from 'react-redux'
import {withRouter} from 'react-router'
import {Link} from 'react-router-dom'
import {SettingOutlined, ScheduleOutlined, BarChartOutlined, FilterOutlined, CameraOutlined, ShoppingOutlined, 
    VideoCameraOutlined, HomeOutlined, GiftOutlined, RobotOutlined, TeamOutlined, ExportOutlined, 
    VerifiedOutlined, ReadOutlined, DesktopOutlined, FundProjectionScreenOutlined} from '@ant-design/icons'

import {getTopMenuCurrentItem, getTopMenuOpenKeys, actSetTopMenuCurrentItem, actSetTopMenuOpenKeys} from './menuRD'



const {Item, SubMenu, ItemGroup, Divider} = Menu;


export class HQTopMenu extends Component {
    constructor(props){
        super(props);

        this.itemOnClickHd = this.itemOnClickHd.bind(this);
        this.state = {
            
        };
    }

    itemOnClickHd(e){
        let clickedItem = e.key
        let {topMenuOnClickHd, currentItem} = this.props
        if (clickedItem !== currentItem) {
            if (topMenuOnClickHd) {
                topMenuOnClickHd(clickedItem)
            }
        }
    }

    render(){
        let {currentItem, openKeys} = this.props
        return (
            <Row>
            <Col span={20}>
            <Menu
            theme="dark"
            onClick={this.itemOnClickHd}
            defaultOpenKeys={openKeys}
            selectedKeys={[currentItem]}
            mode="horizontal"
            >
                <Item key="operate">运营支撑</Item>
                <Item key="setting">功能设置</Item>
            </Menu>
            </Col>

            <Col span={4}>
                <Row justify="end">
                    <Link to="/" component={Typography.Link}>
                            <div style={{height: 32, color: "#f5f5f5"}}>
                            <Space size="small">
                            <ExportOutlined /><span >登出</span>
                            </Space>
                            </div>
                            </Link>
                </Row>
            </Col>
            
            </Row>
        );
    }
}

export class HQOperateMenu extends Component {
    constructor(props){
        super(props);

        this.itemOnClickHd = this.itemOnClickHd.bind(this);
        this.getOpenKey = this.getOpenKey.bind(this);
        this.state = {
            
        };
    }

    itemOnClickHd(e) {
        let {leftMenuOnClickHd} = this.props

        if (leftMenuOnClickHd) {
            leftMenuOnClickHd(e.key)
        }
    }

    getOpenKey() {
        let {currentItem} = this.props

        switch (currentItem) {
            case "orderDraftList":
            case "orderList":
                return "orders"

            case "supportQuestionList":
                return "supports"

            case "dataOrders":
            case "dataUsers":
                return "data"

            default:
                return undefined
        }
    }

    render(){

        let {currentItem} = this.props;
        let openKey = this.getOpenKey()
        console.log("@@@@@@: currentItem = , openKey = ", currentItem, openKey)

        return (
            <Menu
            onClick={this.itemOnClickHd}
            defaultOpenKeys={[openKey]}
            selectedKeys={[currentItem]}
            mode="inline"
            theme="dark"
            >
                <Item key="dashBoard" icon={<HomeOutlined />}>工作台</Item>

                <SubMenu
                key="orders"
                title={
                <span>
                    <GiftOutlined />
                    <span>订单管理</span>
                </span>
                }>
                
                <Item key="orderDraftList">订单草稿列表</Item>
                <Item key="orderList">订单列表</Item>

                </SubMenu>

                <SubMenu
                key="supports"
                title={
                <span>
                    <RobotOutlined />
                    <span>智能客服</span>
                </span>
                }>
                
                <Item key="supportQuestionList">回复问题列表</Item>
                

                </SubMenu>

                <SubMenu
                key="data"
                title={
                <span>
                    <BarChartOutlined />
                    <span>数据分析</span>
                </span>
                }>
                
                <Item key="dataOrders">订单数据</Item>
                <Item key="dataUsers">用户数据</Item>
                

                </SubMenu>


            </Menu>
        );
    }
}

export class HQSettingMenu extends Component {
    constructor(props){
        super(props);

        this.itemOnClickHd = this.itemOnClickHd.bind(this);
        this.getOpenKey = this.getOpenKey.bind(this);
        this.state = {
            
        };
    }

    itemOnClickHd(e) {
        let {leftMenuOnClickHd} = this.props
        if (leftMenuOnClickHd) {
            leftMenuOnClickHd(e.key)
        }
    }

    getOpenKey() {
        let {currentItem} = this.props

        switch (currentItem) {
            case "usersMntnList":
            case "usersNormalList":
            case "usersGuestList":
            case "usersStoreList":
                return "users"

            case "certCertification":
            case "certTestCategory":
            case "certTestStandard":
            case "certTestCase":
                return "certification"

            case "productObjCategory":
            case "productObj":
            case "productFeatureCategory":
            case "productFeatureSubcategory":
            case "productFeature":
                return "product"

            case "klCertificationUploadList":
            case "klCertificationOnlineList":
            // case "klTestUploadList":
            // case "klTestOnlineList":
                return "konwledgebase"

            case "testsuitList":
                return "testsuit"
            

            default:
                return undefined
        }
    }

    render(){

        let {currentItem} = this.props;

        console.log("@@@@@@@: currentItem = " + currentItem)

        let openKey = this.getOpenKey()

        return (
            <Menu
            onClick={this.itemOnClickHd}
            defaultOpenKeys={[openKey]}
            selectedKeys={[currentItem]}
            mode="inline"
            theme="dark"
            >
                
                <SubMenu
                key="certification"
                title={
                <span>
                    <VerifiedOutlined />
                    <span>认证信息</span>
                </span>
                }>
                
                <Item key="certCertification">认证信息</Item>
                <Item key="certTestCategory">检测大类</Item>
                <Item key="certTestStandard">检测标准</Item>
                <Item key="certTestCase">检测项目</Item>

                </SubMenu>

                <SubMenu
                key="certProduct"
                title={
                <span>
                    <FundProjectionScreenOutlined />
                    <span>产品信息</span>
                </span>
                }>
                
                <Item key="productObjCategory">产品类别</Item>
                <Item key="productFeatureCategory">附加特性类别</Item>
                <Item key="productFeatureSubcategory">附加特性子类别</Item>
                <Item key="productFeature">附加特性</Item>
                <Item key="productObj">认证产品</Item>

                </SubMenu>

                <SubMenu
                key="testsuit"
                title={
                <span>
                    <ShoppingOutlined />
                    <span>检测套餐</span>
                </span>
                }>
                
                <Item key="testsuitList">套餐列表</Item>
                
                </SubMenu>

                <SubMenu
                key="konwledgebase"
                title={
                <span>
                    <ReadOutlined />
                    <span>知识库管理</span>
                </span>
                }>
                
                <Item key="klCertificationUploadList">文档（上传）</Item>
                <Item key="klCertificationOnlineList">文档（在线）</Item>
                
                </SubMenu>

                <SubMenu
                key="adver"
                title={
                <span>
                    <DesktopOutlined />
                    <span>广告管理</span>
                </span>
                }>
                

                </SubMenu>

                <SubMenu
                key="users"
                title={
                <span>
                    <TeamOutlined />
                    <span>用户管理</span>
                </span>
                }>
                
                <Item key="usersMntnList">运营用户列表</Item>
                <Item key="usersNormalList">注册用户列表</Item>
                <Item key="usersGuestList">游客列表</Item>
                <Item key="usersStoreList">商家列表</Item>

                </SubMenu>


            </Menu>
        );
    }
}