import React, { Component } from 'react';

import {Card, Row, Col, Button, Popconfirm, Space, Modal, Descriptions, Select, 
    Tag, Form, Input } from 'antd'
import {SyncOutlined, PlusOutlined} from '@ant-design/icons'

import {HoBreadcrumb} from '../../component/element'
import {HoTable, HoMkTblColumn, HoMkTblColActions} from '../../component/tbl'

import {cert_scopes} from '../../comm/config'


export class CertCertificationListPage extends Component {
    constructor(props){
        super(props)

        this.mkOpButtons = this.mkOpButtons.bind(this)
        this.onChangeHd = this.onChangeHd.bind(this)
        this.mkEditModal = this.mkEditModal.bind(this)
        this.addOnclickHd = this.addOnclickHd.bind(this)
        this.editOnclickHd = this.editOnclickHd.bind(this)
        this.modalConfirmHd = this.modalConfirmHd.bind(this)
        this.modalCancelHd = this.modalCancelHd.bind(this)


        let {deleteOnClickHd, delConfirmHd, selectFlag, selectedHd, detailOnclickHd} = props
        this.state = {
            showEdit: false, 
            editMode: undefined,
            editRecord: {}, 
        }

        this._breadConf = [
            {name: "认证检测", link: '/setting/certCertification'}, 
            {name: "认证信息", link: undefined}, 
        ]

        const disDelete = (
            <Popconfirm title="请确认是否要删除？" 
            onConfirm={(e) => delConfirmHd ? delConfirmHd(e, 'confirm') : undefined}
            onCancel={(e) => delConfirmHd ? delConfirmHd(e, 'cancel') : undefined}
            >
                <span>删除</span>
            </Popconfirm>
        )

        let actionContent = HoMkTblColActions([{key: 'detail', display: '详情', onClickedHd: detailOnclickHd}, 
                                               {key: 'edit', display: '编辑', onClickedHd: this.editOnclickHd}, 
                                               {key: 'delete', display: disDelete, onClickedHd: deleteOnClickHd}, ])
        if (selectFlag) {
            actionContent = HoMkTblColActions([{key: 'select', display: '选择', onClickedHd: selectedHd}])
        }

        this._columns = [
            HoMkTblColumn('认证类型', 'Name', 'str', '10%', true, false), 
            HoMkTblColumn('地域', 'Scope', 'str', '10%', true, false), 
            HoMkTblColumn('中文名', 'CName', 'str', '10%', true, false), 
            HoMkTblColumn('英文名', 'EName', 'str', '10%', true, false), 
            HoMkTblColumn('是否显示', 'Display', 'number', '8%', false, false, this.displayMap), 
            HoMkTblColumn('概述', 'Description', 'str', '10%', true, false), 
            HoMkTblColumn('logo地址', 'LogoUrl', 'str', '10%', false, false), 
            HoMkTblColumn('详情地址', 'DetailUrl', 'str', '10%', false, false), 
            HoMkTblColumn('备注', 'Remark', 'number', '10%', true, false), 
            actionContent, 
        ]

    }

    displayMap(Display) {
        if (Display === 0) {
            return "否"
        }else {
            return "是"
        }
    }

    
    mkOpButtons() {
        let {refreshHd} = this.props
        return (
            <Row>
                <Space>
                    <Button type="primary" icon={<PlusOutlined />} onClick={this.addOnclickHd}>新增</Button>
                    <Button type="default" icon={<SyncOutlined />} onClick={refreshHd}>刷新</Button>
                </Space>
            </Row>
        )
    }

    mkEditModal() {
        let {showEdit, editRecord} = this.state

        if (this._form) {
            this._form.setFieldsValue(
                {
                    Name: editRecord.Name, 
                    Scope: editRecord.Scope, 
                    CName: editRecord.CName, 
                    EName: editRecord.EName, 
                    Description: editRecord.Description, 
                    Display: editRecord.Display, 
                    LogoUrl: editRecord.LogoUrl, 
                    DetailUrl: editRecord.DetailUrl, 
                    Remark: editRecord.Remark, 
                }
            )
        }
        
        const selectOps = cert_scopes.map(
            (item, index) => (
                <Select.Option key={index} value={item}>{item}</Select.Option>
            )
        )

        let content = (
            <Form onFinish={(values) => this.modalConfirmHd(values)} labelCol={{span: 8}} wrapperCol={{span: 16}}
            ref={node => this._form = node}
            initialValues={
                {
                    Name: editRecord.Name, 
                    Scope: editRecord.Scope, 
                    CName: editRecord.CName, 
                    EName: editRecord.EName, 
                    Description: editRecord.Description, 
                    Display: editRecord.Display, 
                    LogoUrl: editRecord.LogoUrl, 
                    DetailUrl: editRecord.DetailUrl, 
                    Remark: editRecord.Remark, 
                }
            }
            >
                <Row>
                <Col span={12}>
                <Form.Item 
                    name="Name" rules={[{required: true, message: '请输入认证类型!'}]} label="认证类型" >
                        <Input  />
                </Form.Item>
                </Col>
                    
                <Col span={12}>
                <Form.Item name="Scope" rules={[{required: true, message: '请选择地域信息！'}]} label="地域" >
                    <Select >
                        {selectOps}  
                    </Select>
                </Form.Item>
                </Col>
                </Row>

                <Row>
                    <Col span={12}>
                    <Form.Item name="CName" label="中文名" >
                        <Input />
                    </Form.Item>
                    </Col>

                    <Col span={12}>
                    <Form.Item name="EName" label="英文名" >
                        <Input />
                    </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={12}>
                    <Form.Item name="Description" label="概述" >
                        <Input />
                    </Form.Item>
                    </Col>

                    <Col span={12}>
                    <Form.Item name="Display" label="是否显示">
                        <Select >
                            <Select.Option value={1}>是</Select.Option>
                            <Select.Option value={0}>否</Select.Option> 
                        </Select>
                    </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={12}>
                    <Form.Item name="LogoUrl" label="logo地址" >
                        <Input />
                    </Form.Item>
                    </Col>

                    <Col span={12}>
                    <Form.Item name="DetailUrl" label="详情地址" >
                        <Input />
                    </Form.Item>
                    </Col>
                </Row>

                <Form.Item name="Remark" label="备注" labelCol={{span: 4}} wrapperCol={{span: 20}}>
                        <Input />
                </Form.Item>
                
                <Row justify="end">
                    <Space size="middle" >
                    <Button type="default" onClick={() => this.modalCancelHd()}>取消</Button>
                    <Button type="primary" onClick={undefined} htmlType="submit">保存</Button>
                    </Space>
                </Row>
            </Form>
        )

        return (
            <Modal 
                title="认证类型"
                visible={showEdit}
                width={640}
                onCancel={this.modalCancelHd}
                okText="保存" cancelText="取消"
                footer={null}
                >
                {content}
                   
            </Modal>
        )
    }

    addOnclickHd() {
        let newState = Object.assign({}, this.state)
        newState.editMode = "add"
        newState.showEdit = true
        this.setState(newState)
    }

    editOnclickHd(key, record) {
        let newState = Object.assign({}, this.state)
        newState.editMode = "mod"
        newState.showEdit = true
        newState.editRecord = record
        this.setState(newState)
    }

    modalConfirmHd(values) {
        console.log("modalConfirmHd: values = ", values)


        let {editMode, editRecord} = this.state
        let {editConfirmHd, addConfirmHd} = this.props

        let result = {}

        result.Name = values.Name
        result.Scope = values.Scope
        result.CName = values.CName
        result.EName = values.EName
        result.Description = values.Description
        result.Display = values.Display
        result.LogoUrl = values.LogoUrl
        result.DetailUrl = values.DetailUrl
        result.Remark = values.Remark
        result.Id = editRecord.Id

        if (editMode === 'add') {
            if (addConfirmHd) {
                addConfirmHd(result)
            }
        }else if (editMode === 'mod') {
            if (editConfirmHd) {
                editConfirmHd(result)
            }
        }

        let newState = Object.assign({}, this.state)
        newState.editMode = undefined
        newState.showEdit = false
        this._editRecord = {}
        this.setState(newState)
    }

    modalCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.editMode = undefined
        newState.editRecord = {}
        newState.showEdit = false
        this.setState(newState)
    }

    onChangeHd(pagination, filters, sorter, extra) {
        let {pageChangeHd} = this.props
        if (extra === undefined) {
            return
        }

        if (extra.action === "paginate"){
            // 分页操作
            if (pageChangeHd === undefined || pagination === {}) {
                return
            }
            pageChangeHd(pagination.current, pagination.pageSize)
            return
        }
    }

    render() {
        let {pageChangeHd, totalNum, dataList, searchHd, loading} = this.props       
        let disModal = this.mkEditModal() 
        return (
            <div>
                <HoBreadcrumb itemList={this._breadConf} extra={{style:{marginBottom: '8px'}}} />
                <Card title="认证类型列表" extra={this.mkOpButtons()}>
                    {disModal}
                    <HoTable columns={this._columns} 
                        size="middle" 
                        dataList={dataList}
                        searchHd={searchHd}
                        totalNum={totalNum}
                        pageChangeHd={pageChangeHd}
                        loading={loading}
                    />
                </Card>
            </div>
        );
    }

}

