import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux'


// ************************************************************
// 各模块路由表配置
// ************************************************************

// home


import LoginCT from '../app/account/loginCT'

import HQLayout from '../component/layout'
import OperateRoute from './router/operate'
import SettingRoute from './router/setting'



class AppRoute extends Component {
    
    render(){
        return (
            <Switch>
                <Route path={`${this.props.match.path}`} exact component={LoginCT} />
                <HQLayout children={this.props.children}>
                    <Route path={`${this.props.match.path}operate`} component={OperateRoute} />
                    <Route path={`${this.props.match.path}setting`} component={SettingRoute} />
                </HQLayout>

            </Switch>
        );
    }
}

export default connect(
    null, 
    null
)(AppRoute);

