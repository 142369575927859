import React from 'react';
import ReactDOM from 'react-dom';

import {BrowserRouter, Route} from 'react-router-dom';
import {Switch} from 'react-router';
import {Provider} from 'react-redux';

import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';

import './theme/theme1.less';
import './css/hy.css';
import * as serviceWorker from './serviceWorker';

import {configStore, loadStoreState} from './comm/store';
import AppRoute from './comm/route';
import BgCT from './comm/bgCT'


const initState = loadStoreState();
const store = configStore(initState);

const routes = (
    <ConfigProvider locale={zhCN}>
        <Provider store={store}>
            <BrowserRouter>
                <BgCT />
                <Switch>
                    <Route path='/' component={AppRoute}/>
                </Switch>
            </BrowserRouter>
        </Provider>
    </ConfigProvider>
);


ReactDOM.render(routes, document.getElementById('root'));

serviceWorker.unregister();
