import React, { Component } from 'react';
import {Button, Breadcrumb, Pagination} from 'antd'
import {PoweroffOutlined } from '@ant-design/icons'
import {Link} from 'react-router-dom'
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'


export function HoLoadingButton(props) {
    let {buttonName, isLoading, type, onClick, extra} = props

    return (
        <Button 
            type={type} 
            loading={isLoading}
            onClick={onClick}
            {...extra}
            >
            {buttonName}
        </Button>
    )
}

HoLoadingButton.defaultProps = {
    isLoading: false, 
    buttonName: "", 
    type: "primary", 
    onClick: undefined, 
    extra: undefined
}

export function HoBreadcrumb(props) {
    let {itemList, extra} = props

    let disItems = undefined
    disItems = itemList.map(
        (item, index) => (
            <Breadcrumb.Item key={index}>
                {item.link === undefined ? <span>{item.name}</span> : <Link to={item.link}><span>{item.name}</span></Link>}
            </Breadcrumb.Item>
        )
    )

    return (
        <Breadcrumb {...extra}>
            {disItems}
        </Breadcrumb>
    )
}

HoBreadcrumb.defaultProps = {
    itemList: [], 
    extra: undefined, 
}

export class HoPagination extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageNum: 1, 
            pageSize: 10,
        };

        this.showTotal = this.showTotal.bind(this);
        this.onChangeHd = this.onChangeHd.bind(this);
        this.onShowSizeChangeHd = this.onShowSizeChangeHd.bind(this);

    }

    showTotal(total, range) {
        // let {totalNum} = this.props;
        let {pageNum, pageSize} = this.state;
        if (total === 0) {
            return "总数0";
        }

        let startNum = (pageNum - 1) * pageSize + 1;
        let comparePage = total / pageSize;
        let endNum = pageNum * pageSize;
        if (pageNum >= comparePage) {
            endNum = total;
        }

        return `第${startNum}-${endNum}，总数${total}`;
    }

    onChangeHd(page, size) {
        console.log("@@##: page, size", page, size)
        let {pageChangeHd} = this.props;

        this.setState(
            {
                ...this.state,
                pageNum: page, 
                pageSize: size
            }
        );

        if (pageChangeHd) {
            pageChangeHd(page, size);
        }
    }

    onShowSizeChangeHd(current, size) {
        console.log("@@##@@: current, size", current, size)
        let {pageChangeHd} = this.props;

        this.setState(
            {
                ...this.state, 
                pageNum: current, 
                pageSize: size
            }
        );
        if (pageChangeHd) {
            pageChangeHd(current, size);
        }
    }

    render() {
        let {pageNum, pageSize} = this.state;
        let {totalNum, showQuickJumper, extra} = this.props;
        if (totalNum === undefined) {
            totalNum = 0;
        }
        if (showQuickJumper === undefined) {
            showQuickJumper = true;
        }

        console.log("@#@#@: totalNum = ", totalNum)
        return (
            <Pagination 
            total={totalNum}
            showTotal={(total, range) =>this.showTotal(total, range)}
            current={pageNum}
            pageSize={pageSize}
            onShowSizeChange={this.onShowSizeChangeHd}
            onChange={this.onChangeHd}
            size="small"
            showQuickJumper={showQuickJumper}
            {...extra}
            />
        );
    }
}

HoPagination.defaultProps = {
    totalNum: 0, 
    showQuickJumper: true, 
    pageChangeHd: undefined, 
    extra: undefined, 
}

export function HoDocPreview(props) {
    let {rawData} = props

    let tmp = BraftEditor.createEditorState(rawData)

    const content = tmp.toHTML()
    return (
        <div className="ho_doc_container" dangerouslySetInnerHTML={{__html: content}}>
                
        </div>
    )

}

HoDocPreview.defaultProps = {
    rawData: undefined, 
}
