import React, { Component } from 'react'
import { connect } from 'react-redux'
import {LoginPage} from './login'
import {getNetRequestStatus, netRequestThunk} from '../../network/netReqRD'
import {getTokenSuccessHd, getLoginDefaultUserName, getLoginTokenInfo} from './loginRD'
import {HyMessage} from '../../component/utils'

class LoginCT extends Component {
    constructor(props){
        super(props);

        this.state = {
            // isLoading: false, 
        };

        this.onLoginHd = this.onLoginHd.bind(this)
    }

    componentWillUnmount(){
        
    }

    onLoginHd(values) {
        let {dispatch} = this.props

        // 网络请求处理
        let msgBody = {
            UserName: values.userName, 
            Password: values.password, 
            TokenType: 8, 
        }

        dispatch(netRequestThunk("/hq/v1/account/get/token", msgBody, "getToken", getTokenSuccessHd))
    }




    render(){
        
        let {defaultUser, loginStatus, history, tokenInfo} = this.props

        let isLoading = false
        if (loginStatus === 1) {
            isLoading = true
        }else if (loginStatus === 2) {
            // 成功，跳转到业务页面
            if (history) {
                const directPath = '/operate/orderList'
                console.log("history = , directPath = ", history, directPath)
                history.push(directPath)
                if (tokenInfo) {
                    HyMessage("info", "欢迎您，" + tokenInfo.nickName)
                }
            
            }
        }
        
        return (
            <LoginPage 
            defaultUser={defaultUser}
            isLoading={isLoading}
            onLoginHd={this.onLoginHd}
            />
        );
    }
}

const mapState = (state) => ({
    defaultUser: getLoginDefaultUserName(state), 
    loginStatus: getNetRequestStatus(state, 'getToken'), 
    tokenInfo: getLoginTokenInfo(state), 
    // userInfo: getLoginUserInfo(state), 
    
});

export default connect(
    mapState, 
    null
)(LoginCT);





