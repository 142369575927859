import React, { Component } from 'react';

import {Card, Row, Col, Button, Popconfirm, Space, Divider, Typography, Descriptions, Form, Input, Modal, Select, Collapse, Tag } from 'antd'
import {SyncOutlined, PlusOutlined, EditOutlined, SaveOutlined, CheckOutlined} from '@ant-design/icons'

import FeatureCT from './featureCT'
import CertRelateTestCategoryCT from '../../app/cert/certTestCategoryCT'
import CertTestCaseListCT from '../../app/cert/testCaseCT'
import {findItemByIdFromList} from '../../comm/logic'
import {HyMessage} from '../../component/utils';


export class FeatureForm extends Component {
    constructor(props) {
        super(props)

        this.finishedHd = this.finishedHd.bind(this)
        this.hoClearForm = this.hoClearForm.bind(this)
        this.mkSelectModal = this.mkSelectModal.bind(this)
        this.selectHd = this.selectHd.bind(this)
        this.selectCancelHd = this.selectCancelHd.bind(this)
        this.showSelectHd = this.showSelectHd.bind(this)

        this.state = {
            editRecord: {}, 
            showSelect: false, 
        }

        this._formInit = false
        this._form = undefined
    }

    finishedHd(values) {
        let {formSubmitHd, record} = this.props
        let {editRecord} = this.state

        if (formSubmitHd) {
            values.Id = record ? record.Id : undefined
            values.FeatureId = editRecord.FeatureId ? editRecord.FeatureId : (record ? record.FeatureId : undefined)
            formSubmitHd(values)
        }

        let newState = Object.assign({}, this.state)
        newState.editRecord = {}
        newState.showSelect = false
        this.setState(newState)
    }

    hoClearForm() {
        if (this._form) {
            // 清空相关信息
            this._form.setFieldsValue(
                {
                    CategoryName: undefined, 
                    SubcategoryName: undefined, 
                    Name: undefined, 
                    DisplayName: undefined, 
                    Description: undefined, 
                    CertId: undefined, 
                    Period: undefined, 
                    Price: undefined, 
                    IsDefault: undefined, 
                    Seq: undefined, 
                    Remark: undefined, 
                }
            )
            this._formInit = false
        }
    }

    selectHd(key, record) {
        let {editRecord} = this.state

        console.log("#####: record = ", record)

        editRecord.FeatureId = record.Id
        editRecord.CategoryName = record.CategoryName
        editRecord.SubcategoryName = record.SubcategoryName
        editRecord.DisplayName = record.DisplayName
        editRecord.Name = record.Name
        editRecord.Description = record.Description

        let newState = Object.assign({}, this.state)
        newState.showSelect = false
        newState.editRecord = editRecord
        this.setState(newState)
    }

    selectCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.showSelect = false
        this.setState(newState)
    }

    showSelectHd() {
        let newState = Object.assign({}, this.state)
        newState.showSelect = true
        this.setState(newState)
    }

    mkSelectModal() {
        let {showSelect} = this.state
        return (
            <Modal 
            title=""
            visible={showSelect}
            width={1280}
            footer={null}
            onOk={undefined}
            onCancel={this.selectCancelHd}
            >
                <FeatureCT 
                selectFlag={true}
                selectedHd={this.selectHd}
                />

            </Modal>
        )
    }


    render() {
        let {objCertList, record, modalCancelHd} = this.props
        let {editRecord} = this.state

        let certList = [{CertId: 0, CertName: "适用所有"}]
        if (objCertList) {
            for (let item of objCertList) {
                certList.push({CertId: item.CertId, CertName: item.CertName})
            }
        }

        const certOptions = certList.map(
            (item, index) => (
                <Select.Option key={index} value={item.CertId}>{item.CertName}</Select.Option>
            )
        )

        let selectModal = this.mkSelectModal()

        if (this._form) {
            if (!this._formInit && record) {
                this._form.setFieldsValue(
                    {
                        CategoryName: editRecord.CategoryName ? editRecord.CategoryName : (record ? record.CategoryName : undefined), 
                        SubcategoryName: editRecord.SubcategoryName ? editRecord.SubcategoryName : (record ? record.SubcategoryName : undefined), 
                        Name: editRecord.Name ? editRecord.Name : (record ? record.Name : undefined), 
                        DisplayName: editRecord.DisplayName ? editRecord.DisplayName : (record ? record.DisplayName : undefined), 
                        Description: editRecord.Description ? editRecord.Description : (record ? record.Description : undefined), 
                        CertId: record.CertId, 
                        Period: record.Period, 
                        Price: record.Price, 
                        IsDefault: record.IsDefault, 
                        Seq: record.Seq, 
                        Remark: record.Remark, 
                    }
                )
                this._formInit = true
            }else{
                this._form.setFieldsValue(
                    {
                        CategoryName: editRecord.CategoryName ? editRecord.CategoryName : (record ? record.CategoryName : undefined), 
                        SubcategoryName: editRecord.SubcategoryName ? editRecord.SubcategoryName : (record ? record.SubcategoryName : undefined), 
                        Name: editRecord.Name ? editRecord.Name : (record ? record.Name : undefined), 
                        DisplayName: editRecord.DisplayName ? editRecord.DisplayName : (record ? record.DisplayName : undefined), 
                        Description: editRecord.Description ? editRecord.Description : (record ? record.Description : undefined), 
                    }
                )
            }
        }


        return (
            <div>
                {selectModal}
                <Form onFinish={this.finishedHd} ref={node => this._form = node} 
                labelCol={{span: 6}} wrapperCol={{span: 18}} 
                initialValues={
                    {
                        CategoryName: record ? record.CategoryName : undefined, 
                        SubcategoryName: record ? record.SubcategoryName : undefined, 
                        Name: record ? record.Name : undefined, 
                        DisplayName: record ? record.DisplayName : undefined, 
                        Description: record ? record.Description : undefined, 
                        CertId: record ? record.CertId : undefined, 
                        Period: record ? record.Period : undefined, 
                        Price: record ? record.Price : undefined, 
                        IsDefault: record ? record.IsDefault : undefined, 
                        Seq: record ? record.Seq : undefined, 
                        Remark: record ? record.Remark : undefined, 
                    }
                }
                >
                    <Form.Item rules={[{required: true, message: '请选择产品附加特性!'}]} label="附加特性" name="Name">
                        <Row justify="start">
                            <Col span={14}>
                            <Input placeholder="请点击右边按钮选择产品附加特性" 
                            value={editRecord.Name ? editRecord.Name : (record ? record.Name : undefined)} disabled />
                            </Col>
                            <Col push={2} span={6}>
                            <Button type="primary" icon={<CheckOutlined />} onClick={this.showSelectHd} >选择附加特性</Button>
                            </Col>
                        </Row>    
                    </Form.Item>

                    <Form.Item name="DisplayName" label="显示名称" >
                        <Input value={editRecord.DisplayName ? editRecord.DisplayName : (record ? record.DisplayName : undefined)} disabled />
                    </Form.Item>

                    <Form.Item name="CategoryName" label="特性类别" >
                        <Input value={editRecord.CategoryName ? editRecord.CategoryName : (record ? record.CategoryName : undefined)} disabled />
                    </Form.Item>

                    <Form.Item name="SubcategoryName" label="特性子类别" >
                        <Input value="223333" disabled />
                    </Form.Item>

                    <Form.Item name="Description" label="描述" >
                        <Input value={editRecord.Description }  disabled />
                    </Form.Item>

                    <Form.Item rules={[{required: true, message: '请选择认证类别!'}]} label="认证类别" name="CertId">
                        <Select value={record ? record.CertId : undefined}>
                            {certOptions}
                        </Select>
                    </Form.Item>

                    <Form.Item name="Period" label="周期（工作日）" >
                        <Input  />
                    </Form.Item>

                    <Form.Item name="Price" label="价格" >
                        <Input  />
                    </Form.Item>

                    <Form.Item name="IsDefault" label="默认选中" >
                        <Select>
                            <Select.Option value={1}>是</Select.Option>
                            <Select.Option value={0}>否</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name="Seq" label="序号" >
                        <Input  />
                    </Form.Item>

                    <Form.Item name="Remark" label="备注" >
                        <Input  />
                    </Form.Item>

                    <Row justify="end">
                    <Space size="middle" >
                    <Button type="default" onClick={modalCancelHd}>取消</Button>
                    <Button type="primary" onClick={undefined} htmlType="submit">保存</Button>
                    </Space>
                </Row>

                </Form>

            </div>
        )
    }
}


export class TestCategoryForm extends Component {
    constructor(props) {
        super(props)

        this.finishedHd = this.finishedHd.bind(this)
        this.hoClearForm = this.hoClearForm.bind(this)
        this.mkSelectModal = this.mkSelectModal.bind(this)
        this.selectHd = this.selectHd.bind(this)
        this.selectCancelHd = this.selectCancelHd.bind(this)
        this.showSelectHd = this.showSelectHd.bind(this)

        this.state = {
            editRecord: {}, 
            showSelect: false, 
        }

        this._formInit = false
        this._form = undefined
    }

    finishedHd(values) {
        let {formSubmitHd, record} = this.props
        let {editRecord} = this.state

        if (formSubmitHd) {
            values.Id = record ? record.Id : undefined
            values.TestCategoryId = editRecord.TestCategoryId
            values.CertId = editRecord.CertId
            formSubmitHd(values)
        }

        let newState = Object.assign({}, this.state)
        newState.editRecord = {}
        newState.showSelect = false
        this.setState(newState)
    }

    hoClearForm() {
        if (this._form) {
            // 清空相关信息
            this._form.setFieldsValue(
                {
                    ObjName: undefined,  
                    CertName: undefined,
                    TestCategoryName: undefined, 
                    Period: undefined,  
                    Price: undefined, 
                    IsDefault: undefined,  
                    Seq: undefined,  
                    Remark: undefined,  
                }
            )
            this._formInit = false
        }
    }

    showSelectHd() {
        let newState = Object.assign({}, this.state)
        newState.showSelect = true
        this.setState(newState)
    }

    selectCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.showSelect = false
        this.setState(newState)
    }

    selectHd(key, record) {
        let {editRecord} = this.state
        let {objCertList, ObjTestCategoryList} = this.props

        // 判断 TestCategory 合法性
        // if (ObjTestCategoryList) {
        //     let find = findItemByIdFromList(record.TestCategoryId, "TestCategoryId", ObjTestCategoryList)
        //     if (find) {
        //         // 提示错误
        //         HyMessage('warning', `检测大类：${record.CategoryName} 已经配置，请选取其他检测大类！`, 5);
        //         return
        //     }
        // }

        // 判断 cert 合法性
        if (objCertList) {
            let find = findItemByIdFromList(record.CertId, "CertId", objCertList)
            if (!find) {
                // 提示错误
                HyMessage('warning', `该检测大类所关联的认证类别：${record.CertName}， 未在产品配置中，请选中其他检测大类`, 5);
                return
            }
        }

        // editRecord.Id = record.Id
        editRecord.CertName = record.CertName
        editRecord.CertId = record.CertId

        editRecord.TestCategoryName = record.CategoryName
        editRecord.TestCategoryId = record.TestCategoryId

        let newState = Object.assign({}, this.state)
        newState.showSelect = false
        newState.editRecord = editRecord
        this.setState(newState)
    }

    mkSelectModal() {
        let {showSelect} = this.state
        return (
            <Modal 
            title="关联检测大类配置"
            visible={showSelect}
            width={1280}
            footer={null}
            onOk={undefined}
            onCancel={this.selectCancelHd}
            >
                <CertRelateTestCategoryCT 
                selectFlag={true}
                selectedHd={this.selectHd}
                />

            </Modal>
        )
    }


    render() {
        let {record, ObjName, objCertList, modalCancelHd} = this.props
        let {editRecord} = this.state
        let certOptions = undefined
        if (objCertList) {
            certOptions = objCertList.map(
                (item, index) => (
                    <Select.Option key={index} value={item.CertId}>{item.CertName}</Select.Option>
                )
            )
        }
        if (this._form) {
            if (!this._formInit && record) {
                this._form.setFieldsValue(
                    {
                        ObjName: ObjName,  
                        // CertId: record ? record.CertId : undefined, 
                        CertName: editRecord.CertName ? editRecord.CertName : (record ? record.CertName : undefined),
                        TestCategoryName: editRecord.TestCategoryName ? editRecord.TestCategoryName : (record ? record.TestCategoryName : undefined), 
                        Period: record ? record.Period : undefined, 
                        Price: record ? record.Price : undefined, 
                        IsDefault: record ? record.IsDefault : undefined, 
                        Seq: record ? record.Seq : undefined, 
                        Remark: record ? record.Remark : undefined, 
                    }
                )
                this._formInit = true
            } else {
                this._form.setFieldsValue(
                    {
                        TestCategoryName: editRecord.TestCategoryName ? editRecord.TestCategoryName : (record ? record.TestCategoryName : undefined), 
                        CertName: editRecord.CertName ? editRecord.CertName : (record ? record.CertName : undefined),
                    }
                )
            }
        }

        let selectModal = this.mkSelectModal()


        return (
            <div>
                {selectModal}
                <Form onFinish={this.finishedHd} ref={node => this._form = node} 
                labelCol={{span: 8}} wrapperCol={{span: 16}} 
                initialValues={
                    {
                        ObjName: ObjName,  
                        // CertId: record ? record.CertId : undefined, 
                        CertName: record ? record.CertName : undefined, 
                        TestCategoryName: record ? record.TestCategoryName : undefined, 
                        Period: record ? record.Period : undefined, 
                        Price: record ? record.Price : undefined, 
                        IsDefault: record ? record.IsDefault : undefined, 
                        Seq: record ? record.Seq : undefined, 
                        Remark: record ? record.Remark : undefined, 
                    }
                }
                >
                    <Form.Item name="ObjName" label="产品名称" >
                        <Input disabled />
                    </Form.Item>

                    <Form.Item rules={[{required: true, message: '请选择认证类别!'}]} label="认证类别" name="CertName">
                        <Input placeholder="" value={editRecord.CertName ? editRecord.CertName : (record ? record.CertName : undefined)} disabled/>
                    </Form.Item>

                    <Form.Item rules={[{required: true, message: '请选择检测大类!'}]} label="检测大类" name="TestCategoryName">
                        <Row justify="start">
                            
                            <Col span={14}>
                            <Input placeholder="请点击右边按钮选择检测大类" value={editRecord.TestCategoryName ? editRecord.TestCategoryName : (record ? record.TestCategoryName : undefined)} disabled/>
                            </Col>
                            <Col push={2} span={6}>
                            <Button type="primary" icon={<CheckOutlined />} onClick={this.showSelectHd} >选择检测大类</Button>
                            </Col>
                        </Row>    
                    </Form.Item>

                    <Form.Item name="Period" label="周期（工作日）" >
                        <Input  />
                    </Form.Item>

                    <Form.Item name="Price" label="价格" >
                        <Input  />
                    </Form.Item>

                    <Form.Item name="IsDefault" label="默认选中" >
                        <Select>
                            <Select.Option value={1}>是</Select.Option>
                            <Select.Option value={0}>否</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name="Seq" label="序号" >
                        <Input  />
                    </Form.Item>

                    <Form.Item name="Remark" label="备注" >
                        <Input  />
                    </Form.Item>

                    <Row justify="end">
                        <Space size="middle" >
                        <Button type="default" onClick={modalCancelHd}>取消</Button>
                        <Button type="primary" onClick={undefined} htmlType="submit">保存</Button>
                        </Space>
                    </Row>

                </Form>

            </div>
        )
    }
}

export class TestCaseForm extends Component {
    constructor(props) {
        super(props)

        this.finishedHd = this.finishedHd.bind(this)
        this.hoClearForm = this.hoClearForm.bind(this)
        this.mkSelectModal = this.mkSelectModal.bind(this)
        this.selectHd = this.selectHd.bind(this)
        this.selectCancelHd = this.selectCancelHd.bind(this)
        this.showSelectHd = this.showSelectHd.bind(this)

        this.state = {
            editRecord: {}, 
            showSelect: false, 
        }

        this._formInit = false
        this._form = undefined
    }

    finishedHd(values) {
        let {formSubmitHd, record} = this.props
        let {editRecord} = this.state

        if (formSubmitHd) {
            values.Id = record ? record.Id : undefined
            values.TestCaseId = editRecord.TestCaseId
            formSubmitHd(values)
        }

        let newState = Object.assign({}, this.state)
        newState.editRecord = {}
        newState.showSelect = false
        this.setState(newState)
    }

    hoClearForm() {
        if (this._form) {
            // 清空相关信息
            this._form.setFieldsValue(
                {
                    TestCategoryName: undefined, 
                    StandardName: undefined, 
                    TestCaseName: undefined, 
                    CertId: undefined, 
                    FeatureId: undefined, 
                    Period: undefined, 
                    Price: undefined, 
                    IsDefault: undefined, 
                    Seq: undefined, 
                    Remark: undefined, 
                }
            )
            this._formInit = false
        }

    }

    showSelectHd() {
        let newState = Object.assign({}, this.state)
        newState.showSelect = true
        this.setState(newState)
    }

    selectCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.showSelect = false
        this.setState(newState)
    }

    selectHd(key, record) {
        let {editRecord} = this.state
        let {ObjTestCategoryList} = this.props

        // 判断 testcase 合法性, 只能从已经配置过了的检测大类中选择
        if (ObjTestCategoryList) {
            let find = findItemByIdFromList(record.CategoryId, "TestCategoryId", ObjTestCategoryList)
            if (!find) {
                // 提示错误
                HyMessage('warning', `该检测项所属的检测大类：${record.CategoryName}， 未在产品配置中，请先关联该检测大类后，再进行配置`, 5);
                return
            }
        }

        editRecord.TestCategoryName = record.CategoryName
        editRecord.TestCategoryId = record.CategoryId
        editRecord.TestCaseId = record.Id
        editRecord.TestCaseName = record.Name
        editRecord.StandardName = record.StandardName
        

        let newState = Object.assign({}, this.state)
        newState.showSelect = false
        newState.editRecord = editRecord
        this.setState(newState)
    }

    mkSelectModal() {
        let {showSelect} = this.state
        return (
            <Modal 
            title="关联检测项配置"
            visible={showSelect}
            width={1280}
            footer={null}
            onOk={undefined}
            onCancel={this.selectCancelHd}
            >
                <CertTestCaseListCT 
                selectFlag={true}
                selectedHd={this.selectHd}
                />

            </Modal>
        )
    }

    getCertOptions(objCertList) {
        let srcList = [{CertId: 0, CertName: "适用所有"}]
        if (objCertList) {
            for (let item of objCertList) {
                srcList.push(item)
            }
        }

        return srcList.map(
            (item, index) => (
                <Select.Option key={index} value={item.CertId}>{item.CertName}</Select.Option>
            )
        )
    }

    getFeatureOptions(ObjFeatureRelateList) {
        let srcList = [{FeatureId: 0, Name: "适用所有"}]
        if (ObjFeatureRelateList) {
            for (let item of ObjFeatureRelateList) {
                srcList.push(item)
            }
        }

        return srcList.map(
            (item, index) => (
                <Select.Option key={index} value={item.FeatureId}>{item.Name}</Select.Option>
            )
        )
    }


    render() {
        let {record, ObjName, objCertList, ObjFeatureRelateList, modalCancelHd} = this.props
        let {editRecord} = this.state
        let certOptions = this.getCertOptions(objCertList)
        let featureOptions = this.getFeatureOptions(ObjFeatureRelateList)

        if (this._form) {
            if (!this._formInit && record) {
                this._form.setFieldsValue(
                    {
                        TestCategoryName: editRecord.TestCategoryName ? editRecord.TestCategoryName : (record ? record.TestCategoryName : undefined), 
                        StandardName: editRecord.StandardName ? editRecord.StandardName : (record ? record.StandardName : undefined), 
                        TestCaseName: editRecord.TestCaseName ? editRecord.TestCaseName : (record ? record.TestCaseName : undefined), 
                        CertId: record ? record.CertId : undefined, 
                        FeatureId: record ? record.FeatureId : undefined, 
                        Period: record ? record.Period : undefined, 
                        Price: record ? record.Price : undefined, 
                        IsDefault: record ? record.IsDefault : undefined, 
                        Seq: record ? record.Seq : undefined, 
                        Remark: record ? record.Remark : undefined, 
                    }
                )
                this._formInit = true
            } else {
                this._form.setFieldsValue(
                    {
                        TestCategoryName: editRecord.TestCategoryName ? editRecord.TestCategoryName : (record ? record.TestCategoryName : undefined), 
                        StandardName: editRecord.StandardName ? editRecord.StandardName : (record ? record.StandardName : undefined), 
                        TestCaseName: editRecord.TestCaseName ? editRecord.TestCaseName : (record ? record.TestCaseName : undefined), 
                        CertId: record ? record.CertId : undefined, 
                        FeatureId: record ? record.FeatureId : undefined, 
                        Period: record ? record.Period : undefined, 
                        Price: record ? record.Price : undefined, 
                        IsDefault: record ? record.IsDefault : undefined, 
                        Seq: record ? record.Seq : undefined, 
                        Remark: record ? record.Remark : undefined, 
                    }
                )
            }
        }

        let selectModal = this.mkSelectModal()

        return (
            <div>
                {selectModal}
                <Form onFinish={this.finishedHd} ref={node => this._form = node} 
                labelCol={{span: 8}} wrapperCol={{span: 16}} 
                initialValues={
                    {
                        TestCategoryName: record ? record.TestCategoryName : undefined, 
                        StandardName: record ? record.StandardName : undefined, 
                        TestCaseName: record ? record.TestCaseName : undefined, 
                        CertId: record ? record.CertId : undefined, 
                        FeatureId: record ? record.FeatureId : undefined, 
                        Period: record ? record.Period : undefined, 
                        Price: record ? record.Price : undefined, 
                        IsDefault: record ? record.IsDefault : undefined, 
                        Seq: record ? record.Seq : undefined, 
                        Remark: record ? record.Remark : undefined, 
                    }
                }
                >
                    <Form.Item rules={[{required: true, message: '请选择检测项!'}]} label="检测项" name="TestCaseName">
                        <Row justify="start">
                            <Col span={14}>
                            <Input placeholder="请点击右边按钮选择检测项" value={editRecord.TestCaseName ? editRecord.TestCaseName : (record ? record.TestCaseName : undefined)} disabled/>
                            </Col>
                            <Col push={2} span={6}>
                            <Button type="primary" icon={<CheckOutlined />} onClick={this.showSelectHd} >选择检测项</Button>
                            </Col>
                        </Row>    
                    </Form.Item>

                    <Form.Item name="TestCategoryName" label="检测大类" >
                        <Input  disabled/>
                    </Form.Item>

                    <Form.Item name="StandardName" label="遵循标准" >
                        <Input  disabled/>
                    </Form.Item>

                    <Form.Item rules={[{required: true, message: '请选择认证类别!'}]} label="认证类别" name="CertId">
                        {/* <Select value={record ? record.CertId : undefined}> */}
                        <Select >
                            {certOptions}
                        </Select>
                    </Form.Item>

                    <Form.Item rules={[{required: true, message: '产品附加特性!'}]} label="产品附加特性" name="FeatureId">
                        <Select >
                            {featureOptions}
                        </Select>
                    </Form.Item>


                    <Form.Item name="Period" label="周期（工作日）" >
                        <Input  />
                    </Form.Item>

                    <Form.Item name="Price" label="价格" >
                        <Input  />
                    </Form.Item>

                    <Form.Item name="IsDefault" label="默认选中" >
                        <Select>
                            <Select.Option value={1}>是</Select.Option>
                            <Select.Option value={0}>否</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name="Seq" label="序号" >
                        <Input  />
                    </Form.Item>

                    <Form.Item name="Remark" label="备注" >
                        <Input  />
                    </Form.Item>

                    <Row justify="end">
                        <Space size="middle" >
                        <Button type="default" onClick={modalCancelHd}>取消</Button>
                        <Button type="primary" onClick={undefined} htmlType="submit">保存</Button>
                        </Space>
                    </Row>

                </Form>

            </div>
        )
    }
}
