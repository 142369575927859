import {findItemByIdFromList} from '../../comm/logic'
import {actUpdate, actAdd, actDel} from '../../comm/commRD'


const ACT_PRODUCT_UPDATE_DSTOBJ_CATEGORY = 'ACT_PRODUCT_UPDATE_DSTOBJ_CATEGORY'
const ACT_PRODUCT_UPDATE_DSTOBJ = 'ACT_PRODUCT_UPDATE_DSTOBJ'
const ACT_PRODUCT_UPDATE_FEATURE_CATEGORY = 'ACT_PRODUCT_UPDATE_FEATURE_CATEGORY'
const ACT_PRODUCT_UPDATE_FEATURE_SUBCATEGORY = 'ACT_PRODUCT_UPDATE_FEATURE_SUBCATEGORY'
const ACT_PRODUCT_UPDATE_FEATURE = 'ACT_PRODUCT_UPDATE_FEATURE'
const ACT_PRODUCT_UPDATE_DSTOBJ_DETAIL = 'ACT_PRODUCT_UPDATE_DSTOBJ_DETAIL'


const ProductState = {
    dstObjCategory: {TotalNum: 0, PageSize: 10, PageNum: 1, RecordList: []}, 
    featureCategory: {TotalNum: 0, PageSize: 10, PageNum: 1, RecordList: []}, 
    featureSubcategory: {TotalNum: 0, PageSize: 10, PageNum: 1, RecordList: []}, 
    feature: {TotalNum: 0, PageSize: 10, PageNum: 1, RecordList: []}, 
    dstObj: {TotalNum: 0, PageSize: 10, PageNum: 1, RecordList: []}, 
    dstObjDeail: undefined, 
}

const getStateKeyByActionType = (actionType) => {
    switch(actionType) {
        case ACT_PRODUCT_UPDATE_DSTOBJ_CATEGORY: 
            return "dstObjCategory"

        case ACT_PRODUCT_UPDATE_DSTOBJ: 
            return "dstObj"

        case ACT_PRODUCT_UPDATE_FEATURE_CATEGORY:
            return 'featureCategory'

        case ACT_PRODUCT_UPDATE_FEATURE_SUBCATEGORY:
            return 'featureSubcategory'
        
        case ACT_PRODUCT_UPDATE_FEATURE:
            return 'feature'

        case ACT_PRODUCT_UPDATE_DSTOBJ_DETAIL:
            return 'dstObjDeail'

        default:
            return undefined
    }
}

const getStateOpByActionType = (actionType) => {
    switch(actionType) {
        case ACT_PRODUCT_UPDATE_DSTOBJ_CATEGORY: 
        case ACT_PRODUCT_UPDATE_DSTOBJ: 
        case ACT_PRODUCT_UPDATE_FEATURE_CATEGORY:
        case ACT_PRODUCT_UPDATE_FEATURE_SUBCATEGORY:
        case ACT_PRODUCT_UPDATE_FEATURE:
        case ACT_PRODUCT_UPDATE_DSTOBJ_DETAIL: 
            return "update"


        default:
            return "other"
    }
}

export const product_reducer = (state=ProductState, action) => {
    let op = getStateOpByActionType(action.type)
    switch(op) {
        case "update":
        {
            let retState = Object.assign({}, state);
            let key = getStateKeyByActionType(action.type)
            retState[key] = action.data;
            return retState;
        }

        
        // 其他不定action处理
        // case "other":
        // {
        //     switch(action.type) {
        //         case ACT_CERT_UPDATE_TEST_CASE_DETAIL:
        //         {
        //             return retState
        //         }
                

        //     }
        // }

        default:
            return state
    }

}


// dstObjCategory
// ACT_CERT_UPDATE_DSTOBJ_CATEGORY
export const getdstObjCategory = (state) => {
    if (state.product.dstObjCategory !== undefined){
        return state.product.dstObjCategory;
    }
    return undefined;
}


export const getDstObjCategoryListSuccessHd = (dispatch, rspBody, msgBody) => {
    let data = {
        TotalNum: rspBody.TotalNum, 
        RecordList: rspBody.RecordList, 
        PageSize: msgBody.PageSize, 
        PageNum: msgBody.PageNum, 
    }
    dispatch(actUpdate(data, ACT_PRODUCT_UPDATE_DSTOBJ_CATEGORY));
}


// dstObj
export const getdstObj = (state) => {
    if (state.product.dstObj !== undefined){
        return state.product.dstObj;
    }
    return undefined;
}

export const getDstObjListSuccessHd = (dispatch, rspBody, msgBody) => {
    let data = {
        TotalNum: rspBody.TotalNum, 
        RecordList: rspBody.RecordList, 
        PageSize: msgBody.PageSize, 
        PageNum: msgBody.PageNum, 
    }
    if (!rspBody.RecordList) {
        data.RecordList = []
    }
    dispatch(actUpdate(data, ACT_PRODUCT_UPDATE_DSTOBJ));
}

// featureCategory
export const getFeatureCategory = (state) => {
    if (state.product.featureCategory !== undefined){
        return state.product.featureCategory;
    }
    return undefined;
}

export const getFeatureCategoryListSuccessHd = (dispatch, rspBody, msgBody) => {
    let data = {
        TotalNum: rspBody.TotalNum, 
        RecordList: rspBody.RecordList, 
        PageSize: msgBody.PageSize, 
        PageNum: msgBody.PageNum, 
    }
    if (!rspBody.RecordList) {
        data.RecordList = []
    }
    dispatch(actUpdate(data, ACT_PRODUCT_UPDATE_FEATURE_CATEGORY));
}

// featureSubcategory
export const getFeatureSubcategory = (state) => {
    if (state.product.featureSubcategory !== undefined){
        return state.product.featureSubcategory;
    }
    return undefined;
}

export const getFeatureSubcategoryListSuccessHd = (dispatch, rspBody, msgBody) => {
    let data = {
        TotalNum: rspBody.TotalNum, 
        RecordList: rspBody.RecordList, 
        PageSize: msgBody.PageSize, 
        PageNum: msgBody.PageNum, 
    }
    if (!rspBody.RecordList) {
        data.RecordList = []
    }
    dispatch(actUpdate(data, ACT_PRODUCT_UPDATE_FEATURE_SUBCATEGORY));
}

// feature
export const getFeature = (state) => {
    if (state.product.feature !== undefined){
        return state.product.feature;
    }
    return undefined;
}

export const getFeatureListSuccessHd = (dispatch, rspBody, msgBody) => {
    let data = {
        TotalNum: rspBody.TotalNum, 
        RecordList: rspBody.RecordList, 
        PageSize: msgBody.PageSize, 
        PageNum: msgBody.PageNum, 
    }
    if (!rspBody.RecordList) {
        data.RecordList = []
    }
    dispatch(actUpdate(data, ACT_PRODUCT_UPDATE_FEATURE));
}

// dstObjDeail
export const getDstObjDetail = (state) => {
    if (state.product.dstObjDeail !== undefined){
        return state.product.dstObjDeail;
    }
    return undefined;
}

export const getDstObjDetailSuccessHd = (dispatch, rspBody, msgBody) => {
    let data = {
        DstObjInfo: rspBody.DstObjInfo, 
        ObjCertList: rspBody.ObjCertList ? rspBody.ObjCertList : [], 
        ObjTestCategoryList: rspBody.ObjTestCategoryList ? rspBody.ObjTestCategoryList : [], 
        ObjFeatureRelateList: rspBody.ObjFeatureRelateList ? rspBody.ObjFeatureRelateList : [], 
        ObjTestCaseList: rspBody.ObjTestCaseList ? rspBody.ObjTestCaseList : [], 
    }
    dispatch(actUpdate(data, ACT_PRODUCT_UPDATE_DSTOBJ_DETAIL));
}



