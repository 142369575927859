import React, { Component } from 'react'
import { connect } from 'react-redux'
import {getNetRequestStatus, netRequestThunk, actNetRequestClear} from '../../network/netReqRD'
import {getDstObjDetail, getDstObjDetailSuccessHd} from './productRD'
import {getLoginTokenInfo} from '../account/loginRD'
import {DstObjDetailPage} from './dstObjDetail'
import {HoBuildTableData} from '../../component/utils'
import {HoMkSearchFilters} from '../../component/tbl'

class DstObjDetailCT extends Component {
    constructor(props){
        super(props)
        this.pageChangeHd = this.pageChangeHd.bind(this)
        this.tokenCheckHd = this.tokenCheckHd.bind(this)
        this.searchHd = this.searchHd.bind(this)
        this.requestData = this.requestData.bind(this)
        this.deleteOnClickHd = this.deleteOnClickHd.bind(this)
        this.delConfirmHd = this.delConfirmHd.bind(this)
        this.getLoadingFlag = this.getLoadingFlag.bind(this)
        this.addOnclickHd = this.addOnclickHd.bind(this)
        this.detailOnclickHd = this.detailOnclickHd.bind(this)
        this.addConfirmHd = this.addConfirmHd.bind(this)
        this.editConfirmHd = this.editConfirmHd.bind(this)

        let {match} = props
        let recordId = undefined
        if (match) {
            if (match.params) {
                recordId = parseInt(match.params.objId)
            }
            console.log("@@@@@: recordId = ", recordId)
        }

        
        this._pagination = {
            PageSize: 50, 
            PageNum: 1, 
            Filters: [[]], 
            OrderKey: "", 
            Desc: 1, 
        }

        this.state = {
            recordId: recordId, 
            PageSize: this._pagination.PageSize, 
            PageNum: this._pagination.PageNum, 
        }

        this._delRecord = undefined
        this._delKey = undefined

    }

    tokenCheckHd() {
        console.log("@@@@@_2")
        let {tokenInfo, history} = this.props
        if (tokenInfo === undefined) {
            const directPath = '/'
            history.push(directPath)
            return false
        }
        return true
    }

    requestData() {
        if (!this.tokenCheckHd()) {
            return
        }

        let {recordId} = this.state

        let {dispatch, tokenInfo} = this.props
        let msgBody = {
            Id: recordId, 
        }

        dispatch(netRequestThunk("/hq/v1/mntn/product/dstObj/detail", msgBody, "getDstObjDetail", getDstObjDetailSuccessHd, tokenInfo.token))
    }

    componentWillUnmount(){
        let {dispatch} = this.props
        dispatch(actNetRequestClear("getDstObjDetail"))
        dispatch(actNetRequestClear("addObjCert"))
        dispatch(actNetRequestClear("modObjCert"))
        dispatch(actNetRequestClear("delObjCert"))
        dispatch(actNetRequestClear("addObjTestCategory"))
        dispatch(actNetRequestClear("modObjTestCategory"))
        dispatch(actNetRequestClear("delObjTestCategory"))
        dispatch(actNetRequestClear("addObjFeatureRelate"))
        dispatch(actNetRequestClear("modObjFeatureRelate"))
        dispatch(actNetRequestClear("delObjFeatureRelate"))
    }

    componentDidMount() {
        this.requestData()
    }


    pageChangeHd(current, size) {
        if (current !== this._pagination.PageNum || size !== this._pagination.PageSize) {
            this._pagination.PageNum = current
            this._pagination.PageSize = size
            // 需要重新请求数据
            this.requestData()
            // set state
            let newState = Object.assign({}, this.state)
            newState.PageNum = current
            newState.PageSize = size
            this.setState(newState)
        }
    }

    searchHd(searchInfo) {
        let filters = HoMkSearchFilters(searchInfo)
        this._pagination.Filters = filters
        this._pagination.PageNum = 1

        // 这里进行网络请求
        this.requestData()
    }

    addConfirmHd(record, reqType) {
        if (!this.tokenCheckHd()) {
            return
        }

        console.log("##$$##$$: record = , reqType = ", record, reqType)

        let {dispatch, tokenInfo} = this.props
        let {recordId} = this.state
        let msgBody = {}

        if (reqType === "objCert") {
            msgBody.ObjId = recordId
            msgBody.CertId = record.CertId
            dispatch(netRequestThunk("/hq/v1/mntn/product/objCert/add", msgBody, "addObjCert", undefined, tokenInfo.token))
            return
        }

        if (reqType === "objTestCategory") {
            msgBody.ObjId = recordId
            msgBody.CertId = record.CertId
            msgBody.TestCategoryId = record.TestCategoryId
            msgBody.Period = record.Period
            msgBody.Price = record.Price
            msgBody.IsDefault = record.IsDefault
            msgBody.Seq = record.Seq
            msgBody.Remark = record.Remark
            dispatch(netRequestThunk("/hq/v1/mntn/product/objTestcategory/add", msgBody, "addObjTestCategory", undefined, tokenInfo.token))
            return
        }

        if (reqType === "objFeatureRelate") {
            msgBody.ObjId = recordId
            msgBody.CertId = record.CertId
            msgBody.FeatureId = record.FeatureId
            msgBody.Period = record.Period
            msgBody.Price = record.Price
            msgBody.IsDefault = record.IsDefault
            msgBody.Seq = record.Seq
            msgBody.Remark = record.Remark
            dispatch(netRequestThunk("/hq/v1/mntn/product/objFeature/add", msgBody, "addObjFeatureRelate", undefined, tokenInfo.token))
            return
        }

        if (reqType === "objTestCaseRelate") {
            msgBody.ObjId = recordId
            msgBody.TestCaseId = record.TestCaseId
            msgBody.CertId = record.CertId
            msgBody.FeatureId = record.FeatureId
            msgBody.Period = record.Period
            msgBody.Price = record.Price
            msgBody.IsDefault = record.IsDefault
            msgBody.Seq = record.Seq
            msgBody.Remark = record.Remark
            dispatch(netRequestThunk("/hq/v1/mntn/product/objTestCase/add", msgBody, "addObjTestCaseRelate", undefined, tokenInfo.token))
            return
        }

    }

    editConfirmHd(record, reqType) {
        if (!this.tokenCheckHd()) {
            return
        }
        let {dispatch, tokenInfo} = this.props
        let {recordId} = this.props
        let msgBody = {}

        if (reqType === "objCert") {
            msgBody.Id = record.Id
            msgBody.ObjId = recordId
            msgBody.CertId = record.CertId
            dispatch(netRequestThunk("/hq/v1/mntn/product/objCert/mod", msgBody, "modObjCert", undefined, tokenInfo.token))
            return
        }

        if (reqType === "objTestCategory") {
            msgBody.Id = record.Id
            msgBody.ObjId = recordId
            msgBody.CertId = record.CertId
            msgBody.TestCategoryId = record.TestCategoryId
            msgBody.Period = record.Period
            msgBody.Price = record.Price
            msgBody.IsDefault = record.IsDefault
            msgBody.Seq = record.Seq
            msgBody.Remark = record.Remark
            dispatch(netRequestThunk("/hq/v1/mntn/product/objTestcategory/mod", msgBody, "modObjTestCategory", undefined, tokenInfo.token))
            return
        }

        if (reqType === "objFeatureRelate") {
            msgBody.Id = record.Id
            msgBody.ObjId = recordId
            msgBody.CertId = record.CertId
            msgBody.FeatureId = record.FeatureId
            msgBody.Period = record.Period
            msgBody.Price = record.Price
            msgBody.IsDefault = record.IsDefault
            msgBody.Seq = record.Seq
            msgBody.Remark = record.Remark
            dispatch(netRequestThunk("/hq/v1/mntn/product/objFeature/mod", msgBody, "modObjFeatureRelate", undefined, tokenInfo.token))
            return
        }

        if (reqType === "objTestCaseRelate") {
            msgBody.Id = record.Id
            msgBody.ObjId = recordId
            msgBody.TestCaseId = record.TestCaseId
            msgBody.CertId = record.CertId
            msgBody.FeatureId = record.FeatureId
            msgBody.Period = record.Period
            msgBody.Price = record.Price
            msgBody.IsDefault = record.IsDefault
            msgBody.Seq = record.Seq
            msgBody.Remark = record.Remark

            console.log("###$$$$$$: msgBody = ", msgBody)

            dispatch(netRequestThunk("/hq/v1/mntn/product/objTestCase/mod", msgBody, "modObjTestCaseRelate", undefined, tokenInfo.token))
            return
        }


    }

    deleteOnClickHd(key, record) {
        this._delRecord = record
        this._delKey = key
    }

    delConfirmHd(e, type) {
        e.stopPropagation();
        if (type === 'confirm') {
            if (this._delRecord !== undefined) {
                if (!this.tokenCheckHd()) {
                    this._delRecord = undefined  
                    return
                }

                let {dispatch, tokenInfo} = this.props
                // let msgBody = {}

                let msgBody = {
                    Id: this._delRecord.Id, 
                }

                if (this._delKey === "objCert") {
                    dispatch(netRequestThunk("/hq/v1/mntn/product/objCert/del", msgBody, "delObjCert", undefined, tokenInfo.token))
                }else if (this._delKey === "objTestCategory") {
                    dispatch(netRequestThunk("/hq/v1/mntn/product/objTestcategory/del", msgBody, "delObjTestCategory", undefined, tokenInfo.token))
                }else if (this._delKey === "objFeatureRelate") {
                    dispatch(netRequestThunk("/hq/v1/mntn/product/objFeature/del", msgBody, "delObjFeatureRelate", undefined, tokenInfo.token))
                }else if (this._delKey === "objTestCaseRelate") {
                    dispatch(netRequestThunk("/hq/v1/mntn/product/objTestCase/del", msgBody, "delObjTestCaseRelate", undefined, tokenInfo.token))
                }
            }
        }

        this._delRecord = undefined        
        this._delKey = undefined
    }


    getLoadingFlag() {
        let {reqStatus, addObjCertStatus, modObjCertStatus, delObjCertStatus, addObjTestCategoryStatus, modObjTestCategoryStatus, 
            delObjTestCategoryStatus, addObjFeatureRelateStatus, modObjFeatureRelateStatus, delObjFeatureRelateStatus, dispatch, 
            addObjTestCaseRelateStatus, modObjTestCaseRelateStatus, delObjTestCaseRelateStatus} = this.props

        let isLoading = false
        if (reqStatus === 1 || addObjCertStatus === 1 || modObjCertStatus === 1 || delObjCertStatus === 1) {
            isLoading = true
        } 

        if (addObjTestCategoryStatus === 1 || modObjTestCategoryStatus === 1 || delObjTestCategoryStatus === 1) {
            isLoading = true
        } 

        if (addObjFeatureRelateStatus === 1 || modObjFeatureRelateStatus === 1 || delObjFeatureRelateStatus === 1) {
            isLoading = true
        } 

        if (addObjTestCaseRelateStatus === 1 || modObjTestCaseRelateStatus === 1 || delObjTestCaseRelateStatus === 1) {
            isLoading = true
        } 


        if (addObjCertStatus === 2) {
            dispatch(actNetRequestClear("addObjCert"))
            this.requestData()
            return isLoading
        }

        if (modObjCertStatus === 2) {
            dispatch(actNetRequestClear("modObjCert"))
            this.requestData()
            return isLoading
        }

        if (delObjCertStatus === 2) {
            dispatch(actNetRequestClear("delObjCert"))
            this.requestData()
            return isLoading
        }

        if (addObjTestCategoryStatus === 2) {
            dispatch(actNetRequestClear("addObjTestCategory"))
            this.requestData()
            return isLoading
        }

        if (modObjTestCategoryStatus === 2) {
            dispatch(actNetRequestClear("modObjTestCategory"))
            this.requestData()
            return isLoading
        }

        if (delObjTestCategoryStatus === 2) {
            dispatch(actNetRequestClear("delObjTestCategory"))
            this.requestData()
            return isLoading
        }

        if (addObjFeatureRelateStatus === 2) {
            dispatch(actNetRequestClear("addObjFeatureRelate"))
            this.requestData()
            return isLoading
        }

        if (modObjFeatureRelateStatus === 2) {
            dispatch(actNetRequestClear("modObjFeatureRelate"))
            this.requestData()
            return isLoading
        }

        if (delObjFeatureRelateStatus === 2) {
            dispatch(actNetRequestClear("delObjFeatureRelate"))
            this.requestData()
            return isLoading
        }

        if (addObjTestCaseRelateStatus === 2) {
            dispatch(actNetRequestClear("addObjTestCaseRelate"))
            this.requestData()
            return isLoading
        }

        if (modObjTestCaseRelateStatus === 2) {
            dispatch(actNetRequestClear("modObjTestCaseRelate"))
            this.requestData()
            return isLoading
        }

        if (delObjTestCaseRelateStatus === 2) {
            dispatch(actNetRequestClear("delObjTestCaseRelate"))
            this.requestData()
            return isLoading
        }


        return isLoading
    }

    addOnclickHd() {
        let {history} = this.props
        if (history) {
            let url = "/setting/certTestCase/detail/"
            history.push(url)
        }
    }


    detailOnclickHd(key, record) {
        let {history} = this.props
            if (history) {
                let url = "/setting/certTestCase/detail/" + record.Id
                history.push(url)
            }
    }

    render(){
        let {detailInfo} = this.props

        let isLoading = this.getLoadingFlag()
        
        return (
            <DstObjDetailPage 
            detailInfo={detailInfo}
            editConfirmHd={this.editConfirmHd}
            addConfirmHd={this.addConfirmHd}
            deleteOnClickHd={this.deleteOnClickHd}
            delConfirmHd={this.delConfirmHd}
            isLoading={isLoading}
            
            />
        );
    }
}

const mapState = (state) => ({
    detailInfo: getDstObjDetail(state), 
    tokenInfo: getLoginTokenInfo(state), 
    reqStatus: getNetRequestStatus(state, 'getDstObjDetail'), 
    addObjCertStatus: getNetRequestStatus(state, 'addObjCert'), 
    modObjCertStatus: getNetRequestStatus(state, 'modObjCert'), 
    delObjCertStatus: getNetRequestStatus(state, 'delObjCert'), 
    addObjTestCategoryStatus: getNetRequestStatus(state, 'addObjTestCategory'), 
    modObjTestCategoryStatus: getNetRequestStatus(state, 'modObjTestCategory'), 
    delObjTestCategoryStatus: getNetRequestStatus(state, 'delObjTestCategory'), 
    addObjFeatureRelateStatus: getNetRequestStatus(state, 'addObjFeatureRelate'), 
    modObjFeatureRelateStatus: getNetRequestStatus(state, 'modObjFeatureRelate'), 
    delObjFeatureRelateStatus: getNetRequestStatus(state, 'delObjFeatureRelate'), 
    addObjTestCaseRelateStatus: getNetRequestStatus(state, 'addObjTestCaseRelate'), 
    modObjTestCaseRelateStatus: getNetRequestStatus(state, 'modObjTestCaseRelate'), 
    delObjTestCaseRelateStatus: getNetRequestStatus(state, 'delObjTestCaseRelate'), 
});

export default connect(
    mapState, 
    null
)(DstObjDetailCT);


