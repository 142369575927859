import React, { Component } from 'react';

import {Card, Row, Col, Button, Popconfirm, Space, Divider, Typography, Descriptions, Form, Input, Modal, Select, Collapse, Tag } from 'antd'
import {SyncOutlined, PlusOutlined, EditOutlined, SaveOutlined, CheckOutlined} from '@ant-design/icons'

import {HoBreadcrumb} from '../../component/element'
import {HoTable, HoMkTblColumn, HoMkTblColActions} from '../../component/tbl'
import {yesOrNoMap} from '../../comm/logic'
import CertCertificationListCT from '../../app/cert/certificationCT'
import {FeatureForm, TestCategoryForm, TestCaseForm} from './detailForm'

const { Panel } = Collapse; 


export class DstObjDetailPage extends Component {
    constructor(props){
        super(props)

        this.editOnclickHd = this.editOnclickHd.bind(this)
        this.mkTitleContent = this.mkTitleContent.bind(this)
        this.modalCancelHd = this.modalCancelHd.bind(this)
        this.addOnclickHd = this.addOnclickHd.bind(this)
        this.modalConfirmHd = this.modalConfirmHd.bind(this)
        this.categorySelectedHd = this.categorySelectedHd.bind(this)
        this.selectModalCancelHd = this.selectModalCancelHd.bind(this)
        this.selectCategoryButtonOnClickHd = this.selectCategoryButtonOnClickHd.bind(this)

        this.getRelateCertContent = this.getRelateCertContent.bind(this)
        this.getRelateTestCategoryContent = this.getRelateTestCategoryContent.bind(this)
        this.getRelateFeatureContent = this.getRelateFeatureContent.bind(this)

        this.mkRelateCertModal = this.mkRelateCertModal.bind(this)
        this.mkRelateTestCategoryModal = this.mkRelateTestCategoryModal.bind(this)
        this.mkRelateFeature = this.mkRelateFeature.bind(this)

        this.certAddButtonOnClickHd = this.certAddButtonOnClickHd.bind(this)
        this.testCategoryAddButtonOnClickHd = this.testCategoryAddButtonOnClickHd.bind(this)
        this.featureAddButtonOnClick = this.featureAddButtonOnClick.bind(this)

        this.certModalCancelHd = this.certModalCancelHd.bind(this)
        this.testCategoryModalCancelHd = this.testCategoryModalCancelHd.bind(this)
        this.featureModalCancelHd = this.featureModalCancelHd.bind(this)

        this.selectCertCancelHd = this.selectCertCancelHd.bind(this)
        this.selectTestCategoryCancelHd = this.selectTestCategoryCancelHd.bind(this)

        this.selectCertAddHd = this.selectCertAddHd.bind(this)
        this.selectTestCategoryAddHd = this.selectTestCategoryAddHd.bind(this)

        this.certSelectHd = this.certSelectHd.bind(this)

        this.mkCertSelectModal = this.mkCertSelectModal.bind(this)

        this.relateFeatureFormSubmitHd = this.relateFeatureFormSubmitHd.bind(this)
        this.relateTestCategoryFormSubmitHd = this.relateTestCategoryFormSubmitHd.bind(this)


        this.getRelateTestCaseContent = this.getRelateTestCaseContent.bind(this)
        this.testCaseAddButtonOnClick = this.testCaseAddButtonOnClick.bind(this)
        this.testCaseModalCancelHd = this.testCaseModalCancelHd.bind(this)
        this.relateTestCaseFormSubmitHd = this.relateTestCaseFormSubmitHd.bind(this)


        let {deleteOnClickHd, delConfirmHd} = props
        this.state = {
            editMode: undefined,
            editRecord: {}, 
            srcRecord: undefined, 
            showEdit: false, 

            showRelateCert: false, 
            showSelectCert: false, 
            showRelateTestCategory: false, 
            showSelectTestCategory: false, 
            showRelateFeature: false, 
            showSelectFeature: false, 

            showRelateTestCase: false, 
            showSelectTestCase: false, 
        }

        this._certForm = undefined
        this._testCategoryForm = undefined

        this._featureForm = undefined
        this._testCaseForm = undefined

        this._breadConf = [
            {name: "产品信息", link: '/setting/productObjCategory'}, 
            {name: "认证产品", link: '/setting/productObj'}, 
            {name: "产品详情", link: undefined }, 
        ]

        const disDelete = (
            <Popconfirm title="请确认是否要删除？" 
            onConfirm={(e) => delConfirmHd ? delConfirmHd(e, 'confirm') : undefined}
            onCancel={(e) => delConfirmHd ? delConfirmHd(e, 'cancel') : undefined}
            >
                <span>删除</span>
            </Popconfirm>
        )

        let relateCertAction = HoMkTblColActions([
        {key: 'delete', display: disDelete, onClickedHd: (key, record) => deleteOnClickHd("objCert", record)}, ])

        this._relateCertCols = [
            HoMkTblColumn('认证产品', 'ObjName', 'str', '30%', false, false), 
            HoMkTblColumn('认证类别', 'CertName', 'str', '30%', false, false), 
            relateCertAction, 
        ]


        let relateTestCategoryAction = HoMkTblColActions([{key: 'edit', display: '编辑', onClickedHd: (key, record) => this.editOnclickHd("objTestCategory", record)}, 
        {key: 'delete', display: disDelete, onClickedHd: (key, record) => deleteOnClickHd("objTestCategory", record)}, ])

        this._relateTestCategoryCols = [
            HoMkTblColumn('认证产品', 'ObjName', 'str', '10%', false, false), 
            HoMkTblColumn('认证类别', 'CertName', 'str', '10%', false, false), 
            HoMkTblColumn('检测大类', 'TestCategoryName', 'str', '10%', false, false), 
            HoMkTblColumn('周期(工作日)', 'Period', 'number', '10%', false, false), 
            HoMkTblColumn('价格', 'Price', 'number', '10%', false, false), 
            HoMkTblColumn('默认选中', 'IsDefault', 'number', '10%', false, false, yesOrNoMap), 
            HoMkTblColumn('序号', 'Seq', 'number', '10%', false, false), 
            relateTestCategoryAction, 
        ]

        let relateFeatureAction = HoMkTblColActions([
            {key: 'edit', display: '编辑', onClickedHd: (key, record) => this.editOnclickHd("objFeatureRelate", record)}, 
        {key: 'delete', display: disDelete, onClickedHd: (key, record) => deleteOnClickHd("objFeatureRelate", record)}, ])

        this._relateFeatureCols = [
            HoMkTblColumn('附加特性类别', 'CategoryName', 'str', '8%', false, false), 
            HoMkTblColumn('附加特性子类别', 'SubcategoryName', 'str', '8%', false, false), 
            HoMkTblColumn('附加特性', 'Name', 'str', '8%', false, false), 
            HoMkTblColumn('显示名称', 'DisplayName', 'str', '8%', false, false), 
            HoMkTblColumn('描述', 'Description', 'str', '8%', false, false), 
            HoMkTblColumn('认证类别', 'CertName', 'str', '8%', false, false), 
            HoMkTblColumn('周期(工作日)', 'Period', 'number', '8%', false, false), 
            HoMkTblColumn('价格', 'Price', 'number', '8%', false, false), 
            HoMkTblColumn('默认选中', 'IsDefault', 'number', '8%', false, false, yesOrNoMap), 
            HoMkTblColumn('序号', 'Seq', 'number', '8%', false, false), 
            HoMkTblColumn('备注', 'Remark', 'str', '8%', false, false), 
            relateFeatureAction, 
        ]

        let relateTestCaseAction = HoMkTblColActions([
            {key: 'edit', display: '编辑', onClickedHd: (key, record) => this.editOnclickHd("objTestCaseRelate", record)}, 
        {key: 'delete', display: disDelete, onClickedHd: (key, record) => deleteOnClickHd("objTestCaseRelate", record)}, ])

        this._relateTestCaseCols = [
            HoMkTblColumn('检测项', 'TestCaseName', 'str', '14%', false, false), 
            HoMkTblColumn('所属大类', 'TestCategoryName', 'str', '10%', false, false), 
            HoMkTblColumn('遵循标准', 'StandardName', 'str', '10%', false, false), 
            HoMkTblColumn('认证类别', 'CertName', 'str', '10%', false, false), 
            HoMkTblColumn('附加特性', 'FeatureName', 'str', '10%', false, false), 
            HoMkTblColumn('周期(工作日)', 'Period', 'number', '8%', false, false), 
            HoMkTblColumn('价格', 'Price', 'number', '8%', false, false), 
            HoMkTblColumn('默认选中', 'IsDefault', 'number', '8%', false, false, yesOrNoMap), 
            HoMkTblColumn('序号', 'Seq', 'number', '8%', false, false), 
            HoMkTblColumn('备注', 'Remark', 'str', '8%', false, false), 
            relateTestCaseAction, 
        ]

    }
    
    
    editOnclickHd(key, record) {
        console.log("@@@@: record = , key = ", record, key)
        let newtSate = Object.assign({}, this.state)
        newtSate.editMode = "mod"
        newtSate.editRecord = record
        newtSate.srcRecord = record
        if (key === "objTestCategory") {
            newtSate.showRelateTestCategory = true
        }else if (key === "objFeatureRelate"){
            newtSate.showRelateFeature = true
        }else if (key === "objTestCaseRelate") {
            newtSate.showRelateTestCase = true
        }
        this.setState(newtSate)
    }

    displayCategorys(categoryList) {
        let display = undefined
        if (categoryList) {
            display = categoryList.map(
                (item, index) => (
                    <Tag key={index} color="geekblue">{item.CategoryName}</Tag>
                )
            )
        }
        
        return display
    }


    mkTitleContent() {
        let {detailInfo} = this.props
        if (!detailInfo) {
            return undefined
        }

        if (!detailInfo.DstObjInfo){
            return undefined
        }

        let {Name, Display, Description, DetailUrl, Remark, CategoryList} = detailInfo.DstObjInfo

        let content = (
            <Descriptions title="认证产品概述" column={2}>
                <Descriptions.Item label="产品名称">{Name}</Descriptions.Item>
                <Descriptions.Item label="关联类别">{this.displayCategorys(CategoryList)}</Descriptions.Item>
                <Descriptions.Item label="产品描述">{Description}</Descriptions.Item>
                <Descriptions.Item label="介绍网址">{DetailUrl}</Descriptions.Item>
                <Descriptions.Item label="是否显示">{Display === 1 ? "是" : "否"}</Descriptions.Item>
                <Descriptions.Item label="备注">{Remark}</Descriptions.Item>
            </Descriptions>
        )
        return content  
    }   

    modalCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.editMode = undefined
        newState.showEdit = false
        newState.editRecord = {}
        this.setState(newState) 
    }

    addOnclickHd() {
        let newState = Object.assign({}, this.state)
        newState.editMode = "add"
        newState.showEdit = true
        this.setState(newState) 
    }


    modalConfirmHd(values, key) {
        let {editRecord, editMode} = this.state
        let {editConfirmHd, addConfirmHd} = this.props
        console.log("modalConfirmHd: editRecord = ", editRecord)
        console.log("modalConfirmHd: values = ", values)
        console.log("modalConfirmHd: key = " + key + "editMode = " + editMode)

        let newState = Object.assign({}, this.state)

        let result = {
            Id: editRecord.Id, 
        }

        if (key === "objCert") {
            result.CertId = editRecord.CertId
            newState.showRelateCert = false

        }else if (key === "objTestCategory") {
            result.CertId = values.CertId
            result.TestCategoryId = editRecord.TestCategoryId
            result.Period = parseFloat(values.Period)
            result.Price = parseFloat(values.Price)
            result.IsDefault = values.IsDefault
            result.Seq = parseInt(values.Seq)
            result.Remark = values.Remark

            newState.showRelateTestCategory = false
        }else if (key === "objFeatureRelate") {
            
        }

        if (editMode === 'add') {
            if (addConfirmHd){
                addConfirmHd(result, key)
            }

        }else if (editMode === 'mod') {
            if (editConfirmHd) {
                console.log("#@#@#@#: result = ", result)
                editConfirmHd(result, key)
            }
        }

        
        newState.editMode = undefined
        newState.editRecord = {}
        this.setState(newState)
    }

    categorySelectedHd(key, record) {
        let {editRecord} = this.state

        editRecord.CategoryName = record.Name
        editRecord.TestCategoryId = record.Id

        let newState = Object.assign({}, this.state)
        newState.showCategorySelect = false
        newState.editRecord = editRecord
        this.setState(newState)
    }

    selectModalCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.showCategorySelect = false
        this.setState(newState)
    }

    selectCategoryButtonOnClickHd() {
        let newState = Object.assign({}, this.state)
        newState.showCategorySelect = true
        this.setState(newState)
    }

    
    // cert
    getRelateCertContent() {
        let {detailInfo, loading} = this.props
        let dataList = undefined
        if (detailInfo) {
            dataList = detailInfo.ObjCertList
        }

        return (
            <div style={{marginBottom: "16px"}}>
                <Row justify="start" style={{marginBottom: "16px"}}>
                    <Button type="primary" icon={<PlusOutlined />} onClick={this.certAddButtonOnClickHd}>新增</Button>
                </Row>
                
                <HoTable columns={this._relateCertCols} 
                                size="middle" 
                                dataList={dataList}
                                loading={loading}
                                noPagination={true}
                />
            </div>
        )
    }

    mkCertSelectModal() {
        let {showSelectCert} = this.state
        return (
            <Modal 
            title=""
            visible={showSelectCert}
            width={1280}
            footer={null}
            onOk={undefined}
            onCancel={this.selectCertCancelHd}
            >
                <CertCertificationListCT 
                selectFlag={true}
                selectedHd={this.certSelectHd}
                />

            </Modal>
        )
    }

    mkRelateCertModal() {
        let {showRelateCert, editRecord} = this.state
        let ObjName = undefined
        let {detailInfo} = this.props
        if (detailInfo) {
            if (detailInfo.DstObjInfo){
                ObjName = detailInfo.DstObjInfo.Name
            }
        }
        
        if (this._certForm) {
            this._certForm.setFieldsValue(
                {
                    ObjName: ObjName,  
                    CertName: editRecord.CertName, 
                }
            )
        }
    
        let content = (
            <Form onFinish={(values) => this.modalConfirmHd(values, 'objCert')} 
            labelCol={{span: 4}} wrapperCol={{span: 20}}
            ref={node => this._certForm = node}
            initialValues={
                {
                    ObjName: ObjName,  
                    CertName: editRecord.CertName, 
                }
            }
            > 
                <Form.Item rules={[{required: true, message: '请选择认证类别!'}]} label="认证类别" name="CertName">
                    <Row justify="start">
                        
                        <Col span={14}>
                        <Input placeholder="请点击右边按钮选择认证类别" value={editRecord.CertName} disabled/>
                        </Col>
                        <Col push={2} span={6}>
                        <Button type="primary" icon={<CheckOutlined />} onClick={this.selectCertAddHd} >选择认证类别</Button>
                        </Col>
                    </Row>    
                </Form.Item>

                <Form.Item name="ObjName" label="产品名称" >
                    <Input disabled />
                </Form.Item>
                    
                <Row justify="end">
                    <Space size="middle" >
                    <Button type="default" onClick={() => this.certModalCancelHd()}>取消</Button>
                    <Button type="primary" onClick={undefined} htmlType="submit">保存</Button>
                    </Space>
                </Row>
            </Form>
        )
        
    
        return (
            <Modal 
                title="关联认证类别配置"
                visible={showRelateCert}
                onCancel={this.certModalCancelHd}
                okText="保存" cancelText="取消"
                footer={null}
                width={560}
                >
                {content}    
            </Modal>
        )
    }

    certAddButtonOnClickHd() {
        let newState = Object.assign({}, this.state)
        newState.showRelateCert = true
        newState.editMode = "add"
        this.setState(newState)
    }

    certModalCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.showRelateCert = false
        this.setState(newState)
    }

    selectCertCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.showSelectCert = false
        this.setState(newState)
    }

    selectCertAddHd() {
        let newState = Object.assign({}, this.state)
        newState.showSelectCert = true
        this.setState(newState)
    }

    certSelectHd(key, record) {
        let {editRecord} = this.state

        console.log("#####: record = ", record)

        editRecord.CertName = record.Name
        editRecord.CertId = record.Id

        let newState = Object.assign({}, this.state)
        newState.showSelectCert = false
        newState.editRecord = editRecord
        this.setState(newState)
    }


    // testCategory
    getRelateTestCategoryContent() {
        let {detailInfo, loading} = this.props
        let dataList = undefined
        if (detailInfo) {
            dataList = detailInfo.ObjTestCategoryList
        }

        return (
            <div style={{marginBottom: "16px"}}>
                <Row justify="start" style={{marginBottom: "16px"}}>
                    <Button type="primary" icon={<PlusOutlined />} onClick={this.testCategoryAddButtonOnClickHd}>新增</Button>
                </Row>
                
                <HoTable columns={this._relateTestCategoryCols} 
                                size="middle" 
                                dataList={dataList}
                                loading={loading}
                                noPagination={true}
                />
            </div>
        )
    }

    mkRelateTestCategoryModal() {
        let {showRelateTestCategory, srcRecord} = this.state
        let ObjName = undefined
        let {detailInfo} = this.props
        if (detailInfo) {
            if (detailInfo.DstObjInfo){
                ObjName = detailInfo.DstObjInfo.Name
            }
        }
    
        return (
            <Modal 
                title="关联检测大类配置"
                visible={showRelateTestCategory}
                onCancel={this.testCategoryModalCancelHd}
                okText="保存" cancelText="取消"
                footer={null}
                width={640}
                >
                <TestCategoryForm 
                record={srcRecord}
                modalCancelHd={this.testCategoryModalCancelHd}
                ObjName={ObjName}
                objCertList={detailInfo ? detailInfo.ObjCertList : undefined}
                ObjTestCategoryList={detailInfo ? detailInfo.ObjTestCategoryList : undefined}
                formSubmitHd={this.relateTestCategoryFormSubmitHd}
                />
            </Modal>
        )


    }

    relateTestCategoryFormSubmitHd(values) {
        let {addConfirmHd, editConfirmHd} = this.props
        let {editMode} = this.state


        console.log("##@@@##@#@: values = ", values)

        let result = {}
        result.Id = values.Id
        result.ObjId = values.ObjId
        result.CertId = values.CertId
        result.TestCategoryId = values.TestCategoryId
        result.Period = parseFloat(values.Period)
        result.Price = parseInt(values.Price)
        result.IsDefault = values.IsDefault
        result.Seq = parseInt(values.Seq)
        result.Remark = values.Remark

        if (editMode === 'add') {
            if (addConfirmHd) {
                addConfirmHd(result, "objTestCategory")
            }

        }else if (editMode === 'mod') {
            if (editConfirmHd) {
                editConfirmHd(result, "objTestCategory")
            }
        }


        if (this._testCategoryForm) {
            this._testCategoryForm.hoClearForm()
        }

        let newState = Object.assign({}, this.state)
        newState.showRelateTestCategory = false
        this.setState(newState)
    }

    testCategoryModalCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.showRelateTestCategory = false
        this.setState(newState)
    }

    testCategoryAddButtonOnClickHd() {
        let newState = Object.assign({}, this.state)
        newState.showRelateTestCategory = true
        newState.editMode = "add"
        newState.editRecord = {}
        newState.srcRecord = undefined
        if (this._testCategoryForm) {
            this._testCategoryForm.hoClearForm()
        }
        this.setState(newState)
    }

    selectTestCategoryAddHd() {
        let newState = Object.assign({}, this.state)
        newState.showSelectTestCategory = true
        this.setState(newState)
    }

    selectTestCategoryCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.showSelectTestCategory = false
        this.setState(newState)
    }


    // feature
    getRelateFeatureContent() {
        let {detailInfo, loading} = this.props
        let dataList = undefined
        if (detailInfo) {
            dataList = detailInfo.ObjFeatureRelateList
        }

        return (
            <div style={{marginBottom: "16px"}}>
                <Row justify="start" style={{marginBottom: "16px"}}>
                    <Button type="primary" icon={<PlusOutlined />} onClick={this.featureAddButtonOnClick}>新增</Button>
                </Row>
                
                <HoTable columns={this._relateFeatureCols} 
                                size="middle" 
                                dataList={dataList}
                                loading={loading}
                                noPagination={true}
                />
            </div>
        )
    }

    relateFeatureFormSubmitHd(values) {
        let {addConfirmHd, editConfirmHd} = this.props
        let {editMode} = this.state


        console.log("##@@@##@#@: values = ", values)

        let result = {}
        result.Id = values.Id
        result.ObjId = values.ObjId
        result.CertId = values.CertId
        result.FeatureId = values.FeatureId
        result.Period = parseFloat(values.Period)
        result.Price = parseInt(values.Price)
        result.IsDefault = values.IsDefault
        result.Seq = parseInt(values.Seq)
        result.Remark = values.Remark

        if (editMode === 'add') {
            if (addConfirmHd) {
                addConfirmHd(result, "objFeatureRelate")
            }

        }else if (editMode === 'mod') {
            if (editConfirmHd) {
                editConfirmHd(result, "objFeatureRelate")
            }
        }


        if (this._featureForm) {
            this._featureForm.hoClearForm()
        }

        let newState = Object.assign({}, this.state)
        newState.showRelateFeature = false
        this.setState(newState)
    }

    mkRelateFeature() {
        let {showRelateFeature, srcRecord} = this.state
        let {detailInfo} = this.props

        return (
            <Modal 
                title="关联附加产品特性配置"
                visible={showRelateFeature}
                onCancel={this.featureModalCancelHd}
                okText="保存" cancelText="取消"
                footer={null}
                width={600}
                >
                <FeatureForm  ref={node => this._featureForm = node} 
                record={srcRecord}
                modalCancelHd={this.featureModalCancelHd}
                objCertList={detailInfo ? detailInfo.ObjCertList : undefined}
                formSubmitHd={this.relateFeatureFormSubmitHd}

                />
            </Modal>
        )
    }

    
    featureAddButtonOnClick() {
        let newState = Object.assign({}, this.state)
        newState.editMode = 'add'
        newState.showRelateFeature = true
        newState.editRecord = {}
        newState.srcRecord = undefined
        if (this._featureForm) {
            this._featureForm.hoClearForm()
        }
        this.setState(newState)
    }

    featureModalCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.showRelateFeature = false
        this.setState(newState)
    }

    // testCase
    getRelateTestCaseContent() {
        let {detailInfo, loading} = this.props
        let dataList = undefined
        if (detailInfo) {
            dataList = detailInfo.ObjTestCaseList
        }

        return (
            <div style={{marginBottom: "16px"}}>
                <Row justify="start" style={{marginBottom: "16px"}}>
                    <Button type="primary" icon={<PlusOutlined />} onClick={this.testCaseAddButtonOnClick}>新增</Button>
                </Row>
                
                <HoTable columns={this._relateTestCaseCols} 
                                size="middle" 
                                dataList={dataList}
                                loading={loading}
                                noPagination={true}
                />
            </div>
        )
    }

    testCaseAddButtonOnClick() {
        let newState = Object.assign({}, this.state)
        newState.editMode = 'add'
        newState.showRelateTestCase = true
        newState.editRecord = {}
        newState.srcRecord = undefined
        if (this._testCaseForm) {
            this._testCaseForm.hoClearForm()
        }
        this.setState(newState)
    }

    testCaseModalCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.showRelateTestCase = false
        this.setState(newState)
    }

    relateTestCaseFormSubmitHd(values) {
        let {addConfirmHd, editConfirmHd} = this.props
        let {editMode} = this.state

        let result = {}
        result.Id = values.Id
        result.ObjId = values.ObjId
        result.TestCaseId = values.TestCaseId
        result.CertId = values.CertId
        result.FeatureId = values.FeatureId
        result.Period = parseFloat(values.Period)
        result.Price = parseInt(values.Price)
        result.IsDefault = values.IsDefault
        result.Seq = parseInt(values.Seq)
        result.Remark = values.Remark

        if (editMode === 'add') {
            if (addConfirmHd) {
                addConfirmHd(result, "objTestCaseRelate")
            }

        }else if (editMode === 'mod') {
            if (editConfirmHd) {
                editConfirmHd(result, "objTestCaseRelate")
            }
        }

        if (this._testCaseForm) {
            this._testCaseForm.hoClearForm()
        }

        let newState = Object.assign({}, this.state)
        newState.showRelateTestCase = false
        this.setState(newState)
    }

    mkRelateTestCase() {
        let {showRelateTestCase, srcRecord} = this.state
        let {detailInfo} = this.props

        console.log("detailInfo = ", detailInfo)

        return (
            <Modal 
                title="关联检测项配置"
                visible={showRelateTestCase}
                onCancel={this.testCaseModalCancelHd}
                okText="保存" cancelText="取消"
                footer={null}
                width={600}
                >
                <TestCaseForm  ref={node => this._testCaseForm = node} 
                record={srcRecord}
                modalCancelHd={this.testCaseModalCancelHd}
                ObjTestCategoryList={detailInfo ? detailInfo.ObjTestCategoryList : undefined}
                formSubmitHd={this.relateTestCaseFormSubmitHd}
                objCertList={detailInfo ? detailInfo.ObjCertList : undefined}
                ObjFeatureRelateList={detailInfo ? detailInfo.ObjFeatureRelateList : undefined}

                />
            </Modal>
        )
    }


    render() {
        let {loading} = this.props     
        let titleContent = this.mkTitleContent()

        let relateCertContent = this.getRelateCertContent()
        let relateTestCategoryContent = this.getRelateTestCategoryContent()
        let relateFeatureContent = this.getRelateFeatureContent()

        let relateTestCaseContent = this.getRelateTestCaseContent()

        let certModal = this.mkRelateCertModal()
        let testCategoryModal = this.mkRelateTestCategoryModal()
        let featureModal = this.mkRelateFeature()
        let testCaseModal = this.mkRelateTestCase()

        let certSelectModal = this.mkCertSelectModal()
        
        return (
            <div>
                {certModal}
                {certSelectModal}
                {testCategoryModal}
                {featureModal}
                {testCaseModal}
                <HoBreadcrumb itemList={this._breadConf} extra={{style:{marginBottom: '8px'}}} />
                <Card title="认证产品详情" loading={loading} extra={undefined}>
                    {titleContent}
                    
                    <Collapse collapsible="" bordered={true} defaultActiveKey={[1, 2, 3, 4]} ghost={false}>
                        <Panel header={<Typography.Text strong >关联附加产品特性配置</Typography.Text>} key="1">
                            {relateFeatureContent}
                        </Panel>

                        <Panel header={<Typography.Text strong >关联认证类别配置</Typography.Text>} key="2">
                            {relateCertContent}
                        </Panel>

                        <Panel header={<Typography.Text strong >关联检测大类配置</Typography.Text>} key="3">
                            {relateTestCategoryContent}
                        </Panel>

                        <Panel header={<Typography.Text strong >关联检测项配置</Typography.Text>} key="4">
                            {relateTestCaseContent}
                        </Panel>


                    </Collapse>

                </Card> 
            </div>
        );
    }

}


