import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux'


// ************************************************************
// 各模块路由表配置
// ************************************************************
import CertDstObjCategoryListCT from '../../app/product/dstObjCategoryCT'
import CertDstObjListCT from '../../app/product/dstObjCT'
import CertCertificationListCT from '../../app/cert/certificationCT'
import CertTestCategoryListCT from '../../app/cert/testCategoryCT'
import CertTestStandardListCT from '../../app/cert/testStandardCT'
import CertTestCaseListCT from '../../app/cert/testCaseCT'
import CertTestCaseDetailCT from '../../app/cert/testCaseDetailCT'
import CertDetailCT from '../../app/cert/certDetailCT'
import FeatureCategoryCT from '../../app/product/featureCategoryCT'
import FeatureSubcategoryCT from '../../app/product/featureSubcategoryCT'
import FeatureCT from '../../app/product/featureCT'
import DstObjDetailCT from '../../app/product/dstObjDetailCT'

import CertOnlineDocListCT from '../../app/kl/cert/onlineDocListCT'
import OnlineDocDetailCT from '../../app/kl/cert/onlineDocDetailCT'
import CertUploadDocListCT from '../../app/kl/cert/uploadDocListCT'

import TestsuitListCT from '../../app/testsuit/testsuitListCT'
import TestSuitDetailCT from '../../app/testsuit/testsuitDetailCT'


class SettingRoute extends Component {
    render(){
        return (
            <Switch>
                <Route path={`${this.props.match.path}/test`} exact component={undefined} />
                <Route path={`${this.props.match.path}/productObjCategory`} exact component={CertDstObjCategoryListCT} />
                <Route path={`${this.props.match.path}/productFeatureCategory`} exact component={FeatureCategoryCT} />
                <Route path={`${this.props.match.path}/productFeatureSubcategory`} exact component={FeatureSubcategoryCT} />
                <Route path={`${this.props.match.path}/productFeature`} exact component={FeatureCT} />
                <Route path={`${this.props.match.path}/productObj`} exact component={CertDstObjListCT} />
                <Route path={`${this.props.match.path}/productObj/detail/:objId`} exact component={DstObjDetailCT} />

                <Route path={`${this.props.match.path}/certCertification`} exact component={CertCertificationListCT} />
                <Route path={`${this.props.match.path}/certTestCategory`} exact component={CertTestCategoryListCT} />
                <Route path={`${this.props.match.path}/certTestStandard`} exact component={CertTestStandardListCT} />
                <Route path={`${this.props.match.path}/certTestCase`} exact component={CertTestCaseListCT} />

                <Route path={`${this.props.match.path}/certTestCase/detail/:caseId`} exact component={CertTestCaseDetailCT} />  
                <Route path={`${this.props.match.path}/certCertification/detail/:certId`} exact component={CertDetailCT} />  

                <Route path={`${this.props.match.path}/testsuitList`} exact component={TestsuitListCT} />  
                <Route path={`${this.props.match.path}/testsuit/detail/:suitId`} exact component={TestSuitDetailCT} />  
                
                <Route path={`${this.props.match.path}/klCertificationUploadList`} exact component={CertUploadDocListCT} />  
                <Route path={`${this.props.match.path}/klCertificationOnlineList`} exact component={CertOnlineDocListCT} />  
                <Route path={`${this.props.match.path}/klCertificationOnline/new`} exact component={OnlineDocDetailCT} /> 
                <Route path={`${this.props.match.path}/klCertificationOnline/detail/:docId`} exact component={OnlineDocDetailCT} /> 


                
            </Switch>
        );
    }
}

export default connect(
    null, 
    null
)(SettingRoute);

