import React, { Component } from 'react';

import {Card, Row, Col, Button, Popconfirm, Space, Modal, Descriptions, Select, 
    Tag, Form, Input } from 'antd'
import {SyncOutlined, PlusOutlined} from '@ant-design/icons'

import {HoBreadcrumb} from '../../component/element'
import {HoTable, HoMkTblColumn, HoMkTblColActions} from '../../component/tbl'

import {yesOrNoMap} from '../../comm/logic'


export class CertRelateTestCategoryListPage extends Component {
    constructor(props){
        super(props)

        this.onChangeHd = this.onChangeHd.bind(this)

        let {deleteOnClickHd, delConfirmHd, selectFlag, selectedHd, detailOnclickHd} = props
        this.state = {
            showEdit: false, 
            editMode: undefined,
            editRecord: {}, 
        }

        // this._breadConf = [
        //     {name: "认证检测", link: '/setting/certCertification'}, 
        //     {name: "认证信息", link: undefined}, 
        // ]

        const disDelete = (
            <Popconfirm title="请确认是否要删除？" 
            onConfirm={(e) => delConfirmHd ? delConfirmHd(e, 'confirm') : undefined}
            onCancel={(e) => delConfirmHd ? delConfirmHd(e, 'cancel') : undefined}
            >
                <span>删除</span>
            </Popconfirm>
        )

        let actionContent = HoMkTblColActions([{key: 'detail', display: '详情', onClickedHd: detailOnclickHd}, 
                                               {key: 'edit', display: '编辑', onClickedHd: this.editOnclickHd}, 
                                               {key: 'delete', display: disDelete, onClickedHd: deleteOnClickHd}, ])
        if (selectFlag) {
            actionContent = HoMkTblColActions([{key: 'select', display: '选择', onClickedHd: selectedHd}])
        }

        this._columns = [
            HoMkTblColumn('认证类型', 'CertName', 'str', '10%', true, false), 
            HoMkTblColumn('地域', 'Scope', 'str', '10%', true, false), 
            HoMkTblColumn('检测大类', 'CategoryName', 'str', '10%', true, false), 
            HoMkTblColumn('默认选中', 'IsDefault', 'str', '10%', true, false, yesOrNoMap), 
            actionContent, 
        ]

    }


    onChangeHd(pagination, filters, sorter, extra) {
        let {pageChangeHd} = this.props
        if (extra === undefined) {
            return
        }

        if (extra.action === "paginate"){
            // 分页操作
            if (pageChangeHd === undefined || pagination === {}) {
                return
            }
            pageChangeHd(pagination.current, pagination.pageSize)
            return
        }
    }

    render() {
        let {pageChangeHd, totalNum, dataList, searchHd, loading} = this.props       
        
        return (
            <div>
                {/* <HoBreadcrumb itemList={this._breadConf} extra={{style:{marginBottom: '8px'}}} /> */}
                <Card title="认证关联检测大类列表" >
                    <HoTable columns={this._columns} 
                        size="middle" 
                        dataList={dataList}
                        searchHd={searchHd}
                        totalNum={totalNum}
                        pageChangeHd={pageChangeHd}
                        loading={loading}
                    />
                </Card>
            </div>
        );
    }

}

