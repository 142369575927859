const ACT_LEFT_MENU_SET_CURRENT_ITEM = 'ACT_LEFT_MENU_SET_CURRENT_ITEM'
const ACT_LEFT_MENU_SET_OPEN_KEYS = 'ACT_LEFT_MENU_SET_OPEN_KEYS'
const ACT_TOP_MENU_SET_CURRENT_ITEM = 'ACT_TOP_MENU_SET_CURRENT_ITEM'
const ACT_TOP_MENU_SET_OPEN_KEYS = 'ACT_TOP_MENU_SET_OPEN_KEYS'

const menuState = {
    leftMenuItem: '', 
    leftOpenKeys: [], 
    topMenuItem: 'deviceList', 
    topOpenKeys: [], 
}

// reducer
export const menu_reducer = (state=menuState, action) => {
    switch(action.type){
        case ACT_LEFT_MENU_SET_CURRENT_ITEM:
        {
            let retState = Object.assign({}, state)
            retState.leftMenuItem = action.menuItem
            return retState
        }

        case ACT_LEFT_MENU_SET_OPEN_KEYS:
        {
            let retState = Object.assign({}, state)
            retState.leftOpenKeys = action.openKeys
            return retState
        }

        case ACT_TOP_MENU_SET_CURRENT_ITEM:
        {
            let retState = Object.assign({}, state)
            retState.topMenuItem = action.menuItem
            return retState
        }
               
        case ACT_TOP_MENU_SET_OPEN_KEYS:
        {
            let retState = Object.assign({}, state)
            retState.topOpenKeys = action.openKeys
            return retState
        }
    
        default:
        return state;
    }
}


// select
export const getLeftMenuCurrentItem = (state) => {
    if (state.menu.leftMenuItem !== undefined){
        return state.menu.leftMenuItem;
    }
    
    return undefined;
}

export const getLeftMenuOpenKeys = (state) => {
    return state.menu.leftOpenKeys;
}

export const getTopMenuCurrentItem = (state) => {
    if (state.menu.topMenuItem !== undefined){
        return state.menu.topMenuItem;
    }
    
    return undefined;
}

export const getTopMenuOpenKeys = (state) => {
    return state.menu.topOpenKeys;
}


export const actSetLeftMenuCurrentItem = (menuItem) => {
    return {
        type: ACT_LEFT_MENU_SET_CURRENT_ITEM, 
        menuItem: menuItem, 
    }
} 

export const actSetLeftMenuOpenKeys = (openKeys) => {
    return {
        type: ACT_LEFT_MENU_SET_OPEN_KEYS, 
        openKeys: openKeys, 
    }
}

export const actSetTopMenuCurrentItem = (menuItem) => {
    return {
        type: ACT_TOP_MENU_SET_CURRENT_ITEM, 
        menuItem: menuItem, 
    }
} 

export const actSetTopMenuOpenKeys = (openKeys) => {
    return {
        type: ACT_TOP_MENU_SET_OPEN_KEYS, 
        openKeys: openKeys, 
    }
}



