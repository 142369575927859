import React, { Component } from 'react';

import {Card, Row, Col, Button, Popconfirm, Space, Modal, Descriptions, Select, 
    Tag, Form, Input } from 'antd'
import {SyncOutlined, PlusOutlined} from '@ant-design/icons'

import {HoBreadcrumb} from '../../component/element'
import {HoTable, HoMkTblColumn, HoMkTblColActions} from '../../component/tbl'

import {cert_scopes} from '../../comm/config'


export class CertTestCategoryListPage extends Component {
    constructor(props){
        super(props)

        this.mkOpButtons = this.mkOpButtons.bind(this)
        this.onChangeHd = this.onChangeHd.bind(this)
        this.mkEditModal = this.mkEditModal.bind(this)
        this.addOnclickHd = this.addOnclickHd.bind(this)
        this.editOnclickHd = this.editOnclickHd.bind(this)
        this.modalConfirmHd = this.modalConfirmHd.bind(this)
        this.modalCancelHd = this.modalCancelHd.bind(this)
        this.modalInputChangeHd = this.modalInputChangeHd.bind(this)


        let {deleteOnClickHd, delConfirmHd, selectFlag, selectedHd} = props
        this.state = {
            showEdit: false, 
            editMode: undefined,
            editRecord: {}, 
        }

        this._editRecord = {}


        this._breadConf = [
            {name: "认证检测", link: '/setting/certCertification'}, 
            {name: "检测大类", link: undefined}, 
        ]

        const disDelete = (
            <Popconfirm title="请确认是否要删除？" 
            onConfirm={(e) => delConfirmHd ? delConfirmHd(e, 'confirm') : undefined}
            onCancel={(e) => delConfirmHd ? delConfirmHd(e, 'cancel') : undefined}
            >
                <span>删除</span>
            </Popconfirm>
        )

        let actionContent = HoMkTblColActions([{key: 'edit', display: '编辑', onClickedHd: this.editOnclickHd}, 
                                               {key: 'delete', display: disDelete, onClickedHd: deleteOnClickHd}, ])
        if (selectFlag) {
            actionContent = HoMkTblColActions([{key: 'select', display: '选择', onClickedHd: selectedHd}])
        }

        this._columns = [
            HoMkTblColumn('检测大类', 'Name', 'str', '10%', true, false), 
            HoMkTblColumn('是否显示', 'Display', 'number', '10%', false, false, this.displayMap), 
            HoMkTblColumn('中文名', 'CName', 'str', '10%', true, false), 
            HoMkTblColumn('英文名', 'EName', 'str', '10%', true, false), 
            HoMkTblColumn('logo地址', 'LogoUrl', 'str', '10%', false, false), 
            HoMkTblColumn('详情地址', 'DetailUrl', 'str', '10%', false, false), 
            HoMkTblColumn('概述', 'Description', 'str', '10%', true, false), 
            HoMkTblColumn('备注', 'Remark', 'number', '10%', true, false), 
            actionContent, 
        ]

    }

    displayMap(Display) {
        if (Display === 0) {
            return "否"
        }else {
            return "是"
        }
    }

    
    mkOpButtons() {
        let {refreshHd} = this.props
        return (
            <Row>
                <Space>
                    <Button type="primary" icon={<PlusOutlined />} onClick={this.addOnclickHd}>新增</Button>
                    <Button type="default" icon={<SyncOutlined />} onClick={refreshHd}>刷新</Button>
                </Space>
            </Row>
        )
    }

    mkEditModal() {
        let {showEdit, editMode} = this.state
        let inputStyle = {width: 150}
        if (editMode === "mod") {
            inputStyle = {width: 162}
        }

        let editRecord = this._editRecord

        const selectOps = cert_scopes.map(
            (item, index) => (
                <Select.Option key={index} value={item}>{item}</Select.Option>
            )
        )

        const getFiledName = (key) => (editMode === "mod" ? undefined : key)
        let content = (
            <Form onFinish={(values) => this.modalConfirmHd(values)}
            labelCol={{span: 8}}
            wrapperCol={{span: 16}}
            >
                <Row>
                <Col span={12}>
                <Form.Item 
                    name={getFiledName("Name")}
                    rules={[{required: true, message: '请输入检测大类!'}]} 
                    label="检测大类" value={editRecord ? editRecord.Name : undefined}
                    >
                        <Input placeholder="" value={editRecord ? editRecord.Name : undefined} 
                            onChange={(e)=>this.modalInputChangeHd("Name", e.target.value)} />
                </Form.Item>
                </Col>
                    
                <Col span={12}>
                    <Form.Item 
                        name={getFiledName("Display")}
                        label="是否显示" 
                        >
                        <Select value={editRecord ? editRecord.Display : undefined} 
                            onChange={(value)=> this.modalInputChangeHd("Display", value)} >
                            <Select.Option value={1}>是</Select.Option>
                            <Select.Option value={0}>否</Select.Option> 
                        </Select>
                    </Form.Item>
                </Col>
                </Row>

                <Row>
                    <Col span={12}>
                    <Form.Item 
                        name={getFiledName("CName")}
                        label="中文名" 
                        >
                            <Input placeholder="" value={editRecord ? editRecord.CName : undefined} 
                                onChange={(e)=>this.modalInputChangeHd("CName", e.target.value)} />
                    </Form.Item>
                    </Col>

                    <Col span={12}>
                    <Form.Item 
                        name={getFiledName("EName")}
                        label="英文名" 
                        >
                            <Input placeholder="" value={editRecord ? editRecord.EName : undefined} 
                                onChange={(e)=>this.modalInputChangeHd("EName", e.target.value)} />
                    </Form.Item>
                    </Col>
                </Row>

                

                <Row>
                    <Col span={12}>
                    <Form.Item 
                        name={getFiledName("LogoUrl")}
                        label="logo地址" 
                        >
                            <Input placeholder="" value={editRecord ? editRecord.LogoUrl : undefined} 
                                onChange={(e)=>this.modalInputChangeHd("LogoUrl", e.target.value)} />
                    </Form.Item>
                    </Col>

                    <Col span={12}>
                    <Form.Item 
                        name={getFiledName("DetailUrl")}
                        label="详情地址" 
                        >
                            <Input placeholder="" value={editRecord ? editRecord.DetailUrl : undefined} 
                                onChange={(e)=>this.modalInputChangeHd("DetailUrl", e.target.value)} />
                    </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={12}>
                    <Form.Item 
                        name={getFiledName("Description")}
                        label="概述" 
                        >
                            <Input placeholder="" value={editRecord ? editRecord.Description : undefined} 
                                onChange={(e)=>this.modalInputChangeHd("Description", e.target.value)} />
                    </Form.Item>
                    </Col>

                    <Col span={12}>
                    <Form.Item 
                        name={getFiledName("Remark")}
                        label="备注" 
                        >
                            <Input placeholder="" value={editRecord ? editRecord.Remark : undefined} 
                                onChange={(e)=>this.modalInputChangeHd("Remark", e.target.value)} />
                    </Form.Item>
                    </Col>
                </Row>
                    
                <Row justify="end">
                    <Space size="middle" >
                    <Button type="default" onClick={() => this.modalCancelHd()}>取消</Button>
                    <Button type="primary" onClick={undefined} htmlType="submit">保存</Button>
                    </Space>
                </Row>
            </Form>
        )

        return (
            <Modal 
                title="检测大类"
                visible={showEdit}
                width={640}
                // onOk={this.modalConfirmHd}
                onCancel={this.modalCancelHd}
                okText="保存" cancelText="取消"
                footer={null}
                >
                {content}
                   
            </Modal>
        )
    }

    addOnclickHd() {
        let newState = Object.assign({}, this.state)
        newState.editMode = "add"
        newState.showEdit = true
        this.setState(newState)
    }

    editOnclickHd(key, record) {
        let newState = Object.assign({}, this.state)
        newState.editMode = "mod"
        newState.showEdit = true
        // newState.editRecord = record
        this._editRecord = record
        this.setState(newState)
    }

    modalConfirmHd(values) {
        console.log("modalConfirmHd: values = ", values)


        let {editMode} = this.state
        let {editConfirmHd, addConfirmHd} = this.props

        if (editMode === 'add') {
            if (addConfirmHd) {
                // addConfirmHd(this._editRecord)
                addConfirmHd(values)
            }
        }else if (editMode === 'mod') {
            if (editConfirmHd) {
                editConfirmHd(this._editRecord)
                // editConfirmHd(values)
            }
        }

        let newState = Object.assign({}, this.state)
        newState.editMode = undefined
        newState.showEdit = false
        // newState.editRecord = {}
        this._editRecord = {}
        this.setState(newState)
    }

    modalCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.editMode = undefined
        this._editRecord = {}
        // newState.editRecord = {}
        newState.showEdit = false
        this.setState(newState)
    }

    modalInputChangeHd(key, value) {
        console.log("key = , value = ", key, value)

        this._editRecord[key] = value
        
        let newState = Object.assign({}, this.state)
        // newState.editRecord = this._editRecord
        newState.editRecord = this._editRecord
        this.setState(newState)
    }

    onChangeHd(pagination, filters, sorter, extra) {
        let {pageChangeHd} = this.props
        if (extra === undefined) {
            return
        }

        if (extra.action === "paginate"){
            // 分页操作
            if (pageChangeHd === undefined || pagination === {}) {
                return
            }
            pageChangeHd(pagination.current, pagination.pageSize)
            return
        }
    }

    render() {
        let {pageChangeHd, totalNum, dataList, searchHd, loading} = this.props       
        let disModal = this.mkEditModal() 
        return (
            <div>
                
                    <HoBreadcrumb itemList={this._breadConf} extra={{style:{marginBottom: '8px'}}} />
                    <Card title="检测大类列表" extra={this.mkOpButtons()}>
                        {disModal}
                        <HoTable columns={this._columns} 
                            size="middle" 
                            dataList={dataList}
                            searchHd={searchHd}
                            totalNum={totalNum}
                            pageChangeHd={pageChangeHd}
                            loading={loading}
                        />

                    </Card>
                

            </div>
        );
    }

}

