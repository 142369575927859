import React, { Component } from 'react';

import {Card, Row, Col, Button, Popconfirm, Space, Modal, Descriptions, Select, 
    Tag, Form, Input } from 'antd'
import {SyncOutlined, PlusOutlined} from '@ant-design/icons'

import {HoBreadcrumb} from '../../component/element'
import {HoTable, HoMkTblColumn, HoMkTblColActions} from '../../component/tbl'
import {deviceTypeMap} from '../../app/device/device'


export class CertDstObjCategoryListPage extends Component {
    constructor(props){
        super(props)

        this.mkOpButtons = this.mkOpButtons.bind(this)
        this.onChangeHd = this.onChangeHd.bind(this)
        this.mkEditModal = this.mkEditModal.bind(this)
        this.addOnclickHd = this.addOnclickHd.bind(this)
        this.editOnclickHd = this.editOnclickHd.bind(this)
        this.modalConfirmHd = this.modalConfirmHd.bind(this)
        this.modalCancelHd = this.modalCancelHd.bind(this)
        this.modalInputChangeHd = this.modalInputChangeHd.bind(this)


        let {deleteOnClickHd, delConfirmHd, selectFlag, selectedHd} = props
        this.state = {
            showEdit: false, 
            editMode: undefined,
            editRecord: {}, 
        }

        this._editRecord = {}


        this._breadConf = [
            {name: "认证检测", link: '/setting/certDstObjCategory'}, 
            {name: "认证对象类别", link: undefined}, 
        ]

        const disDelete = (
            <Popconfirm title="请确认是否要删除？" 
            onConfirm={(e) => delConfirmHd ? delConfirmHd(e, 'confirm') : undefined}
            onCancel={(e) => delConfirmHd ? delConfirmHd(e, 'cancel') : undefined}
            >
                <span>删除</span>
            </Popconfirm>
        )

        let actionContent = HoMkTblColActions([{key: 'edit', display: '编辑', onClickedHd: this.editOnclickHd}, 
                                               {key: 'delete', display: disDelete, onClickedHd: deleteOnClickHd}, ])
        if (selectFlag) {
            actionContent = HoMkTblColActions([{key: 'select', display: '选择', onClickedHd: selectedHd}])
        }

        this._columns = [
            HoMkTblColumn('类别名称', 'Name', 'str', '20%', true, false), 
            HoMkTblColumn('类别英文名', 'EnName', 'str', '20%', true, false), 
            HoMkTblColumn('是否显示', 'Display', 'number', '10%', false, false, this.displayMap), 
            HoMkTblColumn('备注', 'Remark', 'number', '10%', true, false), 
            actionContent, 
        ]

        this._form = undefined

    }

    displayMap(Display) {
        if (Display === 0) {
            return "否"
        }else {
            return "是"
        }
    }

    
    mkOpButtons() {
        let {refreshHd} = this.props
        return (
            <Row>
                <Space>
                    <Button type="primary" icon={<PlusOutlined />} onClick={this.addOnclickHd}>新增</Button>
                    <Button type="default" icon={<SyncOutlined />} onClick={refreshHd}>刷新</Button>
                </Space>
            </Row>
        )
    }

    mkEditModal() {
        let {showEdit, editMode} = this.state
        let inputStyle = {width: 150}
        if (editMode === "mod") {
            inputStyle = {width: 162}
        }

        let editRecord = this._editRecord

        if (this._form) {
            this._form.setFieldsValue(
                {
                    Name: editRecord.Name, 
                    EnName: editRecord.EnName,
                    Display: editRecord.Display, 
                    Remark: editRecord.Remark, 
                }
            )
        }

        const getFiledName = (key) => (editMode === "mod" ? undefined : key)



        let content = (
            <Form onFinish={(values) => this.modalConfirmHd(values)}
            labelCol={{span: 6}}
            wrapperCol={{span: 18}}
            ref={node => this._form = node}
            initialValues={
                {
                    Name: editRecord.Name, 
                    EnName: editRecord.EnName,
                    Display: editRecord.Display, 
                    Remark: editRecord.Remark, 
                }
            }
            >
                <Form.Item name="Name" rules={[{required: true, message: '请输入产品类型名称!'}]} label="产品类型名称" >
                    <Input />
                </Form.Item>

                <Form.Item name="EnName" label="英文类型名称" >
                    <Input />
                </Form.Item>

                
                <Form.Item name="Display" label="是否显示" >
                    <Select defaultValue={1}>
                        <Select.Option value={1}>是</Select.Option>
                        <Select.Option value={0}>否</Select.Option> 
                    </Select>
                </Form.Item>    

                <Form.Item name="Remark" label="备注" >
                    <Input />
                </Form.Item>
                    
                <Row justify="end">
                    <Space size="middle" >
                    <Button type="default" onClick={() => this.modalCancelHd()}>取消</Button>
                    <Button type="primary" onClick={undefined} htmlType="submit">保存</Button>
                    </Space>
                </Row>
            </Form>
        )

        return (
            <Modal 
                title="对象类型"
                visible={showEdit}
                // onOk={this.modalConfirmHd}
                onCancel={this.modalCancelHd}
                okText="保存" cancelText="取消"
                footer={null}
                >
                {content}
                   
            </Modal>
        )
    }

    addOnclickHd() {
        let newState = Object.assign({}, this.state)
        newState.editMode = "add"
        newState.showEdit = true
        this.setState(newState)
    }

    editOnclickHd(key, record) {
        let newState = Object.assign({}, this.state)
        newState.editMode = "mod"
        newState.showEdit = true
        // newState.editRecord = record
        this._editRecord = record
        this.setState(newState)
    }

    modalConfirmHd(values) {
        console.log("modalConfirmHd: values = ", values)

        let result = {}
        result.Id = this._editRecord.Id
        result.Display = parseInt(values.Display)
        result.EnName = values.EnName
        result.Name = values.Name
        result.Remark = values.Remark


        let {editMode} = this.state
        let {editConfirmHd, addConfirmHd} = this.props

        if (editMode === 'add') {
            if (addConfirmHd) {
                // addConfirmHd(this._editRecord)
                addConfirmHd(result)
            }
        }else if (editMode === 'mod') {
            if (editConfirmHd) {
                // editConfirmHd(this._editRecord)
                editConfirmHd(result)
            }
        }

        let newState = Object.assign({}, this.state)
        newState.editMode = undefined
        newState.showEdit = false
        // newState.editRecord = {}
        this._editRecord = {}
        this.setState(newState)
    }

    modalCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.editMode = undefined
        this._editRecord = {}
        // newState.editRecord = {}
        newState.showEdit = false
        this.setState(newState)
    }

    modalInputChangeHd(key, value) {
        console.log("key = , value = ", key, value)

        this._editRecord[key] = value
        
        let newState = Object.assign({}, this.state)
        // newState.editRecord = this._editRecord
        newState.editRecord = this._editRecord
        this.setState(newState)
    }

    onChangeHd(pagination, filters, sorter, extra) {
        let {pageChangeHd} = this.props
        if (extra === undefined) {
            return
        }

        if (extra.action === "paginate"){
            // 分页操作
            if (pageChangeHd === undefined || pagination === {}) {
                return
            }
            pageChangeHd(pagination.current, pagination.pageSize)
            return
        }
    }

    render() {
        let {pageChangeHd, totalNum, dataList, searchHd, loading} = this.props       
        let disModal = this.mkEditModal() 
        return (
            <div>
                
                    <HoBreadcrumb itemList={this._breadConf} extra={{style:{marginBottom: '8px'}}} />
                    <Card title="认证对象类型列表" extra={this.mkOpButtons()}>
                        {disModal}
                        <HoTable columns={this._columns} 
                            size="middle" 
                            dataList={dataList}
                            searchHd={searchHd}
                            totalNum={totalNum}
                            pageChangeHd={pageChangeHd}
                            loading={loading}
                        />

                    </Card>
                

            </div>
        );
    }

}

