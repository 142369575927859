

import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import {createLogger} from 'redux-logger';
import {RootReducer} from './reducer';


const middleware = [ thunk ];
if (process.env.NODE_ENV !== 'production') {
    middleware.push(createLogger());
}

export function configStore(initSate=undefined){
    const store = createStore(
        RootReducer, 
        initSate, 
        composeWithDevTools(applyMiddleware(...middleware))
    )
    return store;
}

export const loadStoreState = () => {
    try {
        const binState = localStorage.getItem('ho_login_info');
        if (binState === null){
            return undefined;
        }
        return {login: JSON.parse(binState)};
    }catch (err){
        return undefined;
    }
};

export const saveStoreState = (state) => {
    try {
        const binState = JSON.stringify(state);
        localStorage.setItem('ldqhome_store_state', binState);

    }catch (err){
        console.log('save store state failed! err: ' + err);
    }

};





