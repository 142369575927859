
const ACT_LOGIN_SET_TOKEN = 'ACT_LOGIN_SET_TOKEN'
const ACT_LOGIN_ADD_REDIRCT_AUTH = 'ACT_LOGIN_ADD_REDIRCT_AUTH'

const saveLoginInfo = (userName, token, expire, refreshToken, nickName) => {
    const loginInfo = {
        userName: userName, 
        token: token, 
        expire: expire, 
        refreshToken: refreshToken, 
        nickName: nickName, 
    };

    try {
        const binState = JSON.stringify(loginInfo);
        localStorage.setItem('ho_login_info', binState);

    }catch (err){
        console.log('save store loginInfo failed! err: ' + err);
    }
}


const loginState = {
    userName: undefined, 
    token: undefined, 
    expire: undefined, 
    refreshToken: undefined, 
    nickName: undefined, 
    redirctAuth: false, 
}

export const login_reducer = (state=loginState, action) => {
    switch(action.type){
        case ACT_LOGIN_SET_TOKEN:
        {
            let retState = Object.assign({}, state)
            retState.userName = action.userName
            retState.token = action.token
            retState.expire = action.expire
            retState.refreshToken = action.refreshToken
            retState.nickName = action.nickName
            return retState
        }

        case ACT_LOGIN_ADD_REDIRCT_AUTH:
        {
            let retState = Object.assign({}, state)
            retState.redirctAuth = action.flag
            return retState 
        }

    
        default:
        return state;
    }
}


// select handle
export const getLoginDefaultUserName = (state) => {
    if (state.login.userName !== undefined){
        return state.login.userName;
    }
    
    return undefined;
}

export const getLoginTokenInfo = (state) => {
    return {
        token: state.login.token, 
        expire: state.login.expire, 
        refreshToken: state.login.refreshToken, 
        nickName: state.login.nickName, 
    }
}

export const getRedirctAuth = (state) => {
    return state.login.redirctAuth
}

// actions handle
// ACT_LOGIN_SET_TOKEN
const actLoginSetToken = (userName, token, expire, refreshToken, nickName) => {
    return {
        type: ACT_LOGIN_SET_TOKEN, 
        userName: userName, 
        token: token, 
        expire: expire, 
        refreshToken: refreshToken, 
        nickName: nickName
    }
}

export const getTokenSuccessHd = (dispatch, rspBody, msgBody) => {
    let now = new Date().getTime();
    let expire = now + 1000 * rspBody.Expire
    dispatch(actLoginSetToken(msgBody.UserName, rspBody.Token, expire, rspBody.RefreshToken, rspBody.NickName));
    saveLoginInfo(msgBody.UserName, rspBody.Token, expire, rspBody.RefreshToken, rspBody.NickName)
}

export const actRedirctToAuth = (flag) => {
    return {
        type: ACT_LOGIN_ADD_REDIRCT_AUTH, 
        flag: flag
    }
}





