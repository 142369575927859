import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router";
import {netRequestThunk, actNetRequestClear} from '../network/netReqRD'
import {getTokenSuccessHd, getLoginTokenInfo, getLoginDefaultUserName, getRedirctAuth, 
    actRedirctToAuth} from '../app/account/loginRD'

// 暂定2分钟检测一次
const refreshInterval = 1000 * 60 * 2

class BgCT extends Component {
    constructor(props){
        super(props);

        this.refreshTokenTask = this.refreshTokenTask.bind(this)
        
        let {authCount} = props

        this.state = {
            count: authCount
        }
        
        setTimeout(this.refreshTokenTask, refreshInterval)        
    }

    static getDerivedStateFromProps(props, state){
        let {authCount, history, dispatch} = props

        if (authCount === true) {
           
            // 这里进行跳转操作
            if (dispatch === undefined){
                return state
            }

            dispatch(actRedirctToAuth(false))
            if (history) {
                history.push("/")
            }
        }

        return state
    }

    componentWillUnmount(){
        let {dispatch} = this.props
        dispatch(actNetRequestClear('refreshToken'))
    }


    refreshTokenTask() {
        let {dispatch, userName, tokenInfo} = this.props

        do {
            if (tokenInfo === undefined) {
                break;
            }

            let now = new Date().getTime();

            // 提前20分钟刷新token
            let tmpExpireTime = tokenInfo.expire - (1000 * 60 * 20)

            // console.log("@@@@@_1: now = , tmpExpireTime = ", now, tmpExpireTime)
            if (now < tmpExpireTime){
                break;
            }

            // 刷新token 操作
            let msgBody = {
                RefreshToken: tokenInfo.refreshToken, 
                UserName: userName, 
            }

            console.log("@@@@@_2: now = , tmpExpireTime = ", now, tmpExpireTime)
            dispatch(netRequestThunk("/hq/v1/account/refresh/token", msgBody, "refreshToken", getTokenSuccessHd))

        }while(false)

        setTimeout(this.refreshTokenTask, refreshInterval)
    }

    render(){
        return null;
    }
}

const mapState = (state) => ({
    tokenInfo: getLoginTokenInfo(state), 
    userName: getLoginDefaultUserName(state), 
    authCount: getRedirctAuth(state), 
});

export default connect(
    mapState, 
    null
)(withRouter(BgCT));





