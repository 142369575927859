import React, { Component } from 'react';
import { connect } from 'react-redux'
import {withRouter} from 'react-router'
import { Layout, Menu, Row, Col, Image } from 'antd';

import {HQOperateMenu, HQSettingMenu, HQTopMenu} from './menu';

const {Header, Footer, Content, Sider} = Layout;


const layoutContentStyle = {marginTop: 64, padding: "16px 24px", minHeight: 820}
const layoutHeaderStyle = {zIndex: 1, width: '100%'}

const operateUrlMap = {
    orderDraftList: "/operate/orderDraftList", 
    orderList: "/operate/orderList", 
    supportQuestionList: "/operate/supportQuestionList", 
    dataOrders: "/operate/dataOrders", 
    dataUsers: "/operate/dataUsers", 
}

const settingUrlMap = {
    usersMntnList: "/setting/usersMntnList", 
    usersNormalList: "/setting/usersNormalList", 
    usersGuestList: "/setting/usersGuestList", 
    usersStoreList: "/setting/usersStoreList", 

    productObjCategory: "/setting/productObjCategory", 
    productObj: "/setting/productObj", 
    productFeatureCategory: "/setting/productFeatureCategory", 
    productFeatureSubcategory: "/setting/productFeatureSubcategory", 
    productFeature: "/setting/productFeature", 


    certCertification: "/setting/certCertification", 
    certTestCategory: "/setting/certTestCategory", 
    certTestStandard: "/setting/certTestStandard", 
    certTestCase: "/setting/certTestCase", 
 
    klCertificationUploadList: "/setting/klCertificationUploadList", 
    klCertificationOnlineList: "/setting/klCertificationOnlineList", 
    
    // 检测套餐
    testsuitList: "/setting/testsuitList", 
    
}



class HQLayout extends Component {
    constructor(props){
        super(props);

        this.topMenuOnClickHd = this.topMenuOnClickHd.bind(this)
        this.getLeftMenu = this.getLeftMenu.bind(this)
        this.operateMenuOnClickHd = this.operateMenuOnClickHd.bind(this)
        this.settingMenuOnClickHd = this.settingMenuOnClickHd.bind(this)
        this.windResizeHd = this.windResizeHd.bind(this)


        let {location} = props

        let {clientHeight} = document.documentElement
        let minHeight = clientHeight - 64 - 78

        let topMenu = "operate"
        let operateMenu = "orderList"
        let settingMenu = "certCertification"

        if (location) {
            let {pathname} = location
            let tmpRet = pathname.split("/operate")
            if (tmpRet.length > 1) {
                topMenu = "operate"
                operateMenu = tmpRet[1].substr(1)
            }else{
                tmpRet = pathname.split("/setting")
                if (tmpRet.length > 1) {
                    topMenu = "setting"
                    settingMenu = tmpRet[1].substr(1)
                }
            }
        }

        this.state = {
            topMenu: topMenu, 
            operateMenu: operateMenu, 
            settingMenu: settingMenu, 
            minHeight: minHeight, 
        };
    }


    topMenuOnClickHd(clickItem) {
        let {topMenu, operateMenu, settingMenu} = this.state
        if (clickItem !== topMenu) {
            let url = undefined;
            if (clickItem === "operate") {
                url = this.getMenuUrl(clickItem, operateMenu)
            }else{
                url = this.getMenuUrl(clickItem, settingMenu)
            }

            if (url) {
                let {history} = this.props
                if (history) {
                    history.push(url)
                }
            }

            let newState = Object.assign({}, this.state)
            newState.topMenu = clickItem
            this.setState(newState)
        }

    }

    operateMenuOnClickHd(clickItem) {
        let {operateMenu} = this.state
        if (operateMenu !== clickItem) {
            const url = this.getMenuUrl("operate", clickItem)
            if (url) {
                let {history} = this.props
                if (history) {
                    history.push(url)
                }
            }

            let newState = Object.assign({}, this.state)
            newState.operateMenu = clickItem
            this.setState(newState)
        }

    }

    settingMenuOnClickHd(clickItem) {
        let {settingMenu} = this.state
        if (settingMenu !== clickItem) {
            const url = this.getMenuUrl("setting", clickItem)
            if (url) {
                let {history} = this.props
                if (history) {
                    history.push(url)
                }
            }

            let newState = Object.assign({}, this.state)
            newState.settingMenu = clickItem
            this.setState(newState)
        }
    }

    getLeftMenu() {
        let {topMenu, operateMenu, settingMenu} = this.state
        let {location} = this.props

        let selectMenu = topMenu === "operate" ? operateMenu : settingMenu

        if (location) {
            let {pathname} = location
            let tmpMenu = this.getMenuItemByUrl(topMenu, pathname)
            if (tmpMenu) {
                selectMenu = tmpMenu
            }
        }

        if (topMenu === "operate") {
            return (
                <HQOperateMenu 
                currentItem={selectMenu}  
                leftMenuOnClickHd={this.operateMenuOnClickHd}
                />
            )
        } else {
            return (
                <HQSettingMenu 
                currentItem={selectMenu}
                leftMenuOnClickHd={this.settingMenuOnClickHd}
                />
            )
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.windResizeHd)
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.windResizeHd)
    }

    windResizeHd() {
        let {clientHeight} = document.documentElement
        let minHeight = clientHeight - 64 - 78
        
        let newState = Object.assign({}, this.state)
        newState.minHeight = minHeight
        this.setState(newState)
    }

    getMenuUrl(top, left) {
        if (top === "operate") {
           for (let key in operateUrlMap) {
               if (key === left) {
                    return operateUrlMap[key];
               }
           }
        }else{
            for (let key in settingUrlMap) {
                if (key === left) {
                     return settingUrlMap[key];
                }
            }
        }
        return undefined
    }

    getMenuItemByUrl(top, url) {
        if (top === "operate") {
            for (let key in operateUrlMap) {
                if (operateUrlMap[key] === url) {
                    return key
                }   
            }
        }else{
            for (let key in settingUrlMap) {
                if (settingUrlMap[key] === url) {
                    return key
                }   
            }
        }

        return undefined
    }

    render() {
        let {minHeight} = this.state
        const letMenu = this.getLeftMenu()

        return (
            <Layout>
                <Sider 
                theme="dark"
                // width={160}
                >
                    <div style={{height: 64, alignContent: "center"}}>
                        <Image width={148} height={64} preview={false} src="/logo3.png"></Image>
                    </div>
                    {letMenu}
                </Sider>

                <Layout>
                    <Header style={layoutHeaderStyle}>
                        <HQTopMenu 
                        currentItem={this.state.topMenu}
                        topMenuOnClickHd={this.topMenuOnClickHd}
                        />
                    </Header>

                    <Content style={{
                        background: '#fff',
                        marginTop: "8px", 
                        padding: "16px 24px", 
                        minHeight: minHeight,
                        }}>
                        {this.props.children}
                    </Content>

                    <Footer style={{ textAlign: 'center' }}>华秋认证平台 ©2016-2021</Footer>

                </Layout>
            </Layout>
        )
    }
}

export default connect(
    null, 
    null
)(withRouter(HQLayout));




