import React, { Component } from 'react'
import { connect } from 'react-redux'
import {getNetRequestStatus, netRequestThunk, actNetRequestClear} from '../../network/netReqRD'
import {getOrderList, getOrderListSuccessHd} from './orderRD'
import {getLoginTokenInfo} from '../account/loginRD'
import {HoBuildTableData} from '../../component/utils'
import {HoMkSearchFilters} from '../../component/tbl'
import {OrderListPage} from './orderList'

class OrderListCT extends Component {
    constructor(props){
        super(props)
        this.pageChangeHd = this.pageChangeHd.bind(this)
        this.tokenCheckHd = this.tokenCheckHd.bind(this)
        this.refreshHd = this.refreshHd.bind(this)
        this.searchHd = this.searchHd.bind(this)
        this.requestData = this.requestData.bind(this)
        this.getLoadingFlag = this.getLoadingFlag.bind(this)
        this.detailOnclickHd = this.detailOnclickHd.bind(this)
        
        
        this._pagination = {
            PageSize: 20, 
            PageNum: 1, 
            Filters: [[]], 
            OrderKey: "", 
            Desc: 1, 
        }

        this.state = {
            PageSize: this._pagination.PageSize, 
            PageNum: this._pagination.PageNum, 
        }

        this._delRecord = undefined

    }

    tokenCheckHd() {
        let {tokenInfo, history} = this.props
        if (tokenInfo === undefined) {
            const directPath = '/device'
            history.push(directPath)
            return false
        }
        return true
    }

    requestData() {
        if (!this.tokenCheckHd()) {
            return
        }
        let {PageSize, PageNum, Filters, OrderKey, Desc} = this._pagination

        // 这里构造过滤条件
        for (let item of Filters) {
            item.push({Key: "State", Type: "str", Value: "draft", Op: "!="})
        }

        console.log("#######: Filters = ", Filters)

        let {dispatch, tokenInfo} = this.props
        let msgBody = {
            PageSize: PageSize, 
            PageNum: PageNum,
            Filters: Filters, 
            OrderKey: OrderKey, 
            Desc: Desc
        }
        dispatch(netRequestThunk("/hq/v1/mntn/cert/order/list", msgBody, "getOrderList", getOrderListSuccessHd, tokenInfo.token))
    }

    componentWillUnmount(){
        let {dispatch} = this.props
        dispatch(actNetRequestClear("getOrderList"))
    }

    componentDidMount() {
        this.requestData()
    }

    refreshHd() {
        this.requestData()
    }

    pageChangeHd(current, size) {
        if (current !== this._pagination.PageNum || size !== this._pagination.PageSize) {
            this._pagination.PageNum = current
            this._pagination.PageSize = size
            // 需要重新请求数据
            this.requestData()
            // set state
            let newState = Object.assign({}, this.state)
            newState.PageNum = current
            newState.PageSize = size
            this.setState(newState)
        }
    }

    searchHd(searchInfo) {
        let filters = HoMkSearchFilters(searchInfo)
        this._pagination.Filters = filters
        this._pagination.PageNum = 1

        // 这里进行网络请求
        this.requestData()
    }

    deleteOnClickHd(key, record) {
        this._delRecord = record
    }


    getLoadingFlag() {
        let {reqStatus, dispatch} = this.props

        let isLoading = false
        if (reqStatus === 1) {
            isLoading = true
        } 

        return isLoading
    }

    
    detailOnclickHd(key, record) {
        let {history} = this.props
            if (history) {
                let url = "/operate/order/detail/" + record.Id
                history.push(url)
            }
    }

    render(){
        let {dataInfo, selectFlag, selectedHd} = this.props
        let {PageNum, PageSize} = this.state
        let totalNum = 0
        let dataList = undefined
        if (dataInfo !== undefined) {
            totalNum = dataInfo.TotalNum
            dataList = HoBuildTableData(dataInfo.RecordList)
        }

        let isLoading = this.getLoadingFlag()
        
        return (
            <OrderListPage 
            pageChangeHd={this.pageChangeHd}
            totalNum={totalNum}
            dataList={dataList}
            refreshHd={this.refreshHd}
            searchHd={this.searchHd}
            pageNum={PageNum}
            pageSize={PageSize}
            loading={isLoading}
            detailOnclickHd={this.detailOnclickHd}
            />
        );
    }
}

const mapState = (state) => ({
    dataInfo: getOrderList(state), 
    tokenInfo: getLoginTokenInfo(state), 
    reqStatus: getNetRequestStatus(state, 'getOrderList'), 
});

export default connect(
    mapState, 
    null
)(OrderListCT);


