export const MarginDefault = "16px"

export const HqCompany = "深圳华秋认证技术有限公司"
export const HqAddress = "深圳市南山区西丽街道西丽社区打石一路万科云城万科里A座13栋B05"
export const HqTel = "13510215581"

// 订单审核类型
// 付款
export const OrderApproveTypePayment = 1
// 资料上传
export const OrderApproveTypeResUpload = 2
// 样品
export const OrderApproveTypeSample = 3
// 订单受理确认
export const OrderApproveTypeConfirm = 4
// 后台管理测试结果审核
export const OrderApproveTypeMntnReport = 5
// 前台用户测试报告结果审核
export const OrderApproveTypeShowReport = 6

export const OnlineDocCateCert = "certification"
export const OnlineDocCateTest = "test"