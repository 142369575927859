import React, { Component } from 'react';

import {Card, Row, Col, Button, Popconfirm, Space, Form, Input, Modal, Select } from 'antd'
import {SyncOutlined, PlusOutlined, CheckOutlined} from '@ant-design/icons'

import {HoBreadcrumb} from '../../component/element'
import {HoTable, HoMkTblColumn, HoMkTblColActions} from '../../component/tbl'
import {yesOrNoMap} from '../../comm/logic'


export class FeatureSubcategoryPage extends Component {
    constructor(props){
        super(props)

        this.mkOpButtons = this.mkOpButtons.bind(this)
        this.addOnclickHd = this.addOnclickHd.bind(this)
        this.mkEditModal = this.mkEditModal.bind(this)
        this.modalCancelHd = this.modalCancelHd.bind(this)
        this.modalConfirmHd = this.modalConfirmHd.bind(this)
        this.editOnclickHd = this.editOnclickHd.bind(this)


        let {deleteOnClickHd, delConfirmHd, selectFlag, selectedHd} = props
        this.state = {
            showEdit: false, 
            editMode: undefined,
            editRecord: {}, 
            showCategorySelect: false, 
            showStandardSelect: false, 
        }

        this._form = undefined

        this._editRecord = {}


        this._breadConf = [
            {name: "产品信息", link: '/setting/productObjCategory'}, 
            {name: "附加特性子类别", link: undefined}, 
        ]

        const disDelete = (
            <Popconfirm title="请确认是否要删除？" 
            onConfirm={(e) => delConfirmHd ? delConfirmHd(e, 'confirm') : undefined}
            onCancel={(e) => delConfirmHd ? delConfirmHd(e, 'cancel') : undefined}
            >
                <span>删除</span>
            </Popconfirm>
        )

        let actionContent = HoMkTblColActions([{key: 'edit', display: '编辑', onClickedHd: this.editOnclickHd}, 
                                               {key: 'delete', display: disDelete, onClickedHd: deleteOnClickHd}, ])
        if (selectFlag) {
            actionContent = HoMkTblColActions([{key: 'select', display: '选择', onClickedHd: selectedHd}])
        }

        this._columns = [
            HoMkTblColumn('特性类别', 'CategoryName', 'str', '20%', true, false), 
            HoMkTblColumn('特性子类别', 'SubcategoryName', 'str', '20%', true, false), 
            HoMkTblColumn('是否显示', 'Display', 'number', '20%', true, false, yesOrNoMap), 
            HoMkTblColumn('备注', 'Remark', 'str', '20%', true, false), 
            actionContent, 
        ]
    }
    
    mkOpButtons() {
        let {refreshHd, addOnclickHd} = this.props
        return (
            <Row>
                <Space>
                    <Button type="primary" icon={<PlusOutlined />} onClick={this.addOnclickHd}>新增</Button>
                    <Button type="default" icon={<SyncOutlined />} onClick={refreshHd}>刷新</Button>
                </Space>
            </Row>
        )
    }

    editOnclickHd(key, record) {
        let newState = Object.assign({}, this.state)
        newState.editMode = "mod"
        newState.showEdit = true
        newState.editRecord = record
        this.setState(newState)
    }

    mkEditModal() {
        let {showEdit, editRecord} = this.state
        let {categorys} = this.props

        let categoryOptions = undefined
        if (categorys) {
            let {RecordList} = categorys
            categoryOptions = RecordList.map(
                (item, index) => (
                    <Select.Option key={index} value={item.Id}>{item.Name}</Select.Option>
                )
            )
        } 

        if (this._form) {
            this._form.setFieldsValue(
                {
                    CategoryId: editRecord.CategoryId, 
                    SubcategoryName: editRecord.SubcategoryName, 
                    Display: editRecord.Display, 
                    Remark: editRecord.Remark, 
                }
            )
        }

        let content = (
            <Form onFinish={(values) => this.modalConfirmHd(values)}
            labelCol={{span: 6}}
            wrapperCol={{span: 18}}
            ref={node => this._form = node}
            initialValues={
                {
                    CategoryId: editRecord.CategoryId, 
                    SubcategoryName: editRecord.SubcategoryName, 
                    Display: editRecord.Display, 
                    Remark: editRecord.Remark, 
                }
            }
            >
                <Form.Item name="CategoryId" rules={[{required: true, message: '请选择所属特性类别!'}]} label="所属类别" >
                    <Select >
                        {categoryOptions}
                    </Select>
                </Form.Item>

                <Form.Item name="SubcategoryName" rules={[{required: true, message: '请输入特性子类别!'}]} label="特性子类别" >
                    <Input />
                </Form.Item>

                <Form.Item name="Display" label="是否显示" >
                    <Select >
                        <Select.Option value={1}>是</Select.Option>
                        <Select.Option value={0}>否</Select.Option> 
                    </Select>
                </Form.Item>

                <Form.Item name="Remark" label="备注" >
                    <Input />
                </Form.Item>
                    
                <Row justify="end">
                    <Space size="middle" >
                    <Button type="default" onClick={() => this.modalCancelHd()}>取消</Button>
                    <Button type="primary" onClick={undefined} htmlType="submit">保存</Button>
                    </Space>
                </Row>
            </Form>
        )

        return (
            <Modal 
                title="附加特性子类别"
                visible={showEdit}
                onCancel={this.modalCancelHd}
                okText="保存" cancelText="取消"
                footer={null}
                width={560}
                >
                {content}
                   
            </Modal>
        )
    }

    modalConfirmHd(values) {
        let {editMode, editRecord} = this.state
        let {editConfirmHd, addConfirmHd} = this.props

        let result = {}
        result.Id = editRecord.SubcategoryId
        result.CategoryId = values.CategoryId
        result.Display = parseInt(values.Display)
        result.Name = values.SubcategoryName
        result.Remark = values.Remark

        if (editMode === 'add') {
            if (addConfirmHd) {
                addConfirmHd(result)
            }
        }else if (editMode === 'mod') {
            if (editConfirmHd) {
                editConfirmHd(result)
            }
        }

        let newState = Object.assign({}, this.state)
        newState.editMode = undefined
        newState.showEdit = false
        newState.editRecord = {}
        this.setState(newState)
    }

    addOnclickHd() {
        let newState = Object.assign({}, this.state)
        newState.editMode = "add"
        newState.showEdit = true
        this.setState(newState)
    }

    modalCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.editMode = undefined
        newState.showEdit = false
        this.setState(newState)
    }

   
    render() {
        let {pageChangeHd, totalNum, dataList, searchHd, loading} = this.props   
        let editModal = this.mkEditModal()
        return (
            <div>
                <HoBreadcrumb itemList={this._breadConf} extra={{style:{marginBottom: '8px'}}} />
                {editModal}
                <Card title="特性子类别列表" extra={this.mkOpButtons()}>
                    <HoTable columns={this._columns} 
                        size="middle" 
                        dataList={dataList}
                        searchHd={searchHd}
                        totalNum={totalNum}
                        pageChangeHd={pageChangeHd}
                        loading={loading}
                    />
                </Card>
            </div>
        );
    }

}

