import {c_apiUrlBase, c_apiToken} from '../comm/config'

//-------------------------------
const host = c_apiUrlBase
const token = c_apiToken


export const sendRequest = (path, body, method='POST', token=undefined, absoluteUrl=undefined, headers={}) => {
    let inputToken = token
    if (token !== undefined) {
        inputToken = token
    }
    const request = {
        method: method, 
        // mode: 'no-cors', 
        headers: Object.assign({
            'Accept': 'application/json', 
            'Content-Type': 'text/plain',
            'Token': inputToken, 
        }, headers), 
        body: JSON.stringify(body)
    };
    return fetch(absoluteUrl || (host + path), request)
        .then(
            ((response) => {
                // console.log(response.json())
                return response.ok ? response.json() : Promise.reject(response.text());
            }), 
            ((errer) => {
                return Promise.reject(errer)
            })
        )
        .then(
            ((json) => {
                // console.log(json);
                // return {msg: json}
                return json;
            }), 
            ((error) => {
                console.log('then error = ' + error);
                return undefined;
            })
        )
        .catch(
            (error) => {
                console.log('catch error = ' + error);
                return undefined;
            }
        )
}






