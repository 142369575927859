import React, { Component } from 'react'
import { connect } from 'react-redux'
import {getNetRequestStatus, netRequestThunk, actNetRequestClear} from '../../network/netReqRD'
import {getFeature, getFeatureListSuccessHd} from './productRD'
import {getTestStandard, getTestStandardListSuccessHd} from './../cert/certRD'
import {getLoginTokenInfo} from '../account/loginRD'
import {FeaturePage} from './feature'
import {HoBuildTableData} from '../../component/utils'
import {HoMkSearchFilters} from '../../component/tbl'

class FeatureCT extends Component {
    constructor(props){
        super(props)
        this.pageChangeHd = this.pageChangeHd.bind(this)
        this.tokenCheckHd = this.tokenCheckHd.bind(this)
        this.refreshHd = this.refreshHd.bind(this)
        this.searchHd = this.searchHd.bind(this)
        this.requestData = this.requestData.bind(this)
        this.deleteOnClickHd = this.deleteOnClickHd.bind(this)
        this.delConfirmHd = this.delConfirmHd.bind(this)
        this.getLoadingFlag = this.getLoadingFlag.bind(this)
        this.addConfirmHd = this.addConfirmHd.bind(this)
        this.editConfirmHd = this.editConfirmHd.bind(this)


        this._pagination = {
            PageSize: 10, 
            PageNum: 1, 
            Filters: [[]], 
            OrderKey: "", 
            Desc: 1, 
        }

        this.state = {
            PageSize: this._pagination.PageSize, 
            PageNum: this._pagination.PageNum, 
        }

        this._delRecord = undefined

    }

    tokenCheckHd() {
        let {tokenInfo, history} = this.props
        if (tokenInfo === undefined) {
            const directPath = '/'
            history.push(directPath)
            return false
        }
        return true
    }

    requestData() {
        if (!this.tokenCheckHd()) {
            return
        }
        let {PageSize, PageNum, Filters, OrderKey, Desc} = this._pagination
        let {dispatch, tokenInfo} = this.props
        let msgBody = {
            PageSize: PageSize, 
            PageNum: PageNum,
            Filters: Filters, 
            OrderKey: OrderKey, 
            Desc: Desc
        }
        dispatch(netRequestThunk("/hq/v1/mntn/product/feature/list", msgBody, "getFeatureList", 
        getFeatureListSuccessHd, tokenInfo.token))
    }

    componentWillUnmount(){
        let {dispatch} = this.props
        dispatch(actNetRequestClear("getFeatureList"))
        dispatch(actNetRequestClear("addFeature"))
        dispatch(actNetRequestClear("modFeature"))
        dispatch(actNetRequestClear("delFeature"))
        dispatch(actNetRequestClear("getTestStandardList"))
    }

    componentDidMount() {
        this.requestData()

        // 还需求请求标准列表信息
        let {dispatch, tokenInfo} = this.props
        let msgBody = {
            PageSize: 5000, 
            PageNum: 1, 
            Filters: [[]], 
        }
        dispatch(netRequestThunk("/hq/v1/mntn/cert/testStandard/list", msgBody, "getTestStandardList", getTestStandardListSuccessHd, tokenInfo.token))
    }

    refreshHd() {
        this.requestData()
    }

    pageChangeHd(current, size) {
        if (current !== this._pagination.PageNum || size !== this._pagination.PageSize) {
            this._pagination.PageNum = current
            this._pagination.PageSize = size
            // 需要重新请求数据
            this.requestData()
            // set state
            let newState = Object.assign({}, this.state)
            newState.PageNum = current
            newState.PageSize = size
            this.setState(newState)
        }
    }

    searchHd(searchInfo) {
        let filters = HoMkSearchFilters(searchInfo)
        this._pagination.Filters = filters
        this._pagination.PageNum = 1

        // 这里进行网络请求
        this.requestData()
    }

    deleteOnClickHd(key, record) {
        this._delRecord = record
    }

    delConfirmHd(e, type) {
        e.stopPropagation();
        if (type === 'confirm') {
            if (this._delRecord !== undefined) {
                if (!this.tokenCheckHd()) {
                    this._delRecord = undefined  
                    return
                }

                let {dispatch, tokenInfo} = this.props
                let msgBody = {
                    Id: this._delRecord.Id, 
                }
                dispatch(netRequestThunk("/hq/v1/mntn/product/feature/del", msgBody, "delFeature", undefined, tokenInfo.token))
            }
        }

        this._delRecord = undefined        
    }


    getLoadingFlag() {
        let {reqStatus, addStatus, modStatus, delStatus, dispatch} = this.props

        let isLoading = false
        if (reqStatus === 1 || addStatus === 1 || modStatus === 1 || delStatus === 1) {
            isLoading = true
        } 

        if (addStatus === 2) {
            dispatch(actNetRequestClear("addFeature"))
            this.requestData()
            return isLoading
        }

        if (modStatus === 2) {
            dispatch(actNetRequestClear("modFeature"))
            this.requestData()
            return isLoading
        }

        if (delStatus === 2) {
            dispatch(actNetRequestClear("delFeature"))
            this.requestData()
            return isLoading
        }

        return isLoading
    }

    editConfirmHd(record) {
        if (!this.tokenCheckHd()) {
            return
        }
        let {dispatch, tokenInfo} = this.props
        let msgBody = {
            Id: record.Id, 
            SubCategoryId: record.SubCategoryId, 
            Name: record.Name, 
            DisplayName: record.DisplayName, 
            Description: record.Description, 
            Period: record.Period, 
            Price: record.Price, 
            IsDefault: record.IsDefault, 
            Remark: record.Remark, 
            StandardIds: record.StandardList, 
        }

        dispatch(netRequestThunk("/hq/v1/mntn/product/feature/mod", msgBody, "modFeature", undefined, tokenInfo.token))
    }

    addConfirmHd(record) {
        if (!this.tokenCheckHd()) {
            return
        }

        console.log("@@@@: record = ", record)

        let {dispatch, tokenInfo} = this.props
        let msgBody = {
            SubCategoryId: record.SubCategoryId, 
            Name: record.Name, 
            DisplayName: record.DisplayName, 
            Description: record.Description, 
            Period: record.Period, 
            Price: record.Price, 
            IsDefault: record.IsDefault, 
            Remark: record.Remark, 
            StandardIds: record.StandardList, 
        }

        // return

        dispatch(netRequestThunk("/hq/v1/mntn/product/feature/add", msgBody, "addFeature", undefined, tokenInfo.token))
    }


    render(){
        let {dataInfo, selectFlag, selectedHd, categorys, standardList} = this.props
        let {PageNum, PageSize} = this.state
        let totalNum = 0
        let dataList = undefined
        if (dataInfo !== undefined) {
            totalNum = dataInfo.TotalNum
            dataList = HoBuildTableData(dataInfo.RecordList)
        }

        let isLoading = this.getLoadingFlag()
        
        return (
            <FeaturePage 
            pageChangeHd={this.pageChangeHd}
            totalNum={totalNum}
            dataList={dataList}
            refreshHd={this.refreshHd}
            searchHd={this.searchHd}
            pageNum={PageNum}
            pageSize={PageSize}
            loading={isLoading}
            selectFlag={selectFlag}
            selectedHd={selectedHd}
            categorys={categorys}
            deleteOnClickHd={this.deleteOnClickHd}
            delConfirmHd={this.delConfirmHd}
            editConfirmHd={this.editConfirmHd}
            addConfirmHd={this.addConfirmHd}
            standardList={standardList}
            />
        );
    }
}

const mapState = (state) => ({
    dataInfo: getFeature(state), 
    tokenInfo: getLoginTokenInfo(state), 
    reqStatus: getNetRequestStatus(state, 'getFeatureList'), 
    addStatus: getNetRequestStatus(state, 'addFeature'), 
    modStatus: getNetRequestStatus(state, 'modFeature'), 
    delStatus: getNetRequestStatus(state, 'delFeature'), 
    standardList: getTestStandard(state), 
});

export default connect(
    mapState, 
    null
)(FeatureCT);


