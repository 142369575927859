import React, { Component } from 'react'
import { connect } from 'react-redux'
import {getNetRequestStatus, netRequestThunk, actNetRequestClear} from '../../network/netReqRD'
import {getOrderDraftDetail, getOrderDraftDetailSuccessHd, clearOrderDraftDetail, getObjDetail, getObjDetailSuccessHd, clearObjDetail} from './orderRD'
import {getLoginTokenInfo} from '../account/loginRD'
import {OrderDraftPage} from './draftDetail'
import {HoBuildTableData} from '../../component/utils'
import {HoMkSearchFilters} from '../../component/tbl'

class OrderDraftDetailCT extends Component {
    constructor(props){
        super(props)
        this.tokenCheckHd = this.tokenCheckHd.bind(this)
        this.requestData = this.requestData.bind(this)
        this.requestObjDetail = this.requestObjDetail.bind(this)
        this.refreshHd = this.refreshHd.bind(this)
        this.getOrderInitInfo = this.getOrderInitInfo.bind(this)
        

        

        let {match} = props
        let orderId = undefined
        if (match) {
            if (match.params) {
                orderId = parseInt(match.params.orderId)
            }
            console.log("@@@@@: orderId = ", orderId)
        }

        this.state = {
            OrderId: orderId, 
        }

        this.__orderInit = 0

    }

    tokenCheckHd() {
        let {tokenInfo, history} = this.props
        if (tokenInfo === undefined) {
            const directPath = '/'
            history.push(directPath)
            return false
        }
        return true
    }

    requestData() {
        if (!this.tokenCheckHd()) {
            return
        }

        let {OrderId} = this.state

        let {dispatch, tokenInfo} = this.props
        let msgBody = {
            OrderId: OrderId, 
        }
        dispatch(netRequestThunk("/hq/v1/mntn/cert/order/detail", msgBody, "getOrderDraftDetail", getOrderDraftDetailSuccessHd, tokenInfo.token))
    }

    componentWillUnmount(){
        let {dispatch} = this.props
        dispatch(actNetRequestClear("getOrderDraftDetail"))
        dispatch(actNetRequestClear("getObjDetail"))

        dispatch(clearOrderDraftDetail())
        dispatch(clearObjDetail())
    }

    componentDidMount() {
        this.requestData()
    }

    refreshHd() {
        this.requestData()
    }


    getLoadingFlag() {
        let {reqStatus, dispatch} = this.props

        let isLoading = false
        if (reqStatus === 1 ) {
            isLoading = true
        } 

        return isLoading
    }

    requestObjDetail(objId) {
        let {dispatch, tokenInfo} = this.props
        let msgBody = {
            Id: objId, 
        }

        dispatch(netRequestThunk("/hq/v1/mntn/product/dstObj/detail", msgBody, "getObjDetail", getObjDetailSuccessHd, tokenInfo.token))
    }

    getOrderInitInfo(orderDetail) {
        // let  {orderDetail} = this.props

        if (this.__orderInit === 1) {
            return
        }

        if (!orderDetail) {
            return
        }

        let {OrderBase} = orderDetail
        if (OrderBase) {
            this.requestObjDetail(OrderBase.DstObjId)
            this.__orderInit = 1
        }
    }

    render(){
        let {detailInfo, objDetail} = this.props

        this.getOrderInitInfo(detailInfo)

        let isLoading = this.getLoadingFlag()
        
        return (
            <OrderDraftPage 
            // requestObjDetail={this.requestObjDetail}
            isLoading={isLoading}
            orderDetail={detailInfo}
            objDetail={objDetail}
            refreshHd={this.refreshHd}
            
            />
        );
    }
}

const mapState = (state) => ({
    detailInfo: getOrderDraftDetail(state), 
    tokenInfo: getLoginTokenInfo(state), 
    reqStatus: getNetRequestStatus(state, 'getTestCaseDetail'), 
    objDetail: getObjDetail(state),
    
});

export default connect(
    mapState, 
    null
)(OrderDraftDetailCT);


