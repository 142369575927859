import React, { Component } from 'react';

import {Card, Row, Col, Button, Popconfirm, Space, Modal, Descriptions, Select, 
    Tag, Form, Input } from 'antd'
import {SyncOutlined, PlusOutlined, CheckOutlined} from '@ant-design/icons'

import {HoBreadcrumb} from '../../component/element'
import {HoTable, HoMkTblColumn, HoMkTblColActions} from '../../component/tbl'
import CertDstObjCategoryListCT from './dstObjCategoryCT'

const { Option } = Select


export class CertDstObjListPage extends Component {
    constructor(props){
        super(props)

        this.mkOpButtons = this.mkOpButtons.bind(this)
        this.onChangeHd = this.onChangeHd.bind(this)
        this.mkEditModal = this.mkEditModal.bind(this)
        this.addOnclickHd = this.addOnclickHd.bind(this)
        this.editOnclickHd = this.editOnclickHd.bind(this)
        this.modalConfirmHd = this.modalConfirmHd.bind(this)
        this.modalCancelHd = this.modalCancelHd.bind(this)
        this.modalInputChangeHd = this.modalInputChangeHd.bind(this)
        this.selectModalCancelHd = this.selectModalCancelHd.bind(this)
        this.mkSelectModal = this.mkSelectModal.bind(this)
        this.selectButtonOnClickHd = this.selectButtonOnClickHd.bind(this)
        this.selectedHd = this.selectedHd.bind(this)
        this.selectCategoryOnChangeHd = this.selectCategoryOnChangeHd.bind(this)


        this._editRecord = {}


        let {deleteOnClickHd, delConfirmHd, detailOnclickHd} = props
        this.state = {
            showEdit: false, 
            editMode: undefined,
            editRecord: {}, 
            showSelect: false, 
        }

        this._breadConf = [
            {name: "产品信息", link: '/setting/certDstObjCategory'}, 
            {name: "认证产品", link: undefined}, 
        ]

        const disDelete = (
            <Popconfirm title="请确认是否要删除？" 
            onConfirm={(e) => delConfirmHd ? delConfirmHd(e, 'confirm') : undefined}
            onCancel={(e) => delConfirmHd ? delConfirmHd(e, 'cancel') : undefined}
            >
                <span>删除</span>
            </Popconfirm>
        )

        this._columns = [
            HoMkTblColumn('产品名称', 'Name', 'str', '15%', true, false), 
            HoMkTblColumn('关联类别', 'CategoryList', 'str', '20%', false, false, this.displayCategorys), 
            HoMkTblColumn('是否显示', 'Display', 'number', '10%', false, false, this.displayMap), 
            HoMkTblColumn('产品描述', 'Description', 'str', '15%', true, false), 
            HoMkTblColumn('介绍网址', 'DetailUrl', 'str', '15%', true, false), 
            HoMkTblColumn('备注', 'Remark', 'str', '10%', true, false), 
            HoMkTblColActions([{key: 'detail', display: '详情', onClickedHd: detailOnclickHd}, 
                               {key: 'edit', display: '编辑', onClickedHd: this.editOnclickHd}, 
                               {key: 'delete', display: disDelete, onClickedHd: deleteOnClickHd}, 
                            ]), 
        ]
    }

    displayMap(Display) {
        if (Display === 0) {
            return "否"
        }else {
            return "是"
        }
    }

    displayCategorys(categoryList) {
        console.log("###  categoryList = ", categoryList)
        let display = undefined
        if (categoryList) {
            display = categoryList.map(
                (item, index) => (
                    <Tag key={index} color="geekblue">{item.CategoryName}</Tag>
                )
            )
        }
        
        return display
    }

    
    mkOpButtons() {
        let {refreshHd} = this.props
        return (
            <Row>
                <Space>
                    <Button type="primary" icon={<PlusOutlined />} onClick={this.addOnclickHd}>新增</Button>
                    <Button type="default" icon={<SyncOutlined />} onClick={refreshHd}>刷新</Button>
                </Space>
            </Row>
        )
    }

    mkEditModal() {
        let {showEdit, editMode, editRecord} = this.state
        let {categoryInfo} = this.props

        let categoryOptions = undefined
        if (categoryInfo) {
            let {RecordList} = categoryInfo
            categoryOptions = RecordList.map(
                (item, index) => (
                    <Option key={index} value={item.Id}>{item.Name}</Option>
                )
            )
        }

        let selectDefault = undefined
        if (editRecord) {
            if (editRecord.CategoryList) {
                selectDefault = []
                for (let item of editRecord.CategoryList) {
                    selectDefault.push(item.CategoryId)
                }
            }
        }

        const getFiledName = (key) => {
            if (editMode === "mod") {
                return undefined
            }else{
                return key
            }
        }

        let content = (
            <Form onFinish={(values) => this.modalConfirmHd(values)}
            labelCol={{span: 6}}
            wrapperCol={{span: 18}}
            >                      
                <Form.Item 
                    name={getFiledName("Name")}
                    rules={[{required: true, message: '请输入对象产品名称!'}]} 
                    label="产品名称" value={editRecord ? editRecord.Name : undefined}
                    >
                        <Input placeholder="" value={editRecord ? editRecord.Name : undefined} 
                            onChange={(e)=>this.modalInputChangeHd("Name", e.target.value)} />
                </Form.Item>
                    
                <Form.Item 
                    name={getFiledName("Display")}
                    rules={[{required: true, message: '是否显示!'}]} 
                    label="是否显示" value={editRecord ? editRecord.Display : undefined} 
                    >
                        <Select value={editRecord ? editRecord.Display : undefined} 
                            onChange={(value)=> this.modalInputChangeHd("Display", value)} >
                                <Select.Option value={1}>是</Select.Option>
                                <Select.Option value={0}>否</Select.Option>
                                
                            </Select>
                </Form.Item>

                <Form.Item 
                    value={editRecord.CategoryIds} label="对象类别" name={getFiledName("CategoryIds")}
                    >
                        <Select 
                        // defaultValue={selectDefault}
                        value={selectDefault}
                        // initialValues={selectDefault}
                        onChange={this.selectCategoryOnChangeHd}
                        mode="multiple"
                        showArrow
                        >
                            {categoryOptions}
                        </Select>
                </Form.Item>

                <Form.Item 
                    name={getFiledName("Description")}
                    label="产品描述" 
                    >
                        <Input placeholder="" value={editRecord ? editRecord.Description : undefined} 
                            onChange={(e)=>this.modalInputChangeHd("Description", e.target.value)} />
                </Form.Item>

                <Form.Item 
                    name={getFiledName("DetailUrl")}
                    label="详情网址" 
                    >
                        <Input placeholder="" value={editRecord ? editRecord.DetailUrl : undefined} 
                            onChange={(e)=>this.modalInputChangeHd("DetailUrl", e.target.value)} />
                </Form.Item>

                <Form.Item 
                    name={getFiledName("Remark")}
                    label="备注" 
                    >
                        <Input placeholder="" value={editRecord ? editRecord.Remark : undefined} 
                            onChange={(e)=>this.modalInputChangeHd("Remark", e.target.value)} />
                </Form.Item>


                    
                <Row justify="end">
                    <Space size="middle" >
                    <Button type="default" onClick={() => this.modalCancelHd()}>取消</Button>
                    <Button type="primary" onClick={undefined} htmlType="submit">保存</Button>
                    </Space>
                </Row>
            </Form>
        )

        return (
            <Modal 
                title="认证对象（产品）"
                visible={showEdit}
                // onOk={this.modalConfirmHd}
                onCancel={this.modalCancelHd}
                okText="保存" cancelText="取消"
                width={640}
                footer={null}
                >
                {content}
                   
            </Modal>
        )
    }

    selectCategoryOnChangeHd(values, ops) {
        let {editRecord} = this.state
        editRecord.CategoryIds = values

        editRecord.CategoryList = []
        for (let item of ops) {
            let tmpItem = {CategoryId: item.value, CategoryName: item.children}
            editRecord.CategoryList.push(tmpItem)
        }
        
        let newState = Object.assign({}, this.state)
        newState.editRecord = editRecord
        this.setState(newState)
    }

    addOnclickHd() {
        let newState = Object.assign({}, this.state)
        newState.editMode = "add"
        newState.showEdit = true
        this.setState(newState)
    }

    editOnclickHd(key, record) {
        let newState = Object.assign({}, this.state)
        newState.editMode = "mod"
        newState.showEdit = true
        newState.editRecord = record
        this.setState(newState)
    }

    modalConfirmHd(values) {
        console.log("modalConfirmHd: values = ", values)

        for (let key in values) {
            console.log("key = " + key + ", value = " + values[key])
        }


        let {editMode, editRecord} = this.state
        let {editConfirmHd, addConfirmHd} = this.props

        if (editMode === 'add') {
            if (addConfirmHd) {
                for (let key in values) {
                    if (values[key]) {
                        editRecord[key] = values[key]
                    }
                }

                addConfirmHd(editRecord)
            }
        }else if (editMode === 'mod') {
            if (editConfirmHd) {
                editConfirmHd(editRecord)
            }
        }

        let newState = Object.assign({}, this.state)
        newState.editMode = undefined
        newState.showEdit = false
        newState.editRecord = {}
        this.setState(newState)
    }

    modalCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.editMode = undefined
        newState.showEdit = false
        this.setState(newState)
    }

    selectedHd(key, record) {
        let {editRecord} = this.state

        editRecord.CategoryName = record.Name
        editRecord.CategoryId = record.Id

        let newState = Object.assign({}, this.state)
        newState.showSelect = false
        this.setState(newState)
    }

    selectButtonOnClickHd() {
        let newState = Object.assign({}, this.state)
        newState.showSelect = true
        this.setState(newState)
    }

    selectModalCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.showSelect = false
        this.setState(newState)
    }

    mkSelectModal() {
        let {showSelect} = this.state
        return (
            <Modal 
            title=""
            visible={showSelect}
            width={1280}
            footer={null}
            onOk={undefined}
            onCancel={this.selectModalCancelHd}
            >
                <CertDstObjCategoryListCT 
                selectFlag={true}
                selectedHd={this.selectedHd}
                />

            </Modal>
        )
    }

    modalInputChangeHd(key, value) {
        let {editRecord} = this.state
        console.log("key = , value = ", key, value)

        editRecord[key] = value
        
        let newState = Object.assign({}, this.state)
        newState.editRecord = editRecord
        this.setState(newState)
    }

    onChangeHd(pagination, filters, sorter, extra) {
        let {pageChangeHd} = this.props
        if (extra === undefined) {
            return
        }

        if (extra.action === "paginate"){
            // 分页操作
            if (pageChangeHd === undefined || pagination === {}) {
                return
            }
            pageChangeHd(pagination.current, pagination.pageSize)
            return
        }
    }

    render() {
        let {pageChangeHd, totalNum, dataList, searchHd, loading} = this.props       
        let disModal = this.mkEditModal() 
        let selectModal = this.mkSelectModal()
        return (
            <div>
                
                    <HoBreadcrumb itemList={this._breadConf} extra={{style:{marginBottom: '8px'}}} />
                    <Card title="认证对象列表" extra={this.mkOpButtons()}>
                        {disModal}
                        {selectModal}
                        <HoTable columns={this._columns} 
                            size="middle" 
                            dataList={dataList}
                            searchHd={searchHd}
                            totalNum={totalNum}
                            pageChangeHd={pageChangeHd}
                            loading={loading}
                        />

                    </Card>
                

            </div>
        );
    }

}

