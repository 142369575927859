import {findItemByIdFromList} from '../../comm/logic'
import {actUpdate, actAdd, actDel} from '../../comm/commRD'

const ACT_CERT_UPDATE_CERTIFICATION = 'ACT_CERT_UPDATE_CERTIFICATION'
const ACT_CERT_CERTIFICATION_ADD = 'ACT_CERT_CERTIFICATION_ADD'
const ACT_CERT_CERTIFICATION_DEL = 'ACT_CERT_CERTIFICATION_DEL'

const ACT_CERT_UPDATE_TEST_CATEGORY = 'ACT_CERT_UPDATE_TEST_CATEGORY'
const ACT_CERT_TEST_CATEGORY_ADD = 'ACT_CERT_TEST_CATEGORY_ADD'
const ACT_CERT_TEST_CATEGORY_DEL = 'ACT_CERT_TEST_CATEGORY_DEL'

const ACT_CERT_UPDATE_TEST_STANDARD = 'ACT_CERT_UPDATE_TEST_STANDARD'
const ACT_CERT_TEST_STANDARD_ADD = 'ACT_CERT_TEST_STANDARD_ADD'
const ACT_CERT_TEST_STANDARD_DEL = 'ACT_CERT_TEST_STANDARD_DEL'

const ACT_CERT_UPDATE_TEST_CASE = 'ACT_CERT_UPDATE_TEST_CASE'
const ACT_CERT_TEST_CASE_ADD = 'ACT_CERT_TEST_CASE_ADD'
const ACT_CERT_TEST_CASE_DEL = 'ACT_CERT_TEST_CASE_DEL'

// 其他不定aciton 定义
const ACT_CERT_UPDATE_TEST_CASE_DETAIL = 'ACT_CERT_UPDATE_TEST_CASE_DETAIL'
const ACT_CERT_UPDATE_CERT_DETAIL = 'ACT_CERT_UPDATE_CERT_DETAIL'

const ACT_CERT_UPDATE_CERT_TEST_CATEGORY = 'ACT_CERT_UPDATE_CERT_TEST_CATEGORY'



const CertState = {
    certification: {TotalNum: 0, PageSize: 10, PageNum: 1, RecordList: []}, 
    testCategory: {TotalNum: 0, PageSize: 10, PageNum: 1, RecordList: []}, 
    testStandard: {TotalNum: 0, PageSize: 10, PageNum: 1, RecordList: []}, 
    testCase: {TotalNum: 0, PageSize: 10, PageNum: 1, RecordList: []}, 
    testCaseDetail: undefined, 
    certDetail: undefined, 
    certTestCategory: {TotalNum: 0, PageSize: 10, PageNum: 1, RecordList: []}, 
}

const getStateKeyByActionType = (actionType) => {
    switch(actionType) {
        case ACT_CERT_UPDATE_CERTIFICATION: 
        case ACT_CERT_CERTIFICATION_ADD: 
        case ACT_CERT_CERTIFICATION_DEL: 
            return "certification"

        case ACT_CERT_UPDATE_TEST_CATEGORY:
        case ACT_CERT_TEST_CATEGORY_ADD:
        case ACT_CERT_TEST_CATEGORY_DEL:
            return "testCategory"

        case ACT_CERT_UPDATE_TEST_STANDARD:
        case ACT_CERT_TEST_STANDARD_ADD:
        case ACT_CERT_TEST_STANDARD_DEL:
            return "testStandard"

        case ACT_CERT_UPDATE_TEST_CASE:
        case ACT_CERT_TEST_CASE_ADD:
        case ACT_CERT_TEST_CASE_DEL:
            return "testCase"

        case ACT_CERT_UPDATE_TEST_CASE_DETAIL:
            return "testCaseDetail"

        case ACT_CERT_UPDATE_CERT_DETAIL:
            return "certDetail"

        case ACT_CERT_UPDATE_CERT_TEST_CATEGORY:
            return "certTestCategory"

        default:
            return undefined
    }
}

const getStateOpByActionType = (actionType) => {
    switch(actionType) {
        case ACT_CERT_UPDATE_CERTIFICATION: 
        case ACT_CERT_UPDATE_TEST_CATEGORY:
        case ACT_CERT_UPDATE_TEST_STANDARD:
        case ACT_CERT_UPDATE_TEST_CASE:
        case ACT_CERT_UPDATE_TEST_CASE_DETAIL:
        case ACT_CERT_UPDATE_CERT_DETAIL: 
        case ACT_CERT_UPDATE_CERT_TEST_CATEGORY:
            return "update"

        case ACT_CERT_CERTIFICATION_ADD: 
        case ACT_CERT_TEST_CATEGORY_ADD:
        case ACT_CERT_TEST_STANDARD_ADD:
        case ACT_CERT_TEST_CASE_ADD:
            return "add"

        case ACT_CERT_CERTIFICATION_DEL: 
        case ACT_CERT_TEST_CATEGORY_DEL:
        case ACT_CERT_TEST_STANDARD_DEL:
        case ACT_CERT_TEST_CASE_DEL:
            return "del"

        default:
            return "other"
    }
}


export const cert_reducer = (state=CertState, action) => {
    let op = getStateOpByActionType(action.type)
    switch(op) {
        case "update":
        {
            let retState = Object.assign({}, state);
            let key = getStateKeyByActionType(action.type)
            retState[key] = action.data;
            return retState;
        }

        case "add":
        {
            let retState = Object.assign({}, state);
            let key = getStateKeyByActionType(action.type)
            let data = retState[key]
            
            let record = action.record
            let findRet = findItemByIdFromList(record.Id, 'Id', data.RecordList);
            if (findRet === undefined){
                data.RecordList.splice(0, 0, record);
            }
            else{
                data.RecordList.splice(findRet.index, 1);
                data.RecordList.splice(findRet.index, 0, record);
            }
            data.TotalNum = data.RecordList.length
            retState[key] = data
            return retState
        }

        case "del":
        {
            let retState = Object.assign({}, state);
            let key = getStateKeyByActionType(action.type)
            let data = retState[key]

            for (let index in data.RecordList) {
                let item = data.RecordList[index]
                if (item.Id === action.Id) {
                    data.RecordList.splice(index, 1);
                    data.TotalNum = data.RecordList.length
                    break;
                }
            }
            retState[key] = data
            return retState
        } 
        
        // 其他不定action处理
        // case "other":
        // {
        //     switch(action.type) {
        //         case ACT_CERT_UPDATE_TEST_CASE_DETAIL:
        //         {
        //             return retState
        //         }
                

        //     }
        // }

        default:
            return state
    }

}

// comm
const actCertUpdate = (data, actionType) => {
    return {
        type: actionType, 
        data: data, 
    }
}

const actCertAdd = (record, actionType) => {
    return {
        type: actionType, 
        record: record, 
    }
}

const actCertDel = (Id, actionType) => {
    return {
        type: actionType, 
        Id: Id, 
    }
}


// certification
export const getCertification = (state) => {
    if (state.cert.certification !== undefined){
        return state.cert.certification;
    }
    return undefined;
}

export const getCertificationListSuccessHd = (dispatch, rspBody, msgBody) => {
    let data = {
        TotalNum: rspBody.TotalNum, 
        RecordList: rspBody.RecordList, 
        PageSize: msgBody.PageSize, 
        PageNum: msgBody.PageNum, 
    }
    if (!rspBody.RecordList) {
        data.RecordList = []
    }
    dispatch(actCertUpdate(data, ACT_CERT_UPDATE_CERTIFICATION));
}

export const addCertificationSuccessHd = (dispatch, rspBody, msgBody) => {
    let record = {
        Id: rspBody.Id, 
        Scope: msgBody.Scope, 
        Name: msgBody.Name, 
        CName: msgBody.CName, 
        EName: msgBody.EName, 
        Display: msgBody.Display,
        Description: msgBody.Description,
        LogoUrl: msgBody.LogoUrl,
        DetailUrl: msgBody.DetailUrl,
        Remark: msgBody.Remark, 
    }
    dispatch(actCertAdd(record, ACT_CERT_CERTIFICATION_ADD));
}

export const delCertificationSuccessHd = (dispatch, rspBody, msgBody) => {
    dispatch(actCertDel(msgBody.Id, ACT_CERT_CERTIFICATION_DEL));
}

// testCategory
export const getTestCategory = (state) => {
    if (state.cert.testCategory !== undefined){
        return state.cert.testCategory;
    }
    return undefined;
}

export const getTestCategoryListSuccessHd = (dispatch, rspBody, msgBody) => {
    let data = {
        TotalNum: rspBody.TotalNum, 
        RecordList: rspBody.RecordList, 
        PageSize: msgBody.PageSize, 
        PageNum: msgBody.PageNum, 
    }
    if (!rspBody.RecordList) {
        data.RecordList = []
    }
    dispatch(actCertUpdate(data, ACT_CERT_UPDATE_TEST_CATEGORY));
}

export const addTestCategorySuccessHd = (dispatch, rspBody, msgBody) => {
    let record = {
        Id: rspBody.Id, 
        Name: msgBody.Name, 
        CName: msgBody.CName, 
        EName: msgBody.EName, 
        Display: msgBody.Display, 
        Description: msgBody.Description, 
        LogoUrl: msgBody.LogoUrl, 
        DetailUrl: msgBody.DetailUrl, 
        Remark: msgBody.Remark, 
    }
    dispatch(actCertAdd(record, ACT_CERT_TEST_CATEGORY_ADD));
}

export const delTestCategorySuccessHd = (dispatch, rspBody, msgBody) => {
    dispatch(actCertDel(msgBody.Id, ACT_CERT_TEST_CATEGORY_DEL));
}

// testStandard
export const getTestStandard = (state) => {
    if (state.cert.testStandard !== undefined){
        return state.cert.testStandard;
    }
    return undefined;
}

export const getTestStandardListSuccessHd = (dispatch, rspBody, msgBody) => {
    let data = {
        TotalNum: rspBody.TotalNum, 
        RecordList: rspBody.RecordList, 
        PageSize: msgBody.PageSize, 
        PageNum: msgBody.PageNum, 
    }
    if (!rspBody.RecordList) {
        data.RecordList = []
    }
    dispatch(actCertUpdate(data, ACT_CERT_UPDATE_TEST_STANDARD));
}

export const addTestStandardSuccessHd = (dispatch, rspBody, msgBody) => {
    let record = {
        Id: rspBody.Id, 
        Name: msgBody.Name, 
        CName: msgBody.CName, 
        EName: msgBody.EName, 
        Display: msgBody.Display, 
        Description: msgBody.Description, 
        LogoUrl: msgBody.LogoUrl, 
        DetailUrl: msgBody.DetailUrl, 
        Remark: msgBody.Remark, 
    }
    dispatch(actCertAdd(record, ACT_CERT_TEST_STANDARD_ADD));
}

export const delTestStandardSuccessHd = (dispatch, rspBody, msgBody) => {
    dispatch(actCertDel(msgBody.Id, ACT_CERT_TEST_STANDARD_DEL));
}

// testCase
export const getTestCase = (state) => {
    if (state.cert.testCase !== undefined){
        return state.cert.testCase;
    }
    return undefined;
}

export const getTestCaseListSuccessHd = (dispatch, rspBody, msgBody) => {
    let data = {
        TotalNum: rspBody.TotalNum, 
        RecordList: rspBody.RecordList, 
        PageSize: msgBody.PageSize, 
        PageNum: msgBody.PageNum, 
    }
    if (!rspBody.RecordList) {
        data.RecordList = []
    }
    dispatch(actCertUpdate(data, ACT_CERT_UPDATE_TEST_CASE));
}

export const addTestCaseSuccessHd = (dispatch, rspBody, msgBody) => {
    let record = {
        Id: rspBody.Id, 
        CategoryId: msgBody.CategoryId, 
        CategoryName: msgBody.CategoryName, 
        StandardId: msgBody.StandardId, 
        StandardName: msgBody.StandardName, 
        Name: msgBody.Name, 
        Remark: msgBody.Remark, 
    }
    dispatch(actCertAdd(record, ACT_CERT_TEST_CASE_ADD));
}

export const delTestCaseSuccessHd = (dispatch, rspBody, msgBody) => {
    dispatch(actCertDel(msgBody.Id, ACT_CERT_TEST_CASE_DEL));
}

// testCaseDetail
export const getTestCaseDetail = (state) => {
    if (state.cert.testCaseDetail !== undefined){
        return state.cert.testCaseDetail;
    }
    return undefined;
}

export const getTestCaseDetailSuccessHd = (dispatch, rspBody, msgBody) => {
    let data = {
        Id: rspBody.Id, 
        CategoryId: rspBody.CategoryId, 
        CategoryName: rspBody.CategoryName, 
        StandardId: rspBody.StandardId, 
        StandardName: rspBody.StandardName, 
        Name: rspBody.Name, 
        IsDefault: rspBody.IsDefault, 
        Seq: rspBody.Seq, 
        Remark: rspBody.Remark, 
        AllowParamList: rspBody.AllowParamList, 
    }
    dispatch(actCertUpdate(data, ACT_CERT_UPDATE_TEST_CASE_DETAIL));
}

// certDetail
export const getCertDetail = (state) => {
    if (state.cert.certDetail !== undefined){
        return state.cert.certDetail;
    }
    return undefined;
}

export const getCertDetailSuccessHd = (dispatch, rspBody, msgBody) => {
    let data = {
        CertInfo: rspBody.CertInfo, 
        CertTestCategoryList: rspBody.CertTestCategoryList, 
    }
    dispatch(actUpdate(data, ACT_CERT_UPDATE_CERT_DETAIL));
}

// certTestCategory
export const getCertTestCategory = (state) => {
    if (state.cert.certTestCategory !== undefined){
        return state.cert.certTestCategory;
    }
    return undefined;
}

export const getCertTestCategoryListSuccessHd = (dispatch, rspBody, msgBody) => {
    let data = {
        TotalNum: rspBody.TotalNum, 
        RecordList: rspBody.RecordList, 
        PageSize: msgBody.PageSize, 
        PageNum: msgBody.PageNum, 
    }
    if (!rspBody.RecordList) {
        data.RecordList = []
    }
    dispatch(actUpdate(data, ACT_CERT_UPDATE_CERT_TEST_CATEGORY));
}

