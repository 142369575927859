import React, { Component } from 'react';

import {Card, Row, Col, Button, Popconfirm, Space, Form, Input, Modal, Select, Tag } from 'antd'
import {SyncOutlined, PlusOutlined, CheckOutlined} from '@ant-design/icons'

import {HoBreadcrumb} from '../../component/element'
import {HoTable, HoMkTblColumn, HoMkTblColActions} from '../../component/tbl'
import {yesOrNoMap, calcFromFields} from '../../comm/logic'
import FeatureSubcategoryCT from './featureSubcategoryCT'


export class FeaturePage extends Component {
    constructor(props){
        super(props)

        this.mkOpButtons = this.mkOpButtons.bind(this)
        this.addOnclickHd = this.addOnclickHd.bind(this)
        this.mkEditModal = this.mkEditModal.bind(this)
        this.modalCancelHd = this.modalCancelHd.bind(this)
        this.modalConfirmHd = this.modalConfirmHd.bind(this)
        this.editOnclickHd = this.editOnclickHd.bind(this)
        this.mkSelectSubcategoryModal = this.mkSelectSubcategoryModal.bind(this)
        this.subcategorySelectedHd = this.subcategorySelectedHd.bind(this)
        this.selectSubcategoryButtonOnClickHd = this.selectSubcategoryButtonOnClickHd.bind(this)
        this.selectModalCancelHd = this.selectModalCancelHd.bind(this)

        

        let {deleteOnClickHd, delConfirmHd, selectFlag, selectedHd} = props
        this.state = {
            showEdit: false, 
            editMode: undefined,
            editRecord: {}, 
            showSubcategorySelect: false, 
        }

        this._form = undefined

        this._editRecord = {}


        this._breadConf = [
            {name: "产品信息", link: '/setting/productObjCategory'}, 
            {name: "附加特性", link: undefined}, 
        ]

        const disDelete = (
            <Popconfirm title="请确认是否要删除？" 
            onConfirm={(e) => delConfirmHd ? delConfirmHd(e, 'confirm') : undefined}
            onCancel={(e) => delConfirmHd ? delConfirmHd(e, 'cancel') : undefined}
            >
                <span>删除</span>
            </Popconfirm>
        )

        let actionContent = HoMkTblColActions([{key: 'edit', display: '编辑', onClickedHd: this.editOnclickHd}, 
                                               {key: 'delete', display: disDelete, onClickedHd: deleteOnClickHd}, ])
        if (selectFlag) {
            actionContent = HoMkTblColActions([{key: 'select', display: '选择', onClickedHd: selectedHd}])
        }

        this._columns = [
            HoMkTblColumn('特性名称', 'Name', 'str', '10%', true, false), 
            HoMkTblColumn('遵循标准', 'StandardList', 'str', '10%', true, false, this.standardMap), 
            // HoMkTblColumn('显示名称', 'DisplayName', 'str', '10%', true, false), 
            HoMkTblColumn('特性类别', 'CategoryName', 'str', '10%', true, false), 
            HoMkTblColumn('特性子类别', 'SubcategoryName', 'str', '10%', true, false), 
            HoMkTblColumn('描述', 'Description', 'str', '10%', true, false), 
            HoMkTblColumn('周期', 'Period', 'number', '8%', true, false), 
            HoMkTblColumn('价格', 'Price', 'number', '8%', true, false), 
            HoMkTblColumn('默认选中', 'IsDefault', 'number', '8%', false, false, yesOrNoMap), 
            HoMkTblColumn('备注', 'Remark', 'str', '8%', true, false), 
            actionContent, 
        ]
    }
    
    mkOpButtons() {
        let {refreshHd, addOnclickHd} = this.props
        return (
            <Row>
                <Space>
                    <Button type="primary" icon={<PlusOutlined />} onClick={this.addOnclickHd}>新增</Button>
                    <Button type="default" icon={<SyncOutlined />} onClick={refreshHd}>刷新</Button>
                </Space>
            </Row>
        )
    }

    standardMap(standardList) {
        let display = undefined
        if (standardList) {
            display = standardList.map(
                (item, index) => (
                    <Row key={index}>
                        {item.Name}
                    </Row>
                )
            )
        }
        
        return display
    }

    editOnclickHd(key, record) {
        let newState = Object.assign({}, this.state)
        newState.editMode = "mod"
        newState.showEdit = true
        newState.editRecord = record
        this.setState(newState)
    }

    selectSubcategoryButtonOnClickHd() {
        let newState = Object.assign({}, this.state)
        newState.showSubcategorySelect = true
        this.setState(newState)
    }

    subcategorySelectedHd(key, record) {
        let {editRecord} = this.state

        editRecord.CategoryId = record.CategoryId
        editRecord.CategoryName = record.CategoryName
        editRecord.SubcategoryId = record.SubcategoryId
        editRecord.SubcategoryName = record.SubcategoryName

        let newState = Object.assign({}, this.state)
        newState.showSubcategorySelect = false
        newState.editRecord = editRecord
        this.setState(newState)
    }

    selectModalCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.showSubcategorySelect = false
        this.setState(newState)
    }

    mkSelectSubcategoryModal() {
        let {showSubcategorySelect} = this.state
        return (
            <Modal 
            title=""
            visible={showSubcategorySelect}
            width={1280}
            footer={null}
            onOk={undefined}
            onCancel={this.selectModalCancelHd}
            >
                <FeatureSubcategoryCT 
                selectFlag={true}
                selectedHd={this.subcategorySelectedHd}
                />

            </Modal>
        )
    }

    mkEditModal() {
        let {showEdit, editRecord} = this.state
        let {standardList} = this.props

        let StandardOptions = undefined
        if (standardList) {
            StandardOptions = standardList.RecordList.map(
                (item, index) => (
                    <Select.Option key={index} value={item.Id}>{item.Name}</Select.Option>
                )
            )
        }
        
        let srcKeys = ["CategoryName", "SubcategoryName", "StandardList", "Name", "DisplayName", "Description", "Period", "Price", "IsDefault", "Remark"]

        let dstFormValues = calcFromFields(srcKeys, editRecord)
        if (dstFormValues.StandardList) {
            // 要转换下值
            let standardIds = []
            for (let item of dstFormValues.StandardList) {
                standardIds.push(item.Id)
            }
            dstFormValues.StandardList = standardIds
        }

        if (this._form) {
            this._form.setFieldsValue(dstFormValues)
        }

        let content = (
            <Form onFinish={(values) => this.modalConfirmHd(values)}
            labelCol={{span: 4}}
            wrapperCol={{span: 20}}
            ref={node => this._form = node}
            initialValues={dstFormValues}
            >
                <Form.Item rules={[{required: true, message: '请选择特性所属类别信息!'}]} label="特性类别" name="CategoryName">
                    <Row justify="start">
                        
                        <Col span={14}>
                        <Input placeholder="请点击右边按钮选择类别信息" disabled/>
                        </Col>

                        <Col push={2} span={6}>
                        <Button type="primary" icon={<CheckOutlined />} onClick={this.selectSubcategoryButtonOnClickHd} >选择类别信息</Button>
                        </Col>

                    </Row>    
                </Form.Item>

                <Form.Item label="特性子类别" name="SubcategoryName">
                    <Input disabled /> 
                </Form.Item>

                <Form.Item label="关联标准" name="StandardList">
                        <Select 
                        mode="multiple"
                        showArrow
                        >
                            {StandardOptions}
                        </Select>
                </Form.Item>

                <Form.Item label="特性名称" name="Name" rules={[{required: true, message: '请输入特性名称!'}]}>
                    <Input  /> 
                </Form.Item>

                <Form.Item label="显示名称" name="DisplayName">
                    <Input  /> 
                </Form.Item>

                <Form.Item label="描述" name="Description">
                    <Input  /> 
                </Form.Item>

                <Form.Item label="周期（工作日）" name="Period">
                    <Input  /> 
                </Form.Item>

                <Form.Item label="价格" name="Price">
                    <Input  /> 
                </Form.Item>

                <Form.Item name="IsDefault" label="默认选中" >
                    {/* <Select defaultValue={editRecord.IsDefault ? editRecord.IsDefault : 1}> */}
                    <Select >
                        <Select.Option value={1}>是</Select.Option>
                        <Select.Option value={0}>否</Select.Option> 
                    </Select>
                </Form.Item>

                <Form.Item name="Remark" label="备注" >
                    <Input />
                </Form.Item>
                    
                <Row justify="end">
                    <Space size="middle" >
                    <Button type="default" onClick={() => this.modalCancelHd()}>取消</Button>
                    <Button type="primary" onClick={undefined} htmlType="submit">保存</Button>
                    </Space>
                </Row>
            </Form>
        )

        return (
            <Modal 
                title="附加特性"
                visible={showEdit}
                onCancel={this.modalCancelHd}
                okText="保存" cancelText="取消"
                footer={null}
                width={620}
                >
                {content}
                   
            </Modal>
        )
    }

    modalConfirmHd(values) {
        let {editMode, editRecord} = this.state
        let {editConfirmHd, addConfirmHd} = this.props

        console.log("######: values = ", values)

        let result = {}
        result.Id = editRecord.Id
        result.SubCategoryId = editRecord.SubcategoryId
        result.StandardList = values.StandardList
        result.Name = values.Name
        result.DisplayName = values.DisplayName
        result.Description = values.Description
        result.Period = parseFloat(values.Period)
        result.Price = parseFloat(values.Price)
        result.IsDefault = values.IsDefault
        result.Remark = values.Remark

        if (editMode === 'add') {
            if (addConfirmHd) {
                addConfirmHd(result)
            }
        }else if (editMode === 'mod') {
            if (editConfirmHd) {
                editConfirmHd(result)
            }
        }

        let newState = Object.assign({}, this.state)
        newState.editMode = undefined
        newState.showEdit = false
        newState.editRecord = {}
        this.setState(newState)
    }

    addOnclickHd() {
        let newState = Object.assign({}, this.state)
        newState.editMode = "add"
        newState.showEdit = true
        this.setState(newState)
    }

    modalCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.editMode = undefined
        newState.showEdit = false
        newState.editRecord = {}
        this.setState(newState)
    }

   
    render() {
        let {pageChangeHd, totalNum, dataList, searchHd, loading} = this.props   
        let editModal = this.mkEditModal()
        let subcategoryModal = this.mkSelectSubcategoryModal()
        return (
            <div>
                <HoBreadcrumb itemList={this._breadConf} extra={{style:{marginBottom: '8px'}}} />
                {editModal}
                {subcategoryModal}
                <Card title="附加特性列表" extra={this.mkOpButtons()}>
                    <HoTable columns={this._columns} 
                        size="middle" 
                        dataList={dataList}
                        searchHd={searchHd}
                        totalNum={totalNum}
                        pageChangeHd={pageChangeHd}
                        loading={loading}
                    />
                </Card>
            </div>
        );
    }

}

