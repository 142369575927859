import React, { Component } from 'react'
import { connect } from 'react-redux'
import {getNetRequestStatus, netRequestThunk, actNetRequestClear} from '../../network/netReqRD'
import {getCertTestCategory, getCertTestCategoryListSuccessHd} from './certRD'
import {getLoginTokenInfo} from '../account/loginRD'
import {CertRelateTestCategoryListPage} from './certTestCategory'
import {HoBuildTableData} from '../../component/utils'
import {HoMkSearchFilters} from '../../component/tbl'

class CertRelateTestCategoryCT extends Component {
    constructor(props){
        super(props)
        this.pageChangeHd = this.pageChangeHd.bind(this)
        this.tokenCheckHd = this.tokenCheckHd.bind(this)
        this.refreshHd = this.refreshHd.bind(this)
        this.searchHd = this.searchHd.bind(this)
        this.requestData = this.requestData.bind(this)
        this.getLoadingFlag = this.getLoadingFlag.bind(this)

        this._pagination = {
            PageSize: 50, 
            PageNum: 1, 
            Filters: [[]], 
            OrderKey: "", 
            Desc: 1, 
        }

        this.state = {
            PageSize: this._pagination.PageSize, 
            PageNum: this._pagination.PageNum, 
        }

    }

    tokenCheckHd() {
        let {tokenInfo, history} = this.props
        if (tokenInfo === undefined) {
            const directPath = '/'
            history.push(directPath)
            return false
        }
        return true
    }

    requestData() {
        if (!this.tokenCheckHd()) {
            return
        }
        let {PageSize, PageNum, Filters, OrderKey, Desc} = this._pagination
        let {dispatch, tokenInfo} = this.props
        let msgBody = {
            PageSize: PageSize, 
            PageNum: PageNum,
            Filters: Filters, 
            OrderKey: OrderKey, 
            Desc: Desc
        }
        dispatch(netRequestThunk("/hq/v1/mntn/cert/certTestCategory/list", msgBody, "getFeatureList", 
        getCertTestCategoryListSuccessHd, tokenInfo.token))
    }

    componentWillUnmount(){
        let {dispatch} = this.props
        dispatch(actNetRequestClear("getCertTestCategoryList"))
    }

    componentDidMount() {
        this.requestData()
    }

    refreshHd() {
        this.requestData()
    }

    pageChangeHd(current, size) {
        if (current !== this._pagination.PageNum || size !== this._pagination.PageSize) {
            this._pagination.PageNum = current
            this._pagination.PageSize = size
            // 需要重新请求数据
            this.requestData()
            // set state
            let newState = Object.assign({}, this.state)
            newState.PageNum = current
            newState.PageSize = size
            this.setState(newState)
        }
    }

    searchHd(searchInfo) {
        // let {filterInfo} = this.props

        // console.log("#######: searchInfo = ", searchInfo)


        let filters = HoMkSearchFilters(searchInfo)
        this._pagination.Filters = filters
        this._pagination.PageNum = 1

        // 这里进行网络请求
        this.requestData()
    }


    getLoadingFlag() {
        let {reqStatus} = this.props

        let isLoading = false
        if (reqStatus === 1 ) {
            isLoading = true
        } 

        return isLoading
    }

    
    render(){
        let {dataInfo, selectFlag, selectedHd, categorys} = this.props
        let {PageNum, PageSize} = this.state
        let totalNum = 0
        let dataList = undefined
        if (dataInfo !== undefined) {
            totalNum = dataInfo.TotalNum
            dataList = HoBuildTableData(dataInfo.RecordList)
        }

        let isLoading = this.getLoadingFlag()
        
        return (
            <CertRelateTestCategoryListPage 
            pageChangeHd={this.pageChangeHd}
            totalNum={totalNum}
            dataList={dataList}
            refreshHd={this.refreshHd}
            searchHd={this.searchHd}
            pageNum={PageNum}
            pageSize={PageSize}
            loading={isLoading}
            selectFlag={selectFlag}
            selectedHd={selectedHd}
            />
        );
    }
}

const mapState = (state) => ({
    dataInfo: getCertTestCategory(state), 
    tokenInfo: getLoginTokenInfo(state), 
    reqStatus: getNetRequestStatus(state, 'getCertTestCategoryList'), 
});

export default connect(
    mapState, 
    null
)(CertRelateTestCategoryCT);


