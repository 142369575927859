

export const actUpdate = (data, actionType) => {
    return {
        type: actionType, 
        data: data, 
    }
}

export const actAdd = (record, actionType) => {
    return {
        type: actionType, 
        record: record, 
    }
}

export const actDel = (Id, actionType) => {
    return {
        type: actionType, 
        Id: Id, 
    }
}
