import React, { Component } from 'react'
import { connect } from 'react-redux'
import {getNetRequestStatus, netRequestThunk, actNetRequestClear} from '../../network/netReqRD'
import {getCertDetail, getCertDetailSuccessHd} from './certRD'
import {getLoginTokenInfo} from '../account/loginRD'
import {CertDetailPage} from './certDetail'
import {HoBuildTableData} from '../../component/utils'
import {HoMkSearchFilters} from '../../component/tbl'

class CertDetailCT extends Component {
    constructor(props){
        super(props)
        this.pageChangeHd = this.pageChangeHd.bind(this)
        this.tokenCheckHd = this.tokenCheckHd.bind(this)
        this.searchHd = this.searchHd.bind(this)
        this.requestData = this.requestData.bind(this)
        this.deleteOnClickHd = this.deleteOnClickHd.bind(this)
        this.delConfirmHd = this.delConfirmHd.bind(this)
        this.getLoadingFlag = this.getLoadingFlag.bind(this)
        this.addOnclickHd = this.addOnclickHd.bind(this)
        this.detailOnclickHd = this.detailOnclickHd.bind(this)
        this.addConfirmHd = this.addConfirmHd.bind(this)
        this.editConfirmHd = this.editConfirmHd.bind(this)

        

        let {match} = props
        let recordId = undefined
        if (match) {
            if (match.params) {
                recordId = parseInt(match.params.certId)
            }
            console.log("@@@@@: recordId = ", recordId)
        }

        
        this._pagination = {
            PageSize: 50, 
            PageNum: 1, 
            Filters: [[]], 
            OrderKey: "", 
            Desc: 1, 
        }

        this.state = {
            recordId: recordId, 
            PageSize: this._pagination.PageSize, 
            PageNum: this._pagination.PageNum, 
        }

        this._delRecord = undefined

    }

    tokenCheckHd() {
        console.log("@@@@@_2")
        let {tokenInfo, history} = this.props
        if (tokenInfo === undefined) {
            const directPath = '/'
            history.push(directPath)
            return false
        }
        return true
    }

    requestData() {
        if (!this.tokenCheckHd()) {
            return
        }

        let {recordId} = this.state

        let {dispatch, tokenInfo} = this.props
        let msgBody = {
            Id: recordId, 
        }

        console.log("@@@@@: msgBody = " + msgBody)

        dispatch(netRequestThunk("/hq/v1/mntn/cert/certification/detail", msgBody, "getCertDetail", getCertDetailSuccessHd, tokenInfo.token))
    }

    componentWillUnmount(){
        let {dispatch} = this.props
        dispatch(actNetRequestClear("getCertDetail"))
        dispatch(actNetRequestClear("addCertTestCategory"))
        dispatch(actNetRequestClear("modCertTestCategory"))
        dispatch(actNetRequestClear("delCertTestCategory"))
    }

    componentDidMount() {
        this.requestData()
    }


    pageChangeHd(current, size) {
        if (current !== this._pagination.PageNum || size !== this._pagination.PageSize) {
            this._pagination.PageNum = current
            this._pagination.PageSize = size
            // 需要重新请求数据
            this.requestData()
            // set state
            let newState = Object.assign({}, this.state)
            newState.PageNum = current
            newState.PageSize = size
            this.setState(newState)
        }
    }

    searchHd(searchInfo) {
        let filters = HoMkSearchFilters(searchInfo)
        this._pagination.Filters = filters
        this._pagination.PageNum = 1

        // 这里进行网络请求
        this.requestData()
    }

    addConfirmHd(record) {
        console.log("@@@@@_1")

        if (!this.tokenCheckHd()) {
            return
        }

        
        let {dispatch, tokenInfo} = this.props
        let {recordId} = this.state
        let msgBody = {
            CertId: recordId, 
            TestCategoryId: record.TestCategoryId, 
            IsDefault: record.IsDefault, 
        }

        console.log("@@@ msgBody = ", msgBody)

        dispatch(netRequestThunk("/hq/v1/mntn/cert/certTestCategory/add", msgBody, "addCertTestCategory", undefined, tokenInfo.token))
    }

    editConfirmHd(record) {
        if (!this.tokenCheckHd()) {
            return
        }
        let {dispatch, tokenInfo} = this.props
        let msgBody = {
            Id: record.Id, 
            CertId: record.CertId, 
	        TestCategoryId: record.TestCategoryId, 
	        IsDefault: record.IsDefault, 
        }

        dispatch(netRequestThunk("/hq/v1/mntn/cert/certTestCategory/mod", msgBody, "modCertTestCategory", undefined, tokenInfo.token))
    }

    deleteOnClickHd(key, record) {
        this._delRecord = record
    }

    delConfirmHd(e, type) {
        e.stopPropagation();
        if (type === 'confirm') {
            if (this._delRecord !== undefined) {
                if (!this.tokenCheckHd()) {
                    this._delRecord = undefined  
                    return
                }

                let {dispatch, tokenInfo} = this.props
                let msgBody = {
                    Id: this._delRecord.Id, 
                }
                dispatch(netRequestThunk("/hq/v1/mntn/cert/certTestCategory/del", msgBody, "delCertTestCategory", undefined, tokenInfo.token))
            }
        }

        this._delRecord = undefined        
    }


    getLoadingFlag() {
        let {reqStatus, addStatus, modStatus, delStatus, dispatch} = this.props

        let isLoading = false
        if (reqStatus === 1 || addStatus === 1 || modStatus === 1 || delStatus === 1) {
            isLoading = true
        } 


        if (addStatus === 2) {
            dispatch(actNetRequestClear("addCertTestCategory"))
            this.requestData()
            return isLoading
        }

        if (modStatus === 2) {
            dispatch(actNetRequestClear("modCertTestCategory"))
            this.requestData()
            return isLoading
        }

        if (delStatus === 2) {
            dispatch(actNetRequestClear("delCertTestCategory"))
            this.requestData()
            return isLoading
        }

        return isLoading
    }

    addOnclickHd() {
        let {history} = this.props
        if (history) {
            let url = "/setting/certTestCase/detail/"
            history.push(url)
        }
    }


    detailOnclickHd(key, record) {
        let {history} = this.props
            if (history) {
                let url = "/setting/certTestCase/detail/" + record.Id
                history.push(url)
            }
    }

    render(){
        let {detailInfo} = this.props

        let isLoading = this.getLoadingFlag()
        
        return (
            <CertDetailPage 
            detailInfo={detailInfo}
            editConfirmHd={this.editConfirmHd}
            addConfirmHd={this.addConfirmHd}
            deleteOnClickHd={this.deleteOnClickHd}
            delConfirmHd={this.delConfirmHd}
            isLoading={isLoading}
            
            />
        );
    }
}

const mapState = (state) => ({
    detailInfo: getCertDetail(state), 
    tokenInfo: getLoginTokenInfo(state), 
    reqStatus: getNetRequestStatus(state, 'getCertDetail'), 
    addStatus: getNetRequestStatus(state, 'addCertTestCategory'), 
    modStatus: getNetRequestStatus(state, 'modCertTestCategory'), 
    delStatus: getNetRequestStatus(state, 'delCertTestCategory'), 
    
});

export default connect(
    mapState, 
    null
)(CertDetailCT);


