import React, { Component } from 'react';

import {Card, Row, Col, Button, Popconfirm, Space, Modal, Descriptions, Select, 
    Tag, Form, Input } from 'antd'
import {SyncOutlined, PlusOutlined} from '@ant-design/icons'

import {HoBreadcrumb} from '../../component/element'
import {HoTable, HoMkTblColumn, HoMkTblColActions} from '../../component/tbl'

import {cert_scopes} from '../../comm/config'
import {orderStateMap} from '../../comm/logic'


export class OrderListPage extends Component {
    constructor(props){
        super(props)

        this.mkOpButtons = this.mkOpButtons.bind(this)

        let {detailOnclickHd} = props
        this.state = {
            showEdit: false, 
            editMode: undefined,
            editRecord: {}, 
        }

        this._breadConf = [
            {name: "订单管理", link: undefined}, 
            {name: "订单列表", link: undefined}, 
        ]

        let actionContent = HoMkTblColActions([{key: 'detail', display: '详情', onClickedHd: detailOnclickHd}, 
                                               ])


        this._columns = [
            HoMkTblColumn('客户手机', 'PhoneNum', 'str', '8%', true, false), 
            HoMkTblColumn('客户昵称', 'NickName', 'str', '8%', true, false), 
            HoMkTblColumn('产品类别', 'ObjCategoryName', 'str', '8%', true, false), 
            HoMkTblColumn('产品名称', 'ObjName', 'str', '10%', true, false), 
            HoMkTblColumn('价格(元)', 'Price', 'number', '8%', false, false), 
            HoMkTblColumn('周期（工作日）', 'Period', 'str', '8%', true, false), 
            HoMkTblColumn('状态', 'State', 'str', '8%', false, false, orderStateMap), 
            HoMkTblColumn('型号', 'Model', 'str', '8%', true, false, orderStateMap), 
            // HoMkTblColumn('备注', 'Remark', 'str', '8%', false, false), 
            HoMkTblColumn('创建时间', 'CreateTime', 'number', '10%', true, false), 
            actionContent, 
        ]

    }

    
    mkOpButtons() {
        let {refreshHd} = this.props
        return (
            <Row>
                <Space>
                    <Button type="default" icon={<SyncOutlined />} onClick={refreshHd}>刷新</Button>
                </Space>
            </Row>
        )
    }


    render() {
        let {pageChangeHd, totalNum, dataList, searchHd, loading} = this.props       
        return (
            <div>
                <HoBreadcrumb itemList={this._breadConf} extra={{style:{marginBottom: '8px'}}} />
                <Card title="订单列表" extra={this.mkOpButtons()}>
                    <HoTable columns={this._columns} 
                        size="middle" 
                        dataList={dataList}
                        searchHd={searchHd}
                        totalNum={totalNum}
                        pageChangeHd={pageChangeHd}
                        loading={loading}
                    />
                </Card>
            </div>
        );
    }

}

