import React, { Component } from 'react';

import {Card, Row, Col, Button, Popconfirm, Space, Divider, Typography, Descriptions, Form, Input, Modal, Select } from 'antd'
import {SyncOutlined, PlusOutlined, EditOutlined, SaveOutlined, CheckOutlined} from '@ant-design/icons'


import {HoBreadcrumb} from '../../component/element'
import {HoTable, HoMkTblColumn, HoMkTblColActions} from '../../component/tbl'
import CertTestCategoryListCT from './testCategoryCT'


export class CertDetailPage extends Component {
    constructor(props){
        super(props)

        this.editOnclickHd = this.editOnclickHd.bind(this)
        this.mkTitleContent = this.mkTitleContent.bind(this)
        this.mkContent = this.mkContent.bind(this)
        this.mkEditModal = this.mkEditModal.bind(this)
        this.modalCancelHd = this.modalCancelHd.bind(this)
        this.addOnclickHd = this.addOnclickHd.bind(this)
        this.modalConfirmHd = this.modalConfirmHd.bind(this)
        this.categorySelectedHd = this.categorySelectedHd.bind(this)
        this.mkSelectCategoryModal = this.mkSelectCategoryModal.bind(this)
        this.selectModalCancelHd = this.selectModalCancelHd.bind(this)
        this.selectCategoryButtonOnClickHd = this.selectCategoryButtonOnClickHd.bind(this)


        let {deleteOnClickHd, delConfirmHd} = props
        this.state = {
            editMode: undefined,
            editRecord: {}, 
            showEdit: false, 
            showCategorySelect: false, 
        }

        this._form = undefined

        this._breadConf = [
            {name: "认证信息", link: '/setting/certCertification'}, 
            {name: "认证详情", link: undefined }, 
        ]

        const disDelete = (
            <Popconfirm title="请确认是否要删除？" 
            onConfirm={(e) => delConfirmHd ? delConfirmHd(e, 'confirm') : undefined}
            onCancel={(e) => delConfirmHd ? delConfirmHd(e, 'cancel') : undefined}
            >
                <span>删除</span>
            </Popconfirm>
        )

        let actionContent = HoMkTblColActions([{key: 'edit', display: '编辑', onClickedHd: this.editOnclickHd}, 
                                                {key: 'delete', display: disDelete, onClickedHd: deleteOnClickHd}, ])

        this._columns = [
        HoMkTblColumn('检测大类', 'CategoryName', 'str', '30%', false, false), 
        HoMkTblColumn('默认选中', 'IsDefault', 'number', '30%', false, false, this.isDefaultMap), 
        actionContent, 
        ]
    }
    
    
    editOnclickHd(key, record) {
        console.log("@@@@: record = ", record)
        let newtSate = Object.assign({}, this.state)
        newtSate.editMode = "mod"
        newtSate.editRecord = record
        newtSate.showEdit = true
        this.setState(newtSate)
    }

    isDefaultMap(isDefault) {
        if (isDefault === 1) {
            return "是"
        }else{
            return "否"
        }
    }


    mkTitleContent() {
        let {detailInfo} = this.props
        if (!detailInfo) {
            return undefined
        }

        if (!detailInfo.CertInfo){
            return undefined
        }

        let {Scope, Name, CName, EName, Display, Description, LogoUrl,  DetailUrl, Remark} = detailInfo.CertInfo

        let content = (
            <Descriptions title="认证信息概述" column={2}>
                <Descriptions.Item label="认证类型">{Name}</Descriptions.Item>
                <Descriptions.Item label="地域">{Scope}</Descriptions.Item>
                <Descriptions.Item label="中文名">{CName}</Descriptions.Item>
                <Descriptions.Item label="英文名">{EName}</Descriptions.Item>
                <Descriptions.Item label="概述">{Description}</Descriptions.Item>
                <Descriptions.Item label="备注">{Remark}</Descriptions.Item>
                <Descriptions.Item label="logo地址">{LogoUrl}</Descriptions.Item>
                <Descriptions.Item label="介绍网页地址">{DetailUrl}</Descriptions.Item>
                <Descriptions.Item label="是否显示">{Display === 1 ? "是" : "否"}</Descriptions.Item>
            </Descriptions>
        )
        return content  
    }   

    mkContent() {
        let {detailInfo, loading} = this.props
        let dataList = undefined
        if (detailInfo) {
            dataList = detailInfo.CertTestCategoryList
        }

        return (
            <HoTable columns={this._columns} 
                            size="middle" 
                            dataList={dataList}
                            loading={loading}
                            noPagination={true}
            />
        )

    }

    modalCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.editMode = undefined
        newState.showEdit = false
        newState.editRecord = {}
        this.setState(newState) 
    }

    addOnclickHd() {
        let newState = Object.assign({}, this.state)
        newState.editMode = "add"
        newState.showEdit = true
        this.setState(newState) 
    }


    modalConfirmHd(values) {
        let {editRecord, editMode} = this.state
        let {editConfirmHd, addConfirmHd} = this.props
        console.log("modalConfirmHd: editRecord = ", editRecord)
        console.log("modalConfirmHd: values = ", values)

        // for (let key in values) {
        //     console.log("key = " + key + ", value = " + values[key])
        // }

        let result = {
            Id: editRecord.Id, 
            CertId: editRecord.CertId, 
            TestCategoryId: editRecord.TestCategoryId, 
            IsDefault: values.IsDefault, 
        }

        if (editMode === 'add') {
            if (addConfirmHd){
                addConfirmHd(result)
            }

        }else if (editMode === 'mod') {
            if (editConfirmHd) {
                editConfirmHd(result)
            }
        }

        let newState = Object.assign({}, this.state)
        newState.editMode = undefined
        newState.showEdit = false
        newState.editRecord = {}
        this.setState(newState)
    }

    mkEditModal() {
        let {showEdit, editRecord} = this.state

        if (this._form) {
            this._form.setFieldsValue(
                {
                    CategoryName: editRecord.CategoryName, 
                    IsDefault: editRecord.IsDefault ? editRecord.IsDefault : 1, 
                }
            )
        }
    
        let content = (
            <Form onFinish={(values) => this.modalConfirmHd(values)} 
            labelCol={{span: 4}} wrapperCol={{span: 20}}
            ref={node => this._form = node}
            initialValues={
                {
                    CategoryName: editRecord.CategoryName, 
                    IsDefault: editRecord.IsDefault ? editRecord.IsDefault : 1, 
                }
            }
            > 
                <Form.Item rules={[{required: true, message: '请选择检测大类!'}]}
                    value={editRecord.CategoryName} label="检测大类" name="CategoryName"
                    >
                    <Row justify="start">
                        
                        <Col span={14}>
                        <Input placeholder="请点击右边按钮选择检测大类" value={editRecord.CategoryName} disabled/>
                        </Col>
                        <Col push={2} span={6}>
                        <Button type="primary" icon={<CheckOutlined />} onClick={this.selectCategoryButtonOnClickHd} >选择检测大类</Button>
                        </Col>
                        
                    </Row>    
                </Form.Item>

                <Form.Item 
                    name="IsDefault"
                    label="默认选中" 
                    >
                    <Select value={editRecord ? editRecord.IsDefault : undefined} 
                        defaultValue={1}>
                        <Select.Option value={1}>是</Select.Option>
                        <Select.Option value={0}>否</Select.Option> 
                    </Select>
                </Form.Item>
                    
                <Row justify="end">
                    <Space size="middle" >
                    <Button type="default" onClick={() => this.modalCancelHd()}>取消</Button>
                    <Button type="primary" onClick={undefined} htmlType="submit">保存</Button>
                    </Space>
                </Row>
            </Form>
        )
        
    
        return (
            <Modal 
                title="检测大类配置"
                visible={showEdit}
                onCancel={this.modalCancelHd}
                okText="保存" cancelText="取消"
                footer={null}
                width={560}
                >
                {content}    
            </Modal>
        )
    }

    categorySelectedHd(key, record) {
        let {editRecord} = this.state

        editRecord.CategoryName = record.Name
        editRecord.TestCategoryId = record.Id

        let newState = Object.assign({}, this.state)
        newState.showCategorySelect = false
        newState.editRecord = editRecord
        this.setState(newState)
    }

    selectModalCancelHd() {
        let newState = Object.assign({}, this.state)
        newState.showCategorySelect = false
        this.setState(newState)
    }

    selectCategoryButtonOnClickHd() {
        let newState = Object.assign({}, this.state)
        newState.showCategorySelect = true
        this.setState(newState)
    }

    mkSelectCategoryModal() {
        let {showCategorySelect} = this.state
        return (
            <Modal 
            title=""
            visible={showCategorySelect}
            width={1280}
            footer={null}
            onOk={undefined}
            onCancel={this.selectModalCancelHd}
            >
                <CertTestCategoryListCT 
                selectFlag={true}
                selectedHd={this.categorySelectedHd}
                />

            </Modal>
        )
    }



    render() {
        // let {detailInfo, loading} = this.props     
        let titleContent = this.mkTitleContent()
        let content = this.mkContent()
        let editModal = this.mkEditModal()
        let selectModal = this.mkSelectCategoryModal()
        
        return (
            <div>
                {editModal}
                <HoBreadcrumb itemList={this._breadConf} extra={{style:{marginBottom: '8px'}}} />
                <Card title="认证信息详情" extra={undefined}>
                    {titleContent}
                    {selectModal}
    
                    <Divider />
                    <Descriptions title="检测大类列表" 
                    extra={<Button type="primary" icon={<PlusOutlined />} onClick={this.addOnclickHd}>添加</Button>}>
                    </Descriptions>

                    {content}
                </Card> 
            </div>
        );
    }

}

